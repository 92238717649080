import React from "react";

interface Props {
  active: boolean;
  activeColor?: string;
}

export const RadioButton: React.FC<Props> = ({
  active,
  activeColor = "#026284"
}) => {
  return (
    <div
      style={{
        borderRadius: "50%",
        border: `1px solid ${active ? activeColor : "#CCCCCC"}`,
        width: 18,
        height: 18,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {active && (
        <div
          style={{
            borderRadius: "50%",
            backgroundColor: activeColor,
            width: 12,
            height: 12
          }}
        />
      )}
    </div>
  );
};
