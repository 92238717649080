// import { BoldSubhead } from "../../../ui/BoldSubhead";
import { useGroceryList } from "@saffron/controllers";
import React, { useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import { BoldSubhead } from "../../../ui/BoldSubhead";
import { PrintableGroceryHeader } from "./PrintableGroceryHeader";
import { PrintableGroceryItem } from "./PrintableGroceryItem";

interface PrintableGroceryListProps {}

// this is for firefox
// so more than 1 page actually prints
const BlockEverything = createGlobalStyle`
@media print {
    html, body * {
      display: block !important;
      height: auto;
    }
    script {
      display: none !important;
    }
    html, body {
      background-color: #fff !important;
    }
  }
  `;

export const PrintableGroceryList: React.FC<PrintableGroceryListProps> = ({}) => {
  const { recyclerListItems } = useGroceryList({
    openGroups: true,
    fetchPolicy: "cache-first"
  });

  useEffect(() => {
    document.title = "Saffron Grocery List";
  }, []);

  return (
    <div style={{ color: "#000", padding: 30 }}>
      <BlockEverything />
      <BoldSubhead
        style={{ fontSize: "1.5rem", marginBottom: 20, color: "#000" }}
      >
        Grocery List
      </BoldSubhead>
      <div style={{ columnCount: 3, columnGap: 20 }}>
        {recyclerListItems.map((item, i) => {
          if (!i) {
            return null;
          }

          if ("title" in item) {
            return (
              <PrintableGroceryHeader
                key={item.title}
                title={item.title}
                noTop={i === 1}
              />
            );
          }

          if ("groupKeyword" in item) {
            return null;
          }

          return <PrintableGroceryItem item={item} key={item.id} />;
        })}
      </div>
    </div>
  );
};

export class PrintableGroceryListClass extends React.Component<{
  show: boolean;
}> {
  render() {
    return this.props.show ? <PrintableGroceryList /> : <div />;
  }
}
