import { parseIngredient } from "@saffron/common";

const linkRegex = /\[([^\]]+)\]\(([^\]]+)\)/;

export const plainTextToIngredients = (s: string) =>
  s.split("\n").map(line => {
    if (line.endsWith(":")) {
      return {
        text: line.slice(0, line.length - 1)
      };
    }

    const match = linkRegex.exec(line);

    if (match) {
      return {
        ...parseIngredient(match[1]),
        recipeId: match[2]
      };
    }

    return parseIngredient(line);
  });
