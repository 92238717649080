import  React from "react";

interface Props {
  onComponentDidMount: () => any;
}

export class Lifecycle extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.onComponentDidMount();
  }

  render() {
    return null;
  }
}
