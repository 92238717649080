import { CookbooksQuery } from "@saffron/controllers";
import * as React from "react";
import Icon from "../../../ui/Icon";
import { Square } from "../../../ui/Select";
import { IdStuff } from "./types";

interface Props extends IdStuff {
  cookbookListShowing: boolean;
  setCookbookListShowing: (x: boolean) => void;
  currentCookbook: CookbooksQuery["cookbooks"][0] | undefined;
  isCookbookOwner: boolean;
}

const padToAlign = 22.4 - 12.4;

export const CookbookSquare: React.FC<Props> = ({
  cookbookListShowing,
  setCookbookListShowing,
  currentCookbook
}) => {
  let text = currentCookbook ? currentCookbook.name : "Cookbooks";

  return (
    <Square
      style={{ paddingLeft: 19.6, paddingRight: padToAlign }}
      variant="only-border-bottom"
      open={cookbookListShowing}
      onClick={() => setCookbookListShowing(!cookbookListShowing)}
    >
      <div style={{ flex: 1, display: "flex" }}>
        <div style={{ marginRight: 6 }}>
          {cookbookListShowing ? "Cookbooks" : text}
        </div>
        <Icon
          name={cookbookListShowing ? "thinUpArrow" : "thinDownArrow"}
          width=".75em"
          height=".75em"
          fill={cookbookListShowing ? "#fff" : "#999"}
        />
      </div>
    </Square>
  );
};
