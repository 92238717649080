import { MeQuery } from "@saffron/controllers";

export const notUserRoleType = (
  data: MeQuery | undefined,
  loading: boolean,
  type: string
) =>
  !data ||
  loading ||
  !data.me ||
  !data.me.user ||
  data.me.user.role.type !== type;
