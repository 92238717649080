import React from "react";
import Icon from "../../ui/Icon";
import { theme } from "../../ui/theme";
import styled from "styled-components";
import { ErrorText } from "../../ui/ErrorText";

interface Props {
  onX?: () => void;
  setValue: (s: string) => void;
  value: string | number;
  tmpAmount: string | null;
}

const options = [
  {
    label: ".5×",
    value: "0.5"
  },
  {
    label: "1×",
    value: "1"
  },
  {
    label: "1.5×",
    value: "1.5"
  },
  {
    label: "2×",
    value: "2"
  },
  {
    label: "3×",
    value: "3"
  }
];

const OptionButton = styled.div({
  fontSize: "1.125rem",
  cursor: "pointer",
  fontFamily: theme.fonts.ui,
  padding: 15,
  color: "#444",
  ":hover": {
    backgroundColor: "#f1f3f4"
  }
});

export const ScaleBox: React.FC<Props> = ({
  onX,
  setValue,
  value,
  tmpAmount
}) => {
  if (typeof value === "number") {
  }
  return (
    <div
      style={{
        backgroundColor: "#DDE2E3",
        padding: 18,
        paddingBottom: 10,
        position: "relative"
      }}
    >
      {onX && (
        <Icon
          style={{ position: "absolute", top: 8, right: 8 }}
          onClick={onX}
          width="2em"
          height="2em"
          fill="#999"
          name="x"
        />
      )}
      <div
        style={{
          paddingLeft: 10,
          fontFamily: theme.fonts.ui,
          color: "#444",
          fontSize: "1.125rem",
          fontWeight: 600
        }}
      >
        Scale by:
      </div>
      <div style={{ display: "flex", paddingTop: 8 }}>
        <div
          style={{ flex: 1, display: "flex", justifyContent: "space-between" }}
        >
          {options.map(x => {
            return (
              <OptionButton
                style={{
                  height: 40,
                  width: 40,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: value == x.value ? "#C7CFD1" : undefined,
                  borderRadius: "50%"
                }}
                key={x.label}
                onClick={() => setValue(x.value)}
              >
                {x.label}
              </OptionButton>
            );
          })}
        </div>
        <div
          style={{
            paddingRight: 15,
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            marginTop: -9,
            paddingLeft: 15
          }}
        >
          <div
            style={{
              fontFamily: theme.fonts.ui,
              color: "#444",
              fontSize: 12, // no rems
              textAlign: "center"
            }}
          >
            custom
          </div>
          <input
            value={value}
            onChange={e => setValue(e.target.value)}
            style={{
              paddingLeft: 8,
              paddingRight: 8,
              border: "none",
              width: 44,
              fontFamily: theme.fonts.ui,
              fontWeight: 600,
              fontSize: "1.125rem",
              color: "#444",
              outline: "none"
            }}
          />
          {tmpAmount && isNaN(parseFloat(tmpAmount)) ? (
            <ErrorText>invalid value</ErrorText>
          ) : null}
        </div>
      </div>
    </div>
  );
};
