import { sortBy } from "@saffron/common";
import {
  CookbookAccessLevel,
  JustSectionFragment,
  useCookbooksQuery,
  useSectionsByCookbookIdQuery
} from "@saffron/controllers";
import { Field } from "formik";
import React from "react";
import { FormField } from "../../../../ui/Forms/FormField";
import { SmallLabel } from "../../../../ui/Label";
import { SelectVariants } from "../../../../ui/Select";
import { SelectFormInput } from "../../../shared/formFields/SelectFormInput";

interface Props {
  cookbookId: string | null | undefined;
  label?: string;
  modalView?: boolean;
  includeShared?: boolean;
  variant?: SelectVariants;
}

export const SectionSelect = ({
  cookbookId,
  label,
  modalView,
  includeShared = false,
  variant
}: Props) => {
  const { data, loading } = useCookbooksQuery();
  const { data: sectionData } = useSectionsByCookbookIdQuery({
    skip: !cookbookId,
    variables: {
      cookbookId: cookbookId || ""
    }
  });

  if (!data || loading || !data.cookbooks || !data.cookbooks.length) {
    return null;
  }

  let sections: JustSectionFragment[] = [];

  if (sectionData && sectionData.sectionsByCookbookId) {
    sections = sortBy(sectionData.sectionsByCookbookId, "position");
  }

  return (
    <div>
      {modalView ? null : (
        <SmallLabel mb=".75em">{label || "Add recipe to cookbook"}</SmallLabel>
      )}
      <FormField
        style={{
          marginBottom: variant === "only-border-bottom" ? 0 : undefined
        }}
      >
        <Field
          name="cookbookId"
          component={SelectFormInput}
          placeholder="Cookbook"
          options={data.cookbooks
            .filter(
              x => includeShared || x.accessLevel === CookbookAccessLevel.Owner
            )
            .map(cb => ({
              key: cb.id,
              value: cb.id,
              text: cb.name
            }))}
          variant={variant}
        />
      </FormField>
      {cookbookId && (
        <Field
          name="sectionId"
          component={SelectFormInput}
          placeholder="Section"
          options={sections.map(s => ({
            key: s.id,
            value: s.id,
            text: s.name
          }))}
          variant={variant}
        />
      )}
    </div>
  );
};
