import {
  formatRecipeFormValues,
  normalizeErrors,
  oldSlateToNew,
  slugify,
  toFormIngredients
} from "@saffron/common";
import {
  useGetRecipeByIdQuery,
  useUpdateRecipeMutation
} from "@saffron/controllers";
import React, { useRef, useState } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { LoadingLayout } from "../../../ui/Layout/LoadingLayout";
import { isUuid } from "../../../utils/isUuid";
import { useConfirmLeave } from "../../../utils/useConfirmLeave";
import NotFound from "../../misc/error/NotFound";
import { RecipeForm } from "../shared/RecipeForm";

const EditRecipeConnector: React.FC<RouteComponentProps<{
  id: string;
  slug: string;
}>> = ({
  match: {
    params: { id, slug }
  },
  history
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const requestStarted = useRef(false);
  const [updateRecipe] = useUpdateRecipeMutation();
  const isInvalid = !isUuid(id);
  const { data, loading } = useGetRecipeByIdQuery({
    variables: { id },
    skip: isInvalid
  });

  useConfirmLeave();

  if (isInvalid) {
    return <NotFound />;
  }

  if (loading) {
    return <LoadingLayout />;
  }

  if (!data) {
    return null;
  }

  const { getRecipeById } = data;

  if (getRecipeById) {
    const correctSlug = slugify(getRecipeById.name);
    if (slug !== correctSlug) {
      return (
        <Redirect to={`/recipe/edit/${getRecipeById.id}/${correctSlug}`} />
      );
    }
  } else {
    return <NotFound />;
  }

  let rawInstructions = JSON.parse(getRecipeById.instructions);

  if (!Array.isArray(rawInstructions)) {
    rawInstructions = oldSlateToNew(rawInstructions);
  }

  return (
    <RecipeForm
      loading={submitLoading}
      headerText="Edit Recipe"
      initialValues={{
        name: getRecipeById.name,
        description: getRecipeById.description,
        croppedBase64Img: getRecipeById.pictureUrl,
        servings: getRecipeById.servings,
        source: getRecipeById.source,
        sourceUrl: getRecipeById.sourceUrl,
        ingredients: toFormIngredients(getRecipeById.ingredients),
        instructions: rawInstructions,
        times: (getRecipeById.times || []).map(t => ({
          type: t.type,
          hr: `${Math.floor(t.value / 60)}`,
          min: `${t.value % 60}`
        })),
        youtubeVideoId: getRecipeById.youtubeVideoId || undefined,
        cookbookId: null,
        sectionId: null
      }}
      submit={async formValues => {
        if (requestStarted.current) {
          return;
        }
        requestStarted.current = true;
        setSubmitLoading(true);

        let picture: Blob | null = null;
        if (
          formValues.croppedBase64Img &&
          !formValues.croppedBase64Img.startsWith("http")
        ) {
          const res = await fetch(formValues.croppedBase64Img);
          picture = await res.blob();
        }

        let response;

        try {
          response = await updateRecipe({
            variables: {
              id,
              recipe: {
                ...formatRecipeFormValues(formValues),
                picture
              }
            }
          });
        } catch {}

        if (!response || !response.data) {
          requestStarted.current = false;
          setSubmitLoading(false);
          return null;
        }

        const {
          data: {
            updateRecipe: { errors }
          }
        } = response;

        if (errors) {
          requestStarted.current = false;
          setSubmitLoading(false);
          return normalizeErrors(errors);
        }

        history.goBack();
        requestStarted.current = false;
        return null;
      }}
      showCookbookSelect={false}
    />
  );
};

export default EditRecipeConnector;
