import { slugify } from "@saffron/common";
import {
  deleteMenuItemsMutationOptions,
  useDeleteMenuItemsMutation,
  MenuPlannerQueryVariables,
  StandardMenuItemFragment
} from "@saffron/controllers";
import React from "react";
import { Flex } from "../../../../ui/Flex";
import { H4 } from "../../../../ui/Heading";
import { MyLink } from "../../../../ui/MyLink";
import { Dayjs } from "dayjs";
import { Modal } from "../../../../ui/Modal";
import Icon from "../../../../ui/Icon";
import { ScalingBadge } from "../../../../ui/Recipe/ScalingBadge";
import { NoImageSvgData } from "../../../../ui/NoImageSvgData";

interface Props {
  closeModal: () => void;
  menuItem?: StandardMenuItemFragment;
  date: Dayjs;
  menuPlannerQueryVariables: MenuPlannerQueryVariables;
  onScaleClick: () => void;
}

export const RecipeModal: React.FC<Props> = ({
  closeModal,
  menuItem,
  menuPlannerQueryVariables,
  onScaleClick
}) => {
  const [deleteMenuItems, { loading }] = useDeleteMenuItemsMutation();
  const to = menuItem
    ? `/recipe/${menuItem.recipe.id}/${slugify(menuItem.recipe.name)}${
        menuItem.scale !== 1 ? `?scale=${menuItem.scale}` : ""
      }`
    : "";
  return (
    <Modal isOpen={!!menuItem} onRequestClose={closeModal}>
      {menuItem && (
        <>
          <div style={{ marginTop: "1em" }}>
            <MyLink to={to}>
              {menuItem.recipe.pictureUrl ? (
                <img
                  style={{
                    width: "100%",
                    maxWidth: 400,
                    height: 200,
                    objectFit: "cover"
                  }}
                  src={menuItem.recipe.pictureUrl}
                  alt={menuItem.recipe.name}
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    maxWidth: 400,
                    height: 200,
                    marginBottom: 6, // img has 6px space below somehow, so match that
                    backgroundImage: `url(${NoImageSvgData})`,
                    backgroundColor: "#c5bfa8"
                  }}
                />
              )}
            </MyLink>
          </div>
          <Flex
            style={{ justifyContent: "center", alignItems: "center" }}
            mt="10"
          >
            <H4
              m="0"
              style={{ fontFamily: "#444", fontWeight: 600 }}
              fontFamily="ui"
            >
              <MyLink to={to}>{menuItem.recipe.name}</MyLink>
            </H4>
            {menuItem.scale !== 1 && (
              <div style={{ marginLeft: 4 }}>
                <ScalingBadge
                  noAbsolutePos
                  amount={menuItem.scale}
                  onClick={onScaleClick}
                />
              </div>
            )}
          </Flex>
          <Flex
            mt="2em"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            {menuItem.id !== "-1" && (
              <>
                <Icon
                  style={{ marginRight: 30 }}
                  tooltipText="remove"
                  name="circleMinus"
                  fill="#666"
                  template="recipe"
                  onClick={async () => {
                    if (loading) {
                      return;
                    }
                    await deleteMenuItems(
                      deleteMenuItemsMutationOptions(
                        {
                          ids: [menuItem.id]
                        },
                        menuPlannerQueryVariables
                      )
                    );
                    closeModal();
                  }}
                />
                <Icon
                  name="ruler"
                  tooltipText="scale"
                  fill="#666"
                  template="recipe"
                  onClick={onScaleClick}
                />
              </>
            )}
          </Flex>
        </>
      )}
    </Modal>
  );
};
