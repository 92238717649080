import React, { useState, useRef } from "react";
import {
  useCreateGroceryItemsAndLinksMutation,
  createGroceryItemsAndLinksMutationOptions
} from "@saffron/controllers";
import { theme } from "../../../ui/theme";
import styled from "styled-components";
import { notificationState } from "../../misc/notifications/NotificationState";

interface AddGroceryItemInputProps {}

const Input = styled.input({
  "::placeholder": {
    color: "#999"
  }
});

export const AddGroceryItemInput: React.FC<AddGroceryItemInputProps> = ({}) => {
  const [text, setText] = useState("");
  const [
    createGroceryItemsAndLinks,
    { loading }
  ] = useCreateGroceryItemsAndLinksMutation();
  const inputRef = useRef<any>(undefined);

  return (
    <form
      onSubmit={async e => {
        e.preventDefault();
        if (!text || loading) {
          return;
        }
        await createGroceryItemsAndLinks(
          createGroceryItemsAndLinksMutationOptions({
            items: [{ text }],
            noName: true
          })
        );
        notificationState.send({ text: "item added", variant: "success" });
        setText("");
        inputRef.current?.focus();
      }}
    >
      <Input
        ref={inputRef}
        disabled={loading}
        style={{
          width: "100%",
          fontFamily: theme.fonts.ui,
          background: "#F2F4F5",
          border: "1px solid #E6E6E6",
          borderRadius: "38px",
          outline: "none",
          fontSize: "1.25rem",
          color: "#444",
          padding: "5px 20px"
        }}
        placeholder="Add new item"
        value={text}
        onChange={e => setText(e.target.value)}
      />
    </form>
  );
};
