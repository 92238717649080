import { sortBy } from "@saffron/common";
import {
  CookbooksQuery,
  JustSectionFragment,
  RecipeNameFragment,
  useCookbooksQuery,
  useRecipesByCookbookAndSectionIdQuery,
  useSectionsByCookbookIdQuery,
  useRecipeListSortBy,
  sortRecipesByField
} from "@saffron/controllers";
import { NetworkStatus } from "@apollo/client";
import React, { useEffect } from "react";
import { ScrollableBox } from "../../../ui/Box";
import { Select } from "../../../ui/Select";
import { theme } from "../../../ui/theme";
import { useLocalStorage } from "../../../utils/useLocalStorage";
import { LS_COOKBOOK_ORDER } from "../../cookbook/BrowseCookbook/CookbookSidebar";
import { DraggableRecipeCard } from "./DraggableRecipeCard";
import { SortRecipesBySelect } from "../../cookbook/BrowseCookbook/SortRecipesBySelect";

interface Props {}

const MP_COOKBOOK_STATE_LOCAL_STORAGE_KEY = "menu-planner-cookbook-state";

export const MenuPlannerCookbooks: React.FC<Props> = () => {
  const { fieldToSortBy } = useRecipeListSortBy();
  const [ids, setIds] = React.useState<{
    currentSectionId?: string;
    currentCookbookId?: string;
  }>(() => {
    const sState = localStorage.getItem(MP_COOKBOOK_STATE_LOCAL_STORAGE_KEY);

    if (!sState) {
      return {};
    }

    try {
      const state = JSON.parse(sState);
      if (typeof state === "string") {
        return {};
      }
      return state;
    } catch {
      return {};
    }
  });

  const [cookbookOrder, setCookbookOrder] = useLocalStorage<
    Record<string, number>
  >(LS_COOKBOOK_ORDER, {});

  useEffect(() => {
    try {
      localStorage.setItem(
        MP_COOKBOOK_STATE_LOCAL_STORAGE_KEY,
        JSON.stringify(ids)
      );
      return;
    } catch {}
    try {
      localStorage.setItem(
        MP_COOKBOOK_STATE_LOCAL_STORAGE_KEY,
        JSON.stringify({})
      );
    } catch {}
  }, [ids]);

  const { data: cbData, loading: cbLoading } = useCookbooksQuery();
  const {
    data: sectionData,
    networkStatus: sectionNetworkStatus
  } = useSectionsByCookbookIdQuery({
    variables: { cookbookId: ids.currentCookbookId! },
    skip: !ids.currentCookbookId,
    notifyOnNetworkStatusChange: true
  });
  const {
    data: recipesData,
    loading: recipeLoading,
    networkStatus: recipeNetworkStatus
  } = useRecipesByCookbookAndSectionIdQuery({
    variables: {
      sectionId: ids.currentSectionId!
    },
    skip: !ids.currentCookbookId || !ids.currentSectionId,
    notifyOnNetworkStatusChange: true
  });
  let cookbooks: CookbooksQuery["cookbooks"] = [];

  if (cbData && cbData.cookbooks) {
    cookbooks = sortBy(cbData.cookbooks, x => cookbookOrder[x.id] ?? -1);
  }

  let sections: JustSectionFragment[] = [];

  if (sectionData && sectionData.sectionsByCookbookId) {
    sections = sortBy(sectionData.sectionsByCookbookId, "position");
  }

  if (sectionNetworkStatus === NetworkStatus.setVariables) {
    sections = [];
  }

  let recipes: RecipeNameFragment[] = [];

  if (recipesData && recipesData.recipesByCookbookAndSectionId) {
    recipes = sortRecipesByField(
      recipesData.recipesByCookbookAndSectionId,
      fieldToSortBy
    );
  }

  const firstCookbookId = cookbooks[0] ? cookbooks[0].id : "";
  const firstSectionId = sections[0] ? sections[0].id : "";

  useEffect(() => {
    if (!ids.currentCookbookId && firstCookbookId && firstCookbookId !== "-1") {
      setIds({
        currentCookbookId: firstCookbookId
      });
    }

    if (!ids.currentSectionId && firstSectionId && firstSectionId !== "-1") {
      setIds({
        ...ids,
        currentSectionId: firstSectionId
      });
    }
  }, [ids, firstCookbookId, firstSectionId, setIds]);

  const recipesLoading =
    recipeNetworkStatus === NetworkStatus.setVariables ||
    recipeNetworkStatus === NetworkStatus.loading;
  const sectionsLoading =
    sectionNetworkStatus === NetworkStatus.setVariables ||
    sectionNetworkStatus === NetworkStatus.loading;

  const anyLoading = cbLoading || sectionsLoading || recipesLoading;
  const isCurrentSectionInCurrentCookbook = !!sections.find(
    x => x.id === ids.currentSectionId
  );
  const isCurrentCookbookInCookbooks = !!cookbooks.find(
    x => x.id === ids.currentCookbookId
  );
  useEffect(() => {
    if (anyLoading) {
      return;
    }

    if (!isCurrentCookbookInCookbooks && ids.currentCookbookId) {
      setIds({
        currentCookbookId: undefined
      });
    }

    if (!isCurrentSectionInCurrentCookbook && ids.currentSectionId) {
      setIds({
        ...ids,
        currentSectionId:
          firstSectionId === "-1" || !firstSectionId
            ? undefined
            : firstSectionId
      });
    }
  }, [
    isCurrentCookbookInCookbooks,
    ids,
    setIds,
    isCurrentSectionInCurrentCookbook,
    anyLoading,
    firstSectionId
  ]);

  return (
    <>
      <div style={{ marginTop: "12px", marginBottom: "1em" }}>
        <Select
          style={{ paddingLeft: "1em", paddingRight: "1em" }}
          variant="no-border"
          placeholder="Cookbook"
          options={cookbooks.map(cb => ({
            key: cb.id,
            value: cb.id,
            text: cb.name
          }))}
          value={ids.currentCookbookId}
          onChange={newValue => {
            const newCookbookOrder: Record<string, number> = {};
            cookbooks.forEach((c, i) => {
              if (c.id === newValue) {
                newCookbookOrder[c.id] = 0;
              } else {
                newCookbookOrder[c.id] = i + 1;
              }
            });
            setCookbookOrder(newCookbookOrder);
            setIds({ currentCookbookId: newValue });
          }}
        />
        {ids.currentCookbookId && (
          <>
            <Select
              style={{ paddingLeft: "1em", paddingRight: "1em" }}
              placeholder="Section"
              options={sections.map(s => ({
                key: s.id,
                value: s.id,
                text: s.name
              }))}
              variant="no-border"
              value={ids.currentSectionId}
              onChange={newValue =>
                setIds({ ...ids, currentSectionId: newValue })
              }
            />
            <SortRecipesBySelect isMenuPlannerSidebar />
          </>
        )}
      </div>
      <ScrollableBox flex={1}>
        <div style={{ padding: "47px 16px", paddingTop: 0 }}>
          {!recipeLoading && !recipes.length && ids.currentSectionId && (
            <div
              style={{
                paddingLeft: 6.4,
                paddingRight: 6.4,
                fontFamily: theme.fonts.ui,
                fontSize: "0.875rem",
                color: "#999",
                paddingTop: "1em"
              }}
            >
              No recipes in this section yet.
            </div>
          )}
          {recipes.map(r => (
            <DraggableRecipeCard key={r.id} r={r} />
          ))}
        </div>
      </ScrollableBox>
    </>
  );
};
