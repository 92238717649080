import {
  ingredientToParts,
  ingredientToString,
  normalizeErrors,
  scaleIngredient
} from "@saffron/common";
import {
  CreateGroceryItemsAndLinksMutation,
  createGroceryItemsAndLinksMutationOptions,
  GetRecipeByIdQuery,
  getRecipeByIdQuery,
  GetRecipeByIdQueryVariables,
  RecipePreviewFragment,
  RegularIngredient,
  useCreateGroceryItemsAndLinksMutation
} from "@saffron/controllers";
import { FetchResult } from "@apollo/client";
import React, { useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { apolloClient } from "../../../../apollo";
import { Box } from "../../../../ui/Box";
import { ReverseOutlineButton } from "../../../../ui/Button";
import { H3 } from "../../../../ui/Heading";
import Icon from "../../../../ui/Icon";
import { theme } from "../../../../ui/theme";
import { notificationState } from "../../../misc/notifications/NotificationState";
import { genId } from "../../../misc/notifications/utils/genId";
import { ScaleBox } from "../../ScaleBox";

const IngredientsContainer = styled("div")({
  overflowY: "auto",
  maxHeight: "400px",
  paddingBottom: "0.5em",
  paddingRight: 33,
  paddingLeft: 33
});

interface Props {
  open: boolean;
  toggleModal: () => void;
  recipeName: string;
  recipeId: string;
  ingredients: RecipePreviewFragment["ingredients"];
}

export const AddGroceryItemsModal: React.FC<Props> = ({
  ingredients: initialIngredients,
  toggleModal,
  open,
  recipeId
}) => {
  const [ingredients, setIngredients] = useState(() =>
    initialIngredients
      .filter(x => x.__typename !== "IngredientHeader")
      .map(x => ({
        ...x,
        id: genId()
      }))
  );
  const [removed, setRemoved] = useState<Record<string, boolean>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [scaleValue, setScaleValue] = useState("1");
  const [createGroceryItemsAndLinks] = useCreateGroceryItemsAndLinksMutation();

  const toggleIngredient = (id: string) => {
    setRemoved({
      ...removed,
      [id]: !removed[id]
    });
  };

  const fScale = parseFloat(scaleValue);
  const multiplier = isNaN(fScale) ? 1 : fScale;

  return (
    <Modal
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          boxShadow: "3px 6px 6px rgba(0, 0, 0, 0.16)",
          maxHeight: "88vh",
          maxWidth: "88vw",
          padding: 0,
          border: "1px solid #e6e6e6",
          position: "relative",
          width: 400
        }
      }}
      ariaHideApp={false}
      isOpen={open}
      onRequestClose={toggleModal}
    >
      <Icon
        style={{ position: "absolute", top: 8, right: 8 }}
        onClick={() => toggleModal()}
        width="2em"
        height="2em"
        fill="#ccc"
        name="x"
      />
      <H3
        style={{ fontSize: "1.375rem" }}
        mt={33}
        mb={12}
        px={33}
        fontFamily="primary"
        fontStyle="italic"
      >
        Click ingredient to remove
      </H3>
      <IngredientsContainer>
        {ingredients.map((ig, index) => {
          const isRemoved = removed[ig.id];
          const isLink = ig.__typename === "RecipeLink";
          const scaledIg = scaleIngredient(ig, multiplier) as RegularIngredient;
          const { amountAndUnit, rest } = ingredientToParts(scaledIg);

          const textStyles = isRemoved
            ? {
                textDecoration: "line-through",
                color: "#b3b3b3"
              }
            : {};

          return (
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                padding: "10px 0",
                paddingRight: ".25em",
                gridRowGap: "1em"
              }}
              onClick={() => toggleIngredient(ig.id)}
              key={ig.id}
            >
              <>
                <div
                  style={{
                    wordBreak: "break-word",
                    ...textStyles,
                    flex: 1
                  }}
                >
                  <span
                    style={{
                      fontWeight: isRemoved ? 400 : 800,
                      color: isRemoved ? undefined : "#333"
                    }}
                  >
                    {amountAndUnit}
                  </span>
                  {rest}
                </div>
                {isLink && !isRemoved && (
                  <div>
                    <Icon
                      onClick={async e => {
                        e.stopPropagation();
                        const y = window.confirm(
                          "Would you like to add the ingredients from this recipe?"
                        );
                        if (!y) {
                          return;
                        }
                        const response = await apolloClient.query<
                          GetRecipeByIdQuery,
                          GetRecipeByIdQueryVariables
                        >({
                          query: getRecipeByIdQuery,
                          variables: { id: (ig as any).recipeId }
                        });

                        if (response.data?.getRecipeById) {
                          setIngredients([
                            ...ingredients.slice(0, index),
                            ...response.data
                              .getRecipeById!.ingredients.filter(
                                x => x.__typename !== "IngredientHeader"
                              )
                              .map(x => ({ ...x, id: genId() })),
                            ...ingredients.slice(index + 1)
                          ]);
                        } else {
                          notificationState.send({
                            variant: "error",
                            text: "recipe no longer exists"
                          });
                        }
                      }}
                      name="squarePlus"
                      fill={theme.colors.lightBlue}
                    />
                  </div>
                )}
              </>
            </div>
          );
        })}
      </IngredientsContainer>
      {errors.limit && (
        <Box color="brightRed" pr="30px">
          {errors.limit}
        </Box>
      )}
      <Box width="100%" p="16px 33px">
        <ReverseOutlineButton
          style={{ width: "100%" }}
          onClick={async () => {
            let response: void | FetchResult<
              CreateGroceryItemsAndLinksMutation,
              Record<string, any>
            >;
            try {
              response = await createGroceryItemsAndLinks(
                createGroceryItemsAndLinksMutationOptions({
                  items: ingredients
                    .filter(x => !removed[x.id])
                    .map(x =>
                      multiplier && multiplier !== 1
                        ? scaleIngredient(x, multiplier)
                        : x
                    )
                    .map(({ __typename, id: _, ...x }) => {
                      return {
                        parsed:
                          "sid" in x
                            ? {
                                sid: x.sid,
                                name: x.name,
                                unit: x.unit,
                                amount: x.amount,
                                editedByUser: x.editedByUser,
                                keyword: x.keyword
                              }
                            : null,
                        text: ingredientToString(x),
                        recipeLinkId: "recipeId" in x ? x.recipeId : null
                      };
                    }),
                  recipeId
                })
              );
            } catch {
              return toggleModal();
            }

            if (
              response &&
              response.data &&
              response.data.createGroceryItemsAndLinks.errors
            ) {
              setErrors(
                normalizeErrors(response.data.createGroceryItemsAndLinks.errors)
              );
            } else {
              notificationState.send({
                text: "Ingredients added to your grocery list.",
                variant: "success",
                icon: "groceryCart"
              });
              toggleModal();
            }
          }}
        >
          ADD to GROCERY LIST
        </ReverseOutlineButton>
      </Box>
      <ScaleBox
        tmpAmount={scaleValue}
        value={scaleValue}
        setValue={v => setScaleValue(v)}
      />
    </Modal>
  );
};
