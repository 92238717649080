import React, { createRef } from "react";
import { Box } from "../../../ui/Box";
import { CopyInput } from "../../../ui/CopyInput";
import { Flex } from "../../../ui/Flex";
import { H4 } from "../../../ui/Heading";
import { Modal } from "../../../ui/Modal";
import { theme } from "../../../ui/theme";
import { notificationState } from "../../misc/notifications/NotificationState";
import { UsersSharedWith } from "./UsersSharedWith";

interface Props {
  open: boolean;
  toggleModal: () => void;
  showUrl: boolean;
  url: string;
  title: string;
  description: string;
  buttonText: string;
  onButtonClick: () => void;
  embedCode?: string;
  cookbookId?: string;
  loading: boolean;
}

export class PrivacyModal extends React.PureComponent<Props> {
  inputRef: HTMLInputElement | undefined;
  embedRef = createRef<HTMLInputElement>();

  onFocus = () => {
    if (this.inputRef) {
      this.inputRef.select();
    }
  };

  copy = () => {
    if (this.inputRef) {
      this.inputRef.select();
      const success = document.execCommand("copy");
      if (success) {
        notificationState.send({
          text: "successfully copied",
          variant: "success",
          icon: "clipboard"
        });
      }
    }
  };

  copyEmbed = () => {
    if (this.embedRef.current) {
      this.embedRef.current.select();
      const success = document.execCommand("copy");
      if (success) {
        notificationState.send({
          text: "successfully copied",
          variant: "success",
          icon: "clipboard"
        });
      }
    }
  };

  render() {
    const {
      open,
      toggleModal,
      showUrl,
      description,
      title,
      url,
      buttonText,
      onButtonClick,
      embedCode,
      cookbookId,
      loading
    } = this.props;
    return (
      <Modal
        height={cookbookId && showUrl ? 400 : undefined}
        isOpen={open}
        onRequestClose={toggleModal}
        title={title}
        loading={loading}
        mainButtonClick={onButtonClick}
        mainButtonText={buttonText}
      >
        <Box fontFamily="ui" fontSize={"0.875rem"}>
          {description}
        </Box>
        <div
          style={{
            paddingTop: "1em"
          }}
        >
          <Flex
            fontFamily="ui"
            color="otherGray"
            fontSize={"0.875rem"}
            mt="auto"
            mb="auto"
            alignItems="center"
            width="100%"
          >
            {showUrl ? (
              <CopyInput autoFocus text={url} buttonText="COPY LINK" />
            ) : (
              ""
            )}
          </Flex>
          {embedCode && showUrl ? (
            <>
              <H4
                m={0}
                mt="1.25em"
                mb={10}
                fontWeight={600}
                color="four"
                fontFamily="ui"
              >
                Embed Recipe
              </H4>
              <Box fontFamily="ui" fontSize={"0.875rem"}>
                Add this recipe to your website by copying the code below.
              </Box>
              <a
                style={{
                  color: theme.colors.blue,
                  fontSize: "0.875rem"
                }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://youtu.be/vxqrZ7kcLZ4"
              >
                Learn more
              </a>
              <Flex
                fontFamily="ui"
                color="otherGray"
                fontSize={"0.875rem"}
                mt="1em"
                mb="auto"
                alignItems="center"
                width="100%"
              >
                {showUrl ? (
                  <CopyInput text={embedCode} buttonText="COPY CODE" />
                ) : (
                  ""
                )}
              </Flex>
            </>
          ) : null}
        </div>
        {cookbookId && showUrl ? (
          <UsersSharedWith cookbookId={cookbookId} />
        ) : null}
      </Modal>
    );
  }
}
