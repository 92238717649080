import { MeUserFragment, useFreeOrBetaAccountInfo } from "@saffron/controllers";
import React, { useState } from "react";
import { GoldCard } from "./GoldCard";
import { StartSubscriptionButton } from "./StartSubscriptionButton";
import { YearlyToggle } from "../../../ui/YearlyToggle";

interface Props {
  user: MeUserFragment;
}

export const FreeUser: React.FC<Props> = ({ user }) => {
  const [yearly, setYearly] = useState(true);
  const { title, ratio, bodyCopy, upgradeCost } = useFreeOrBetaAccountInfo(
    user,
    yearly
  );

  return (
    <GoldCard
      style={{ height: 400, maxWidth: 330, width: "100%" }}
      goldText="YOUR PLAN"
      title={title}
    >
      <div style={{ color: "#333" }}>
        <div>
          Recipes: <strong>{ratio}</strong>
        </div>
        <div style={{ marginTop: ".75em" }}>{bodyCopy}</div>
      </div>
      <YearlyToggle yearly={yearly} onSwitch={setYearly} cost={upgradeCost} />
      <StartSubscriptionButton yearly={yearly} user={user}>
        UPGRADE NOW
      </StartSubscriptionButton>
    </GoldCard>
  );
};
