import { LS_NEWS_BANNER } from "../ui/Sidebar";

export const clearLocalStorage = () => {
  try {
    // keep this one in there
    const keysToKeep = [LS_NEWS_BANNER];
    const tmp: Record<string, string> = {};
    keysToKeep.forEach(k => {
      const v = localStorage.getItem(k);
      if (v !== null) {
        tmp[k] = v;
      }
    });
    localStorage.clear();
    Object.keys(tmp).forEach(k => {
      localStorage.setItem(k, tmp[k]);
    });
  } catch {}
};
