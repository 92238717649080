import { formatMinutes, formatUrl } from "@saffron/common";
import { RecipePreviewFragment } from "@saffron/controllers";
import React, { useMemo } from "react";
import { Node } from "slate";
import { PrettyLink } from "../PrettyLink";
import {
  AuthorSection,
  Grid,
  Name,
  RecipeWrapper,
  TimeSection,
  TimeType,
  TimeValue
} from "./components";
import { CrossableIngredients } from "./CrossableIngredients";
import { NotesButton } from "./NotesButton";
import { ShortDescription } from "./ShortDescription";
import { RecipeRating } from "./RecipeRating";
import { HighlightableSteps } from "./HighlightableSteps";

export interface RecipeProps {
  id?: string | null;
  name?: string | null;
  pictureUrl?: string | null;
  ingredients?: RecipePreviewFragment["ingredients"];
  times?: Array<{ type?: string; value: number }> | null;
  servings?: string | null;
  rawInstructions?: Node[];
  instructions?: string;
  source?: string | null;
  sourceUrl?: string | null;
  description?: string | null;
  disableRecipeLinks?: boolean;
  notesOpen?: boolean;
  notes?: string | null;
  rating?: number | null;
  youtubeVideoId?: string | null;
  onNotesClicked?: () => void;
  type: "owner" | "viewer" | "fullscreen" | "in-form";
}

const Recipe = ({
  id,
  name,
  pictureUrl,
  ingredients = [],
  rawInstructions,
  instructions,
  times = [],
  servings,
  source,
  sourceUrl,
  description,
  disableRecipeLinks,
  notes,
  notesOpen,
  onNotesClicked,
  youtubeVideoId,
  type,
  rating
}: RecipeProps) => {
  const sourceText = useMemo(() => {
    if (source) {
      return source;
    }
    if (sourceUrl) {
      try {
        return new URL(sourceUrl).host || "";
      } catch {}
    }

    return "";
  }, [source, sourceUrl]);

  if (instructions) {
    try {
      rawInstructions = JSON.parse(instructions);
    } catch (err) {}
  }

  let visual = null;

  if (youtubeVideoId) {
    visual = (
      <div
        style={{
          position: "relative",
          paddingBottom: "56.25%",
          height: "0",
          marginBottom: "2%"
        }}
      >
        <iframe
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%"
          }}
          title="recipe-vid"
          width="560"
          height="315"
          src={`https://www.youtube-nocookie.com/embed/${youtubeVideoId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    );
  } else if (pictureUrl) {
    visual = (
      <div
        style={{
          width: "100%",
          paddingTop: "50%",
          position: "relative",
          marginBottom: "2%",
          backgroundColor: "#f2f2f2"
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden"
          }}
        >
          <img
            key={pictureUrl}
            src={pictureUrl}
            alt="recipe"
            data-testid="recipe-picture"
          />
        </div>
      </div>
    );
  }

  const formattedUrl = sourceUrl && formatUrl(sourceUrl);

  const showRating = type !== "in-form" && type !== "fullscreen" && id;

  return (
    <div
      style={{
        flex: 1,
        height: "100%"
      }}
    >
      <RecipeWrapper>
        {name ? (
          <Name data-testid="recipe-name" descriptionBelow={!!description}>
            {name}
          </Name>
        ) : null}
        {description ? (
          <ShortDescription
            morePaddingBottom={!showRating}
            description={description}
          />
        ) : null}
        {showRating ? (
          <RecipeRating readOnly={type === "viewer"} id={id!} rating={rating} />
        ) : null}
        {visual}
        <Grid>
          <AuthorSection
            style={{
              display: "flex"
            }}
          >
            <div>
              {source || formattedUrl ? (
                <div
                  style={{ wordBreak: "break-word" }}
                  data-testid="recipe-source"
                >
                  {formattedUrl ? (
                    <PrettyLink
                      style={{ fontWeight: 800, wordBreak: "break-word" }}
                      data-testid="recipe-sourceUrl"
                      target="blank"
                      href={formattedUrl}
                    >
                      {sourceText}
                    </PrettyLink>
                  ) : (
                    source
                  )}
                </div>
              ) : null}
              {servings ? (
                <div data-testid="recipe-servings">{`${
                  /^\d+$/.test(servings) ? "Serves: " : ""
                }${servings}`}</div>
              ) : null}
            </div>
            {notes || type === "owner" ? (
              <NotesButton
                notes={notes}
                notesOpen={notesOpen}
                onNotesClicked={onNotesClicked}
                timeSection={false}
              />
            ) : null}
          </AuthorSection>
          <TimeSection>
            {times &&
              times.map((t, i) => (
                <div
                  key={i}
                  style={{
                    borderLeft: i ? "1px solid #DDE2E3" : "",
                    paddingLeft: i === 0 ? 0 : "1em",
                    paddingRight: i === times.length - 1 ? 0 : "1em"
                  }}
                >
                  <TimeType data-testid={`recipe-time-type-${i}`}>
                    {t.type}
                  </TimeType>
                  <TimeValue data-testid={`recipe-time-value-${i}`}>
                    {formatMinutes(t.value)}
                  </TimeValue>
                </div>
              ))}
            {notes || type === "owner" ? (
              <NotesButton
                notes={notes}
                notesOpen={notesOpen}
                onNotesClicked={onNotesClicked}
                timeSection={true}
              />
            ) : null}
          </TimeSection>
          <CrossableIngredients
            key={id || "recipe-form"}
            recipeId={id}
            disableRecipeLinks={disableRecipeLinks}
            ingredients={ingredients}
            viewOnly={type === "in-form"}
          />
          <HighlightableSteps
            key={id ? `s-${id}` : "recipe-form2"}
            rawInstructions={rawInstructions}
            viewOnly={type === "in-form"}
          />
        </Grid>
      </RecipeWrapper>
    </div>
  );
};

export default Recipe;
