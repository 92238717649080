import React, { MouseEvent } from "react";
import Icon from "../Icon";
import { theme } from "../theme";
import { fractionize } from "@saffron/common";

interface Props {
  amount: number;
  onClick?: (event: MouseEvent) => void;
  noAbsolutePos?: boolean;
}

export const ScalingBadge: React.FC<Props> = ({
  amount,
  onClick,
  noAbsolutePos
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        position: noAbsolutePos ? undefined : "absolute",
        cursor: "pointer",
        display: "flex",
        top: "-36px",
        right: "16px"
      }}
    >
      <Icon
        style={{
          transform: "rotate(-12deg)"
        }}
        fill="#ccc"
        name="ruler"
        width="1.85em"
        height="1.85em"
      />
      <div
        style={{
          color: "#ccc",
          fontFamily: theme.fonts.ui,
          width: 26,
          height: 26,
          fontSize: "0.8125rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#444",
          border: "1.5px solid #FAFAFA",
          borderRadius: "50%",
          marginTop: "auto",
          marginLeft: "-12px",
          fontWeight: 600,
          marginBottom: -3,
          zIndex: 1
        }}
      >
        {fractionize(amount)}
      </div>
    </div>
  );
};
