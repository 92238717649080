import React from "react";
import { Flex } from "../Flex";
import { PossibleBackgroundImages } from "../types";
import { Pattern } from "../Pattern";

interface Props {
  backgroundImage?: PossibleBackgroundImages;
  patternImage?: PossibleBackgroundImages;
}

export class Base extends React.PureComponent<Props> {
  render() {
    const { backgroundImage = "blue", patternImage = "white" } = this.props;
    return (
      <>
        <Flex
          flex="1"
          backgroundImage={backgroundImage}
          justifyContent="center"
        >
          {this.props.children}
          <Pattern backgroundImage={patternImage} lite />
        </Flex>
      </>
    );
  }
}
