import {
  RegisterMutationVariables,
  useRegisterMutation,
  registerMutationOptions
} from "@saffron/controllers";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Box } from "../../../ui/Box";
import { Flex } from "../../../ui/Flex";
import { H4 } from "../../../ui/Heading";
import { CardLogoLayout } from "../../../ui/Layout/CardLogoLayout";
import { PrettyMyLink } from "../../../ui/PrettyLink";
import { pushLocation } from "../../../utils/pushLocation";
import { RegisterForm } from "./RegisterForm";
import * as Cookie from "js-cookie";
import { addCookbookToAccount } from "../../../utils/addCookbookToAccount";
import { useState } from "react";
import { getPhoneModel } from "../Login/utils/getPhoneModel";
import { LoginPhoneView } from "../Login/LoginPhoneView";
import { ADD_COOKBOOK_COOKIE } from "../../cookbook/UnlistedCookbook";
import { apolloClient } from "../../../apollo";
import { normalizeErrors } from "@saffron/common";
import history from "../../../history";
import * as Sentry from "@sentry/browser";

const RegisterConnector: React.FC<RouteComponentProps<
  {},
  {},
  { registerOrigin?: string }
>> = ({ location }) => {
  const [register] = useRegisterMutation();
  const registerOrigin = location.state && location.state.registerOrigin;

  const [isOnPhone] = useState(() => getPhoneModel());

  if (isOnPhone) {
    return <LoginPhoneView />;
  }

  return (
    <CardLogoLayout>
      <RegisterForm
        handleSubmit={async data => {
          let utmOptions: Partial<RegisterMutationVariables["input"]> = {};
          let ref = document.referrer;
          try {
            const parsed = Cookie.getJSON("qs");
            if (parsed) {
              if (typeof parsed.utm_medium === "string") {
                utmOptions.utmMedium = parsed.utm_medium;
              }
              if (typeof parsed.utm_campaign === "string") {
                utmOptions.utmCampaign = parsed.utm_campaign;
              }
              if (typeof parsed.utm_source === "string") {
                utmOptions.utmSource = parsed.utm_source;
              }
              if (typeof parsed.utm_content === "string") {
                utmOptions.utmContent = parsed.utm_content;
              }
              if (typeof parsed.utm_term === "string") {
                utmOptions.utmTerm = parsed.utm_term;
              }
            }
            const refFromLanding = Cookie.get("ref");
            if (refFromLanding) {
              ref = refFromLanding;
            }
          } catch (err) {
            Sentry.captureException(err);
          }
          const response = await register(
            registerMutationOptions({
              input: {
                ...data,
                ...utmOptions,
                email: data.email && data.email.trim(),
                registerOrigin: registerOrigin || "",
                ref
              }
            })
          );

          if (response.data?.register.errors) {
            return normalizeErrors(response.data?.register.errors);
          }

          // try {
          //   // Google AdWords conversion
          //   (window as any).dataLayer.push([
          //     "event",
          //     "conversion",
          //     { send_to: "AW-670982691/Bj6JCP2P9sEBEKPE-b8C" }
          //   ]);
          // } catch {}

          const addCookbookOptions = Cookie.getJSON(ADD_COOKBOOK_COOKIE);

          if (addCookbookOptions) {
            Cookie.remove(ADD_COOKBOOK_COOKIE);
            try {
              await addCookbookToAccount(addCookbookOptions);
            } catch {}
          }

          if (addCookbookOptions) {
            await apolloClient.resetStore();
            pushLocation("/browse", "replace");
          } else {
            history.replace("/onboarding", {
              cookbookId: response.data?.register.cookbookId,
              sectionId: response.data?.register.sectionId
            });
          }
        }}
      />
      <Flex mb="45px">
        <Box mx="auto">
          <H4 fontFamily="primary" fontStyle="italic">
            <PrettyMyLink to="/login">Or, just sign in</PrettyMyLink>
          </H4>
        </Box>
      </Flex>
    </CardLogoLayout>
  );
};

export default RegisterConnector;
