import { MenuDay, MenuSectionColor } from "@saffron/controllers";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorText } from "../../../../../ui/ErrorText";
import Icon from "../../../../../ui/Icon";
import { LabelText, PrettyInput } from "../../../../../ui/Input";
import { Modal } from "../../../../../ui/Modal";
import { ConfirmMenuItems } from "./ConfirmMenuItems";
import { AddGroceryPath } from "../../../../../ui/Icon/svgs/AddGroceryPath";

interface Props {
  menuDays: MenuDay[];
  menuSections: MenuSectionColor[];
  anchorDate: Date;
}

export const AddToGroceryListIcon: React.FC<Props> = ({
  menuDays,
  menuSections,
  anchorDate
}) => {
  const [dateRange, setDateRange] = useState<{
    leftDate: Dayjs;
    rightDate: Dayjs;
  } | null>(null);

  const defaultDates = (o = false) => ({
    leftDate: anchorDate,
    rightDate: anchorDate,
    open: o
  });
  const [dates, setDates] = useState<{
    leftDate: Date;
    rightDate: Date;
    open: boolean;
  }>(defaultDates);

  let errorText = "";

  const dl = dayjs(dates.leftDate);
  const dr = dayjs(dates.rightDate);

  if (dr.isBefore(dl)) {
    errorText = "end date needs to be after start date";
  } else if (Math.abs(dl.diff(dr, "day")) > 31) {
    errorText = "range cannot be greater than 31 days";
  }

  return (
    <>
      <Modal
        title="Send recipes from these dates:"
        isOpen={dates.open}
        onRequestClose={() => setDates(defaultDates())}
        mainButtonText="OK"
        mainButtonClick={() => {
          if (!errorText) {
            setDates(defaultDates(false));
            setDateRange({
              leftDate: dayjs(dates.leftDate),
              rightDate: dayjs(dates.rightDate)
            });
          }
        }}
      >
        <LabelText>start date</LabelText>
        <DatePicker
          selected={dates.leftDate}
          onChange={dt => {
            if (dt) {
              setDates({ ...dates, leftDate: Array.isArray(dt) ? dt[0] : dt });
            }
          }}
          dateFormat="PPPP"
          customInput={<PrettyInput data-testid="start-date-input" />}
        />
        <LabelText style={{ marginTop: "1em" }}>end date</LabelText>
        <DatePicker
          selected={dates.rightDate}
          onChange={dt => {
            if (dt) {
              setDates({ ...dates, rightDate: Array.isArray(dt) ? dt[0] : dt });
            }
          }}
          dateFormat="PPPP"
          customInput={<PrettyInput error={!!errorText} />}
          minDate={dates.leftDate}
          maxDate={dl.add(30, "day").toDate()}
        />
        {errorText ? <ErrorText>{errorText}</ErrorText> : null}
      </Modal>
      <Icon
        name="addToList"
        viewBox="1 0 21 22"
        path={<AddGroceryPath />}
        template="recipe"
        tooltipText="add to grocery list"
        fill="#666666"
        onClick={() => setDates(defaultDates(true))}
      />
      <ConfirmMenuItems
        menuDays={menuDays}
        menuSections={menuSections}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
    </>
  );
};
