import React from "react";

export const AddGroceryPath = () => {
  return (
    <>
      <circle cx={5} cy={19.7} r={2} />
      <circle cx={15} cy={19.7} r={2} />
      <path d="M6.5 14.9l11.1-3.2c.2-.1.4-.3.4-.5V9.7h-.3c-2.5 0-4.6-1.7-5.3-4H4V4.1c0-.2-.2-.4-.4-.4H.4c-.2 0-.4.2-.4.4v1.6h2l1.9 9 .1.9v1.6c0 .2.2.4.4.4h13.2c.2 0 .4-.2.4-.4v-1.5H6.8c-1.2 0-1.2-.5-.3-.8z" />
      <path d="M17.7 0c-2.3 0-4.2 1.9-4.2 4.2s1.9 4.2 4.2 4.2 4.2-1.9 4.2-4.2S20 0 17.7 0zm2.5 4.8h-2v2h-1v-2h-2v-1h2v-2h1v2h2v1z" />
    </>
  );
};
