import { slugify } from "@saffron/common";
import {
  useGetSharedCookbookQuery,
  useMeQuery,
  CookbookAccessLevel
} from "@saffron/controllers";
import React, { useState } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { SmallCookbookCover } from "../../../ui/SmallCookbookCover";
import NotFound from "../../misc/error/NotFound";
import { getPhoneModel } from "../../user/Login/utils/getPhoneModel";
import { CookbookCard } from "./CookbookCard";
import { viewSharedCookbook } from "./viewSharedCookbook";
import { HeroBackground } from "../../../ui/HeroBackground";
import { AppStoreButtons } from "../../../ui/AppStoreButtons";

const Header = styled.div({
  marginTop: 24,
  fontFamily: "Muli",
  fontWeight: 800,
  fontSize: "2.25rem",
  lineHeight: "2.5rem",
  paddingBottom: 15,
  textAlign: "center",
  color: "#0D0D0D"
});

const Text = styled.div({
  fontFamily: "Muli",
  fontSize: "1.5rem",
  lineHeight: "2.25rem",
  display: "flex",
  textAlign: "center",
  justifyContent: "center"
});

const GoldNumber = styled.div({
  fontFamily: "Muli",
  fontWeight: 300,
  fontSize: 48, // not using rems on purpose
  lineHeight: "2.75rem",
  display: "flex",
  justifyContent: "center",
  color: "#977535",
  marginTop: 32
});

const Rule = styled.div({
  marginLeft: "auto",
  marginRight: "auto",
  width: "100%",
  height: 1,
  backgroundColor: "#979797",
  maxWidth: 175,
  marginTop: 14,
  marginBottom: 10
});

export const ADD_COOKBOOK_COOKIE = "saffron-add-cookbook";

const UnlistedCookbook: React.FC<RouteComponentProps<{
  "0": string;
  shareId: string;
  slug: string;
  sectionId?: string;
}>> = ({
  match: {
    params: { shareId, slug }
  }
}) => {
  const { data: meData } = useMeQuery();
  const [isOnPhone] = useState(() => getPhoneModel());
  const badValue = !shareId.trim();

  const { data, loading } = useGetSharedCookbookQuery({
    variables: { shareId },
    skip: !!badValue
  });

  if (badValue) {
    return <NotFound />;
  }

  if (!data) {
    return null;
  }

  const { getSharedCookbook: cookbook } = data;

  if (!loading && !cookbook) {
    return <NotFound />;
  }

  if (!loading && cookbook) {
    const correctSlug = slugify(cookbook.name);
    if (slug !== correctSlug) {
      return <Redirect to={`/cookbooks/${shareId}/${correctSlug}`} />;
    }
  }

  if (loading) {
    return null;
  }

  const isOwner = cookbook?.accessLevel === CookbookAccessLevel.Owner;

  if (isOnPhone) {
    return (
      <div style={{ width: "100%" }}>
        <CookbookCard
          deepLinkHref={`mysaffron://shared-cookbook/${cookbook!.shareId}`}
          flat
          buttonText={isOwner ? "" : "View in Saffron"}
        >
          <div
            style={{
              marginTop: 16,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <SmallCookbookCover cookbook={cookbook!} />
          </div>
          <Header>
            {isOwner
              ? "Send this link to friends and they can view your cookbook."
              : `${cookbook?.byName || ""} shared a cookbook with you!`}
          </Header>
          {isOwner ? null : (
            <>
              <Text>Follow these steps:</Text>
              <GoldNumber>1</GoldNumber>
              <Rule />
              <Text style={{ marginBottom: 16 }}>Download the app.</Text>
              <AppStoreButtons />
              <GoldNumber>2</GoldNumber>
              <Rule />
              <Text style={{ marginBottom: 16 }}>
                Once you’ve downloaded the app, click the button below.
              </Text>
            </>
          )}
        </CookbookCard>
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <HeroBackground style={{ marginTop: 0, height: "100vh" }}>
        <div style={{ alignItems: "center", display: "flex", width: "100%" }}>
          <CookbookCard
            onButtonClick={async () => {
              if (!data) {
                return;
              }

              await viewSharedCookbook({
                ...cookbook!,
                cookbookShareId: cookbook!.shareId!,
                isLoggedIn: !!(meData && meData.me && meData.me.user)
              });
            }}
            buttonText={isOwner ? "" : "View in Saffron"}
          >
            <div
              style={{
                marginTop: 16,
                display: "flex",
                justifyContent: "center"
              }}
            >
              <SmallCookbookCover cookbook={cookbook!} />
            </div>
            <Header>
              {isOwner
                ? "Send this link to friends and they can view your cookbook."
                : `${cookbook?.byName || ""} shared a cookbook with you!`}
            </Header>
          </CookbookCard>
        </div>
      </HeroBackground>
    </div>
  );
};

export default UnlistedCookbook;
