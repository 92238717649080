import React from "react";
import { theme } from "../../../ui/theme";

interface PrintableGroceryHeaderProps {
  title: string;
  noTop: boolean;
}

export const PrintableGroceryHeader: React.FC<PrintableGroceryHeaderProps> = ({
  title,
  noTop
}) => {
  return (
    <div
      style={{
        fontFamily: theme.uiFontStack,
        fontWeight: 600,
        fontSize: ".85rem",
        borderBottom: "1px solid #DDE2E3",
        paddingTop: noTop ? 0 : 16,
        marginBottom: 14,
        paddingBottom: 4,
        textTransform: "uppercase",
        letterSpacing: "0.02em"
      }}
    >
      {title}
    </div>
  );
};
