import React, { useEffect } from "react";
import { useGetRecipeByIdQuery } from "@saffron/controllers";
import Recipe from "../../../ui/Recipe";
import { TopRightIcon } from "../../../ui/TopRightIcon";
import { RouteComponentProps } from "react-router";
import { isUuid } from "../../../utils/isUuid";
import NotFound from "../../misc/error/NotFound";

const FullscreenRecipe: React.FC<RouteComponentProps<{
  id: string;
  slug: string;
}>> = ({ match: { params }, history }) => {
  const validId = isUuid(params.id);

  const { data, loading } = useGetRecipeByIdQuery({
    skip: !validId,
    variables: { id: params.id }
  });

  useEffect(() => {
    const done = (e: any) => {
      if (e.key === "Escape") {
        e.preventDefault();
        history.goBack();
      }
    };
    window.addEventListener("keydown", done, false);
    return () => {
      window.removeEventListener("keydown", done, false);
    };
  }, [history]);

  if (!validId) {
    return <NotFound />;
  }

  if (!data || loading) {
    return null;
  }

  if (!data.getRecipeById) {
    return <NotFound />;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        // position: "fixed",
        // top: 0,
        // right: 0,
        // left: 0,
        // bottom: 0,
        backgroundColor: "#fff"
        // zIndex: 9999
        // overflowY: "auto"
      }}
    >
      <TopRightIcon name="x" onClick={() => history.goBack()} />
      <Recipe type="fullscreen" {...data.getRecipeById} />
    </div>
  );
};

export default FullscreenRecipe;
