import { style } from "styled-system";

export const theme = {
  breakpoints: ["32em", "48em", "64em", "80em", "96em"],
  space: [0, 4, 8, 16, 32, 64, 128],
  fonts: {
    primary: "Lora, Georgia, serif",
    secondary: 'Muli, "Helvetica Neue", sans-serif',
    ui: '"Source Sans Pro", Arial, sans-serif'
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72, 96],
  weights: {
    normal: 400,
    bold: 700
  },
  hoverColor: "#E9ECEE",
  selectedColor: "#4D5862",
  colors: {
    legitWhite: "#ffffff",
    blue: "#447588",
    lightBlue: "#70A3B7",
    white: "#EEF4F6",
    brightWhite: "#fafafa",
    gray: "#DDE2E3",
    darkGold: "#91763F",
    gold: "#C6A15B",
    lightGold: "#D7BE8E",
    beige: "#C5BFA9",
    lightBeige: "#F5F4F0",
    darkRed: "#531410",
    brownRed: "#824028",
    red: "#972409",
    brightRed: "#D13C1E",
    tomato: "#c3603c",
    darkGray: "#333333",
    otherGray: "#808080",
    four: "#444"
  },
  radii: [0, 2, 4],
  backgroundImages: {
    blue:
      "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc0MDAnIGhlaWdodD0nNDAwJyB2aWV3Qm94PScwIDAgNDAwIDQwMCc+CjxkZWZzPgoJPHBhdHRlcm4gaWQ9J2JsdWVzdHJpcGUnIHBhdHRlcm5Vbml0cz0ndXNlclNwYWNlT25Vc2UnIHg9JzAnIHk9JzAnIHdpZHRoPScxMCcgaGVpZ2h0PScyMCcgdmlld0JveD0nMCAwIDUgMTAnID4KCQk8cmVjdCB4PSctNScgeT0nLTUnIHdpZHRoPScxNScgaGVpZ2h0PScyMCcgZmlsbD0nI2Q2ZGRkZCcvPgoJCTxsaW5lIHgxPSctMicgeTE9JzEnIHgyPSc3JyB5Mj0nMTAnIHN0cm9rZT0nI2RkZTJlMycgc3Ryb2tlLXdpZHRoPScyJy8+CgkJPGxpbmUgeDE9Jy0yJyB5MT0nNicgeDI9JzcnIHkyPScxNScgc3Ryb2tlPScjZGRlMmUzJyBzdHJva2Utd2lkdGg9JzInLz4KCQk8bGluZSB4MT0nLTInIHkxPSctNCcgeDI9JzcnIHkyPSc1JyBzdHJva2U9JyNkZGUyZTMnIHN0cm9rZS13aWR0aD0nMicvPgoJCTxsaW5lIHgxPSc3JyB5MT0nMScgeDI9Jy0yJyB5Mj0nMTAnIHN0cm9rZT0nI2RkZTJlMycgc3Ryb2tlLXdpZHRoPScyJy8+CgkJPGxpbmUgeDE9JzcnIHkxPSc2JyB4Mj0nLTInIHkyPScxNScgc3Ryb2tlPScjZGRlMmUzJyBzdHJva2Utd2lkdGg9JzInLz4KCQk8bGluZSB4MT0nNycgeTE9Jy00JyB4Mj0nLTInIHkyPSc1JyBzdHJva2U9JyNkZGUyZTMnIHN0cm9rZS13aWR0aD0nMicvPgoJPC9wYXR0ZXJuPiAKCTxmaWx0ZXIgaWQ9J2Z1enonIHg9JzAnIHk9JzAnPgoJCTxmZVR1cmJ1bGVuY2UgdHlwZT0ndHVyYnVsZW5jZScgcmVzdWx0PSd0JyBiYXNlRnJlcXVlbmN5PScuMiAuMScgbnVtT2N0YXZlcz0nMicgc3RpdGNoVGlsZXM9J3N0aXRjaCcvPgoJCTxmZUNvbG9yTWF0cml4IHR5cGU9J3NhdHVyYXRlJyBpbj0ndCcgdmFsdWVzPScwLjQnLz4KCQk8ZmVDb252b2x2ZU1hdHJpeCBvcmRlcj0nMywzJyBrZXJuZWxNYXRyaXg9JzAsLS4yNSwwLC0uMjUsMiwtLjI1LDAsLS4yNSwwJy8+Cgk8L2ZpbHRlcj4KPC9kZWZzPgo8cmVjdCB3aWR0aD0nMTAwJScgaGVpZ2h0PScxMDAlJyBmaWxsPSd1cmwoI2JsdWVzdHJpcGUpJy8+CjxyZWN0IHdpZHRoPScxMDAlJyBoZWlnaHQ9JzEwMCUnIGZpbHRlcj0ndXJsKCNmdXp6KScgb3BhY2l0eT0nMC4xJy8+Cjwvc3ZnPg==);",
    creme:
      "url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc0MDAnIGhlaWdodD0nNDAwJyB2aWV3Qm94PScwIDAgNDAwIDQwMCc+CjxkZWZzPgoJPHBhdHRlcm4gaWQ9J2JsdWVzdHJpcGUnIHBhdHRlcm5Vbml0cz0ndXNlclNwYWNlT25Vc2UnIHg9JzAnIHk9JzAnIHdpZHRoPSc4JyBoZWlnaHQ9JzE2JyB2aWV3Qm94PScwIDAgNSAxMCcgPgoJCTxyZWN0IHg9Jy01JyB5PSctNScgd2lkdGg9JzE1JyBoZWlnaHQ9JzIwJyBmaWxsPScjZWNlYWUxJy8+CgkJPGxpbmUgeDE9Jy0yJyB5MT0nMScgeDI9JzcnIHkyPScxMCcgc3Ryb2tlPScjZTZlMmQ0JyBzdHJva2Utd2lkdGg9JzInLz4KCQk8bGluZSB4MT0nLTInIHkxPSc2JyB4Mj0nNycgeTI9JzE1JyBzdHJva2U9JyNlNmUyZDQnIHN0cm9rZS13aWR0aD0nMicvPgoJCTxsaW5lIHgxPSctMicgeTE9Jy00JyB4Mj0nNycgeTI9JzUnIHN0cm9rZT0nI2U2ZTJkNCcgc3Ryb2tlLXdpZHRoPScyJy8+CgkJPGxpbmUgeDE9JzcnIHkxPScxJyB4Mj0nLTInIHkyPScxMCcgc3Ryb2tlPScjZTZlMmQ0JyBzdHJva2Utd2lkdGg9JzInLz4KCQk8bGluZSB4MT0nNycgeTE9JzYnIHgyPSctMicgeTI9JzE1JyBzdHJva2U9JyNlNmUyZDQnIHN0cm9rZS13aWR0aD0nMicvPgoJCTxsaW5lIHgxPSc3JyB5MT0nLTQnIHgyPSctMicgeTI9JzUnIHN0cm9rZT0nI2U2ZTJkNCcgc3Ryb2tlLXdpZHRoPScyJy8+Cgk8L3BhdHRlcm4+IAoJPGZpbHRlciBpZD0nZnV6eicgeD0nMCcgeT0nMCc+CgkJPGZlVHVyYnVsZW5jZSB0eXBlPSd0dXJidWxlbmNlJyByZXN1bHQ9J3QnIGJhc2VGcmVxdWVuY3k9Jy4yIC4xJyBudW1PY3RhdmVzPScyJyBzdGl0Y2hUaWxlcz0nc3RpdGNoJy8+CgkJPGZlQ29sb3JNYXRyaXggdHlwZT0nc2F0dXJhdGUnIGluPSd0JyB2YWx1ZXM9JzAuNCcvPgoJCTxmZUNvbnZvbHZlTWF0cml4IG9yZGVyPSczLDMnIGtlcm5lbE1hdHJpeD0nMCwtLjI1LDAsLS4yNSwyLC0uMjUsMCwtLjI1LDAnLz4KCTwvZmlsdGVyPgo8L2RlZnM+CjxyZWN0IHdpZHRoPScxMDAlJyBoZWlnaHQ9JzEwMCUnIGZpbGw9J3VybCgjYmx1ZXN0cmlwZSknLz4KPHJlY3Qgd2lkdGg9JzEwMCUnIGhlaWdodD0nMTAwJScgZmlsdGVyPSd1cmwoI2Z1enopJyBvcGFjaXR5PScwLjEnLz4KPC9zdmc+);",
    white:
      "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nODAnIGhlaWdodD0nODgnIHZpZXdCb3g9JzAgMCA4MCA4OCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMjIgMjEuOTFWMjZoLTJjLTkuOTQgMC0xOCA4LjA2LTE4IDE4IDAgOS45NDMgOC4wNTggMTggMTggMThoMnY0LjA5YzguMDEyLjcyMiAxNC43ODUgNS43MzggMTggMTIuNzMgMy4yMTItNi45OSA5Ljk4My0xMi4wMDggMTgtMTIuNzNWNjJoMmM5Ljk0IDAgMTgtOC4wNiAxOC0xOCAwLTkuOTQzLTguMDU4LTE4LTE4LTE4aC0ydi00LjA5Yy04LjAxMi0uNzIyLTE0Ljc4NS01LjczOC0xOC0xMi43My0zLjIxMiA2Ljk5LTkuOTgzIDEyLjAwOC0xOCAxMi43M3pNNTQgNTh2NC42OTZjLTUuNTc0IDEuMzE2LTEwLjQ1NSA0LjQyOC0xNCA4LjY5LTMuNTQ1LTQuMjYyLTguNDI2LTcuMzc0LTE0LTguNjlWNThoLTUuOTkzQzEyLjI3IDU4IDYgNTEuNzM0IDYgNDRjMC03LjczMiA2LjI3NS0xNCAxNC4wMDctMTRIMjZ2LTQuNjk2YzUuNTc0LTEuMzE2IDEwLjQ1NS00LjQyOCAxNC04LjY5IDMuNTQ1IDQuMjYyIDguNDI2IDcuMzc0IDE0IDguNjlWMzBoNS45OTNDNjcuNzMgMzAgNzQgMzYuMjY2IDc0IDQ0YzAgNy43MzItNi4yNzUgMTQtMTQuMDA3IDE0SDU0ek00MiA4OGMwLTkuOTQgOC4wNi0xOCAxOC0xOGgydi00LjA5YzguMDE2LS43MjIgMTQuNzg3LTUuNzM4IDE4LTEyLjczdjcuNDM0Yy0zLjU0NSA0LjI2Mi04LjQyNiA3LjM3NC0xNCA4LjY5Vjc0aC01Ljk5M0M1Mi4yNzUgNzQgNDYgODAuMjY4IDQ2IDg4aC00em0tNCAwYzAtOS45NDMtOC4wNTgtMTgtMTgtMThoLTJ2LTQuMDljLTguMDEyLS43MjItMTQuNzg1LTUuNzM4LTE4LTEyLjczdjcuNDM0YzMuNTQ1IDQuMjYyIDguNDI2IDcuMzc0IDE0IDguNjlWNzRoNS45OTNDMjcuNzMgNzQgMzQgODAuMjY2IDM0IDg4aDR6bTQtODhjMCA5Ljk0MyA4LjA1OCAxOCAxOCAxOGgydjQuMDljOC4wMTIuNzIyIDE0Ljc4NSA1LjczOCAxOCAxMi43M3YtNy40MzRjLTMuNTQ1LTQuMjYyLTguNDI2LTcuMzc0LTE0LTguNjlWMTRoLTUuOTkzQzUyLjI3IDE0IDQ2IDcuNzM0IDQ2IDBoLTR6TTAgMzQuODJjMy4yMTMtNi45OTIgOS45ODQtMTIuMDA4IDE4LTEyLjczVjE4aDJjOS45NCAwIDE4LTguMDYgMTgtMThoLTRjMCA3LjczMi02LjI3NSAxNC0xNC4wMDcgMTRIMTR2NC42OTZjLTUuNTc0IDEuMzE2LTEwLjQ1NSA0LjQyOC0xNCA4LjY5djcuNDMzeicgZmlsbD0nIzIzNzBhM2I3JyBmaWxsLW9wYWNpdHk9JzAuNCcgZmlsbC1ydWxlPSdldmVub2RkJy8+PC9zdmc+)",
    gold:
      "url(\"data:image/svg+xml,%3Csvg width='80' height='88' viewBox='0 0 80 88' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 21.91V26h-2c-9.94 0-18 8.06-18 18 0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73 3.212-6.99 9.983-12.008 18-12.73V62h2c9.94 0 18-8.06 18-18 0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73-3.212 6.99-9.983 12.008-18 12.73zM54 58v4.696c-5.574 1.316-10.455 4.428-14 8.69-3.545-4.262-8.426-7.374-14-8.69V58h-5.993C12.27 58 6 51.734 6 44c0-7.732 6.275-14 14.007-14H26v-4.696c5.574-1.316 10.455-4.428 14-8.69 3.545 4.262 8.426 7.374 14 8.69V30h5.993C67.73 30 74 36.266 74 44c0 7.732-6.275 14-14.007 14H54zM42 88c0-9.94 8.06-18 18-18h2v-4.09c8.016-.722 14.787-5.738 18-12.73v7.434c-3.545 4.262-8.426 7.374-14 8.69V74h-5.993C52.275 74 46 80.268 46 88h-4zm-4 0c0-9.943-8.058-18-18-18h-2v-4.09c-8.012-.722-14.785-5.738-18-12.73v7.434c3.545 4.262 8.426 7.374 14 8.69V74h5.993C27.73 74 34 80.266 34 88h4zm4-88c0 9.943 8.058 18 18 18h2v4.09c8.012.722 14.785 5.738 18 12.73v-7.434c-3.545-4.262-8.426-7.374-14-8.69V14h-5.993C52.27 14 46 7.734 46 0h-4zM0 34.82c3.213-6.992 9.984-12.008 18-12.73V18h2c9.94 0 18-8.06 18-18h-4c0 7.732-6.275 14-14.007 14H14v4.696c-5.574 1.316-10.455 4.428-14 8.69v7.433z' fill='%23c5bfa9' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E\");"
  },

  primaryFontStack: "Lora, Georgia, serif",
  secondaryFontStack: 'Muli, "Helvetica Neue", sans-serif',
  uiFontStack: '"Source Sans Pro", Arial, sans-serif',
  border: "1px solid #DDE3E3",
  fullWidth: "900px"
};

export const backgroundImage = style({
  // React prop name
  prop: "backgroundImage",
  // The corresponding CSS property (defaults to prop argument)
  cssProperty: "background-image",
  // key for theme values
  key: "backgroundImages"
});

export const opacity = style({
  // React prop name
  prop: "opacity",
  // The corresponding CSS property (defaults to prop argument)
  cssProperty: "opacity",
  // key for theme values
  key: "opacities"
});

export const fontStyle = style({
  // React prop name
  prop: "fontStyle",
  // The corresponding CSS property (defaults to prop argument)
  cssProperty: "font-style",
  // key for theme values
  key: "fontStyles"
});

export const fontFamily = style({
  // React prop name
  prop: "fontFamily",
  // The corresponding CSS property (defaults to prop argument)
  cssProperty: "font-family",
  // key for theme values
  key: "fonts"
});

export const hoverColor = style({
  // React prop name
  prop: "hoverColor",
  // The corresponding CSS property (defaults to prop argument)
  cssProperty: "background-color",
  // key for theme values
  key: "colors"
});

export const letterSpacing = style({
  // React prop name
  prop: "letterSpacing",
  // The corresponding CSS property (defaults to prop argument)
  cssProperty: "letter-spacing",
  // key for theme values
  key: "letter-spacings"
});

export const maxWidth = style({
  // React prop name
  prop: "maxWidth",
  // The corresponding CSS property (defaults to prop argument)
  cssProperty: "max-width",
  // key for theme values
  key: "maxWidths"
});

export const patternWidthValue = 57.6;
export const patternWidth = `${patternWidthValue}px`;
export const litePatternOpacity = 0.5;

export const baseModalContainerStyles = {
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  height: 600,
  width: 380,
  display: "flex",
  flexDirection: "column",
  padding: "30px",
  paddingTop: "20px",
  backgroundColor: "#fafafa",
  boxShadow: "3px 6px 6px rgba(0, 0, 0, 0.16)",
  maxHeight: "88vh",
  maxWidth: "88vw"
};

export const HEADER_HEIGHT = 95;
