import React from "react";
import {
  useRecipeListSortBy,
  fieldToSortByToLabel,
  fieldToSortByOptions
} from "@saffron/controllers";
import { SelectController } from "../../../ui/Select/SelectController";
import { DivButton } from "../../../ui/DivButton";
import { theme } from "../../../ui/theme";
import Icon from "../../../ui/Icon";
import styled from "styled-components";

interface SortRecipesBySelectProps {
  isMenuPlannerSidebar?: boolean;
}

const DivButtonWithHover = styled(DivButton)({
  "&:hover": {
    backgroundColor: theme.hoverColor
  }
});

export const SortRecipesBySelect: React.FC<SortRecipesBySelectProps> = ({
  isMenuPlannerSidebar
}) => {
  const { fieldToSortBy, setFieldToSortBy } = useRecipeListSortBy();
  return (
    <SelectController
      value={fieldToSortBy}
      options={fieldToSortByOptions.map(x => ({
        key: x.value,
        value: x.value,
        text: x.label
      }))}
      onChange={v => {
        if (v) {
          setFieldToSortBy(v as any);
        }
      }}
    >
      {({ toggle }) => (
        <DivButtonWithHover
          style={{
            color: "#444",
            fontFamily: theme.fonts.ui,
            fontWeight: 600,
            width: "100%",
            display: "flex",
            padding: `10px ${isMenuPlannerSidebar ? 16 : 19.6}px`,
            fontSize: "0.875rem",
            alignItems: "center",
            borderBottom: isMenuPlannerSidebar ? undefined : "1px solid #dde3e3"
          }}
          onClick={toggle}
        >
          Sort by {fieldToSortByToLabel[fieldToSortBy]}{" "}
          <Icon
            style={{
              marginLeft: 2,
              transform: "rotate(270deg) scaleX(-1)"
            }}
            width="1.15em"
            height="1.15em"
            name="swap"
            fill="#444"
          />
        </DivButtonWithHover>
      )}
    </SelectController>
  );
};
