import React, { useState, useEffect } from "react";
import { Modal } from "../../../ui/Modal";
import { Select } from "../../../ui/Select";
import { groceryCategoryNameToPositionMap, sortBy } from "@saffron/common";
import {
  GroceryListItemFragment,
  useChangeGroceryCategoryMutation,
  changeGroceryCategoryMutationOptions
} from "@saffron/controllers";

interface ChangeCatModalProps {
  item: GroceryListItemFragment | null;
  onRequestClose: () => void;
}

const catOptions = sortBy(
  Object.keys(groceryCategoryNameToPositionMap),
  x => groceryCategoryNameToPositionMap[x]
).map(x => ({ text: x, value: x, key: x }));

export const ChangeCatModal: React.FC<ChangeCatModalProps> = ({
  item,
  onRequestClose
}) => {
  const [cat, setCat] = useState("");
  const [
    changeGroceryCategory,
    { loading }
  ] = useChangeGroceryCategoryMutation();

  const catName = item ? item.groceryCategoryName : "";

  useEffect(() => {
    setCat(catName);
  }, [catName]);

  return (
    <Modal
      isOpen={!!item}
      loading={loading}
      onRequestClose={onRequestClose}
      title="Pick Category"
      mainButtonClick={() => {
        changeGroceryCategory(
          changeGroceryCategoryMutationOptions({
            id: item!.id,
            keyword: item!.keyword || "",
            groceryCategoryName: cat
          })
        ).then(() => onRequestClose());
      }}
      mainButtonText="MOVE"
    >
      <Select
        value={cat}
        onChange={x => {
          setCat(x);
        }}
        options={catOptions}
      />
    </Modal>
  );
};
