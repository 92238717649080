import { slugify } from "@saffron/common";
import {
  ViewRecipeSearchQuery,
  viewRecipeSearchQuery,
  useGetRecipeByIdQuery,
  useMeQuery
} from "@saffron/controllers";
import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { CookbookBreadcrumbs } from "../../../ui/CookbookBreadcrumbs";
import { Flex } from "../../../ui/Flex";
import { MainContent } from "../../../ui/Layout/MainContent";
import { SideBarLayout } from "../../../ui/Layout/SidebarLayout";
import Recipe from "../../../ui/Recipe";
import { RecipeWrapper } from "../../../ui/Recipe/components";
import { Sidebar } from "../../../ui/Sidebar";
import { WarnIfNoRecipe } from "../../../utils/WarnIfNoRecipes";
import { RiconBar } from "../../ricons/RiconBar/index";
import { RecipeNoteSidebar } from "../../shared/RecipeNoteSidebar";
import { RecipeSearch } from "../../shared/RecipeSearch";
import { useFindRecipeState } from "./FindRecipeState";
import { usePrevious } from "../../../utils/usePrevious";

type Props = RouteComponentProps<{}>;

const FindRecipeConnector: React.FC<Props> = ({ history, location }) => {
  const { data: meData } = useMeQuery();
  const [showNotes, setShowNotes] = useState(false);
  const {
    recipe,
    variables,
    onSearch,
    setCurrentRecipe
  } = useFindRecipeState();
  const fetchPolicy = recipe ? "cache-first" : "network-only";
  const { data } = useGetRecipeByIdQuery({
    variables: {
      id: recipe?.id!
    },
    skip: !recipe,
    notifyOnNetworkStatusChange: true
  });

  const pKey = usePrevious(location.key);
  useEffect(() => {
    if (showNotes && pKey && location.key && pKey !== location.key) {
      setShowNotes(false);
    }
  }, [showNotes, pKey, location.key]);

  const recipeFromCache = data ? data.getRecipeById : null;
  return (
    <WarnIfNoRecipe
      title="Search for recipes"
      message="Add recipes to your cookbooks to search and view results here."
    >
      <SideBarLayout>
        {showNotes && data?.getRecipeById ? (
          <RecipeNoteSidebar
            onRequestClose={() => setShowNotes(false)}
            recipe={data.getRecipeById}
          />
        ) : (
          <Sidebar>
            <RecipeSearch<
              ViewRecipeSearchQuery,
              ViewRecipeSearchQuery["recipeSearch"]["recipes"][0]
            >
              query={viewRecipeSearchQuery}
              onSearch={onSearch}
              onRecipeClick={setCurrentRecipe}
              sidebar
              initialState={{
                ...variables,
                fetchPolicy
              }}
            />
          </Sidebar>
        )}
        <MainContent>
          <Flex flex={1} flexDirection="column">
            {recipe && recipeFromCache ? (
              <React.Fragment>
                <RiconBar
                  recipe={recipeFromCache}
                  onEditRiconClick={() =>
                    recipeFromCache &&
                    history.push(
                      `/recipe/edit/${recipeFromCache.id}/${slugify(
                        recipeFromCache.name
                      )}`,
                      {
                        next: "/search"
                      }
                    )
                  }
                />
                {recipeFromCache && recipe.cookbookLookup ? (
                  <RecipeWrapper style={{ marginTop: "3em", marginBottom: 0 }}>
                    <CookbookBreadcrumbs
                      cookbookLookup={recipe.cookbookLookup}
                      recipeId={recipeFromCache.id}
                    />
                  </RecipeWrapper>
                ) : null}
                <Flex flex={1}>
                  <Recipe
                    type={
                      meData?.me.user?.id === recipeFromCache.owner
                        ? "owner"
                        : "viewer"
                    }
                    notesOpen={showNotes}
                    onNotesClicked={() => setShowNotes(!showNotes)}
                    {...recipeFromCache}
                  />
                </Flex>
              </React.Fragment>
            ) : null}
          </Flex>
        </MainContent>
      </SideBarLayout>
    </WarnIfNoRecipe>
  );
};

export default FindRecipeConnector;
