import React, { useState } from "react";
import { CenterLayout } from "../../ui/Layout/CenterLayout";
import Dropzone from "react-dropzone";
import GhostButton from "../../ui/Button";
import { notificationState } from "../misc/notifications/NotificationState";
import { Spinner } from "../../ui/Spinner";
import { useBulkImportPaprikaRecipesMutation } from "@saffron/controllers";

interface BulkImportPageProps {}

const typeToAccept = {
  "pepperplate-text": ".zip",
  paprika: ".paprikarecipes",
  "recipe-keeper": ".zip",
  evernote: ".enex",
  "plan-to-eat": ".txt",
  urls: "",
  json: ".json"
};

export const BulkImportPage: React.FC<BulkImportPageProps> = ({}) => {
  const [
    bulkImportPaprikaRecipes,
    { loading }
  ] = useBulkImportPaprikaRecipesMutation();
  const [type, setType] = useState("json");

  return (
    <CenterLayout
      patternImage="gold"
      backgroundImage="creme"
      width={900}
      innerPadding="6em"
      style={{ maxHeight: "100%", paddingTop: "3em", paddingBottom: "3em" }}
    >
      <div>
        <select value={type} onChange={e => setType(e.target.value)}>
          <option value="pepperplate-text">Pepperplate Text</option>
          <option value="paprika">Paprika</option>
          <option value="plan-to-eat">Plan to Eat</option>
          <option value="recipe-keeper">Recipe Keeper</option>
          <option value="evernote">Evernote</option>
          <option value="urls">Urls</option>
          <option value="json">json</option>
        </select>
        <Dropzone
          onDrop={async ([file]) => {
            if (!file) {
              notificationState.send({
                text: "invalid file",
                variant: "error"
              });
              return;
            }

            if (file.size > 500000000) {
              notificationState.send({
                text: "File cannot be larger than 500 MB",
                variant: "error"
              });
              return;
            }

            await bulkImportPaprikaRecipes({
              variables: {
                input: {
                  file,
                  type
                }
              }
            });

            notificationState.send({
              text: "Recipes successfully added",
              variant: "success"
            });
          }}
          multiple={false}
          accept={typeToAccept[type as keyof typeof typeToAccept]}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...(getRootProps() as any)}>
              <input {...(getInputProps() as any)} />
              <GhostButton disabled={loading} type="button">
                upload file
              </GhostButton>
            </div>
          )}
        </Dropzone>
        {loading ? <Spinner /> : null}
      </div>
    </CenterLayout>
  );
};
