import React from "react";
import TooltipTrigger from "react-popper-tooltip";
import styled from "styled-components";
import { DivButton } from "../../../../ui/DivButton";
import Icon from "../../../../ui/Icon";
import { ListButton } from "../../../../ui/ListButton";
import { SmallCookbookCover } from "../../../../ui/SmallCookbookCover";
import { theme } from "../../../../ui/theme";
import { pushLocation } from "../../../../utils/pushLocation";
import { mediaSizes, media } from "../../../../ui/mediaTemplates";
import { CookbooksQuery, CookbookAccessLevel } from "@saffron/controllers";

type Cb = CookbooksQuery["cookbooks"][0];

interface Props {
  onClick: () => void;
  cookbook: Cb;
  isCookbookOwner: boolean;
  setRenameCookbookModalOpen: (cb: Cb) => void;
  setShareCookbookModalOpen: (cb: Cb) => void;
  setCookbookDeleteModalOpen: (cb: Cb) => void;
  setSharedCookbookRemoveModalOpen: (cb: Cb) => void;
}

const Container = styled.div({
  display: "flex",
  paddingTop: 30,
  paddingBottom: 30,
  borderBottom: "1px solid #DDE2E3",
  marginLeft: 10,
  marginRight: 0,
  color: "#444",
  "&:hover .cb-title": {
    color: "#ccc"
  },
  [`@media (min-width: ${mediaSizes.oneColSteps}px)`]: {
    marginLeft: 20,
    marginRight: 40
  }
});

const SharedContainer = styled.div`
  flex-direction: column;
  ${media.oneColSteps`
  align-items: center;
flex-direction: row;
`}
`;

const SharedText = styled.div`
  margin-top: 4px;
  ${media.oneColSteps`
  margin-left: 8px;
`}
`;

export const CookbookCoverCell: React.FC<Props> = ({
  cookbook,
  onClick,
  isCookbookOwner,
  setCookbookDeleteModalOpen,
  setRenameCookbookModalOpen,
  setShareCookbookModalOpen,
  setSharedCookbookRemoveModalOpen
}) => {
  const tooltip = React.useRef<TooltipTrigger>(null);
  return (
    <Container>
      <DivButton style={{ display: "flex", flex: 1 }} onClick={onClick}>
        <SmallCookbookCover cookbook={cookbook} />
        <div style={{ flex: 1, paddingLeft: 20, paddingTop: 10 }}>
          <div
            className="cb-title"
            style={{
              fontFamily: theme.fonts.ui,
              fontWeight: 600,
              fontSize: "1.125rem",
              lineHeight: 1.2
            }}
          >
            {cookbook.name}
          </div>
          <div
            style={{
              fontFamily: theme.fonts.ui,
              fontSize: "1.0rem",
              color: "#444",
              lineHeight: 1.45
            }}
          >
            {cookbook.byName}
          </div>
          {cookbook.accessLevel === CookbookAccessLevel.Owner &&
          cookbook.privacy !== "private" ? (
            <SharedContainer style={{ display: "flex", marginTop: 10 }}>
              <Icon name="openBook" width="24px" height="24px" fill="#999" />
              <SharedText
                style={{
                  fontFamily: theme.fonts.ui,
                  fontSize: "1.0rem",
                  lineHeight: 1.45,
                  color: "#999"
                }}
              >
                SHARED
              </SharedText>
            </SharedContainer>
          ) : null}
        </div>
      </DivButton>

      <div>
        <TooltipTrigger
          ref={tooltip}
          placement="bottom"
          trigger="click"
          tooltip={({ tooltipRef, getTooltipProps }) => {
            return (
              <div
                {...getTooltipProps({
                  ref: tooltipRef,
                  className: "tooltip-container",
                  style: {
                    width: 185,
                    paddingBottom: 5,
                    paddingTop: 5,
                    borderRadius: 4,
                    backgroundColor: "#fff",
                    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
                    border: "1px solid rgba(27,31,35,.15)"
                  }
                })}
              >
                {isCookbookOwner ? (
                  <>
                    <ListButton
                      onClick={() => {
                        setRenameCookbookModalOpen(cookbook);
                        if (tooltip.current) {
                          // @ts-ignore
                          tooltip.current.hideTooltip();
                        }
                      }}
                    >
                      Rename
                    </ListButton>
                    <ListButton
                      onClick={() => {
                        pushLocation(`/cookbook/cover/${cookbook.id}`);
                      }}
                    >
                      Customize Cover
                    </ListButton>

                    <ListButton
                      onClick={() => {
                        setShareCookbookModalOpen(cookbook);
                        if (tooltip.current) {
                          // @ts-ignore
                          tooltip.current.hideTooltip();
                        }
                      }}
                    >
                      Share
                    </ListButton>
                    <ListButton
                      onClick={e => {
                        e.stopPropagation();
                        setCookbookDeleteModalOpen(cookbook);
                        if (tooltip.current) {
                          // @ts-ignore
                          tooltip.current.hideTooltip();
                        }
                      }}
                    >
                      Delete
                    </ListButton>
                  </>
                ) : (
                  <ListButton
                    onClick={e => {
                      e.stopPropagation();
                      setSharedCookbookRemoveModalOpen(cookbook);
                      if (tooltip.current) {
                        // @ts-ignore
                        tooltip.current.hideTooltip();
                      }
                    }}
                  >
                    Remove Shared Cookbook
                  </ListButton>
                )}
              </div>
            );
          }}
        >
          {({ getTriggerProps, triggerRef }) => (
            <div
              {...getTriggerProps({
                ref: triggerRef,
                className: "trigger",
                style: {
                  marginTop: 2.5,
                  padding: 10
                }
              })}
            >
              <Icon name="dots" template="dotsWhenOnWhite" />
            </div>
          )}
        </TooltipTrigger>
      </div>
    </Container>
  );
};
