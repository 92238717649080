import * as Cookie from "js-cookie";
import { ADD_COOKBOOK_COOKIE } from ".";
import { pushLocation } from "../../../utils/pushLocation";
import { addCookbookToAccount } from "../../../utils/addCookbookToAccount";

interface Options {
  id: string;
  name: string;
  cookbookShareId: string;
  isLoggedIn: boolean;
}

export const viewSharedCookbook = async ({
  id,
  name,
  cookbookShareId,
  isLoggedIn
}: Options) => {
  if (!isLoggedIn) {
    const oneHour = 1 / 24;
    Cookie.set(
      ADD_COOKBOOK_COOKIE,
      { name, cookbookShareId },
      { expires: oneHour }
    );
    pushLocation("/register");
    return;
  }

  try {
    await addCookbookToAccount({
      name,
      cookbookShareId
    });
  } catch {}

  pushLocation(`/browse/${id}`);
};
