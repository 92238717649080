import styled from "styled-components";
import {
  alignItems,
  flexDirection,
  flexWrap,
  justifyContent
} from "styled-system";
import { Box } from "../Box";
import { FlexCProps } from "../types";

export const Flex = styled(Box as any)<FlexCProps>`
  display: flex;
  ${flexWrap};
  ${flexDirection};
  ${alignItems};
  ${justifyContent};
`;

export const ClickableFlex = styled(Flex as any)<FlexCProps>`
  cursor: pointer;
`;

export const ScrollableFlex = styled(Flex as any)<FlexCProps>`
  overflow-y: auto;
`;

export const FlexCenter = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;
