import {
  ALERT_ERROR_CODE,
  DOES_NOT_EXIST,
  FREE_TRIAL_EXPIRED_ERROR,
  NOT_AUTHENTICATED_ERROR,
  NOT_AUTHORIZED_ERROR,
  STRIPE_CHARGE_ERROR
} from "@saffron/common";
import { apolloCache } from "@saffron/controllers";
import { createUploadLink } from "apollo-upload-client";
import { notificationState } from "./modules/misc/notifications/NotificationState";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { pushLocation } from "./utils/pushLocation";
import pkg from "../package.json";
import { ApolloLink, ApolloClient } from "@apollo/client";

const httpLink = createUploadLink({
  uri: `https://36d3f8657a7f.ngrok.io/graphql`,
  credentials: "include",
  headers: {
    "x-app-version": pkg.version,
    "x-platform": "main-web"
  }
});

const errorAfterware = onError(({ graphQLErrors, networkError, operation }) => {
  if (networkError && (networkError as any).statusCode === 429) {
    notificationState.send({
      variant: "error",
      text: "you're doing that too much"
    });
  } else if (networkError && (networkError as any).statusCode === 413) {
    notificationState.send({
      variant: "error",
      text: "image too big"
    });
  } else if (networkError && networkError.message === "Failed to fetch") {
    if (navigator && navigator.onLine === false) {
      notificationState.send({
        variant: "error",
        text: "Connection failed, check your wifi"
      });
    } else {
      notificationState.send({
        variant: "error",
        text: "Unable to reach server"
      });
    }
  } else if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      if (message === NOT_AUTHENTICATED_ERROR) {
        pushLocation("/login/na", "replace");
      } else if (message === FREE_TRIAL_EXPIRED_ERROR) {
        pushLocation("/settings/account", "replace");
      } else if (message === NOT_AUTHORIZED_ERROR) {
        notificationState.send({
          text: "not authorized to do that",
          variant: "error"
        });
      } else if (extensions && extensions.code === ALERT_ERROR_CODE) {
        // ignore showing not in cookbook error
        // just take them to the cookbook
        if (operation.operationName === "AddCookbookToCollection") {
          return;
        }
        const { variant } = extensions.exception;
        notificationState.send({ text: message, variant });
      } else if (message === DOES_NOT_EXIST) {
        notificationState.send({
          text: "out of sync please refresh",
          variant: "info"
        });
      } else if (message === STRIPE_CHARGE_ERROR) {
        notificationState.send({ text: message, variant: "error" });
      } else if (
        extensions &&
        extensions.exception &&
        extensions.exception.name === "InputError"
      ) {
        notificationState.send({ text: message, variant: "error" });
      } else {
        notificationState.send({
          text: "an unexpected error has occured, try refreshing",
          variant: "error"
        });
      }
    });
  }
});

const link = ApolloLink.from([
  new RetryLink({
    delay: {
      initial: 100,
      max: Infinity,
      jitter: true
    },
    attempts: {
      max: 2,
      retryIf: (error, _operation) => !!error
    }
  }),
  errorAfterware,
  httpLink as any
]);

export const apolloClient = new ApolloClient({
  link,
  cache: apolloCache,
  assumeImmutableResults: true
});
