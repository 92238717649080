import styled from "styled-components";

export const Spinner = styled("div")`
  border: 4px solid #ebeeee;
  border-top: 4px solid rgb(112, 163, 183);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
