import React, { useState } from "react";
import {
  StandardMenuNoteFragment,
  MenuPlannerQueryVariables,
  useDeleteMenuNoteMutation,
  deleteMenuNoteMutationOptions,
  useUpdateMenuNoteMutation,
  updateMenuNoteMutationOptions
} from "@saffron/controllers";
import { Modal } from "../../../ui/Modal";
import { Linkify } from "../../../ui/Linkify";
import { Flex } from "../../../ui/Flex";
import Icon from "../../../ui/Icon";
import { TextArea } from "../../../ui/Input";
import { theme } from "../../../ui/theme";
import { addPrettyFractionsToText } from "@saffron/common";

interface ViewNoteModalProps {
  onRequestClose: () => void;
  menuNote: StandardMenuNoteFragment;
  menuPlannerQueryVariables: MenuPlannerQueryVariables;
}

export const ViewNoteModal: React.FC<ViewNoteModalProps> = ({
  menuNote,
  onRequestClose,
  menuPlannerQueryVariables
}) => {
  const [editMode, setEditMode] = useState(false);
  const [text, setText] = useState(menuNote.text);
  const [deleteNote, { loading }] = useDeleteMenuNoteMutation();
  const [updateNote, { loading: l2 }] = useUpdateMenuNoteMutation();
  return (
    <Modal
      loading={l2}
      secondaryButtonText={editMode ? "CANCEL" : ""}
      secondaryButtonClick={
        editMode
          ? () => {
              setText(menuNote.text);
              setEditMode(false);
            }
          : undefined
      }
      mainButtonText={editMode ? "SAVE" : ""}
      mainButtonClick={
        editMode
          ? () => {
              updateNote(
                updateMenuNoteMutationOptions(
                  {
                    id: menuNote.id,
                    input: {
                      text
                    }
                  },
                  menuPlannerQueryVariables,
                  undefined
                )
              );
              setEditMode(false);
            }
          : undefined
      }
      isOpen={!!menuNote}
      onRequestClose={onRequestClose}
    >
      {editMode ? (
        <TextArea
          autoFocus
          placeholder="note"
          style={{ maxHeight: 200 }}
          value={text}
          onChange={e => setText(e.target.value)}
        />
      ) : (
        <pre
          style={{
            flex: 1,
            color: "#444",
            fontSize: "1rem",
            lineHeight: "1.5rem",
            fontFamily: theme.uiFontStack,
            marginTop: 12,
            maxHeight: 300,
            whiteSpace: "pre-wrap",
            overflowY: "auto",
            overflowWrap: "break-word"
          }}
        >
          <Linkify>{addPrettyFractionsToText(menuNote.text)}</Linkify>
        </pre>
      )}
      {!editMode && menuNote && menuNote.id !== "-1" ? (
        <Flex
          mt="4em"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Icon
            style={{ marginRight: 30 }}
            tooltipText="remove"
            name="circleMinus"
            fill="#666"
            template="recipe"
            onClick={async () => {
              if (loading) {
                return;
              }
              await deleteNote(
                deleteMenuNoteMutationOptions(
                  {
                    id: menuNote.id
                  },
                  menuPlannerQueryVariables
                )
              );
              onRequestClose();
            }}
          />
          <Icon
            name="pencil"
            tooltipText="edit"
            fill="#666"
            template="recipe"
            onClick={() => setEditMode(true)}
          />
        </Flex>
      ) : null}
    </Modal>
  );
};
