import React from "react";
import {
  GroceryRecipeInfoFragment,
  useDeleteGroceryItemsByRecipeMutation,
  deleteGroceryItemsByRecipeMutationOptions
} from "@saffron/controllers";
import Icon from "../../../ui/Icon";
import { theme } from "../../../ui/theme";
import { ConfirmModal } from "../../../ui/ConfirmModal";
import { Link } from "react-router-dom";
import { slugify } from "@saffron/common";

interface RecipeRowProps {
  recipe: GroceryRecipeInfoFragment;
  color: string;
}

const marginTop = 7;

export const RecipeRow: React.FC<RecipeRowProps> = ({ recipe, color }) => {
  const [
    deleteGroceryItemsByRecipe,
    { loading }
  ] = useDeleteGroceryItemsByRecipeMutation();

  return (
    <div style={{ display: "flex", marginBottom: 16 }}>
      <div
        style={{
          width: 11,
          height: 11,
          borderRadius: "50%",
          backgroundColor: color,
          marginTop,
          marginRight: 8
        }}
      />
      <Link
        style={{
          lineHeight: "1.5rem",
          flex: 1,
          fontSize: "1.0rem",
          color: "#444",
          fontFamily: theme.fonts.ui,
          marginRight: 10
        }}
        to={`/a/recipe/${recipe.id}/${slugify(recipe.name)}`}
      >
        {recipe.name}
      </Link>
      <ConfirmModal
        loading={loading}
        title="Confirm"
        description="This will remove all grocery items from this recipe."
        mainButtonText="OK"
        onConfirm={async () => {
          await deleteGroceryItemsByRecipe(
            deleteGroceryItemsByRecipeMutationOptions({
              recipeId: recipe.id
            })
          );
          return;
        }}
      >
        {open => (
          <div>
            <Icon
              style={{ marginTop: 2 }}
              onClick={() => open(true)}
              template="groceryRecipeX"
              name="x"
            />
          </div>
        )}
      </ConfirmModal>
    </div>
  );
};
