import React from "react";
import Icon from "../../ui/Icon";
import {
  RecipePreviewFragment,
  useMoveRecipeSectionsMutation,
  moveRecipeSectionsMutationOptions,
  useAddRecipeToSectionMutation,
  addRecipeToSectionMutationOptions
} from "@saffron/controllers";
import TooltipTrigger from "react-popper-tooltip";
import { ListButton } from "../../ui/ListButton";
import { useRef } from "react";
import { useState } from "react";
import { PickSectionModal } from "../shared/PickSectionModal";
import { notificationState } from "../misc/notifications/NotificationState";

interface Props {
  recipe: RecipePreviewFragment;
  sectionId: string;
  cookbookId: string;
}

export const MoreRicon: React.FC<Props> = ({
  recipe,
  sectionId: currentSectionId,
  cookbookId: currentCookbookId
}) => {
  const [
    moveRecipe,
    { loading: loadingMoveRecipe }
  ] = useMoveRecipeSectionsMutation();
  const [
    addRecipeToSection,
    { loading: loadingAddRecipeToSection }
  ] = useAddRecipeToSectionMutation();
  const [moveOpen, setMoveOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const tooltip = useRef<TooltipTrigger>(null);
  return (
    <>
      <TooltipTrigger
        ref={tooltip}
        placement="bottom"
        trigger="click"
        tooltip={({ tooltipRef, getTooltipProps }) => {
          return (
            <div
              {...getTooltipProps({
                ref: tooltipRef,
                className: "tooltip-container",
                style: {
                  zIndex: 1,
                  width: 200,
                  paddingBottom: 5,
                  paddingTop: 5,
                  borderRadius: 4,
                  backgroundColor: "#fff",
                  boxShadow: "0 3px 12px rgba(27,31,35,.15)",
                  border: "1px solid rgba(27,31,35,.15)"
                }
              })}
            >
              <ListButton
                onClick={() => {
                  setMoveOpen(true);
                }}
              >
                Move Recipe
              </ListButton>
              <ListButton
                onClick={() => {
                  setAddOpen(true);
                }}
              >
                Add to Additional Section
              </ListButton>
            </div>
          );
        }}
      >
        {({ getTriggerProps, triggerRef }) => (
          <div
            {...getTriggerProps({
              ref: triggerRef,
              className: "trigger",
              style: {
                cursor: "pointer"
              }
            })}
          >
            <Icon name="dots" template="recipe" />
          </div>
        )}
      </TooltipTrigger>
      <PickSectionModal
        title="Add Recipe to Additional Cookbook"
        buttonText="ADD"
        open={addOpen}
        loading={loadingAddRecipeToSection}
        cookbookId={currentCookbookId}
        sectionId={currentSectionId}
        onRequestClosed={() => setAddOpen(false)}
        onSubmit={async ({ sectionId }) => {
          await addRecipeToSection(
            addRecipeToSectionMutationOptions(
              {
                recipeId: recipe.id,
                sectionId
              },
              recipe
            )
          );
          notificationState.send({
            text: "successfully added",
            variant: "success"
          });
        }}
      />
      <PickSectionModal
        title="Move Recipe"
        buttonText="MOVE"
        open={moveOpen}
        cookbookId={currentCookbookId}
        loading={loadingMoveRecipe}
        sectionId={currentSectionId}
        onRequestClosed={() => setMoveOpen(false)}
        onSubmit={async ({ sectionId }) => {
          await moveRecipe(
            moveRecipeSectionsMutationOptions(
              {
                options: {
                  recipeId: recipe.id,
                  targetSectionId: sectionId,
                  sectionIdToRemove: currentSectionId
                }
              },
              currentSectionId,
              {
                __typename: "Recipe",
                id: recipe.id,
                name: recipe.name,
                pictureUrl: recipe.pictureUrl,
                smallPictureUrl: recipe.smallPictureUrl,
                source: recipe.source,
                createdAt: recipe.createdAt
              }
            )
          );
          notificationState.send({
            text: "successfully moved",
            variant: "success"
          });
        }}
      />
    </>
  );
};
