import { sortBy } from "@saffron/common";
import {
  createMenuItemsMutationOptions,
  RecipePreviewFragment,
  useCreateMenuItemsMutation,
  useMenuSectionsQuery
} from "@saffron/controllers";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Icon from "../../ui/Icon";
import { LabelText, PrettyInput } from "../../ui/Input";
import { Modal } from "../../ui/Modal";
import { RadioButtonWithLabel } from "../../ui/RadioButtonWithLabel";
import { notificationState } from "../misc/notifications/NotificationState";

interface Props {
  recipe: RecipePreviewFragment;
}

export const MenuPlannerRicon: React.FC<Props> = ({ recipe }) => {
  const [open, setOpen] = useState(false);
  const [menuSectionId, setMenuSectionId] = useState("");
  const [date, setDate] = useState<Date | null>(new Date());
  const [createMenuItems, { loading }] = useCreateMenuItemsMutation();
  const { data: msd } = useMenuSectionsQuery({ skip: !open });
  const menuSections =
    msd && msd.menuSections ? sortBy(msd.menuSections, "position") : [];

  const firstMenuSection = menuSections[0];

  useEffect(() => {
    if (firstMenuSection && !menuSectionId) {
      setMenuSectionId(firstMenuSection.id);
    }
  }, [firstMenuSection, menuSectionId]);

  return (
    <>
      <Icon
        arrow
        tooltipText="add recipe to meal planner"
        name="calendarDate"
        onClick={() => setOpen(true)}
        template="recipe"
      />
      <Modal
        title="Add to Meal Planner"
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        mainButtonText="ADD TO MEAL PLANNER"
        loading={loading}
        mainButtonClick={async () => {
          if (!date || !menuSectionId) {
            return;
          }
          const dtString = dayjs(date).format("YYYY-MM-DD");
          await createMenuItems(
            createMenuItemsMutationOptions(
              {
                menuItems: [
                  {
                    date: dtString,
                    menuSectionId,
                    recipeId: recipe.id
                  }
                ]
              },
              [recipe],
              { startDate: dtString, endDate: dtString }
            )
          );
          notificationState.send({
            text: "successfully added to meal planner",
            variant: "success"
          });
          setOpen(false);
        }}
      >
        <LabelText>date</LabelText>
        <DatePicker
          selected={date}
          onChange={dt => setDate(Array.isArray(dt) ? dt[0] : dt)}
          dateFormat="iiii, LLLL d, y"
          customInput={<PrettyInput />}
        />
        <div
          style={{
            marginTop: "1em",
            maxHeight: 220,
            overflowY: "auto"
          }}
        >
          {menuSections.map((ms, i) => (
            <div key={ms.id} style={{ marginTop: i ? "1em" : 0 }}>
              <RadioButtonWithLabel
                active={ms.id === menuSectionId}
                onClick={() => setMenuSectionId(ms.id)}
                label={ms.name}
              />
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};
