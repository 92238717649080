import styled from "styled-components";

import { theme } from "./theme";

export const BottomButton = styled.div({
  width: "100%",
  padding: "8px 15px",
  fontFamily: theme.fonts.ui,
  color: "#444",
  cursor: "pointer",
  outline: "none",
  lineHeight: "1.625rem",
  fontSize: "1.125rem",
  fontWeight: 600,
  borderTop: theme.border,
  borderBottom: theme.border,
  position: "sticky",
  bottom: 0,
  backgroundColor: "#fafafa",
  zIndex: 2,
  marginTop: "1em",
  ":hover": {
    backgroundColor: theme.hoverColor
  }
});
