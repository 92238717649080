import { RecipeLinkSearchQuery, useRecipeSearch } from "@saffron/controllers";
import { FetchPolicy } from "@apollo/client";
import React, { useState, useRef } from "react";
import { ScrollableBox } from "../../../ui/Box";
import { SmallButton } from "../../../ui/Button";
import { Flex } from "../../../ui/Flex";
import { RecipeCard } from "./RecipeCard";
import { SearchForm, SearchFormValues } from "./SearchForm";

interface State extends SearchFormValues {
  fetchPolicy: FetchPolicy;
}

interface Props<R> {
  query: any;
  initialState?: State;
  onRecipeClick: (recipe: R) => void;
  onSearch?: (data: SearchFormValues) => void;
  recipeIdMap?: { [key: string]: string };
  sidebar?: boolean;
  horizontalRecipeCardMargin?: string;
}

export const RecipeSearch = <
  Q extends RecipeLinkSearchQuery,
  R extends RecipeLinkSearchQuery["recipeSearch"]["recipes"][0]
>({
  recipeIdMap = {},
  onRecipeClick,
  query,
  sidebar,
  horizontalRecipeCardMargin,
  initialState,
  onSearch
}: Props<R>) => {
  const [
    { searchQuery, fetchPolicy, recipesNotInCookbooks },
    setState
  ] = useState<State>(
    initialState || {
      fetchPolicy: "network-only",
      searchQuery: "",
      recipesNotInCookbooks: false
    }
  );
  const atleastOneSearch = useRef(false);

  const search = (data: SearchFormValues) => {
    atleastOneSearch.current = true;
    setState(s => ({ ...s, ...data, fetchPolicy: "network-only" }));
    if (onSearch) {
      onSearch(data);
    }
  };

  const handleAuthorSearch = (author: string) => {
    search({
      searchQuery: author,
      recipesNotInCookbooks: false
    });
  };

  const allRecipesSearch = () => {
    search({
      searchQuery: "show all recipes",
      recipesNotInCookbooks: false
    });
  };

  const variables = {
    searchQuery,
    offset: 0,
    recipesNotInCookbooks
  };

  const {
    recipes,
    hasMoreRecipes,
    loadMore,
    loading,
    refetch
  } = useRecipeSearch<Q, R>({
    query,
    fetchPolicy,
    skip: !searchQuery,
    variables
  });

  const paddingLeftRecipes = "47px";
  const paddingLeft = horizontalRecipeCardMargin
    ? horizontalRecipeCardMargin
    : sidebar
    ? "1em"
    : paddingLeftRecipes;

  return (
    <React.Fragment>
      <SearchForm
        sidebar={sidebar}
        initialValues={{ searchQuery, recipesNotInCookbooks }}
        search={x => {
          if (variables.searchQuery === x.searchQuery) {
            refetch({
              searchQuery: variables.searchQuery,
              offset: 0,
              recipesNotInCookbooks: false
            });
          } else {
            search(x);
          }
        }}
        allRecipesSearch={() => {
          if (variables.searchQuery === "show all recipes") {
            refetch({
              searchQuery: variables.searchQuery,
              offset: 0,
              recipesNotInCookbooks: false
            });
          } else {
            allRecipesSearch();
          }
        }}
      />
      <ScrollableBox flex={1}>
        <div style={{ margin: `0px ${paddingLeft}` }}>
          {!loading && !recipes.length && atleastOneSearch && (
            <div>no results found</div>
          )}
          {recipes.map(r => {
            const sectionName = recipeIdMap[r.id];
            return (
              <RecipeCard<R>
                sidebar={sidebar}
                key={r.id}
                onAuthorClick={handleAuthorSearch}
                onRecipeClicked={onRecipeClick}
                recipe={r}
                disabled={!!sectionName}
                tooltipText={sectionName}
              />
            );
          })}
          {hasMoreRecipes && (
            <Flex>
              <SmallButton m="auto" mb="2em" onClick={loadMore}>
                Load More
              </SmallButton>
            </Flex>
          )}
        </div>
      </ScrollableBox>
    </React.Fragment>
  );
};
