import styled from "styled-components";
import { theme } from "../../../../../ui/theme";

export const EditorBar = styled("div")`
  width: 100%;
  padding: 1em;
  background-color: #f5f4f0;
  display: flex;
  margin-bottom: 2em;
  align-items: center;
  position: sticky;
  top: 0;
`;

export const EditorBarLeft = styled("div")`
  flex: 1;
  display: flex;
  align-items: center;
  & > span {
    margin-right: 1em;
  }
`;

export const EditorContainer = styled("div")`
  margin-top: 1em;
  max-width: 900px;
  min-width: 600px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5em;
`;

export const EditorDiv = styled("div")`
  height: 100%;
  width: 100%;
`;

export const RecipeHeaderContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RecipeHeader = styled("div")`
  font-family: ${theme.primaryFontStack};
  font-size: 1.25rem;
  color: #977535;
  font-style: italic;
  border-bottom: #d6d1c2 1px solid;
  border-top: #d6d1c2 1px solid;
  padding: 0.25em 1.25em;
  margin-bottom: 2em;
`;

export const EditorBlockButton = styled("span")`
  font-family: ${theme.uiFontStack};
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #808080;
  font-size: 1.125rem;
  outline: none;
  &:hover {
    color: #0d0d0d;
  }
`;

export const EditorWrapper = styled("div")`
  margin: 0px 16px;
  height: 100%;
  width: 100%;
`;
