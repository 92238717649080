import React, { useState } from "react";
import ReactModal from "react-modal";
import { Box } from "../../../../../ui/Box";
import { ReverseOutlineButton } from "../../../../../ui/Button";
import { DivButton } from "../../../../../ui/DivButton";
import { FlexCenter } from "../../../../../ui/Flex";
import { H3 } from "../../../../../ui/Heading";
import { ScalingBadge } from "../../../../../ui/Recipe/ScalingBadge";
import { Spinner } from "../../../../../ui/Spinner";
import { theme } from "../../../../../ui/theme";
import { notificationState } from "../../../../misc/notifications/NotificationState";
import {
  MenuDay,
  MenuSectionColor,
  useMenuPlanner,
  useCreateGroceryItemsFromRecipesMutation,
  createGroceryItemsFromRecipesMutationOptions
} from "@saffron/controllers";
import dayjs, { Dayjs } from "dayjs";
import Icon from "../../../../../ui/Icon";
import styled from "styled-components";

interface Props {
  menuDays: MenuDay[];
  menuSections: MenuSectionColor[];
  dateRange: {
    leftDate: Dayjs;
    rightDate: Dayjs;
  } | null;
  setDateRange: (
    x: {
      leftDate: Dayjs;
      rightDate: Dayjs;
    } | null
  ) => void;
}

const RecipeListContainer = styled("div")({
  overflowY: "auto",
  maxHeight: "400px",
  paddingBottom: "0.5em",
  paddingRight: 33,
  paddingLeft: 33
});

export const ConfirmMenuItems: React.FC<Props> = ({
  dateRange,
  menuDays,
  menuSections,
  setDateRange
}) => {
  const leftOutOfBounds =
    !menuDays.length ||
    !dateRange ||
    dayjs(dateRange.leftDate).isBefore(menuDays[0].dt, "day");
  const rightOutOfBounds =
    !menuDays.length ||
    !dateRange ||
    dayjs(dateRange.rightDate).isAfter(menuDays[menuDays.length - 1].dt, "day");
  const skipMenuDaysFetch = !leftOutOfBounds && !rightOutOfBounds;
  const {
    menuDays: fetchedMenuDays,
    loading: menuDaysLoading
  } = useMenuPlanner({
    menuItemsSkip: skipMenuDaysFetch,
    startDate: dateRange && dateRange.leftDate.format("YYYY-MM-DD"),
    endDate: dateRange && dateRange.rightDate.format("YYYY-MM-DD")
  });
  const [
    createGroceryItemsFromRecipes,
    { loading }
  ] = useCreateGroceryItemsFromRecipesMutation();
  const [removed, setRemoved] = useState<Record<string, boolean>>({});

  const currentMenuDays = skipMenuDaysFetch ? menuDays : fetchedMenuDays;

  return (
    <ReactModal
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          boxShadow: "3px 6px 6px rgba(0, 0, 0, 0.16)",
          maxHeight: "88vh",
          maxWidth: "88vw",
          padding: 0,
          border: "1px solid #e6e6e6",
          position: "relative",
          width: 400
        }
      }}
      isOpen={!!dateRange}
      onRequestClose={() => setDateRange(null)}
    >
      <Icon
        style={{ position: "absolute", top: 8, right: 8 }}
        onClick={() => setDateRange(null)}
        width="2em"
        height="2em"
        fill="#ccc"
        name="x"
      />
      {loading || menuDaysLoading ? (
        <FlexCenter>
          <Spinner />
        </FlexCenter>
      ) : (
        <>
          <H3
            style={{ fontSize: "1.375rem" }}
            mt={33}
            mb={12}
            px={33}
            fontFamily="primary"
            fontStyle="italic"
          >
            Add ingredients from these recipes or click to remove
          </H3>
          <RecipeListContainer>
            {currentMenuDays.map(md => {
              if (
                !dateRange ||
                md.dt.isBefore(dateRange.leftDate, "day") ||
                md.dt.isAfter(dateRange.rightDate, "day")
              ) {
                return null;
              }

              let isEmpty = true;
              let isAllRemoved = true;

              const body: JSX.Element[] = [];

              menuSections.forEach(ms => {
                const menuItemsOrNotes =
                  md.menuItemOrNoteSectionMap[ms.id] || [];
                let anyMenuItems = false;
                const group = (
                  <div key={ms.id}>
                    <strong
                      style={{
                        color: ms.color,
                        fontSize: "0.75rem"
                      }}
                    >
                      {ms.name}
                    </strong>
                    {menuItemsOrNotes.map(mi => {
                      anyMenuItems = true;
                      isEmpty = false;
                      if (!removed[mi.id]) {
                        isAllRemoved = false;
                      }
                      const isRemoved = removed[mi.id];
                      if (mi.__typename === "MenuNote") {
                        return null;
                      }
                      return (
                        <div key={mi.id}>
                          <DivButton
                            style={{
                              display: "flex",
                              fontSize: "1.0rem",
                              fontFamily: theme.fonts.ui,
                              color: isRemoved ? "#b3b3b3" : "#444",
                              flex: 1,
                              textDecorationLine: isRemoved
                                ? "line-through"
                                : "",
                              paddingBottom: ".75em"
                            }}
                            onClick={() => {
                              setRemoved({
                                ...removed,
                                [mi.id]: !removed[mi.id]
                              });
                            }}
                          >
                            {mi.recipe.name}
                            {mi.scale !== 1 && (
                              <ScalingBadge noAbsolutePos amount={mi.scale} />
                            )}
                          </DivButton>
                        </div>
                      );
                    })}
                  </div>
                );
                if (anyMenuItems) {
                  body.push(group);
                }
              });

              if (isEmpty) {
                return null;
              }

              return (
                <div key={md.dtKey} style={{ marginTop: "1.5em" }}>
                  <div style={{ display: "flex" }}>
                    <DivButton
                      style={{
                        fontFamily: theme.fonts.ui,
                        color: isAllRemoved ? "#b3b3b3" : "#444",
                        fontSize: "1.125rem",
                        borderBottom: "1px solid #DDE2E3",
                        textDecorationLine: isAllRemoved ? "line-through" : ""
                      }}
                      onClick={() => {
                        const idMap: Record<string, boolean> = {};
                        Object.values(md.menuItemOrNoteSectionMap).forEach(x =>
                          x.forEach(mi => {
                            idMap[mi.id] = isAllRemoved ? false : true;
                          })
                        );
                        setRemoved({
                          ...removed,
                          ...idMap
                        });
                      }}
                    >
                      {md.dt.format("dddd, MMMM D")}
                    </DivButton>
                  </div>
                  {body}
                </div>
              );
            })}
          </RecipeListContainer>
          <Box width="100%" p="33px">
            <ReverseOutlineButton
              style={{ width: "100%" }}
              data-testid="add-to-grocery-submit-button"
              onClick={async () => {
                const recipeScaleMap: Record<string, number> = {};
                currentMenuDays.forEach(md => {
                  if (
                    dateRange &&
                    (md.dt.isBefore(dateRange.leftDate, "day") ||
                      md.dt.isAfter(dateRange.rightDate, "day"))
                  ) {
                    return;
                  }
                  Object.values(md.menuItemOrNoteSectionMap).forEach(x => {
                    x.filter(y => !removed[y.id]).forEach(mi => {
                      if (mi.__typename === "MenuNote") {
                        return;
                      }

                      if (mi.recipe.id in recipeScaleMap) {
                        recipeScaleMap[mi.recipe.id] += mi.scale;
                      } else {
                        recipeScaleMap[mi.recipe.id] = mi.scale;
                      }
                    });
                  });
                });
                const recipeGroceryItemInputs = Object.entries(
                  recipeScaleMap
                ).map(([k, v]) => ({ id: k, scale: v }));
                if (recipeGroceryItemInputs.length) {
                  const response = await createGroceryItemsFromRecipes(
                    createGroceryItemsFromRecipesMutationOptions({
                      input: {
                        recipeGroceryItemInputs
                      }
                    })
                  );
                  if (response && !response.errors) {
                    notificationState.send({
                      text: "ingredients added to your grocery list",
                      variant: "success",
                      icon: "groceryCart"
                    });
                  }
                }
                setDateRange(null);
              }}
            >
              ADD to GROCERY LIST
            </ReverseOutlineButton>
          </Box>
        </>
      )}
    </ReactModal>
  );
};
