import * as Sentry from "@sentry/browser";
import React from "react";
import { FlexCenter } from "../ui/Flex";
import { H3 } from "../ui/Heading";
import { CardLogoLayout } from "../ui/Layout/CardLogoLayout";

export class ErrorBoundary extends React.Component<
  {},
  { hasError: boolean; isUnexpected: boolean }
> {
  static getDerivedStateFromError(_: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, isUnexpected: false };
  }

  constructor(props: {}) {
    super(props);
    this.state = { hasError: false, isUnexpected: false };
  }

  componentDidCatch(error: any, info: any) {
    if (
      (error.message && /Loading chunk \d+ failed/.test(error.message)) ||
      error.message.includes("Unexpected token")
    ) {
      window.location.reload(true);
      return;
    }
    // You can also log the error to an error reporting service
    Sentry.withScope(scope => {
      Object.keys(info).forEach(key => {
        scope.setExtra(key, info[key]);
      });
      scope.setExtra("componentDidCatch", "true");
      scope.setExtra("error.message", error.message);
      scope.setExtra(
        'error.message.includes("Unexpected token"):',
        error.message.includes("Unexpected token")
      );
      Sentry.captureException(error);
    });

    this.setState({ isUnexpected: true });
  }

  render() {
    const { hasError, isUnexpected } = this.state;

    if (hasError && !isUnexpected) {
      return null;
    }

    if (hasError && isUnexpected) {
      // You can render any custom fallback UI
      return (
        <CardLogoLayout>
          <div style={{ padding: "2em 3em", paddingTop: "0" }}>
            <FlexCenter>
              <H3 mt="0">Oops!</H3>
            </FlexCenter>
            Unexpected error. Try refreshing the page and if the error persists,
            send me an email at ben@mysaffronapp.com.
          </div>
        </CardLogoLayout>
      );
    }

    return this.props.children;
  }
}
