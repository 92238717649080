import React from "react";
import { DivButton } from "../../../ui/DivButton";
import { patternWidth, theme } from "../../../ui/theme";
import { skinnySideBarWidth } from "../shared/constants";
import { MenuPlannerCookbooks } from "./MenuPlannerCookbooks";
import { MenuPlannerSearch } from "./MenuPlannerSearch";

interface Props {
  currentTab: string;
  setCurrentTab: (x: string) => void;
}

export const MenuPlannerSidebar: React.FC<Props> = ({
  currentTab,
  setCurrentTab
}) => {
  let body = null;

  if (currentTab === "Search") {
    body = <MenuPlannerSearch />;
  } else {
    body = <MenuPlannerCookbooks />;
  }

  return (
    <div
      style={{
        zIndex: 4,
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        top: "0px",
        height: "100%",
        left: patternWidth,
        width: skinnySideBarWidth,
        backgroundColor: "#fafafa"
      }}
    >
      <div style={{ height: 85, padding: "14px 1em" }}>
        <div
          style={{
            fontFamily: theme.uiFontStack,
            fontSize: "1.563rem",
            color: "#444444",
            lineHeight: 1
          }}
        >
          Meal Planner
        </div>
      </div>
      <div
        style={{
          display: "flex",
          borderTop: "2px solid #666666",
          marginLeft: "1em",
          marginRight: "1em",
          paddingTop: 7
        }}
      >
        {["Cookbooks", "Search"].map((x, i) => (
          <DivButton
            style={{
              fontFamily: theme.uiFontStack,
              color: currentTab === x ? "#977535" : "#444444",
              fontSize: ".875rem",
              marginRight: !i ? 24 : 0,
              textTransform: "uppercase",
              letterSpacing: 0.3
            }}
            key={x}
            onClick={() => setCurrentTab(x)}
          >
            {x}
          </DivButton>
        ))}
      </div>
      <div
        style={{
          flex: 1,
          overflow: "auto",
          display: "flex",
          flexDirection: "column"
        }}
      >
        {body}
      </div>
    </div>
  );
};
