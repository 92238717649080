const drawImageProp = (
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement,
  x: number,
  y: number,
  w: number,
  h: number
) => {
  const offsetX = 0.5;
  const offsetY = 0.5;

  const iw = img.width;
  const ih = img.height;
  const r = Math.min(w / iw, h / ih);
  let nw = iw * r;
  let nh = ih * r;
  let cx: number;
  let cy: number;
  let cw: number;
  let ch: number;
  let ar = 1;

  // decide which gap to fill
  if (nw < w) {
    ar = w / nw;
  }
  if (Math.abs(ar - 1) < 1e-14 && nh < h) {
    ar = h / nh;
  } // updated
  nw *= ar;
  nh *= ar;

  // calc source rectangle
  cw = iw / (nw / w);
  ch = ih / (nh / h);

  cx = (iw - cw) * offsetX;
  cy = (ih - ch) * offsetY;

  // make sure source rectangle is valid
  if (cx < 0) {
    cx = 0;
  }
  if (cy < 0) {
    cy = 0;
  }
  if (cw > iw) {
    cw = iw;
  }
  if (ch > ih) {
    ch = ih;
  }

  // fill image in dest. rectangle
  ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
};

export const cropPictureObjectFitCover = (
  img: HTMLImageElement,
  { height, width } = { height: 600, width: 1200 }
) => {
  width = Math.min(width, img.width);
  height = Math.min(height, img.height);
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  (ctx as any).webkitImageSmoothingEnabled = false;
  (ctx as any).mozImageSmoothingEnabled = false;
  (ctx as any).imageSmoothingEnabled = false;

  drawImageProp(ctx, img, 0, 0, width, height);

  return canvas.toDataURL("image/jpeg", 1);
};
