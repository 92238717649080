import React from "react";
import { EditorBlockButton } from "../../../recipe/shared/RecipeForm/ui/EditorComponents";
import { SymbolModal } from "./SymbolModal";

interface State {
  open: boolean;
}

interface Props {
  onSymbol: (text: string) => void;
}

export class SymbolPicker extends React.PureComponent<Props, State> {
  state = {
    open: false
  };

  close = () => this.setState({ open: false });

  render() {
    return (
      <div style={{ position: "relative" }}>
        <EditorBlockButton
          style={{ marginRight: "1em" }}
          onMouseDown={e => {
            e.preventDefault();
            this.setState({
              open: true
            });
          }}
        >
          Symbols
        </EditorBlockButton>
        {this.state.open && (
          <SymbolModal
            onSymbol={this.props.onSymbol}
            onRequestClose={this.close}
          />
        )}
      </div>
    );
  }
}
