import { ADMIN_STATUS } from "@saffron/common";
import { PossibleIcons } from "../Icon";

export interface NavIcon {
  name: PossibleIcons;
  tooltipText: string;
  to: string;
  status?: string;
}

export const navIconList = [
  {
    to: "/browse",
    name: "book",
    tooltipText: "my cookbooks"
  },
  {
    to: "/search",
    name: "search",
    tooltipText: "search"
  },
  // {
  //   to: "/new-recipe",
  //   name: "circlePlus",
  //   tooltipText: "add new recipe"
  // },
  // {
  //   to: "/my-recipes",
  //   name: "bowl",
  //   tooltipText: "my recipes"
  // },
  // {
  //   to: "/manage-cookbook",
  //   name: "openBook",
  //   tooltipText: "manage cookbook"
  // },
  // {
  //   to: "/fill-cookbook",
  //   name: "archive",
  //   tooltipText: "add recipe to cookbook"
  // },
  {
    to: "menuPlannerPath",
    name: "calendar",
    tooltipText: "meal planner"
    // status: ADMIN_STATUS
  },
  {
    to: "/grocery-list",
    name: "groceryCart",
    tooltipText: "grocery list"
  },
  {
    to: "/account",
    name: "cog",
    tooltipText: "my account"
    // status: ADMIN_STATUS
  },
  {
    to: "/admin/parse-ingredient",
    name: "eye",
    tooltipText: "admin panel",
    status: ADMIN_STATUS
  }
] as NavIcon[];

export const bottomNavIcon: NavIcon = {
  to: "/logout",
  name: "logout",
  tooltipText: "logout"
};
