import styled from "styled-components";

import { space, color, textAlign, fontWeight } from "styled-system";
import { HeadingProps } from "../types";
import { fontStyle, fontFamily } from "../theme";

export const Heading = styled("h3")<HeadingProps>`
  color: #0d0d0d;
  ${color};
  ${textAlign};
  ${fontStyle};
  ${fontWeight};
  ${fontFamily};
  ${space};
`;

export const H1 = Heading.withComponent("h1");
export const H2 = Heading.withComponent("h2");
export const H3 = Heading.withComponent("h3");
export const H4 = Heading.withComponent("h4");
export const H5 = Heading.withComponent("h5");
