import { validUser } from "@saffron/common";
import { Field, FormikErrors, FormikProps, withFormik } from "formik";
import React from "react";
import { SolidButton } from "../../../ui/Button";
import { Flex } from "../../../ui/Flex";
import { FormField } from "../../../ui/Forms/FormField";
import { FormGroup } from "../../../ui/Forms/FormGroup";
import { CheckboxField } from "../../shared/formFields/CheckboxField";
import { FormInput } from "../../shared/formFields/FormInput";
import { ButtonSpinner } from "../../../ui/Button/ButtonSpinner";

export interface RegisterFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  receiveNewFeatureEmails: boolean;
}

interface Props {
  handleSubmit: (
    values: RegisterFormValues
  ) => Promise<FormikErrors<RegisterFormValues> | null>;
}

class C extends React.Component<Props & FormikProps<RegisterFormValues>> {
  render() {
    const { handleSubmit, isSubmitting } = this.props;

    return (
      <form style={{ padding: "0 1.8em" }} onSubmit={handleSubmit}>
        <FormGroup>
          <FormField>
            <Field
              name="firstName"
              placeholder="First Name"
              component={FormInput}
            />
          </FormField>
          <FormField>
            <Field
              name="lastName"
              placeholder="Last Name"
              component={FormInput}
            />
          </FormField>
          <FormField>
            <Field
              type="email"
              name="email"
              placeholder="Email"
              autoComplete="username"
              component={FormInput}
            />
          </FormField>
          <FormField>
            <Field
              type="password"
              name="password"
              placeholder="Password"
              autoComplete="new-password"
              component={FormInput}
            />
          </FormField>
          <FormField>
            <Field
              name="receiveNewFeatureEmails"
              label="email me when new features are available"
              component={CheckboxField}
            />
          </FormField>
        </FormGroup>
        <Flex pt={20}>
          <SolidButton
            style={{ maxWidth: 142, height: 41, width: "100%" }}
            type="submit"
            hoverColor="blue"
            mx="auto"
            bg="lightBlue"
            color="brightWhite"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <ButtonSpinner color="#fff" style={{ margin: "auto" }} />
            ) : (
              "SIGN UP"
            )}
          </SolidButton>
        </Flex>
      </form>
    );
  }
}

(C as any).displayName = "RegisterForm";

export const RegisterForm = withFormik<Props, RegisterFormValues>({
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: validUser,
  mapPropsToValues: () => ({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    receiveNewFeatureEmails: true
  }),
  handleSubmit: async (formValues, { props, setErrors, setSubmitting }) => {
    const errors = await props.handleSubmit(formValues);
    if (errors) {
      setErrors(errors);
      setSubmitting(false);
    }
  }
})(C as any);
