// @ts-ignore
import { css, createGlobalStyle } from "styled-components";
import { theme } from "./theme";

export const GlobalStyle = createGlobalStyle`
*,
*:before,
*:after {
    box-sizing: border-box;
}
html {
    font-size: 16px;
    height: 100%;
    width: 100%;
    @media only screen and (max-width: 1170px) {
      font-size: 14px;
    }
}
#root {
    height: 100%;
    width: 100%;
}
html,
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #FAFAFA;
    color: #0d0d0d;
    font-family: ${theme.secondaryFontStack};
    font-weight: 400;
    line-height: 1.45;
    margin: 0;
    text-rendering: optimizeLegibility;
    height: 100%;
    width: 100%;
}
p {
    margin-bottom: 1.3em;
}
h1, h2, h3, h4 {
  margin: 1.414em 0 0.5em;
  font-weight: inherit;
  line-height: 1.2;
}
h1 {
    margin-top: 0;
    font-size: 2.441rem;
}
h2 {
    font-family: ${theme.secondaryFontStack};
    font-size: 1.953rem;
    font-style: italic;
    font-weight: 400;
    margin: auto;
    padding-top: 1em;
    padding-bottom: .563em;
    width: 93%;
}

h3 {
    font-family: ${theme.primaryFontStack};
    font-size: 1.563rem;
}
h4 {
    font-family: ${theme.secondaryFontStack};
    font-size: 1.25rem;
    font-weight: 400;
}
h5 {
    font-family: ${theme.secondaryFontStack};
    font-size: 1rem;
    font-weight: 400;
}
small, .font_small {
    font-size: 0.8rem;
}
h5 {
    font-size: 1rem;
}

img {
    max-width: 100%;
    height: auto;
}

a {
  color: #444;
}
a:link {
    text-decoration: none;
}
a:visited {
    color: #666;
}
a:active {
    color: #824028;
}
input {
    font-size: 1.0rem;
}
/*
doesn't work on safari
.ReactModal__Body--open > div {
    position: fixed;
    overflow: hidden;
}
*/
.FullscreenRecipe--open {
    position: fixed;
    overflow: hidden;
}
.fill-cb-in-section-recipe-enter {
  color: rgb(198, 161, 91);
}
.draftjs-ingredient-header {
  grid-column: 1/3;
  color: #824028;
  font-size: 1.563rem;
  font-style: normal;
  font-weight: 400;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0.563em;
  padding-top: 1em;
  padding-bottom: 0.125em;
  width: 93%;
}
.draftjs-instruction-header {
  margin-top: 1em;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.0262em;
  text-transform: uppercase;
  margin-bottom: 0.262em;
}
.draftjs-ingredient-unstyled {
  font-family: ${theme.secondaryFontStack};
  padding-bottom: 1em;
}
.draftjs-instruction-unstyled {
  font-family: ${theme.primaryFontStack};
  padding-bottom: 1em;
}
.ReactModal__Overlay {
    z-index: 6;
}
.react-datepicker-wrapper {
  display: flex!important;
  align-items: center!important;
}
.react-datepicker__day--selected {
  background-color: #3F7C94 !important;
}
.react-datepicker__header {
  background-color: #EAE9E1 !important;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: #eae9e1 !important;
}
.react-datepicker__navigation--previous {
  border-right-color: #c5c1a0 !important;
}
.react-datepicker__navigation--next {
  border-left-color: #c5c1a0 !important;
}
.react-datepicker__month-container {
  box-shadow: rgba(0, 0, 0, 0.16) 3px 6px 6px;
}
.tooltip-container {
  z-index: 10;
}
`;

const breakpoints = {
  laptop: 1478,
  smallLaptop: 1176,
  ipadPro: 1025,
  filterIngredientModalBreaks: 979,
  almostIpad: 856,
  almostPhone: 670
};

export const queries = Object.keys(breakpoints).reduce(
  (accumulator: any, label: string) => {
    accumulator[label] = (...args: any[]) =>
      css`
        @media (max-width: ${breakpoints[
            label as keyof typeof breakpoints
          ]}px) {
          ${(css as any)(...args)};
        }
      `;

    return accumulator;
  },
  {}
) as { [key in keyof typeof breakpoints]: any };
