import styled from "styled-components";

export const ButtonSpinner = styled("div")<{ color?: string }>`
  border: 2px solid ${p => p.color || "#997535"};
  border-top: 2px solid transparent;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-right: 1em;
  animation: 0.75s linear 0s infinite normal both running spin;

  @keyframes spin {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(180deg) scale(0.8);
    }
    100% {
      transform: rotate(360deg) scale(1);
    }
  }
`;
