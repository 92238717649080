import { FieldProps, getIn } from "formik";
import React, { useState, useEffect } from "react";
import { DropzoneProps, useDropzone } from "react-dropzone";
import GhostButton from "../../../ui/Button";
import { cropPictureObjectFitCover } from "../cropPicture";
import { ErrorText } from "../../../ui/ErrorText";
import { Modal } from "../../../ui/Modal";
import Icon from "../../../ui/Icon";
import styled from "styled-components";
import { theme } from "../../../ui/theme";

const Title = styled.div({
  marginTop: 10,
  fontFamily: theme.uiFontStack,
  fontSize: "20px",
  color: "#444444"
});

const Desc = styled.div({
  fontFamily: theme.uiFontStack,
  fontSize: "16px",
  color: "#999999"
});

export const DropzoneFormInput: React.FC<FieldProps<any> &
  DropzoneProps & { text: string; buttonWidth?: number }> = ({
  field: { name }, // { name, value, onChange, onBlur }
  form: { errors, setFieldError, setFieldValue, validateForm }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  text,
  buttonWidth,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: "image/*",
    onDrop: ([file]: File[]) => {
      if (!file) {
        setFieldError(name, "Invalid image");
        return;
      }

      // 5mb
      if (file.size > 5000000) {
        setFieldError(name, "Image cannot be larger than 5 MB");
      } else {
        const preview = URL.createObjectURL(file);
        if (preview) {
          const image = new Image();
          image.onload = () => {
            const croppedBase64 = cropPictureObjectFitCover(image, {
              height: 800,
              width: 1600
            });
            setFieldValue(name, croppedBase64);
            validateForm();
            if (preview) {
              window.URL.revokeObjectURL(preview);
            }
          };
          image.src = preview;
        }
      }
    },
    ...props
  });

  useEffect(() => {
    if (!open) {
      return;
    }

    const pasteHandler = (event: ClipboardEvent) => {
      if (!event.clipboardData || !event.clipboardData.items) {
        return;
      }

      const { items } = event.clipboardData;

      let blob = null;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") === 0) {
          blob = items[i].getAsFile();
          break;
        }
      }

      if (blob !== null) {
        var reader = new FileReader();
        reader.onload = e => {
          if (e.target && e.target.result) {
            const image = new Image();
            image.onload = () => {
              const croppedBase64 = cropPictureObjectFitCover(image, {
                height: 800,
                width: 1600
              });
              setFieldValue(name, croppedBase64);
              validateForm();
            };
            if (typeof e.target.result === "string") {
              image.src = e.target.result;
            }
          }
        };
        reader.readAsDataURL(blob);
      }
    };
    document.addEventListener("paste", pasteHandler);
    return () => {
      document.removeEventListener("paste", pasteHandler);
    };
  }, [open, name, setFieldValue, validateForm]);

  const errorText = getIn(errors, name);
  return (
    <>
      <GhostButton
        onClick={() => setOpen(true)}
        style={{
          minWidth: buttonWidth,
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        type="button"
      >
        <Icon
          style={{ marginRight: 6 }}
          name="picture"
          template="ghostButtonSmall"
        />
        {text}
      </GhostButton>
      <Modal
        title="Image Upload"
        isOpen={open}
        onRequestClose={() => setOpen(false)}
      >
        <div
          {...getRootProps({
            style: {
              marginTop: 10,
              height: 300,
              border: "1px dashed #444444",
              boxSizing: "border-box",
              borderRadius: "3px",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              backgroundColor: isDragActive ? "#fafafa" : undefined
            }
          })}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <>
              <Icon fill="#666666" width={70} height={70} name="picture" />
              <Title>Drop your image here ...</Title>
              <Desc>&nbsp;</Desc>
            </>
          ) : (
            <>
              <Icon fill="#666666" width={70} height={70} name="picture" />
              <Title>Select Image to Upload</Title>
              <Desc>or Drag and Drop, Copy and Paste an Image</Desc>
            </>
          )}
        </div>
        {errorText && <ErrorText>{errorText}</ErrorText>}
      </Modal>
    </>
  );
};
