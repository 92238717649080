import styled from "styled-components";
import { theme } from "./theme";

export const BoldSubhead = styled.h3({
  margin: 0,
  fontFamily: theme.fonts.ui,
  fontWeight: 600,
  fontSize: "1.5rem",
  lineHeight: "1.875rem",
  color: "#333333"
});
