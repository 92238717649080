import React from "react";
import { MenuPlannerRecipeSearchQuery } from "@saffron/controllers";
import { useDrag } from "react-dnd";
import { ItemTypes } from "../shared/ItemTypes";
import { theme } from "../../../ui/theme";
import styled from "styled-components";
import { NoImageSvgData } from "../../../ui/NoImageSvgData";
import { Stars } from "../../../ui/Stars";

interface Props {
  r: MenuPlannerRecipeSearchQuery["recipeSearch"]["recipes"][0];
}

const imgHeight = "74px";

const NoImage = styled("div")<{ sidebar?: boolean }>`
  background-color: #c5bfa9;
  background-image: url(${NoImageSvgData});
  background-size: contain;
  height: ${imgHeight};
  width: 100%;
`;

const Container = styled("div")({
  "&:hover": {
    // boxShadow: "3px 6px 6px rgba(0, 0, 0, 0.16)"
    boxShadow: "0 0 15px rgba(0,0,0,.3)"
  }
});

export const DraggableRecipeCard: React.FC<Props> = ({ r }) => {
  const [, drag] = useDrag({
    item: { type: ItemTypes.MENU_ITEM, recipe: r }
    // collect: monitor => {
    //   return {
    //     isDragging: !!monitor.isDragging()
    //   };
    // }
  });

  return (
    <Container
      ref={drag}
      style={{
        border: theme.border,
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        marginBottom: 20,
        cursor: "move"
      }}
      key={r.id}
    >
      {r.pictureUrl ? (
        <img
          style={{ width: "100%", height: imgHeight, objectFit: "cover" }}
          src={r.pictureUrl}
          alt={r.name}
        />
      ) : (
        <NoImage />
      )}
      <div
        style={{
          display: "flex",
          padding: 6,
          paddingBottom: r.rating ? 6 : 12
          // height: 48
        }}
      >
        <div
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            flex: 1,
            overflow: "hidden",
            margin: "auto"
          }}
        >
          <p
            style={{
              wordBreak: "break-word",
              fontFamily: theme.fonts.ui,
              fontSize: "14px", // not using rems on purpose here, we don't want the size to shrink
              fontWeight: 600,
              lineHeight: 1.25,
              color: "#444",
              margin: 0,
              textAlign: "center"
            }}
          >
            {r.name}
          </p>
        </div>
      </div>
      {r.rating ? (
        <div
          style={{
            paddingBottom: 8,
            display: "flex",
            justifyContent: "center",
            width: "100%"
          }}
        >
          <Stars size=".75em" numStars={r.rating} />
        </div>
      ) : null}
    </Container>
  );
};
