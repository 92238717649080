import { emptyValueJSON } from "@saffron/common";
import { Node } from "slate";

export const instructionsValueToPlainText = (value: Node[]) => {
  return value
    .map(n => {
      if (n.type === "header-four") {
        return `${Node.string(n)}:`;
      }

      return Node.string(n);
    })
    .join("\n");
};

export const plainTextToInstructionsValueJSON = (s: string) => {
  const paragraphs = s.split("\n");
  if (!paragraphs.length) {
    return emptyValueJSON;
  }

  return paragraphs.map(line => {
    const isHeader = line.endsWith(":");
    return {
      children: [
        {
          text: isHeader ? line.slice(0, line.length - 1) : line
        }
      ],
      type: isHeader ? "header-four" : "paragraph"
    };
  });
};
