import { MenuPlannerQuery } from "@saffron/controllers";
import React from "react";
import { useDrag } from "react-dnd";
import { ItemTypes } from "../../shared/ItemTypes";
import { DivButton } from "../../../../ui/DivButton";
import { ScalingBadge } from "../../../../ui/Recipe/ScalingBadge";
import { theme } from "../../../../ui/theme";
import { addPrettyFractionsToText } from "@saffron/common";

interface Props {
  menuItemOrNote: MenuPlannerQuery["menuPlanner"][0];
  onScaleClick: (menuItem: MenuPlannerQuery["menuPlanner"][0]) => void;
  onClick: () => void;
}

export const DraggableMenuItem: React.FC<Props> = ({
  menuItemOrNote,
  onClick,
  onScaleClick
}) => {
  const [{ isDragging }, drag] = useDrag({
    canDrag: menuItemOrNote.id !== "-1",
    item:
      menuItemOrNote.__typename === "MenuItem"
        ? {
            type: ItemTypes.MENU_ITEM,
            recipe: menuItemOrNote.recipe,
            menuItemId: menuItemOrNote.id
          }
        : {
            type: ItemTypes.MENU_NOTE,
            menuNoteId: menuItemOrNote.id
          },
    collect: monitor => {
      return {
        isDragging: !!monitor.isDragging()
      };
    }
  });
  const isNote = "text" in menuItemOrNote;
  return (
    <DivButton
      style={{
        cursor: isDragging ? "move" : "pointer",
        fontSize: "0.875rem",
        display: "flex",
        wordBreak: "break-word",
        paddingBottom: 10,
        alignItems: "center",
        userSelect: "none"
      }}
      onClick={onClick}
      ref={drag}
    >
      <div
        style={{
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical"
        }}
      >
        <p
          style={{
            fontFamily: isNote ? theme.primaryFontStack : undefined,
            fontStyle: isNote ? "italic" : undefined,
            fontSize: isNote ? "0.8125rem" : undefined,
            overflow: "hidden",
            margin: 0
          }}
        >
          {"text" in menuItemOrNote
            ? addPrettyFractionsToText(menuItemOrNote.text)
            : menuItemOrNote.recipe.name}
        </p>
      </div>
      {"scale" in menuItemOrNote && menuItemOrNote.scale !== 1 && (
        <div style={{ marginLeft: 4 }}>
          <ScalingBadge
            noAbsolutePos
            amount={menuItemOrNote.scale}
            onClick={e => {
              e.stopPropagation();
              onScaleClick(menuItemOrNote);
            }}
          />
        </div>
      )}
    </DivButton>
  );
};
