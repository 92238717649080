import { createBrowserHistory } from "history";
import ReactGA from "react-ga";

const history = createBrowserHistory();

history.listen(location => {
  if (process.env.NODE_ENV === "production") {
    ReactGA.pageview(location.pathname);
  }
});

export default history;
