import { MeUserFragment, useCancelledAccountInfo } from "@saffron/controllers";
import React, { useState } from "react";
import { GoldCard } from "./GoldCard";
import { StartSubscriptionButton } from "./StartSubscriptionButton";
import { YearlyToggle } from "../../../ui/YearlyToggle";

interface Props {
  user: MeUserFragment;
}

export const CancelledUser: React.FC<Props> = ({ user }) => {
  const [yearly, setYearly] = useState(true);
  const {
    expirationDate,
    title,
    cancelledCopy,
    upgradeCost
  } = useCancelledAccountInfo(user, yearly);
  return (
    <GoldCard
      style={{ height: 450, maxWidth: 330, width: "100%" }}
      goldText="YOUR PLAN"
      title={title}
    >
      <div>
        {expirationDate ? (
          <div>
            Expires: <strong>{expirationDate}</strong>
          </div>
        ) : (
          <div>Subscription expired</div>
        )}
        <div style={{ marginTop: "1em" }}>{cancelledCopy}</div>
      </div>
      <YearlyToggle yearly={yearly} onSwitch={setYearly} cost={upgradeCost} />
      <StartSubscriptionButton yearly={yearly} user={user}>
        RESUME SUBSCRIPTION
      </StartSubscriptionButton>
    </GoldCard>
  );
};
