import React from "react";
import { Modal } from "../../../ui/Modal";
import {
  useUpdateProfileMutation,
  updateProfileMutationOptions
} from "@saffron/controllers";
import { Formik, Form, Field } from "formik";
import { FormInput } from "../../shared/formFields/FormInput";
import { FormField } from "../../../ui/Forms/FormField";

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  firstName: string;
  lastName: string;
}

export const UpdateProfileModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  firstName,
  lastName
}) => {
  const [updateProfile] = useUpdateProfileMutation();
  return isOpen ? (
    <Formik
      initialValues={{ firstName, lastName }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await updateProfile(updateProfileMutationOptions({ input: values }));
          onRequestClose();
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Modal
          title="Edit Profile"
          mainButtonText="SAVE"
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          loading={isSubmitting}
          mainButtonClick={handleSubmit}
        >
          <Form>
            <FormField>
              <Field
                placeholder="first name"
                name="firstName"
                component={FormInput}
              />
            </FormField>
            <Field
              placeholder="last name"
              name="lastName"
              component={FormInput}
            />
          </Form>
        </Modal>
      )}
    </Formik>
  ) : null;
};
