import React from "react";
import { theme } from "../../../ui/theme";

interface Props {
  goldText: string;
  title: string;
  style?: React.CSSProperties;
}

export const GoldCard: React.FC<Props> = ({
  style = {},
  goldText,
  title,
  children
}) => {
  return (
    <div
      style={{
        backgroundColor: "#fff",
        paddingBottom: 24,
        paddingTop: 20,
        paddingLeft: 26,
        paddingRight: 26,
        border: "1px solid #DDE2E3",
        display: "flex",
        flexDirection: "column",
        ...style
      }}
    >
      <div
        style={{
          paddingTop: 6,
          borderTop: "1px solid #979797",
          fontWeight: 900,
          fontSize: "0.875rem",
          letterSpacing: "0.026em",
          color: "#91763F"
        }}
      >
        {goldText}
      </div>
      <div
        style={{
          fontFamily: theme.primaryFontStack,
          fontSize: "2.0rem",
          color: "#333333",
          fontStyle: "italic",
          marginBottom: 18
        }}
      >
        {title}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        {children}
      </div>
    </div>
  );
};
