import  React from "react";
import { FieldProps } from "formik";

import { Checkbox } from "../../../ui/Checkbox";

export class CheckboxField extends React.PureComponent<
  FieldProps<any> & {
    label?: string;
    style: any;
    afterChange: (e: any) => void;
  }
> {
  render() {
    const {
      field: { value, onChange: _, ...field },
      form: { setFieldValue },
      label,
      afterChange,
      ...props
    } = this.props;
    return (
      <Checkbox
        label={label}
        onChange={newValue => {
          setFieldValue(field.name, newValue);
        }}
        value={value}
        afterChange={afterChange}
        {...field}
        {...props}
      />
    );
  }
}
