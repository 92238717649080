import  React from "react";
import { Flex } from "../Flex";
import { PatternCard } from "../Card";
import { PossibleBackgroundImages } from "../types";

interface Props {
  height?: number;
  width?: string | number | string[] | number[];
  innerPadding?: string;
  patternImage?: PossibleBackgroundImages;
}

export class SimpleCard extends React.PureComponent<Props> {
  render() {
    const {
      width = 400,
      height = 400,
      innerPadding,
      patternImage
    } = this.props;
    return (
      <Flex flexDirection="row" width="100%">
        <Flex flex="1" backgroundImage="blue" justifyContent="center">
          <PatternCard
            patternImage={patternImage}
            width={width}
            bg="brightWhite"
            my="auto"
            flexDirection="column"
            p={innerPadding}
          >
            <Flex alignItems="center" justifyContent="center" height={height}>
              {this.props.children}
            </Flex>
          </PatternCard>
        </Flex>
      </Flex>
    );
  }
}
