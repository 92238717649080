import React from "react";
import { Pattern } from "../Pattern";

interface Props {
  pattern?: boolean;
}

export class SideBarLayout extends React.PureComponent<Props> {
  render() {
    const { pattern = true } = this.props;
    return (
      <>
        {this.props.children}
        {pattern && <Pattern />}
      </>
    );
  }
}
