import { Node } from "slate";

export const slateSerialize = (value: Node[]) => {
  return value.map(n => Node.string(n)).join("\n");
};

export const isSlateTextEqual = (n1: Node[], n2: Node[]) => {
  if (n1.length !== n2.length) {
    return false;
  }

  for (let i = 0; i < n1.length; i++) {
    if (Node.string(n1[i]) !== Node.string(n2[i])) {
      return false;
    }
  }

  return true;
};
