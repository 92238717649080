import React from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../../shared/ItemTypes";
import {
  useCreateMenuItemsMutation,
  useUpdateMenuItemMutation,
  updateMenuItemMutationOptions,
  createMenuItemsMutationOptions,
  MenuDay,
  MenuSectionColor,
  MenuPlannerQuery,
  MenuPlannerQueryVariables,
  updateMenuNoteMutationOptions,
  useUpdateMenuNoteMutation
} from "@saffron/controllers";

interface Props {
  k: number;
  lastSection: boolean;
  gridColumn: number;
  gridRow: number;
  menuItems: MenuPlannerQuery["menuPlanner"];
  md: MenuDay;
  ms: MenuSectionColor;
  month?: boolean;
  menuPlannerQueryVariables: MenuPlannerQueryVariables;
}

export const MenuSectionDragSource: React.FC<Props> = ({
  children,
  k,
  md,
  ms,
  lastSection,
  gridColumn,
  menuItems,
  gridRow,
  month,
  menuPlannerQueryVariables
}) => {
  const [createMenuItems] = useCreateMenuItemsMutation();
  const [updateMenuItem] = useUpdateMenuItemMutation();
  const [updateMenuNote] = useUpdateMenuNoteMutation();

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: [ItemTypes.MENU_ITEM, ItemTypes.MENU_NOTE],
    drop: ({ recipe, menuItemId, menuNoteId }) => {
      if (menuNoteId) {
        updateMenuNote(
          updateMenuNoteMutationOptions(
            {
              id: menuNoteId,
              input: {
                date: md.dtKey,
                menuSectionId: ms.id
              }
            },
            menuPlannerQueryVariables,
            undefined,
            true
          )
        );
      } else if (menuItemId) {
        updateMenuItem(
          updateMenuItemMutationOptions(
            {
              menuItem: {
                id: menuItemId,
                date: md.dtKey,
                menuSectionId: ms.id
              }
            },
            menuPlannerQueryVariables
          )
        );
      } else {
        createMenuItems(
          createMenuItemsMutationOptions(
            {
              menuItems: [
                {
                  date: md.dtKey,
                  menuSectionId: ms.id,
                  recipeId: recipe.id
                }
              ]
            },
            [recipe],
            menuPlannerQueryVariables
          )
        );
      }
    },
    collect: mon => ({
      isOver: !!mon.isOver(),
      canDrop: !!mon.canDrop()
    }),
    canDrop: ({ menuItemId, menuNoteId }: any) => {
      return !menuItems.find(x => x.id === menuItemId || x.id === menuNoteId);
    }
  });

  return (
    <div
      ref={drop}
      style={{
        minHeight: 40,
        marginTop: !k ? 50 : 0,
        marginBottom: month ? undefined : lastSection ? 40 : 0,
        paddingBottom: lastSection ? 10 : 0,
        paddingLeft: 10,
        backgroundColor: canDrop && isOver ? "#F6F3E1" : undefined,
        paddingRight: 10,
        gridColumn,
        gridRow: gridRow + k + 1
      }}
    >
      {children}
    </div>
  );
};
