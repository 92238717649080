import styled, { css } from "styled-components";
import { borders, color, space, width } from "styled-system";
import { hoverColor, theme } from "../theme";
import {
  BorderProps,
  ColorProps,
  HoverColorProps,
  SpaceProps,
  WidthProps
} from "../types";
import { Link } from "react-router-dom";

export const baseButton = css`
  outline: none;
  padding: 8px 1.6em;
  text-align: center;
  text-decoration: none;
  border: none;
  display: inline-block;
  letter-spacing: 0.026em;
  cursor: pointer;
  font-size: 0.875rem;
  border-radius: 3px;
`;

const ghostButtonThemes = {
  default: `
  border: solid 1px #dde3e3;
  color: #444;
  &:hover {
    color: #333333;
    background-color: ${theme.hoverColor};
  }
  `,
  gold: `
  border: solid 1px #D6D1C2;
  color: #977535;
  &:hover {
    background-color: #E3E0DA;
  }
  `,
  white: `
  background-color: transparent;
  border: solid 1px #dde3e3;
  color: #0d0d0d;
  &:hover {
    color: #333333;
    background-color: ${theme.hoverColor};
  }
  `,
  "light grey": `
  background-color: #DDE2E3;
  color: #444444;
  &:hover {
    background-color: #BCC6C8;
  }
  `
};

type GBProps = SpaceProps & { variant?: keyof typeof ghostButtonThemes };

export const GhostButton = styled("button")<GBProps>`
  ${space};
  ${baseButton};
  font-family: ${theme.uiFontStack};
  text-transform: uppercase;
  background-color: transparent;
  ${p => ghostButtonThemes[p.variant || "default"]};
`;

export const BoldButton = styled("button")<SpaceProps & WidthProps>`
  ${width};
  ${space};
  ${baseButton};
  font-family: ${theme.uiFontStack};
  background-color: #824027;
  border: solid 1px #824027;
  color: #fff;
  &:hover {
    background-color: #531410;
    border: solid 1px #531410;
  }
`;

const blackOutlineButtonStyle = css`
  background-color: Transparent;
  color: #444;
  border: solid 1px #444;
  &:hover {
    background-color: #444;
    color: #fff;
  }
`;

const redOutlineButtonStyle = css`
  background-color: Transparent;
  color: #531410;
  border: solid 1px #531410;
  &:hover {
    color: #ffffff;
    background-color: #531410;
  }
`;

const outlineButtonStyleMap = {
  black: blackOutlineButtonStyle,
  red: redOutlineButtonStyle,
  gray: css`
    text-transform: none;
    background-color: Transparent;
    color: #999999;
    border: solid 1px #999999;
    &:hover {
      color: #ffffff;
      background-color: #999999;
    }
    & svg {
      fill: #999999;
    }
    &:hover svg {
      fill: #ffffff;
    }
  `,
  gold: css`
    background-color: Transparent;
    color: #444;
    border: solid 1px #c5bfa9;
    font-size: 1rem;
    &:hover {
      background-color: #c5bfa9;
    }
  `
};

type OBProps = { color?: keyof typeof outlineButtonStyleMap };

export const OutlineButton = styled("button")<OBProps>`
  ${baseButton};
  font-family: ${theme.uiFontStack};
  text-transform: uppercase;
  ${p => outlineButtonStyleMap[p.color || "red"]};
`;

export const OutlineButtonA = OutlineButton.withComponent("a");
export const OutlineButtonLink = OutlineButton.withComponent(Link);

const reverseOutlineButtonButtonStyleMap = {
  default: css`
    background-color: #0d0d0d;
    border: solid 1px #0d0d0d;
    color: #fff;
    &:hover,
    &:disabled {
      color: #fff;
      background-color: #333;
      border-color: #333;
    }
  `,
  offWhite: css`
    background-color: #0d0d0d;
    border: solid 1px #0d0d0d;
    color: #fff;
    &:hover,
    &:disabled {
      color: #0d0d0d;
      background-color: #fafafa;
      border-color: #333;
    }
  `,
  red: css`
    background-color: #972409;
    border: solid 1px #972409;
    color: #fff;
    &:hover,
    &:disabled {
      color: #fff;
      background-color: #d13c1e;
      border-color: #d13c1e;
    }
  `
};

export const ReverseOutlineButton = styled("button")<{
  variant?: keyof typeof reverseOutlineButtonButtonStyleMap;
}>`
  ${baseButton};
  font-family: ${theme.uiFontStack};
  ${p => reverseOutlineButtonButtonStyleMap[p.variant || "default"]}
`;

export const SmallButton = styled("button")<SpaceProps & { small?: boolean }>`
  outline: none;
  background-color: #666;
  border: none;
  color: #fff;
  padding: ${p => (p.small ? "3.25px .75em" : "5.5px 1em")};
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: ${p => (p.small ? "0.875rem" : "1rem")};
  font-family: ${theme.uiFontStack};
  &:hover {
    opacity: 0.75;
  }
  ${space};
`;

export const BigLandingPageButton = styled("button")`
  ${baseButton};
  font-family: ${theme.secondaryFontStack};
  background-color: #333;
  color: #fff;
  border: solid 1px #808080;
  &:hover {
    background-color: #fff;
    color: #333;
  }
`;

type SBProps = SpaceProps & ColorProps & HoverColorProps;

export const SolidButton = styled("button")<SBProps>`
  ${baseButton};
  ${space};
  ${color};
  &:hover {
    ${hoverColor};
  }
  font-family: ${theme.uiFontStack};
  font-size: 1.25rem;
  font-weight: 600;
`;

type TBProps = BorderProps & { noHover?: boolean };

export const TransparentButton = styled("button")<TBProps>`
  ${baseButton};
  border: none;
  ${borders};
  ${p =>
    p.border
      ? `
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 13px;
  padding-right: 13px;
      `
      : `
  padding-bottom: 1px;
  padding-top: 1px;
  padding-left: 14px;
  padding-right: 14px;
  `};
  font-size: 0.875rem;
  font-family: ${theme.uiFontStack};
  background-color: transparent;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  border-radius: 1em;
  outline: none;
  ${p =>
    p.noHover
      ? "cursor: default;"
      : `&:hover {
    color: #333333;
    background-color: #ebeeee;
  }`};
`;

export const ArrowButton = styled("button")<SpaceProps>`
  ${space};
  height: 100%;
  padding: 1px 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-family: ${theme.uiFontStack};
  background-color: #f2f2f2;
  color: #808080;
  border: solid 1px #dde2e3;
  text-transform: uppercase;
  &:hover {
    background-color: #c6a15b;
    padding: 2px 1px;
    border: 0;
  }
`;

export default GhostButton;
