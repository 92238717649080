import { Container } from "unstated";
import { MP_DATE_SIZE_PREFERENCE } from "./NavBarWatcher";

export class NavBarContainer extends Container<{ menuPlannerPath: string }> {
  constructor() {
    super();
    let dateSize = "week";
    try {
      dateSize = localStorage.getItem(MP_DATE_SIZE_PREFERENCE) || "week";
    } catch {}
    if (dateSize !== "week" && dateSize !== "month") {
      dateSize = "week";
    }

    const menuPlannerPath = `/menu-planner/${dateSize}`;
    this.state = {
      menuPlannerPath
    };
  }

  setMenuPlannerPath = (s: string) => this.setState({ menuPlannerPath: s });
}

export const navBarContainer = new NavBarContainer();
