import * as React from "react";
import { Modal } from "./Modal";

interface Props {
  title: string;
  description: string;
  mainButtonText: string;
  onConfirm: () => Promise<void>;
  children: (setOpen: (x: boolean) => void) => JSX.Element | null;
  loading?: boolean;
}

export const ConfirmModal: React.FC<Props> = ({
  title,
  description,
  mainButtonText,
  onConfirm,
  children,
  loading
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        title={title}
        secondaryButtonText="CANCEL"
        secondaryButtonClick={() => setOpen(false)}
        mainButtonText={mainButtonText}
        loading={loading}
        mainButtonClick={async () => {
          await onConfirm();
          setOpen(false);
        }}
      >
        <div>{description}</div>
      </Modal>
      {children(setOpen)}
    </>
  );
};
