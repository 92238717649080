import { RecipeNameFragment, useMeQuery } from "@saffron/controllers";
import * as React from "react";
import { useRef } from "react";
import { BottomButton } from "../../../ui/BottomButton";
import { pushLocation } from "../../../utils/pushLocation";
import { UpgradeModal } from "../../shared/UpgradeModal";
import { RecipeList } from "./CookbookSidebar";
import { IdStuff } from "./types";
import { RecipeItem } from "./ui/RecipeItem";
import { SortRecipesBySelect } from "./SortRecipesBySelect";

interface Props extends IdStuff {
  recipes: RecipeNameFragment[];
  isCookbookOwner: boolean;
  loading: boolean;
}

export const BrowseRecipes: React.FC<Props> = ({
  ids,
  setIds,
  recipes,
  isCookbookOwner
}) => {
  const { data } = useMeQuery();
  const showButton =
    ids.currentCookbookId && ids.currentSectionId && isCookbookOwner;

  const prevId = useRef("");
  const nextId = useRef("");

  const currentIdx = recipes.findIndex(x => x.id === ids.currentRecipeId);

  if (currentIdx !== -1) {
    prevId.current = recipes[currentIdx - 1]?.id;
    nextId.current = recipes[currentIdx + 1]?.id;
  }

  React.useEffect(() => {
    const fn = (e: any) => {
      if (e.code === "ArrowUp") {
        e.preventDefault();
        if (prevId.current) {
          setIds(state => ({ ...state, currentRecipeId: prevId.current }));
        }
      } else if (e.code === "ArrowDown") {
        e.preventDefault();
        if (nextId.current) {
          setIds(state => ({ ...state, currentRecipeId: nextId.current }));
        }
      }
    };
    window.addEventListener("keydown", fn);

    return () => {
      window.removeEventListener("keydown", fn);
    };
  }, [setIds]);

  return (
    <div
      style={{
        flex: 3,
        display: "flex",
        flexDirection: "column"
      }}
    >
      <RecipeList>
        <SortRecipesBySelect />
        {recipes.map(recipe => {
          return (
            <RecipeItem
              sectionId={ids.currentSectionId}
              key={recipe.id}
              onRecipeClick={id => setIds({ ...ids, currentRecipeId: id })}
              selected={recipe.id === ids.currentRecipeId}
              recipe={recipe}
              enableDrag={isCookbookOwner}
            />
          );
        })}
        {showButton && (
          <UpgradeModal title="Recipe Limit Reached">
            {setUpgradeModalOpen => (
              <BottomButton
                onClick={() => {
                  if (
                    data &&
                    data.me.user &&
                    data.me.user.numRecipes >= data.me.user.role.recipeLimit
                  ) {
                    setUpgradeModalOpen(true);
                  } else {
                    pushLocation(
                      `/new-recipe/cookbook/${ids.currentCookbookId}/${ids.currentSectionId}`
                    );
                  }
                }}
              >
                Add Recipe
              </BottomButton>
            )}
          </UpgradeModal>
        )}
      </RecipeList>
    </div>
  );
};
