import {
  formatUrl,
  SEASONED_SUBCRIPTION_RECIPE_LIMIT,
  statusToAccountInfoMap,
  UPGRADE_TO_SEASONED_COOK_OPERATION
} from "@saffron/common";
import {
  MeUserFragment,
  useFreeOrBetaAccountInfo,
  useChangeSubscriptionPlanMutation
} from "@saffron/controllers";
import React, { useState } from "react";
import { OutlineButton } from "../../../ui/Button";
import { GoldCard } from "./GoldCard";
import { YearlyToggle } from "../../../ui/YearlyToggle";
import { Modal } from "../../../ui/Modal";
import { notificationState } from "../../misc/notifications/NotificationState";

interface Props {
  user: MeUserFragment;
}

const seasonedPlan = statusToAccountInfoMap["seasoned-subscription"];

export const StandardUser: React.FC<Props> = ({ user }) => {
  const [
    changeSubscriptionPlan,
    { loading }
  ] = useChangeSubscriptionPlanMutation();
  const [yearly, setYearly] = useState(true);
  const {
    title,
    ratio,
    bodyCopy,
    isStandard,
    upgradeCost
  } = useFreeOrBetaAccountInfo(user, yearly);
  const [open, setOpen] = useState(false);
  return (
    <GoldCard
      style={{ maxWidth: 330, width: "100%" }}
      goldText="YOUR PLAN"
      title={title}
    >
      <div style={{ color: "#333", marginBottom: isStandard ? 0 : "2em" }}>
        <div>
          Recipes: <strong>{ratio}</strong>
        </div>
        <div style={{ marginTop: ".75em" }}>{bodyCopy}</div>
      </div>
      {isStandard ? (
        <>
          <YearlyToggle
            yearly={yearly}
            onSwitch={setYearly}
            cost={upgradeCost}
          />
          <OutlineButton
            color="gold"
            type="button"
            style={{ marginBottom: 16 }}
            onClick={() => {
              setOpen(true);
            }}
          >
            UPGRADE NOW
          </OutlineButton>
          <Modal
            title="Confirm Upgrade"
            isOpen={open}
            onRequestClose={() => setOpen(false)}
            secondaryButtonText="CANCEL"
            secondaryButtonClick={() => setOpen(false)}
            mainButtonClick={() => {
              changeSubscriptionPlan({
                variables: {
                  operation: UPGRADE_TO_SEASONED_COOK_OPERATION,
                  yearly
                }
              }).then(() => {
                notificationState.send({
                  variant: "success",
                  text: "upgrade successful"
                });
                setOpen(false);
              });
            }}
            loading={loading}
            mainButtonText="OK"
          >
            {`You'll be charged ${
              yearly
                ? `$${seasonedPlan.cost * 12}/year`
                : `$${seasonedPlan.cost + 1}/month`
            } and can add up to ${SEASONED_SUBCRIPTION_RECIPE_LIMIT.toLocaleString()} recipes.`}
          </Modal>
        </>
      ) : null}
      <OutlineButton
        color="gold"
        type="button"
        onClick={() => {
          if (user.paddleUpdateUrl) {
            // eslint-disable-next-line no-undef
            Paddle.Checkout.open({
              override: user.paddleUpdateUrl
            });
          }
        }}
      >
        EDIT CREDIT CARD
      </OutlineButton>

      <div
        style={{
          marginTop: "3em",
          marginBottom: "1.5em",
          width: "100%",
          height: 1,
          backgroundColor: "#979797"
        }}
      />

      <div style={{ color: "#333", marginBottom: "2em" }}>
        If you decide to cancel your paid subscription, you may still access and
        use your recipes—but won’t be able to add any new recipes.
      </div>

      <a style={{ width: "100%" }} href={formatUrl(user.paddleCancelUrl!)}>
        <OutlineButton style={{ width: "100%" }} color="gold" type="button">
          CANCEL SUBSCRIPTION
        </OutlineButton>
      </a>
    </GoldCard>
  );
};
