import React from "react";
import styled from "styled-components";
import { Flex } from "../Flex";
import { FlexCProps, PossibleBackgroundImages } from "../types";
import { Box } from "../Box";

export const Card = styled(Flex as any)<FlexCProps>`
  border-radius: 7px;
  box-shadow: 3px 6px 6px rgba(0, 0, 0, 0.16);
`;

export class PatternCard extends React.PureComponent<
  FlexCProps & { patternImage?: PossibleBackgroundImages }
> {
  render() {
    const { patternImage = "gold", ...other } = this.props;
    return (
      <Card {...other}>
        {this.props.children}
        <Box
          backgroundImage={patternImage}
          height="55px"
          m="auto 10px 10px 10px"
          style={{ backgroundSize: "54.5px" }}
        />
      </Card>
    );
  }
}
