import { Field } from "formik";
import React from "react";
import { ReverseOutlineButton } from "../../../../../ui/Button";
import { SymbolPicker } from "../../../../shared/draft-utils/SymbolPicker";
import { IngredientDraftField } from "../../../../shared/formFields/IngredientDraftField";
import {
  EditorBar,
  EditorBarLeft,
  EditorBlockButton,
  EditorDiv
} from "../ui/EditorComponents";
import { Tooltip } from "react-tippy";

export const SlateIngredientsEditor: React.FC<{ handleSubmit: any }> = ({
  handleSubmit
}) => {
  return (
    <EditorDiv>
      <Field
        name="ingredientsValue"
        placeholder="Paste your ingredients..."
        component={IngredientDraftField}
        renderHeader={({
          toggleHeader,
          toggleRecipeLink,
          toggleConvertUnits,
          insertText,
          formatIngredients
        }: any) => (
          <>
            <EditorBar style={{ marginTop: "2em" }}>
              <EditorBarLeft>
                <EditorBlockButton onMouseDown={toggleHeader}>
                  Header
                </EditorBlockButton>
                <EditorBlockButton onMouseDown={toggleRecipeLink}>
                  Recipe Link
                </EditorBlockButton>
                <SymbolPicker onSymbol={insertText} />
                <EditorBlockButton onMouseDown={formatIngredients}>
                  <Tooltip
                    html={
                      <div style={{ textAlign: "left" }}>
                        <div>1. Removes extra space</div>
                        <div>2. Removes symbols from the start of lines</div>
                        <div>3. Style text that ends with a : as a header</div>
                        {/* <div>4. Cleanup units</div> */}
                      </div>
                    }
                    position="bottom"
                    theme="light"
                  >
                    AutoFormat
                  </Tooltip>
                </EditorBlockButton>
                <EditorBlockButton onMouseDown={toggleConvertUnits}>
                  Convert Units
                </EditorBlockButton>
                {/* <EditorBlockButton
                            onMouseDown={() => {
                              const value = values.ingredientsValue.toJSON();
                              setFieldValue(
                                "ingredientsValue",
                                Value.fromJSON(
                                  produce(value, v => {
                                    console.log(value.document!.nodes!);
                                    v.document!.nodes!.forEach((n: any) => {
                                      if (n.type !== "paragraph") {
                                        return;
                                      }

                                      if (
                                        n.nodes &&
                                        n.nodes[0] &&
                                        n.nodes[0].text
                                      ) {
                                        const text = n.nodes[0].text;
                                        const ig = parseIngredient(text);
                                        const newIg = convertIngredientMeasurement(
                                          ig
                                        );
                                        if (newIg.converted) {
                                          n.nodes[0].text = ingredientToString(
                                            newIg
                                          );
                                        }
                                      }
                                    });
                                  })
                                )
                              );
                            }}
                          >
                            Convert Units
                          </EditorBlockButton> */}
              </EditorBarLeft>
              <ReverseOutlineButton type="button" onClick={handleSubmit}>
                SAVE INGREDIENTS
              </ReverseOutlineButton>
            </EditorBar>
          </>
        )}
      />
    </EditorDiv>
  );
};
