import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { ViewMenuPlanner } from "./ViewMenuPlanner";
import { DateSize } from "@saffron/controllers";
import { WarnIfNoRecipe } from "../../../utils/WarnIfNoRecipes";

type Props = RouteComponentProps<{
  year?: string;
  month?: string;
  day?: string;
  "0"?: string;
}>;

const ViewMenuPlannerContainer = (props: Props) => {
  const {
    match: {
      params: { day, month, year, ...other }
    }
  } = props;

  return (
    <WarnIfNoRecipe
      title="Create a menu plan"
      message="Add recipes to your cookbooks to use the meal planner here."
    >
      <ViewMenuPlanner
        day={day}
        month={month}
        year={year}
        dateSize={other["0"] as DateSize}
      />
    </WarnIfNoRecipe>
  );
};

export default ViewMenuPlannerContainer;
