import React from "react";
import { Tooltip } from "react-tippy";
import styled from "styled-components";
import { DivButton } from "../../../ui/DivButton";
import { NoImageSvgData } from "../../../ui/NoImageSvgData";
import { theme } from "../../../ui/theme";
import { Stars } from "../../../ui/Stars";

const SearchImage = styled("img")<{ sidebar?: boolean }>`
  object-fit: cover;
  width: 100%;
  height: 126px;
  ${p =>
    p.sidebar
      ? `@media screen and (max-width: 64em) {
    height: 113px;
  }`
      : ""};
`;

const NoImage = styled("div")<{ sidebar?: boolean }>`
  background-color: #c5bfa9;
  background-image: url(${NoImageSvgData});
  height: 126px;
  width: 100%;
  ${p =>
    p.sidebar
      ? `@media screen and (max-width: 64em) {
    height: 113px;
  }`
      : ""};
`;

const Container = styled("div")<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.75em;
  opacity: ${p => (p.disabled ? 0.25 : 1)};
  width: 100%;
`;

const Anchor = styled("a")<{ disabled?: boolean }>`
  width: 100%;
  color: #444;
  ${p =>
    p.disabled
      ? ""
      : `
              cursor: pointer;
            &:hover {
              color: #ccc;
              border: none;
            }`};
`;

const Name = styled("h4")`
  text-align: center;
  margin-top: 0.5em;
  margin-bottom: 0.1em;
  font-family: ${theme.uiFontStack};
  font-weight: 600;
  font-size: 1.175rem;
`;

const ItalicThing = styled("div")`
  font-family: ${theme.uiFontStack};
  color: #444;
  font-size: 0.875rem;
  line-height: 1.45;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: #ccc;
  }
`;

export interface RecipeCardData {
  id: string;
  pictureUrl?: string | null | undefined;
  name: string;
  source?: string | null | undefined;
  rating?: number | null;
}

interface Props<R extends RecipeCardData> {
  recipe: R;
  onRecipeClicked: (recipe: R) => void;
  onAuthorClick: (author: string) => void;
  disabled: boolean;
  sidebar?: boolean;
  tooltipText?: string;
}

export class RecipeCard<R extends RecipeCardData> extends React.PureComponent<
  Props<R>
> {
  onClick = () => {
    const { recipe, onRecipeClicked } = this.props;
    onRecipeClicked(recipe);
  };
  onAuthorClick = () => {
    const {
      recipe: { source },
      onAuthorClick
    } = this.props;
    if (source) {
      onAuthorClick(source);
    }
  };

  render() {
    const {
      recipe: { id, pictureUrl, name, source, rating },
      disabled,
      sidebar,
      tooltipText
    } = this.props;

    const width = sidebar ? "328px" : "366px";

    const body = (
      <Container
        style={{ maxWidth: width, width: "100%" }}
        disabled={disabled}
        key={id}
      >
        <Anchor
          disabled={disabled}
          onClick={disabled ? undefined : this.onClick}
        >
          {pictureUrl ? (
            <SearchImage
              className="rpic"
              sidebar={sidebar}
              src={pictureUrl}
              alt="Recipe"
            />
          ) : (
            <NoImage className="rpic" sidebar={sidebar} />
          )}
          <Name>{name}</Name>
        </Anchor>
        <DivButton onClick={disabled ? undefined : this.onAuthorClick}>
          <ItalicThing>{source}</ItalicThing>
        </DivButton>
        {rating ? <Stars numStars={rating} /> : null}
      </Container>
    );

    return disabled ? (
      <Tooltip title={`In ${tooltipText}`}>{body}</Tooltip>
    ) : (
      body
    );
  }
}
