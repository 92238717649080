import { css } from "styled-components";

export const mediaSizes = {
  patternDies: 1400 as any,
  dropTime: 1050 as any,
  oneColSteps: 950 as any,
  leftNavDies: 600 as any,
  medium: 992 as any,
  small: 768 as any
};

export const media: typeof mediaSizes = Object.keys(mediaSizes).reduce(
  (acc, label) => {
    acc[label] = (...args: any[]) => css`
      @media (min-width: ${mediaSizes[label as keyof typeof mediaSizes] /
          16}em) {
        ${(css as any)(...args)};
      }
    `;

    return acc;
  },
  {} as any
);
