import {
  recipeLinkSearchQuery,
  RecipeLinkSearchQuery
} from "@saffron/controllers";
import React from "react";
import { Label } from "../../ui/Label";
import { Modal } from "../../ui/Modal";
import { RecipeSearch } from "./RecipeSearch";
import styled from "styled-components";

interface Props {
  onRecipeClick: (data: { id: string; name: string }) => void;
  closeModal: () => void;
  open: boolean;
}

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  overflowY: "auto",
  "& .search-form-container": {
    marginLeft: "30px",
    marginRight: "30px"
  }
});

export class RecipeLinkModal extends React.PureComponent<Props> {
  render() {
    return (
      <Modal
        title="Add Recipe Link"
        isOpen={this.props.open}
        onRequestClose={this.props.closeModal}
        content={{ width: 380, maxHeight: 600, height: "100%", padding: 0 }}
        headerStyles={{ padding: 30, paddingBottom: 0 }}
        xRight={10}
        xTop={10}
      >
        <Container>
          <Label style={{ paddingLeft: 30, paddingRight: 30 }}>
            Select recipe to create a link for:
          </Label>
          <RecipeSearch<
            RecipeLinkSearchQuery,
            RecipeLinkSearchQuery["recipeSearch"]["recipes"][0]
          >
            query={recipeLinkSearchQuery}
            onRecipeClick={this.props.onRecipeClick}
            horizontalRecipeCardMargin="30px"
          />
        </Container>
      </Modal>
    );
  }
}
