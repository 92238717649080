import React from "react";
import styled from "styled-components";
import { ErrorText } from "../ErrorText";
import { theme } from "../theme";

export type ElementProps<
  Tag extends keyof JSX.IntrinsicElements
> = JSX.IntrinsicElements[Tag] & {
  innerRef?: JSX.IntrinsicElements[Tag]["ref"];
};

const Input = styled("input")`
  font-family: ${theme.uiFontStack};
  font-size: 1.125rem;
  color: #444;
  width: 100%;
  outline: none;
  outline-style: none;
  outline-offset: 0;
  border: none;
  &::placeholder {
    color: #b3b3b3;
  }
`;

export interface SearchInputProps {
  inputRef: any;
  errorText?: string | null;
  value: any;
  icon?: JSX.Element;
}

export class SearchInput extends React.PureComponent<SearchInputProps> {
  render() {
    const { errorText, icon, inputRef, ...inputProps } = this.props;
    return (
      <>
        <div
          style={{
            flex: 1
          }}
        >
          <div
            style={{
              alignItems: "start",
              display: "flex",
              borderBottom: "solid 1px #dde2e3",
              paddingBottom: "0.25em",
              minHeight: 31
            }}
          >
            <Input ref={inputRef} autoComplete="off" {...inputProps} />
            {icon}
          </div>
          {errorText && <ErrorText>{errorText}</ErrorText>}
        </div>
      </>
    );
  }
}
