import React from "react";
import Icon from "../../ui/Icon";
import { pushLocation } from "../../utils/pushLocation";
import { slugify } from "@saffron/common";
import { RecipePreviewFragment } from "@saffron/controllers";

interface Props {
  recipe: RecipePreviewFragment;
}

export const ExpandRicon: React.FC<Props> = ({ recipe: { id, name } }) => {
  return (
    <Icon
      tooltipText="fullscreen"
      template="recipe"
      name="expand"
      onClick={() => pushLocation(`/recipe/${id}/${slugify(name)}/fullscreen`)}
      arrow
    />
  );
};
