import styled from "styled-components";
import {
  space,
  width,
  height,
  fontSize,
  fontWeight,
  color,
  flex,
  order,
  borders,
  borderRadius,
  alignSelf,
  justifySelf,
  display
} from "styled-system";
import { BoxProps } from "../types";
import {
  backgroundImage,
  opacity,
  fontStyle,
  fontFamily,
  hoverColor,
  letterSpacing,
  maxWidth
} from "../theme";

export const Box = styled("div")<BoxProps>`
  ${fontFamily}
  ${justifySelf}
  ${alignSelf}
  ${fontWeight}
  ${borderRadius}
  ${borders}
  ${space}
  ${width}
  ${height}
  ${fontSize}
  ${color}
  ${backgroundImage}
  ${flex}
  ${order}
  ${opacity}
  ${fontStyle}
  ${letterSpacing}
  ${maxWidth}
  box-sizing: border-box;
  ${display}
  &:hover {
    ${hoverColor}
  }
  `;

export const ClickableBox = styled(Box as any)<BoxProps>`
  cursor: pointer;
`;

export const ScrollableBox = styled(Box as any)<BoxProps>`
  overflow-y: auto;
`;

export const BoxForm = Box.withComponent("form");
