import React from "react";
import { genId } from "../../modules/misc/notifications/utils/genId";

interface Props {
  width?: number | string;
  height?: number | string;
  c1: string;
  c2: string;
  c3: string;
}

export const GrungePattern: React.FC<Props> = ({
  c1,
  c2,
  c3,
  height,
  width
}) => {
  const randomId = genId();
  return (
    <svg style={{ width, height }}>
      <defs>
        <pattern
          id={`prefix__${randomId}`}
          patternUnits="userSpaceOnUse"
          width={10}
          height={20}
          viewBox="0 0 5 10"
        >
          <path fill={c1} d="M-5-5h15v20H-5z" opacity={1} />
          <path
            stroke={c2}
            strokeWidth={2}
            opacity={0.1}
            d="M-2 1l9 9m-9-4l9 9M-2-4l9 9"
          />
          <path
            stroke={c3}
            strokeWidth={2}
            opacity={0.1}
            d="M7 1l-9 9m9-4l-9 9M7-4l-9 9"
          />
        </pattern>
      </defs>
      <rect width="100%" height="100%" fill={`url(#prefix__${randomId})`} />
    </svg>
  );
};
