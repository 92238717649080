export function truncString(s: string, n: number) {
  const smallS = s.slice(0, n);
  const pos = smallS.lastIndexOf(" ");

  let noSpaceS = smallS;
  if (pos !== -1) {
    noSpaceS = smallS.slice(0, pos);
  }

  return noSpaceS.replace(/[\s.?!-()]+$/, "") + "...";
}
