import React from "react";
import { FullscreenLayout } from "../../../../../ui/Layout/FullscreenLayout";
import {
  EditorContainer,
  RecipeHeader,
  RecipeHeaderContainer
} from "./EditorComponents";

interface Props {
  headerText: string;
  noBottom?: boolean;
  onCheck?: () => void;
}

export class EditorScreenTemplate extends React.PureComponent<Props> {
  render() {
    const { headerText, children, noBottom, onCheck } = this.props;
    return (
      <FullscreenLayout innerWidth="100%">
        <EditorContainer>
          <RecipeHeaderContainer>
            <RecipeHeader
              style={noBottom ? { marginBottom: "1em" } : undefined}
              onClick={onCheck}
            >
              {headerText}
            </RecipeHeader>
          </RecipeHeaderContainer>
          {children}
        </EditorContainer>
      </FullscreenLayout>
    );
  }
}
