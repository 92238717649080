import { addPrettyFractionsToText } from "@saffron/common";
import React from "react";
import { Node } from "slate";
import get from "lodash.get";
import { InstructionHeader } from "../../ui/Recipe/components";

export const slateInstructionsToReact = (rawInstructions?: Node[]) => {
  if (!rawInstructions) {
    return null;
  }

  const nodes = Array.isArray(rawInstructions)
    ? rawInstructions
    : (((rawInstructions as any).document.nodes || []) as Node[]);

  return nodes.map((b, idx) => {
    const text = Node.isNode(b) ? Node.string(b) : get(b, "nodes[0].text", "");

    if (!("type" in b)) {
      return null;
    }

    if (b.type === "paragraph") {
      return <li key={`${text}-${idx}`}>{addPrettyFractionsToText(text)}</li>;
    } else if (b.type === "header-four") {
      return (
        <InstructionHeader key={`${text}-${idx}`}>{text}</InstructionHeader>
      );
    }

    return null;
  });
};
