import  React from "react";
import { Spinner } from "../Spinner";
import { SideBarLayout } from "./SidebarLayout";

export const LoadingLayout = () => {
  return (
    <SideBarLayout>
      <div style={{ margin: "auto" }}>
        <Spinner />
      </div>
    </SideBarLayout>
  );
};
