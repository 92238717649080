import React, { useState } from "react";
import { Modal } from "../../ui/Modal";
import { JustCookbookFragment, useCookbooksQuery } from "@saffron/controllers";
import { Select } from "../../ui/Select";

interface Props {
  open: boolean;
  onRequestClosed: () => void;
  onSubmit: (cookbook: JustCookbookFragment | undefined) => Promise<void>;
  title: string;
  buttonText: string;
  cookbookId: string;
  loading: boolean;
}

export const PickCookbookModal: React.FC<Props> = ({
  onSubmit,
  open,
  onRequestClosed,
  title,
  buttonText,
  cookbookId,
  loading
}) => {
  const { data } = useCookbooksQuery();

  const [currentCookbookId, setCurrentCookbookId] = useState(cookbookId);

  return (
    <Modal
      title={title}
      mainButtonText={buttonText}
      loading={loading}
      mainButtonClick={() => {
        onSubmit(data?.cookbooks?.find(x => x.id === currentCookbookId));
        setCurrentCookbookId(currentCookbookId);
      }}
      isOpen={open}
      onRequestClose={() => {
        onRequestClosed();
        setCurrentCookbookId(cookbookId);
      }}
    >
      <Select
        options={
          data && data.cookbooks
            ? data.cookbooks.map(x => ({
                value: x.id,
                key: x.id,
                text: x.name
              }))
            : []
        }
        value={currentCookbookId}
        onChange={newValue => setCurrentCookbookId(newValue)}
      />
    </Modal>
  );
};
