import styled from "styled-components";
import { theme } from "./theme";

export const ListButton = styled.div({
  color: "#0d0d0d",
  display: "block",
  overflow: "hidden",
  padding: "4px 10px 4px 16px",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "100%",
  backgroundColor: "transparent",
  border: "0",
  cursor: "pointer",
  textDecoration: "none",
  userSelect: "none",
  fontFamily: theme.fonts.ui,
  fontSize: "0.875rem",
  ":hover": {
    backgroundColor: "#ebeeee"
  }
});
