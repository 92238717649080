import * as Sentry from "@sentry/browser";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";
import { DndProvider } from "react-dnd";
import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import "react-tippy/dist/tippy.css";
import { ThemeProvider } from "styled-components";
import { Provider } from "unstated";
import { apolloClient } from "./apollo";
import { notificationState } from "./modules/misc/notifications/NotificationState";
import Routes from "./routes";
import { unregister } from "./serviceWorker";
import { GlobalStyle } from "./ui/globalStyles";
import { theme } from "./ui/theme";
import { navBarContainer } from "./ui/NavBar/NavBarContainer";
import { scaleIgContainer } from "./modules/ricons/ScaleIgContainer";
import pkg from "../package.json";
import ReactGA from "react-ga";
import { ApolloProvider } from "@apollo/client";

Modal.setAppElement("#root");

//
const enableSentry = process.env.NODE_ENV === "production";
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  release: pkg.version,
  enabled: enableSentry,
  beforeSend: enableSentry
    ? undefined
    : e => {
        console.log(e.exception);

        return e;
      }
});

ReactGA.initialize("UA-116002540-1", {
  debug: process.env.NODE_ENV === "development"
});
ReactGA.pageview(window.location.pathname + window.location.search);

// fixes touchable items that you also want to be scrollable i.e. recipe list in table of contents
HTML5toTouch.backends[1].options.delayTouchStart = 50;

const App = (
  <DndProvider backend={MultiBackend as any} options={HTML5toTouch}>
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <Provider
          inject={[notificationState, navBarContainer, scaleIgContainer]}
        >
          <Routes />
          <GlobalStyle />
        </Provider>
      </ThemeProvider>
    </ApolloProvider>
  </DndProvider>
);

const rootElement = document.getElementById("root");
ReactDOM.render(App, rootElement);
unregister();
