import React from "react";
import { Flex } from "../Flex";
import { Base } from "./Base";
import { patternWidth } from "../theme";
import { PossibleBackgroundImages } from "../types";
import styled from "styled-components";
import { media } from "../mediaTemplates";

interface Props {
  width?: string | number | string[] | number[];
  innerPadding?: string;
  style?: any;
  backgroundImage?: PossibleBackgroundImages;
  patternImage?: PossibleBackgroundImages;
}

const Wrapper = styled(Flex as any)`
  margin-left: 0;
  ${media.leftNavDies`
  margin-left: ${patternWidth};
  `}
`;

export class CenterLayout extends React.PureComponent<Props> {
  render() {
    const {
      width = [300, 400, 600],
      innerPadding,
      style,
      backgroundImage = "blue",
      patternImage = "white"
    } = this.props;
    return (
      <Base backgroundImage={backgroundImage} patternImage={patternImage}>
        <Wrapper
          width={width}
          bg="brightWhite"
          py="1.8em"
          flexDirection="column"
          p={innerPadding}
          style={style}
        >
          {this.props.children}
        </Wrapper>
      </Base>
    );
  }
}
