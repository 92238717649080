import React, { useState, useEffect } from "react";
import { useMeQuery } from "@saffron/controllers";
import { Modal } from "../ui/Modal";
import history from "../history";
import { theme } from "../ui/theme";

interface Props {
  title: string;
  message: string;
  children: JSX.Element | null;
}

export const WarnIfNoRecipe: React.FC<Props> = ({
  children,
  title,
  message
}) => {
  const [open, setOpen] = useState(false);
  const { data, loading } = useMeQuery();

  useEffect(() => {
    if (!loading && data && data.me.user && data.me.user.numRecipes === 0) {
      setOpen(true);
    }
  }, [data, loading]);

  return (
    <>
      <Modal
        isOpen={open}
        mainButtonClick={() => history.push("/browse")}
        mainButtonText="OK"
        title={title}
        onRequestClose={() => setOpen(false)}
      >
        <div
          style={{ fontFamily: theme.fonts.ui, fontSize: "1.0rem", color: "#444" }}
        >
          {message}
        </div>
      </Modal>
      {children}
    </>
  );
};
