import React from "react";

import { AppleStoreButton } from "./AppleStoreButton";
import { FlexCenter } from "../../ui/Flex";

import * as Cookie from "js-cookie";

const getAndroidReferrer = () => {
  const _fbc = Cookie.get("_fbc");
  const _fbp = Cookie.get("_fbp");
  if (_fbc && _fbp) {
    return `&referrer=${_fbc}||${_fbp}`;
  }

  try {
    const parsed = Cookie.getJSON("qs");
    const refFromLanding = Cookie.get("ref");
    if (Object.keys(parsed) || refFromLanding) {
      return `&referrer=${window.btoa(
        JSON.stringify({ search: parsed, ref: refFromLanding })
      )}`;
    }
  } catch {}

  return "";
};

export const AppStoreButtons = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <FlexCenter style={style}>
      <a href="https://itunes.apple.com/us/app/saffron-your-digital-cookbook/id1438683531?mt=8">
        <AppleStoreButton />
      </a>
      <a
        href={`https://play.google.com/store/apps/details?id=com.awad.saffron${getAndroidReferrer()}`}
      >
        <img
          style={{
            height: "auto",
            width: 150
          }}
          alt="Get it on Google Play"
          src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
        />
      </a>
    </FlexCenter>
  );
};
