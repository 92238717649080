import React from "react";
import { DivButton } from "./DivButton";

interface SwitchProps {
  on: boolean;
  onSwitch: (on: boolean) => void;
}

const height = 20;

export const Switch: React.FC<SwitchProps> = ({ on, onSwitch }) => {
  return (
    <DivButton
      style={{
        width: 40,
        height,
        backgroundColor: on ? "#531410" : "#91763F",
        borderRadius: height
      }}
      onClick={() => onSwitch(!on)}
    >
      <div
        style={{
          marginLeft: on ? "auto" : undefined,
          marginRight: !on ? "auto" : undefined,
          backgroundColor: "#FFFFFF",
          border: `2px solid ${on ? "#531410" : "#91763F"}`,
          height,
          width: height,
          borderRadius: "50%"
        }}
      />
    </DivButton>
  );
};
