import  React from "react";
import Icon from "../../ui/Icon";

interface Props {
  onClick: () => void;
}

export class EditRicon extends React.PureComponent<Props> {
  render() {
    return (
      <Icon
        arrow
        tooltipText="edit recipe"
        name="pencil"
        onClick={this.props.onClick}
        template="recipe"
      />
    );
  }
}
