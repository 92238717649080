import React from "react";
import { DivButton } from "../../../ui/DivButton";
import { theme } from "../../../ui/theme";
import { NativeSelect } from "../../../ui/Select";
import { Checkbox } from "../../../ui/Checkbox";
import { CenterCheckboxContainer } from "../../../ui/CenterCheckboxContainer";
import { SelectOptions } from "../../../ui/Select/SelectOptions";

interface IngredientUnitSectionProps {
  onCheckAll: () => void;
  allChecked: boolean;
  checked: Record<string, boolean>;
  title: string;
  onUnitChange: (u: string) => void;
  optionGroups: Array<
    { label: string; options: SelectOptions[] } | SelectOptions
  >;
}

export const IngredientUnitSection: React.FC<IngredientUnitSectionProps> = ({
  onCheckAll,
  allChecked,
  checked,
  title,
  optionGroups,
  onUnitChange,
  children
}) => {
  const showSelect = Object.keys(checked).some((key: any) => checked[key]);

  return (
    <>
      <DivButton
        style={{
          display: "flex",
          borderBottom: "2px solid #0D0D0D",
          alignItems: "center",
          paddingTop: 20,
          paddingBottom: 8,
          marginBottom: 14,
          width: "100%"
        }}
        onClick={onCheckAll}
      >
        <CenterCheckboxContainer
          style={{
            fontSize: 16,
            fontFamily: theme.fonts.ui
          }}
          checkbox={<Checkbox onChange={onCheckAll} value={allChecked} />}
        >
          <div
            style={{
              color: "#0d0d0d",
              textTransform: "uppercase"
            }}
          >
            {title}
          </div>
        </CenterCheckboxContainer>
      </DivButton>
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto"
          }}
        >
          {children}
        </div>
        {showSelect ? (
          <NativeSelect
            style={{ marginTop: 16, width: "100%" }}
            placeholder="convert to"
            onChange={onUnitChange}
            options={optionGroups.map(x =>
              "label" in x ? (
                <optgroup key={x.label} label={x.label}>
                  {x.options.map(o => (
                    <option key={o.key} value={o.value}>
                      {o.text}
                    </option>
                  ))}
                </optgroup>
              ) : (
                <option key={x.key} value={x.value}>
                  {x.text}
                </option>
              )
            )}
          />
        ) : (
          <div style={{ height: 54 }} />
        )}
      </div>
    </>
  );
};
