import React from "react";
import { ingredientToParts, IngredientFormValue } from "@saffron/common";
import { DivButton } from "../../../ui/DivButton";
import { Checkbox } from "../../../ui/Checkbox";
import { theme } from "../../../ui/theme";
import { CenterCheckboxContainer } from "../../../ui/CenterCheckboxContainer";

type Ig = IngredientFormValue;

interface useCreateUnitRowsProps {
  localIngredients: Ig[];
  isValid: (ig: Ig) => boolean;
  onCheck: (idx: number) => void;
  checked: Record<string, boolean>;
  converted: Record<string, boolean>;
}

export const useCreateUnitRows: (
  x: useCreateUnitRowsProps
) => React.ReactNode[] = ({
  localIngredients,
  isValid,
  checked,
  onCheck,
  converted
}) => {
  const items: any[] = [];
  localIngredients.forEach((ig, i) => {
    if ("text" in ig) {
      return;
    }
    const { amountAndUnit, rest } = ingredientToParts(ig);

    const enabled = ig.amount && ig.unit && isValid(ig);

    if (!enabled) {
      return;
    }

    items.push(
      <DivButton
        key={i}
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          display: "flex",
          alignItems: "flex-start"
        }}
        onClick={() => onCheck(i)}
      >
        <CenterCheckboxContainer
          style={{
            fontSize: 16,
            fontFamily: theme.fonts.secondary,
            lineHeight: 1.45
          }}
          checkbox={
            <Checkbox
              // style={{marginTop: 3}}
              value={!!checked[i]}
              onChange={() => onCheck(i)}
            />
          }
        >
          <div
            style={{
              wordBreak: "break-word",
              flex: 1,
              color: "#0d0d0d"
            }}
          >
            <strong
              style={{
                color: converted[i] ? "#026284" : "#333"
              }}
            >
              {amountAndUnit}
            </strong>
            {rest}
          </div>
        </CenterCheckboxContainer>
      </DivButton>
    );
  });

  return items;
};
