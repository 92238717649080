import { parseIngredient } from "@saffron/common";
import { Node } from "slate";
import { simplifyAmount } from "@saffron/controllers";

interface RegularIngredient {
  name: string;
  unit: string;
  amount: string;
}

const cacheOrIngredient = (node: Node, text: string): RegularIngredient => {
  if (
    node.data &&
    (node.data as any).cache &&
    !(node.data as any).cache.recipeId &&
    (node.data as any).text &&
    text === (node.data as any).text
  ) {
    return (node.data as any).cache;
  }

  return parseIngredient(text);
};

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

// simplifyAmounts:
// 2 x 400g => 1000g
export const valueToIngredients = (value: Node[], simplifyAmounts?: boolean) =>
  value
    .map(node => {
      const text = Node.string(node);
      if (!text) {
        return null;
      } else if (node.type === "paragraph" || node.type === "optional-text") {
        const item = cacheOrIngredient(node, text);
        if (simplifyAmounts && !item.unit) {
          const canSimplify = simplifyAmount(item.amount, item.name);
          if (canSimplify) {
            return {
              ...item,
              ...canSimplify
            };
          } else {
            return item;
          }
        } else {
          return item;
        }
      } else if (node.type === "header-three") {
        return { text };
      } else if (
        node.type === "recipe-link" &&
        node.data &&
        (node.data as any).recipeId
      ) {
        return {
          ...cacheOrIngredient(node, text),
          recipeId: (node.data as any).recipeId
        };
      }

      return null;
    })
    .filter(notEmpty);
