import React from "react";
import { theme } from "../../../ui/theme";
import { GroceryListItemFragment } from "@saffron/controllers";
import styled from "styled-components";

interface PrintableGroceryItemProps {
  item: GroceryListItemFragment;
}

const ForceFlex = styled.div`
  display: flex !important;
`;

const ForceInlineBlock = styled.div`
  display: inline-block !important;
`;

const ForceInlineBlockSpan = styled.span`
  display: inline-block !important;
`;

export const PrintableGroceryItem: React.FC<PrintableGroceryItemProps> = ({
  item
}) => {
  return (
    <ForceFlex
      style={{
        maxWidth: 200,
        marginBottom: 15,
        alignItems: "flex-start"
      }}
    >
      <ForceFlex
        style={{
          alignItems: "center",
          flex: "0 0 14px",
          fontFamily: theme.uiFontStack,
          fontWeight: 600,
          fontSize: "0.75rem"
        }}
      >
        &#8203;
        <div
          style={{
            marginTop: 1,
            width: 13,
            height: 13,
            border: "1px solid #CCCCCC",
            marginRight: 8
          }}
        />
      </ForceFlex>
      <ForceFlex style={{ flexDirection: "column" }}>
        {item.recipeLinkId ? (
          <div
            style={{
              fontFamily: theme.uiFontStack,
              fontWeight: 600,
              fontSize: "0.75rem"
            }}
          >
            {item.amount || ""} {item.unit || ""} {item.name || ""}
          </div>
        ) : (
          <>
            <ForceInlineBlock
              style={{
                fontFamily: theme.uiFontStack,
                fontWeight: 600,
                fontSize: "0.75rem"
              }}
            >
              {item.keyword || ""}{" "}
              <ForceInlineBlockSpan style={{ fontWeight: 400 }}>
                {item.amount || ""} {item.unit || ""}
              </ForceInlineBlockSpan>
            </ForceInlineBlock>
            <div
              style={{
                fontFamily: theme.uiFontStack,
                fontSize: "0.625rem"
              }}
            >
              {item.name || ""}
            </div>
          </>
        )}
      </ForceFlex>
    </ForceFlex>
  );
};
