import React from "react";
import { Box } from "../Box";
import { PotLogo } from "../Logo";
import { CardLayout } from "./CardLayout";

export class CardLogoLayout extends React.PureComponent {
  render() {
    return (
      <CardLayout patternImage="white">
        <Box m="auto" pt="40px" pb="30px">
          <a href="/">
            <PotLogo width="190px" height="65.708333349px" />
          </a>
        </Box>
        {this.props.children}
      </CardLayout>
    );
  }
}
