import React from "react";
import { Switch } from "./Switch";

interface ToggleProps {
  yearly: boolean;
  onSwitch: (on: boolean) => void;
  cost: number;
  noMarginBottom?: boolean;
}

export const YearlyToggle: React.FC<ToggleProps> = ({
  yearly,
  onSwitch,
  cost,
  noMarginBottom
}) => {
  return (
    <div style={{ marginTop: 30, marginBottom: noMarginBottom ? 0 : 20 }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: 8 }}>Monthly</div>
        <Switch on={yearly} onSwitch={onSwitch} />
        <div style={{ marginLeft: 8 }}>Yearly</div>
      </div>
      <div style={{ fontSize: "0.875rem" }}>
        <strong
          style={{ fontSize: "2rem", color: yearly ? "#531410" : "#91763F" }}
        >
          ${cost}
        </strong>{" "}
        /month
      </div>
      <div style={{ fontSize: "0.875rem" }}>
        {yearly ? `(billed at $${cost * 12}/year)` : ""}&nbsp;
      </div>
    </div>
  );
};
