import  React from "react";

export default class UnexpectedError extends React.PureComponent {
  render() {
    return (
      <div>
        The website encountered an unexpected error. Please try again later.
      </div>
    );
  }
}
