import React from "react";
import styled from "styled-components";
import { media } from "../../ui/mediaTemplates";

const OuterContainer = styled("div")`
  padding-top: 24px;
  padding-bottom: 8px;
  align-self: left;
  margin: auto;
  padding-left: 40px;
  padding-right: 40px;
  max-width: 880px;
  width: 100%;
  position: sticky;
  top: 0;
  background: #fafafa;
  z-index: 1;
  ${media.oneColSteps`
    padding-left: 50px;
    padding-right: 00px;
    max-width: 900px;
  `}
`;

const Inner = styled("div")`
  height: 20px;
  display: flex;
  & > div {
    margin-right: 20px;
  }
`;

export class RiconContainer extends React.PureComponent {
  render() {
    return (
      <OuterContainer {...this.props}>
        <Inner>{this.props.children}</Inner>
      </OuterContainer>
    );
  }
}
