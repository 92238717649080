import React, { useState } from "react";
import Icon from "../Icon";
import {
  usePartialRecipeEditMutation,
  partialRecipeEditMutationOptions
} from "@saffron/controllers";

interface RecipeRatingProps {
  id: string;
  rating?: number | null;
  readOnly: boolean;
}

export const RecipeRating: React.FC<RecipeRatingProps> = ({
  id,
  rating,
  readOnly
}) => {
  const [n, setN] = useState(-1);
  const [partialRecipeEdit] = usePartialRecipeEditMutation();

  if (!rating && readOnly) {
    return <div style={{ paddingBottom: "24px" }} />;
  }

  return (
    <div style={{ display: "flex", paddingBottom: "24px" }}>
      {Array.from(Array(5), (_, i) => {
        const realI = i + 1;
        const active = n === -1 && rating ? rating >= realI : n >= realI;
        return (
          <Icon
            key={i}
            style={{ cursor: readOnly ? "unset" : "pointer" }}
            onMouseEnter={() => {
              if (!readOnly) {
                setN(realI);
              }
            }}
            onMouseLeave={() => {
              if (!readOnly) {
                setN(-1);
              }
            }}
            onClick={() => {
              if (!readOnly) {
                partialRecipeEdit(
                  partialRecipeEditMutationOptions({
                    id,
                    input: {
                      rating: realI
                    }
                  })
                );
              }
            }}
            name={active || readOnly ? "star" : "starOutline"}
            fill={active ? "#977535" : readOnly ? "#C5BFA9" : "#b3b3b3"}
            width="1.45em"
            height="1.45em"
          />
        );
      })}
    </div>
  );
};
