import { sortBy } from "@saffron/common";
import {
  JustSectionFragment,
  RecipeNameFragment,
  useMeQuery,
  useRecipesByCookbookAndSectionIdQuery,
  useSectionsByCookbookIdQuery,
  useCookbooksQuery,
  CookbooksQuery,
  CookbookAccessLevel,
  useRecipeListSortBy,
  sortRecipesByField
} from "@saffron/controllers";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { ScrollableFlex } from "../../../ui/Flex";
import { Sidebar } from "../../../ui/Sidebar";
import { BrowseCookbooks } from "./BrowseCookbooks";
import { BrowseRecipes } from "./BrowseRecipes";
import { BrowseSections } from "./BrowseSections";
import { CookbookSquare } from "./CookbookSquare";
import { IdStuff } from "./types";
import { useEffect } from "react";
import { useLocalStorage } from "../../../utils/useLocalStorage";
import { NetworkStatus } from "@apollo/client";

interface Props extends IdStuff {}

export const RecipeList = styled("div")`
  overflow-y: auto;
  flex: 1;
  color: #0d0d0d;
  font-weight: 400;
`;
export const LS_COOKBOOK_ORDER = "cookbookOrder";

export const CookbookSidebar: React.FC<Props> = ({ ids, setIds }) => {
  const { fieldToSortBy } = useRecipeListSortBy();
  const { data: cbData, loading: cbLoading } = useCookbooksQuery();
  const {
    data: sectionData,
    networkStatus: sectionNetworkStatus
  } = useSectionsByCookbookIdQuery({
    variables: { cookbookId: ids.currentCookbookId! },
    skip: !ids.currentCookbookId,
    notifyOnNetworkStatusChange: true
  });
  const {
    data: recipesData,
    networkStatus: recipeNetworkStatus
  } = useRecipesByCookbookAndSectionIdQuery({
    variables: {
      sectionId: ids.currentSectionId!
    },
    skip: !ids.currentCookbookId || !ids.currentSectionId,
    notifyOnNetworkStatusChange: true
  });
  const { data: meData } = useMeQuery();

  const [cookbookListShowing, setCookbookListShowing] = useState(false);
  const [cookbookOrder, setCookbookOrder] = useLocalStorage<
    Record<string, number>
  >(LS_COOKBOOK_ORDER, {});

  let cookbooks: CookbooksQuery["cookbooks"] = [];
  let currentCookbook: CookbooksQuery["cookbooks"][0] | undefined = undefined;

  if (cbData && cbData.cookbooks) {
    cookbooks = sortBy(cbData.cookbooks, x => cookbookOrder[x.id] ?? -1);
    currentCookbook = cookbooks.find(x => x.id === ids.currentCookbookId);
  }

  useEffect(() => {
    if (!cbLoading && cookbooks.length === 0) {
      setCookbookListShowing(true);
    }
  }, [cbLoading, cookbooks.length]);

  let sections: JustSectionFragment[] = [];

  if (sectionData && sectionData.sectionsByCookbookId) {
    sections = sortBy(sectionData.sectionsByCookbookId, "position");
  }

  if (sectionNetworkStatus === NetworkStatus.setVariables) {
    sections = [];
  }

  let recipes: RecipeNameFragment[] = [];

  if (recipesData && recipesData.recipesByCookbookAndSectionId) {
    recipes = sortRecipesByField(
      recipesData.recipesByCookbookAndSectionId,
      fieldToSortBy
    );
  }

  if (recipeNetworkStatus === NetworkStatus.setVariables) {
    recipes = [];
  }

  let isCookbookOwner = false;

  if (meData && meData.me && meData.me.user && currentCookbook) {
    isCookbookOwner = meData.me.user.id === currentCookbook.owner;
  }

  const firstCookbookId = cookbooks[0] ? cookbooks[0].id : "";
  const firstSectionId = sections[0] ? sections[0].id : "";
  const firstRecipeId = recipes[0] ? recipes[0].id : "";
  const isCurrentSectionInCurrentCookbook = !!sections.find(
    x => x.id === ids.currentSectionId
  );
  const isCurrentRecipeInCurrentSection = !!recipes.find(
    x => x.id === ids.currentRecipeId
  );
  const isCurrentCookbookInCookbooks = !!cookbooks.find(
    x => x.id === ids.currentCookbookId
  );

  useEffect(() => {
    if (!ids.currentCookbookId && firstCookbookId && firstCookbookId !== "-1") {
      setIds({
        currentCookbookId: firstCookbookId
      });
    }

    if (!ids.currentSectionId && firstSectionId && firstSectionId !== "-1") {
      setIds({
        ...ids,
        currentSectionId: firstSectionId,
        currentRecipeId: undefined
      });
    }

    if (!ids.currentRecipeId && firstRecipeId && firstRecipeId !== "-1") {
      setIds({ ...ids, currentRecipeId: firstRecipeId });
    }
  }, [ids, firstCookbookId, firstSectionId, firstRecipeId, setIds]);

  const recipesLoading =
    recipeNetworkStatus === NetworkStatus.setVariables ||
    recipeNetworkStatus === NetworkStatus.loading;
  const sectionsLoading =
    sectionNetworkStatus === NetworkStatus.setVariables ||
    sectionNetworkStatus === NetworkStatus.loading;

  const anyLoading = cbLoading || sectionsLoading || recipesLoading;

  useEffect(() => {
    if (anyLoading) {
      return;
    }

    if (!isCurrentCookbookInCookbooks && ids.currentCookbookId) {
      setIds({
        currentCookbookId: undefined
      });
    }

    if (!isCurrentRecipeInCurrentSection && ids.currentRecipeId) {
      setIds({
        ...ids,
        currentRecipeId:
          firstRecipeId === "-1" || !firstRecipeId ? undefined : firstRecipeId
      });
    }

    if (!isCurrentSectionInCurrentCookbook && ids.currentSectionId) {
      setIds({
        ...ids,
        currentSectionId:
          firstSectionId === "-1" || !firstSectionId
            ? undefined
            : firstSectionId
      });
    }
  }, [
    isCurrentCookbookInCookbooks,
    isCurrentRecipeInCurrentSection,
    ids,
    setIds,
    firstRecipeId,
    isCurrentSectionInCurrentCookbook,
    anyLoading,
    firstSectionId
  ]);

  return (
    <Sidebar>
      <CookbookSquare
        ids={ids}
        setIds={setIds}
        cookbookListShowing={cookbookListShowing}
        currentCookbook={currentCookbook}
        isCookbookOwner={isCookbookOwner}
        setCookbookListShowing={setCookbookListShowing}
      />

      {cookbookListShowing ? (
        <BrowseCookbooks
          ids={ids}
          setIds={setIds}
          cookbooks={cookbooks}
          setCookbookListShowing={setCookbookListShowing}
          setCookbookOrder={setCookbookOrder}
          cbLoading={cbLoading}
        />
      ) : (
        <ScrollableFlex flex={1} fontFamily="ui">
          <BrowseSections
            ids={ids}
            setIds={setIds}
            sections={sections}
            numRecipes={meData?.me.user?.numRecipes}
            cookbookAccessLevel={
              currentCookbook?.accessLevel || CookbookAccessLevel.Viewer
            }
            loading={sectionsLoading}
          />
          <BrowseRecipes
            ids={ids}
            setIds={setIds}
            isCookbookOwner={isCookbookOwner}
            recipes={recipes}
            loading={recipesLoading}
          />
        </ScrollableFlex>
      )}
    </Sidebar>
  );
};
