import React from "react";
import { UlInstructions, InstructionHeader } from "./components";
import { Node } from "slate";
import get from "lodash.get";
import { addPrettyFractionsToText } from "@saffron/common";

interface HighlightableStepsProps {
  viewOnly?: boolean;
  rawInstructions: undefined | Node[];
}

const stepStyles = {
  active: {
    paddingLeft: 8,
    paddingRight: 4,
    backgroundColor: "#F5F4F0",
    color: "#0d0d0d"
  },
  inactive: {
    color: "#999"
  },
  regular: {}
};

const headerStyles = {
  active: {
    paddingLeft: 8,
    paddingRight: 4,
    backgroundColor: "#F5F4F0"
  },
  inactive: {
    color: "#824028",
    opacity: 0.4
  },
  regular: {}
};

export const HighlightableSteps: React.FC<HighlightableStepsProps> = ({
  viewOnly,
  rawInstructions
}) => {
  const [activeIdx, setActiveIdx] = React.useState(-1);

  if (!rawInstructions) {
    return <UlInstructions />;
  }

  const instructionArray = Array.isArray(rawInstructions)
    ? rawInstructions
    : (((rawInstructions as any).document.nodes || []) as Node[]);

  return (
    <UlInstructions>
      {instructionArray.map((b, index) => {
        const text = Node.isNode(b)
          ? Node.string(b)
          : get(b, "nodes[0].text", "");

        if (!("type" in b)) {
          return null;
        }
        const isHeader = b.type === "header-four";

        let state: "regular" | "active" | "inactive" = "regular";

        const implicitHeaderActive =
          isHeader &&
          index !== instructionArray.length - 1 &&
          activeIdx === index + 1 &&
          instructionArray[index + 1].type === "paragraph";
        const implicitStepActive =
          !isHeader &&
          index !== 0 &&
          activeIdx === index - 1 &&
          instructionArray[index - 1].type !== "paragraph";
        const isActive =
          activeIdx === index || implicitHeaderActive || implicitStepActive;

        if (activeIdx === -1) {
          state = "regular";
        } else if (isActive) {
          state = "active";
        } else {
          state = "inactive";
        }

        const onClick = viewOnly
          ? undefined
          : () => {
              if (activeIdx === -1 || !isActive) {
                setActiveIdx(index);
              } else {
                setActiveIdx(-1);
              }
            };

        const previousNodeIsHeader =
          index !== 0 && instructionArray[index - 1].type !== "paragraph";

        if (b.type === "paragraph") {
          return (
            <li
              style={{
                ...stepStyles[state],
                cursor: viewOnly ? undefined : "pointer",
                paddingBottom:
                  index === 0 || previousNodeIsHeader ? undefined : 8,
                paddingTop: index === 0 || previousNodeIsHeader ? undefined : 8
              }}
              onClick={onClick}
              key={`${text}-${index}`}
            >
              {addPrettyFractionsToText(text)}
            </li>
          );
        } else if (isHeader) {
          return (
            <InstructionHeader
              style={{
                ...headerStyles[state],
                cursor: viewOnly ? undefined : "pointer"
              }}
              onClick={onClick}
              key={`${text}-${index}`}
            >
              {text}
            </InstructionHeader>
          );
        }

        return null;
      })}
    </UlInstructions>
  );
};
