import React from "react";
import { Modal } from "../../../ui/Modal";
import {
  GroceryListItemFragment,
  useUpdateGroceryItemMutation,
  updateGroceryItemMutationOptions,
  UpdateGroceryItemMutationVariables
} from "@saffron/controllers";
import { Formik, Field } from "formik";
import { FormInput } from "../../shared/formFields/FormInput";
import { FormField } from "../../../ui/Forms/FormField";
import { CheckboxField } from "../../shared/formFields/CheckboxField";

interface EditGroceryItemModalProps {
  item: GroceryListItemFragment | null;
  onRequestClose: () => void;
}

export const EditGroceryItemModal: React.FC<EditGroceryItemModalProps> = ({
  item,
  onRequestClose
}) => {
  const [updateGroceryItem, { loading }] = useUpdateGroceryItemMutation();

  return item ? (
    <Formik
      initialValues={{
        amount: item.amount || "",
        keyword: item.keyword || "",
        unit: item.unit || "",
        name: item.name,
        rememberChange: false
      }}
      onSubmit={async ({ rememberChange, ...values }) => {
        const input: UpdateGroceryItemMutationVariables["input"] = {
          keyword: values.keyword
        };
        (Object.keys(values) as any).forEach((k: keyof typeof values) => {
          if (values[k] !== item[k]) {
            input[k] = values[k];
          }
        });

        // this is important for when name === keyword
        // that way ground black pepper pepper doesn't happen
        if (values.name === "") {
          input.name = "";
        }

        await updateGroceryItem(
          updateGroceryItemMutationOptions({
            id: item.id,
            input,
            rememberOptions: rememberChange
              ? { ingredientId: item.ingredientId!, recipeId: item.recipeId! }
              : null
          })
        );
        onRequestClose();
      }}
    >
      {({ handleSubmit }) => (
        <Modal
          title="Edit Item"
          isOpen={!!item}
          loading={loading}
          onRequestClose={onRequestClose}
          mainButtonClick={() => handleSubmit()}
          mainButtonText="SAVE"
        >
          <FormField>
            <Field
              placeholder="item"
              name="keyword"
              autoComplete="false"
              component={FormInput}
            />
          </FormField>
          <FormField>
            <Field
              autoComplete="false"
              placeholder="amount"
              name="amount"
              component={FormInput}
            />
          </FormField>
          <FormField>
            <Field
              autoComplete="false"
              placeholder="unit"
              name="unit"
              component={FormInput}
            />
          </FormField>
          {item.ingredientId && item.recipeId ? (
            <>
              <FormField>
                <Field
                  placeholder="details"
                  name="name"
                  autoComplete="false"
                  component={FormInput}
                />
              </FormField>
              <Field
                label="update ingredient on the recipe to remember for next time"
                name="rememberChange"
                component={CheckboxField}
              />
            </>
          ) : (
            <Field placeholder="details" name="name" component={FormInput} />
          )}
        </Modal>
      )}
    </Formik>
  ) : null;
};
