import React from "react";
import styled from "styled-components";
import { Pattern } from "../../../ui/Pattern";
import { theme } from "../../../ui/theme";
import spoonImg from "./spoon.jpg";
import { InnerWrapper } from "../../../ui/InnerWrapper";
import { SinglePaneLayout } from "../../../ui/Layout/SinglePaneLayout";
import { OutlineButtonLink } from "../../../ui/Button";
import { GoldCard } from "../../user/AccountStatus/GoldCard";
import { RouteComponentProps } from "react-router-dom";
import { useOnboardingCb } from "@saffron/controllers";

const GetStarted = styled("div")`
  margin-top: 62px;
  font-family: ${theme.primaryFontStack};
  font-size: 4.875rem;
  line-height: 4.5rem;
`;

const Grid = styled.div({
  marginTop: 24,
  display: "grid",
  gridGap: 32,
  marginBottom: 70,
  gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
  "@media(max-width: 900px)": {
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))"
  }
});

export default ({
  location
}: RouteComponentProps<
  {},
  {},
  { sectionId?: string; cookbookId?: string }
>) => {
  const { cookbook, section } = useOnboardingCb(
    !!(location.state && location.state.cookbookId && location.state.sectionId)
  );
  const cookbookId = location.state?.cookbookId || cookbook?.id;
  const sectionId = location.state?.sectionId || section?.id;
  const goToRecipeForm = cookbookId && sectionId;
  const baseUrl = goToRecipeForm
    ? `/new-recipe/cookbook/${cookbookId}/${sectionId}`
    : "/browse";
  return (
    <SinglePaneLayout pattern={<Pattern backgroundImage="gold" />}>
      <InnerWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "70px"
          }}
        >
          <img
            style={{
              height: 200,
              marginTop: "-2em"
            }}
            alt="spoon"
            src={spoonImg}
          />
          <div style={{ marginLeft: 25 }}>
            <GetStarted>Get Started</GetStarted>
            <div
              style={{
                marginTop: 16,
                marginLeft: 6,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              Saffron’s ready to be filled with your favorite recipes.
            </div>
          </div>
        </div>
        <Grid>
          {[
            {
              goldText: "RECIPE FROM",
              title: "Website",
              description:
                "Import just the recipe from a website without pop-ups, ads, or stories.",
              buttonText: "IMPORT WEBSITE",
              to: baseUrl + (goToRecipeForm ? "?website-import=true" : "")
            },
            {
              goldText: "RECIPE FROM",
              title: "Text",
              description:
                "Copy and paste text from a PDF, Google Doc, or any block of copy that’s a recipe.",
              buttonText: "IMPORT TEXT",
              to: baseUrl + (goToRecipeForm ? "?text-import=true" : "")
            },
            {
              goldText: "OTHER RECIPES",
              title: "By Hand",
              description:
                "Have a recipe in another form? Or you’d like to develop your own? You can always type it out in our recipe friendly form.",
              buttonText: "ENTER BY HAND",
              to: baseUrl
            }
          ].map(x => (
            <GoldCard
              key={x.title}
              style={{
                height: 340,
                maxWidth: 340,
                width: "100%",
                margin: "auto"
              }}
              goldText={x.goldText}
              title={x.title}
            >
              <div>{x.description}</div>
              <OutlineButtonLink to={x.to} color="gold">
                {x.buttonText}
              </OutlineButtonLink>
            </GoldCard>
          ))}
        </Grid>
        <Pattern backgroundImage="gold" />
      </InnerWrapper>
    </SinglePaneLayout>
  );
};
