import * as React from "react";
import styled from "styled-components";
import { theme } from "../../../ui/theme";
import heroBackground from "../../../ui/HeroBackground/hero-background.jpg";
import { AppStoreButtons } from "../../../ui/AppStoreButtons";
import { heroBackgroundSize } from "../../../ui/HeroBackground";
import { GoldHeading, LandingPageButton } from "../../../ui/LandingComponents";

const Background = styled("div")`
  width: 100%;
  height: 100%;
  background: url(${heroBackground});
  ${heroBackgroundSize};
`;

export const LoginPhoneView = () => {
  return (
    <Background>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <GoldHeading
          style={{
            width: 350,
            fontSize: "1.75rem",
            lineHeight: "normal",
            marginTop: 160,
            marginBottom: 20
            // letterSpacing: -1
          }}
        >
          For the best experience, get the app!
        </GoldHeading>
      </div>
      <AppStoreButtons />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 50
        }}
      >
        <div
          style={{ fontFamily: theme.fonts.secondary, fontSize: "1.125rem" }}
        >
          Have the app already?
        </div>
        <div>
          <a href="mysaffron://check-auth">
            <LandingPageButton
              style={{
                paddingLeft: 40,
                paddingRight: 40,
                marginTop: 16,
                fontSize: "0.9375rem"
              }}
            >
              Open Saffron
            </LandingPageButton>
          </a>
        </div>
      </div>
    </Background>
  );
};
