import React from "react";
import onClickOutside from "react-onclickoutside";
import styled from "styled-components";
import { theme } from "../theme";
import { SelectOptions } from "./SelectOptions";

export interface SelectProps {
  style?: React.CSSProperties;
  placeholder?: string;
  value?: string | null;
  onChange: (value: string) => void;
  options: SelectOptions[];
  children: (x: {
    open: boolean;
    currentOption?: SelectOptions;
    toggle: () => void;
  }) => React.ReactNode;
}

const ListContainer = styled("div")`
  position: absolute;
  overflow-y: auto;
  z-index: 5;
  width: 100%;
  max-height: 200px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
`;

const List = styled("ul")`
  font-family: ${theme.uiFontStack};
  background-color: #fff;
  font-size: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const selectPaddingAmount = "8px 22.4px";

const padding = `
  padding: ${selectPaddingAmount}
`;

const ListItem = styled("li")`
  ${padding};
  cursor: pointer;
  &:hover {
    background-color: #ebeeee;
  }
`;

class C extends React.PureComponent<SelectProps, { open: boolean }> {
  state = {
    open: false
  };

  handleClickOutside = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    window.addEventListener("keyup", this.handleKeyUp, false);
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false);
  }

  handleKeyUp = (e: any) => {
    if (e.key === "Escape") {
      this.setState({ open: false });
    }
  };

  toggle = () =>
    this.setState(state => ({
      open: !state.open
    }));

  render() {
    const { options, onChange, value, children, style } = this.props;

    const currentOption = options.find(x => x.value === value);

    return (
      <div style={{ position: "relative", ...style }}>
        {children({
          currentOption,
          open: this.state.open,
          toggle: this.toggle
        })}
        {this.state.open && (
          <ListContainer>
            <List>
              {options.map(opt => (
                <ListItem
                  key={opt.key || opt.value}
                  onClick={() => {
                    onChange(opt.value);
                    this.toggle();
                  }}
                >
                  {opt.text}
                </ListItem>
              ))}
            </List>
          </ListContainer>
        )}
      </div>
    );
  }
}

export const SelectController = (onClickOutside(C as any) as any) as React.SFC<
  SelectProps
>;
