import React from "react";
import { Input } from "../../ui/Input";

// tslint:disable-next-line:no-var-requires
const Autosuggest = require("react-autosuggest");

export interface AutoSuggestProps {
  name?: string;
  onBlur?: any;
  suggestions: string[];
  value: string;
  onChange: (e: any, text: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
}

interface State {
  suggestions: string[];
}

function renderInput({ ref, ...props }: any) {
  return <Input inputRef={ref} {...props} />;
}

function renderSuggestion(
  suggestion: string,
  { isHighlighted }: { isHighlighted: boolean }
) {
  return (
    <div
      style={{
        backgroundColor: isHighlighted ? "#ebeeee" : "#fff",
        padding: "8px 1.4em"
      }}
    >
      {suggestion}
    </div>
  );
}

function renderSuggestionsContainer({ containerProps, children }: any) {
  return <div {...containerProps}>{children}</div>;
}

function getSuggestionValue(suggestion: string) {
  return suggestion;
}

function getSuggestions(value: string, suggestions: string[]) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        const keep =
          count < 5 &&
          suggestion.toLowerCase().slice(0, inputLength) === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

const styles = {
  container: {
    flexGrow: 1,
    position: "relative"
  },
  suggestionsContainer: {
    boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)`
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: 0,
    left: 0
  },
  suggestion: {},
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  }
};

export class AutoSuggest extends React.PureComponent<AutoSuggestProps, State> {
  state = {
    value: "",
    suggestions: []
  };

  handleSuggestionsFetchRequested = ({ value }: { value: string }) => {
    this.setState({
      suggestions: getSuggestions(value, this.props.suggestions)
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onChange = (e: any, { newValue }: any) => {
    this.props.onChange(e, newValue);
  };

  render() {
    const { name, value, onBlur, placeholder, autoFocus } = this.props;
    return (
      <Autosuggest
        theme={styles}
        renderInputComponent={renderInput}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{
          value,
          onChange: this.onChange,
          name,
          onBlur,
          placeholder,
          autoFocus
        }}
      />
    );
  }
}
