import React from "react";
import Icon, { PossibleIcons } from "../Icon";

interface Props {
  name: PossibleIcons;
  onClick: () => void;
}

export class TopRightIcon extends React.PureComponent<Props> {
  render() {
    return (
      <div
        style={{
          position: "fixed",
          right: 30,
          top: 20,
          zIndex: 5
        }}
      >
        <Icon
          name={this.props.name}
          height="3em"
          width="3em"
          onClick={this.props.onClick}
          tooltipPosition="bottom"
        />
      </div>
    );
  }
}
