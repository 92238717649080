import React, { useState } from "react";
import { Description } from "./components";
import { SpanButton } from "../DivButton";
import { theme } from "../theme";
import { truncString } from "../../utils/truncString";

interface ShortDescriptionProps {
  description: string;
  morePaddingBottom?: boolean;
}

const THRESHOLD = 150;
const MIN_DIFF = 100;

export const ShortDescription: React.FC<ShortDescriptionProps> = ({
  description,
  morePaddingBottom
}) => {
  const [open, setOpen] = useState(false);

  let inside = description;

  if (!open && description.length > THRESHOLD + MIN_DIFF) {
    inside = truncString(description, THRESHOLD);
  }

  return (
    <Description
      style={{ paddingBottom: morePaddingBottom ? "32px" : undefined }}
      data-testid="recipe-description"
    >
      {inside}
      {description.length > THRESHOLD + MIN_DIFF ? (
        <SpanButton
          style={{
            color: "#3F7C94",
            fontFamily: theme.fonts.ui,
            fontStyle: "normal",
            fontSize: "1.125rem"
          }}
          onClick={() => setOpen(!open)}
        >
          {open ? " less" : " more"}
        </SpanButton>
      ) : null}
    </Description>
  );
};
