import React from "react";
import { useState } from "react";
import { Modal } from "../Modal";
import { TextArea } from "../Input";
import { notificationState } from "../../modules/misc/notifications/NotificationState";
import { useFeedbackMutation } from "@saffron/controllers";
import Icon from "../Icon";

interface Props {}

export const FeedbackModal: React.FC<Props> = () => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [text, setText] = useState("");
  const [feedback, { loading }] = useFeedbackMutation();

  const close = () => {
    setShowFeedbackModal(false);
    setText("");
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "1.5em"
        }}
      >
        <Icon
          onClick={() => setShowFeedbackModal(true)}
          name="smiley"
          tooltipText="feedback"
          tooltipPosition="right"
        />
      </div>
      <Modal
        title="Your Feedback"
        isOpen={showFeedbackModal}
        onRequestClose={() => close()}
        mainButtonText="Send"
        loading={loading}
        mainButtonClick={async () => {
          if (!text.trim()) {
            return;
          }
          await feedback({ variables: { text } });
          close();
          notificationState.send({
            variant: "success",
            text: "thanks for the feedback"
          });
        }}
      >
        <TextArea
          autoFocus
          value={text}
          onChange={(e: any) => setText(e.target.value)}
        />
      </Modal>
    </>
  );
};
