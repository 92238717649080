import * as React from "react";
import ReactModal from "react-modal";
import { OutlineButton, ReverseOutlineButton } from "../Button";
import { ButtonSpinner } from "../Button/ButtonSpinner";
import { H4, H3 } from "../Heading";
import Icon from "../Icon";
import { theme } from "../theme";

interface Props {
  height?: number;
  width?: number;
  overflow?: string;
  isOpen: boolean;
  onRequestClose: () => void;
  children: React.ReactNode;
  content?: Record<string, any>;
  title?: string;
  mainButtonClick?: () => void;
  mainButtonText?: string;
  loading?: boolean;
  secondaryButtonClick?: () => void;
  secondaryButtonText?: string;
  variant?: "default" | "skinny" | "skinny-instructional";
  buttonVariant?: "default" | "red";
  headerStyles?: React.CSSProperties;
  xTop?: number;
  xRight?: number;
}

export const Modal: React.FC<Props> = ({
  height,
  width = 440,
  isOpen,
  overflow,
  onRequestClose,
  content,
  children,
  title,
  mainButtonClick,
  mainButtonText,
  secondaryButtonClick,
  loading,
  secondaryButtonText,
  xTop = -20,
  xRight = -20,
  variant = "default",
  buttonVariant = "default",
  headerStyles
}) => {
  const isSkinny = variant === "skinny" || variant === "skinny-instructional";
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          fontFamily: theme.fonts.ui,
          color: "#444",
          height,
          width: isSkinny ? 400 : width,
          overflow,
          display: "flex",
          flexDirection: "column",
          padding: "30px",
          backgroundColor: "#fff",
          boxShadow: "3px 6px 6px rgba(0, 0, 0, 0.16)",
          maxHeight: "88vh",
          maxWidth: "88vw",
          border: "1px solid #e6e6e6",
          ...content
        }
      }}
      ariaHideApp={false}
    >
      <div
        style={{
          position: "relative",
          height: "100%",
          display: "flex",
          flexDirection: "column"
          // overflowY: isSkinny ? "auto" : undefined
        }}
      >
        <Icon
          style={{ position: "absolute", top: xTop, right: xRight }}
          onClick={onRequestClose}
          width="2em"
          height="2em"
          fill="#ccc"
          name="x"
        />
        {title ? (
          variant === "skinny-instructional" ? (
            <H3
              style={{ fontSize: "1.375rem" }}
              m={0}
              mb={12}
              fontFamily="primary"
              fontStyle="italic"
            >
              {title}
            </H3>
          ) : (
            <H4
              style={headerStyles}
              m={0}
              mb={10}
              fontWeight={600}
              color="four"
              fontFamily="ui"
            >
              {title}
            </H4>
          )
        ) : null}
        {children}
        {mainButtonText || secondaryButtonText ? (
          <div style={{ marginTop: "3em", width: "100%", display: "flex" }}>
            {secondaryButtonText ? (
              <OutlineButton
                style={{
                  marginLeft: "auto",
                  display: "flex"
                }}
                color="black"
                type="button"
                disabled={loading}
                onClick={secondaryButtonClick}
              >
                {secondaryButtonText}
              </OutlineButton>
            ) : null}
            {mainButtonText ? (
              <ReverseOutlineButton
                style={{
                  width: isSkinny ? "100%" : undefined,
                  justifyContent: isSkinny ? "center" : undefined,
                  marginLeft: secondaryButtonText ? "1em" : "auto",
                  display: "flex"
                }}
                type="button"
                disabled={loading}
                variant={buttonVariant}
                color=""
                onClick={mainButtonClick}
              >
                {loading ? <ButtonSpinner /> : null}
                {mainButtonText}
              </ReverseOutlineButton>
            ) : null}
          </div>
        ) : null}
      </div>
    </ReactModal>
  );
};
