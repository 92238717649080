import { myYup, validMenuSections, normalizeErrors } from "@saffron/common";
import {
  useSyncMenuSectionsMutation,
  syncMenuSectionsMenuItemsMutationOptions
} from "@saffron/controllers";
import { FieldArray, Form, Formik } from "formik";
import React from "react";
import GhostButton from "../../../ui/Button";
import Icon from "../../../ui/Icon";
import { SortableMenuSections } from "./SortableMenuSections";
import { Modal } from "../../../ui/Modal";
import { Flex } from "../../../ui/Flex";

interface FormValues {
  menuSections: Array<{
    id?: string;
    name: string;
  }>;
  open: boolean;
}

interface Props {
  menuSections: FormValues["menuSections"];
}

export const SettingsModal: React.FC<Props> = props => {
  const [syncMenuSections] = useSyncMenuSectionsMutation();

  return (
    <Formik
      initialValues={{ menuSections: props.menuSections, open: false }}
      validationSchema={myYup.object({
        menuSections: validMenuSections
      })}
      onSubmit={async (
        { menuSections },
        { setSubmitting, setErrors, resetForm }
      ) => {
        let change = false;

        if (menuSections.length !== props.menuSections.length) {
          change = true;
        } else {
          for (let i = 0; i < props.menuSections.length; i += 1) {
            if (
              menuSections[i].id !== props.menuSections[i].id ||
              menuSections[i].name !== props.menuSections[i].name
            ) {
              change = true;
              break;
            }
          }
        }

        if (change) {
          const response = await syncMenuSections(
            syncMenuSectionsMenuItemsMutationOptions({
              menuSections: menuSections.map(x => ({ id: x.id, name: x.name }))
            })
          );
          if (
            response &&
            response.data &&
            response.data.syncMenuSections.errors
          ) {
            setErrors(normalizeErrors(response.data.syncMenuSections.errors));
            return setSubmitting(false);
          }
        }

        resetForm();
      }}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        resetForm,
        setFieldValue,
        isSubmitting,
        handleSubmit
      }) => (
        <>
          <Icon
            onClick={() => setFieldValue("open", true)}
            tooltipText="edit meals"
            name="bowl"
            fill="#666"
            width="1.25em"
            height="1.25em"
          />
          <Modal
            title="Create meal sections"
            isOpen={values.open}
            loading={isSubmitting}
            onRequestClose={() => resetForm()}
            mainButtonText="SAVE"
            mainButtonClick={handleSubmit}
          >
            <Form
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                overflowY: "auto",
                maxHeight: 400
              }}
            >
              <FieldArray
                name="menuSections"
                render={arrayHelpers => {
                  return (
                    <React.Fragment>
                      <Flex mt="1em">
                        <GhostButton
                          type="button"
                          onClick={() =>
                            arrayHelpers.push({ name: "", hasRecipes: false })
                          }
                          mb="1em"
                        >
                          add meal
                        </GhostButton>
                      </Flex>
                      <SortableMenuSections
                        onSortEnd={({ oldIndex, newIndex }) =>
                          arrayHelpers.move(oldIndex, newIndex)
                        }
                        removeMenuSection={i => arrayHelpers.remove(i)}
                        menuSections={values.menuSections}
                        errors={errors}
                        touched={touched}
                        useDragHandle
                      />
                    </React.Fragment>
                  );
                }}
              />
            </Form>
          </Modal>
        </>
      )}
    </Formik>
  );
};
