import React, { InputHTMLAttributes, useState } from "react";
import { useFormikContext } from "formik";
import { Input } from "../../../ui/Input";
import { Modal } from "../../../ui/Modal";
import GhostButton from "../../../ui/Button";
import Icon from "../../../ui/Icon";
import { getYoutubeId } from "@saffron/common";

type TextModalFormFieldProps = {
  name: string;
  modalTitle: string;
  buttonText: string;
  modalMainButtonText?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const TextModalFormField: React.FC<TextModalFormFieldProps> = ({
  modalTitle,
  buttonText,
  modalMainButtonText = "SAVE",
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [errorText, setErrorText] = useState("");
  const { setFieldValue } = useFormikContext<any>();

  const reset = () => {
    setText("");
    setErrorText("");
    setOpen(false);
  };

  return (
    <>
      <GhostButton
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        onClick={() => setOpen(true)}
      >
        <Icon
          style={{ marginRight: 6 }}
          name="video"
          template="ghostButtonSmall"
        />
        {buttonText}
      </GhostButton>
      <Modal
        title={modalTitle}
        mainButtonClick={() => {
          const id = getYoutubeId(text);
          if (id) {
            setFieldValue(props.name, id);
            reset();
          } else {
            setErrorText("invalid youtube url");
          }
        }}
        mainButtonText={modalMainButtonText}
        isOpen={open}
        onRequestClose={reset}
      >
        <Input
          errorText={errorText}
          value={text}
          onChange={e => setText(e.target.value)}
          autoFocus
          {...props}
        />
      </Modal>
    </>
  );
};
