import * as React from "react";
import { useGetSharedRecipeQuery, useMeQuery } from "@saffron/controllers";
import NotFound from "../misc/error/NotFound";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { slugify } from "@saffron/common";
import { SideBarLayout } from "../../ui/Layout/SidebarLayout";
import { Box } from "../../ui/Box";
import { SharedRecipeHeader } from "./shared/SharedRecipeHeader";
import Recipe from "../../ui/Recipe";

type Props = RouteComponentProps<{ shareId: string; slug: string }>;

const ViewSharedRecipe: React.FC<Props> = ({
  match: {
    params: { shareId, slug }
  }
}) => {
  const { data: meData } = useMeQuery();
  const { data, loading } = useGetSharedRecipeQuery({ variables: { shareId } });
  if (!data || loading) {
    return null;
  }

  if (!data.getSharedRecipe) {
    return <NotFound />;
  }

  if (data.getSharedRecipe) {
    const correctSlug = slugify(data.getSharedRecipe.name);
    if (slug !== correctSlug) {
      return (
        <Redirect
          to={`/a/shared/recipe/${data.getSharedRecipe.shareId}/${correctSlug}`}
        />
      );
    }
  }

  return (
    <SideBarLayout>
      <Box flex={1} mb="2em">
        <SharedRecipeHeader />
        <Recipe
          type={
            meData?.me.user?.id === data.getSharedRecipe.owner
              ? "owner"
              : "viewer"
          }
          disableRecipeLinks
          {...data.getSharedRecipe}
        />
      </Box>
    </SideBarLayout>
  );
};

export default ViewSharedRecipe;
