import styled from "styled-components";
import heroBackground from "./hero-background.jpg";
import { HEADER_HEIGHT } from "../theme";

export const heroBackgroundSize = `
  background-position: center;
  background-size: auto 100%;
  @media (min-width: 1920px) {
    background-size: 100% 100%;
  }
`;

export const HeroBackground = styled("div")`
  margin-top: ${HEADER_HEIGHT}px;
  background: url(${heroBackground});
  display: flex;
  ${heroBackgroundSize};
`;
