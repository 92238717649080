import * as React from "react";
import GhostButton from "./Button";
import { notificationState } from "../modules/misc/notifications/NotificationState";
import styled from "styled-components";
import { theme } from "./theme";

interface Props {
  text: string;
  buttonText: string;
  autoFocus?: boolean;
}

const ShareInput = styled("input")`
  font-family: ${theme.uiFontStack};
  flex: 1;
  background-color: #fafafa;
  padding: 8px;
  color: #808080;
  font-size: 0.875rem;
  border: 1px solid #e6e6e6;
`;

export const CopyInput: React.FC<Props> = ({ autoFocus, text, buttonText }) => {
  const ref = React.useRef<HTMLInputElement>();

  React.useLayoutEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.select();
    }
  }, [autoFocus]);

  return (
    <>
      <ShareInput
        autoFocus={autoFocus}
        onFocus={() => {
          if (ref.current) {
            ref.current.select();
          }
        }}
        ref={ref as any}
        value={text}
        readOnly
      />
      <GhostButton
        style={{
          width: 120,
          borderRadius: "0 3px 3px 0",
          borderLeft: "none"
        }}
        variant="white"
        onClick={() => {
          if (ref.current) {
            ref.current.select();
            const success = document.execCommand("copy");
            if (success) {
              notificationState.send({
                text: "successfully copied",
                variant: "success",
                icon: "clipboard"
              });
            }
          }
        }}
      >
        {buttonText}
      </GhostButton>
    </>
  );
};
