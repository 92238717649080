import { validForgotPassword } from "@saffron/common";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { SolidButton } from "../../../ui/Button";
import { FlexCenter } from "../../../ui/Flex";
import { FormGroup } from "../../../ui/Forms/FormGroup";
import { CardLogoLayout } from "../../../ui/Layout/CardLogoLayout";
import { FormInput } from "../../shared/formFields/FormInput";
import { useForgotPasswordMutation } from "@saffron/controllers";

const ForgotPassword: React.FC = () => {
  const [forgotPassword] = useForgotPasswordMutation();
  const history = useHistory();
  return (
    <Formik
      validationSchema={validForgotPassword}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{
        email: ""
      }}
      onSubmit={async values => {
        await forgotPassword({ variables: { input: values } });

        history.push("/forgot-password-check-email", {
          email: values.email
        });
      }}
    >
      {({ isSubmitting }) => (
        <CardLogoLayout>
          <Form
            style={{
              height: "100%",
              width: "100%",
              padding: "0 2.6em"
            }}
          >
            <FormGroup>
              <Field
                component={FormInput}
                name="email"
                placeholder="email"
                style={{ width: "100%" }}
              />
            </FormGroup>
            <FlexCenter>
              <SolidButton
                type="submit"
                disabled={isSubmitting}
                hoverColor="darkRed"
                bg="brownRed"
                color="brightWhite"
                style={{
                  marginBottom: "2em"
                }}
              >
                GET RESET LINK
              </SolidButton>
            </FlexCenter>
          </Form>
        </CardLogoLayout>
      )}
    </Formik>
  );
};

export default ForgotPassword;
