import { FieldAttributes, useField } from "formik";
import React from "react";
import { ReverseOutlineButton } from "../../../../../ui/Button";
import {
  EditorBar,
  EditorBarLeft,
  EditorDiv,
  EditorWrapper
} from "../ui/EditorComponents";
import TextareaAutosize from "react-textarea-autosize";
import { theme } from "../../../../../ui/theme";

export const PlainTextInstructionsEditor: React.FC<FieldAttributes<{
  handleSubmit: any;
}>> = ({ handleSubmit, ...props }) => {
  const [field] = useField<any>(props);
  return (
    <EditorDiv>
      <EditorBar style={{ marginTop: "2em" }}>
        <EditorBarLeft></EditorBarLeft>
        <ReverseOutlineButton type="button" onClick={handleSubmit}>
          SAVE INSTRUCTIONS
        </ReverseOutlineButton>
      </EditorBar>
      <EditorWrapper>
        <TextareaAutosize
          style={{
            width: "100%",
            resize: "none",
            outline: "none",
            backgroundColor: "Transparent",
            border: "none",
            fontSize: "1.0rem",
            fontFamily: theme.fonts.ui,
            color: "#444",
            lineHeight: "1.75rem"
          }}
          {...field}
          placeholder="Paste steps..."
        />
      </EditorWrapper>
    </EditorDiv>
  );
};
