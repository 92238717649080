import React from "react";
import { genId } from "../../modules/misc/notifications/utils/genId";

interface Props {
  width?: number | string;
  height?: number | string;
  c1: string;
  c2: string;
  c3: string;
}

export const ZiggyPattern: React.FC<Props> = ({
  c1,
  c2,
  c3,
  width = 400,
  height = 400
}) => {
  const randomId = genId();
  return (
    <svg width={width} height={height}>
      <defs>
        <pattern
          id={`prefix__${randomId}`}
          patternUnits="userSpaceOnUse"
          width={7}
          height={7}
        >
          <rect width="100%" height="100%" fill={c1} />
          <rect width="2" height="2" fill={c2} opacity={0.25} />
          <rect x="4" y="4" width="2" height="2" fill={c3} opacity={0.25} />
          {/* <Rect x="5" y="5" width="2" height="2" fill="yellow" opacity={0.25} /> */}
        </pattern>
      </defs>
      <rect width="100%" height="100%" fill={`url(#prefix__${randomId})`} />
    </svg>
  );
};
