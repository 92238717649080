import { FormikErrors } from "formik";
import React from "react";
import { Box } from "../../../ui/Box";
import { Flex } from "../../../ui/Flex";
import { H4 } from "../../../ui/Heading";
import { CardLogoLayout } from "../../../ui/Layout/CardLogoLayout";
import { PrettyMyLink } from "../../../ui/PrettyLink";
import { LoginForm, LoginFormValues } from "./LoginForm";

interface Props {
  noRegisterLink?: boolean;
  noForgotPassword?: boolean;
  handleLogin: (
    values: LoginFormValues
  ) => Promise<FormikErrors<LoginFormValues> | null>;
}

export class LoginView extends React.PureComponent<Props> {
  render() {
    const { handleLogin, noRegisterLink, noForgotPassword } = this.props;
    return (
      <CardLogoLayout>
        <LoginForm
          noForgotPassword={noForgotPassword}
          handleSubmit={handleLogin}
        />
        <Flex mb="45px">
          {noRegisterLink ? null : (
            <Box mx="auto">
              <H4 fontFamily="primary" fontStyle="italic">
                <PrettyMyLink to="/register">
                  Or, create an account.
                </PrettyMyLink>
              </H4>
            </Box>
          )}
        </Flex>
      </CardLogoLayout>
    );
  }
}
