import {
  DateSize,
  useMenuPlanner,
  StandardMenuItemFragment,
  MenuPlannerQuery,
  StandardMenuNoteFragment
} from "@saffron/controllers";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Box } from "../../../ui/Box";
import { Flex } from "../../../ui/Flex";
import { MainContent } from "../../../ui/Layout/MainContent";
import { SideBarLayout } from "../../../ui/Layout/SidebarLayout";
import { theme } from "../../../ui/theme";
import { pushLocation } from "../../../utils/pushLocation";
import { DateHeader } from "../shared/DateHeader";
import { SettingsModal } from "../shared/SettingsModal";
import { createWeekString } from "../utils/createWeekString";
import { AddToGroceryListIcon } from "./components/AddToGroceryListIcon";
import { MonthView } from "./components/MonthView";
import { RecipeModal } from "./components/RecipeModal";
import { ScaleMenuItem } from "./components/ScaleMenuItem";
import { WeekView } from "./components/WeekView";
import { MenuPlannerSidebar } from "./MenuPlannerSidebar";
import { NewNoteModal } from "./NewNoteModal";
import { ViewNoteModal } from "./ViewNoteModal";
import { useLocation } from "react-router";

interface Props {
  year?: string;
  month?: string;
  day?: string;
  dateSize?: DateSize;
}

// if you change the zoom settings
// you need to change it on the navbar too
const Zoomer = styled.div({
  width: "100%"
});

const START_OF_WEEK = "START_OF_WEEK";

const MONDAY = 1;

export const ViewMenuPlanner: React.FC<Props> = props => {
  const location = useLocation();
  const pushDate = (dt: Dayjs, dateSize: DateSize) => {
    pushLocation(
      `/menu-planner/${dateSize}/${dt.year()}/${dt.month()}/${dt.date()}`
    );
  };
  const [defaultStartOfWeek, setDefaultStartOfWeek] = useState(() => {
    try {
      const v = localStorage.getItem(START_OF_WEEK);
      if (v) {
        const n = parseInt(v);
        return isNaN(n) ? MONDAY : n;
      }
    } catch {}
    return MONDAY;
  });
  useEffect(() => {
    try {
      localStorage.setItem(START_OF_WEEK, "" + defaultStartOfWeek);
    } catch {}
  }, [defaultStartOfWeek]);
  const [menuItemPopUp, setMenuItemPopUp] = useState<
    StandardMenuItemFragment | undefined
  >(undefined);
  const [menuNotePopUp, setMenuNotePopUp] = useState<
    StandardMenuNoteFragment | undefined
  >(undefined);
  const [newNoteModalOpenDate, setNewNoteModalOpenDate] = useState("");
  const [currentTab, setCurrentTab] = useState("Cookbooks");
  const [menuItemToScale, setMenuItemToScale] = useState<
    StandardMenuItemFragment | undefined
  >(undefined);

  const validStartOfWeek =
    defaultStartOfWeek === 7 ? dayjs().day() : defaultStartOfWeek;

  const {
    dateSize,
    date,
    menuDays,
    menuSections,
    menuPlannerQueryVariables,
    menuItemsOrNotes
  } = useMenuPlanner({ ...props, validStartOfWeek });

  const todayRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (location.hash === "#today" && todayRef.current) {
      todayRef.current.scrollIntoView();
      // minus height of sticky header
      window.scrollTo({ top: window.scrollY - 119 });
    }
  }, [location.hash, location.key]);

  const onMenuItemClick = (
    menuItemOrNote: MenuPlannerQuery["menuPlanner"][0]
  ) => {
    if (menuItemOrNote.__typename === "MenuItem") {
      setMenuItemPopUp(menuItemOrNote);
    } else {
      setMenuNotePopUp(menuItemOrNote);
    }
  };

  const onScaleClick = (menuItemOrNote: MenuPlannerQuery["menuPlanner"][0]) => {
    if (menuItemOrNote.__typename === "MenuItem") {
      setMenuItemToScale(menuItemOrNote);
    }
  };

  let dateGrid = (
    <WeekView
      menuDays={menuDays}
      menuSections={menuSections}
      menuPlannerQueryVariables={menuPlannerQueryVariables}
      onMenuItemClick={onMenuItemClick}
      onScaleClick={onScaleClick}
      onOpenNewNoteModal={dateKey => {
        setNewNoteModalOpenDate(dateKey);
      }}
    />
  );

  if (dateSize === "month") {
    dateGrid = (
      <MonthView
        todayRef={todayRef}
        onMenuItemClick={onMenuItemClick}
        onScaleClick={onScaleClick}
        menuDays={menuDays}
        menuSections={menuSections}
        menuPlannerQueryVariables={menuPlannerQueryVariables}
        onOpenNewNoteModal={dateKey => {
          setNewNoteModalOpenDate(dateKey);
        }}
      />
    );
  }

  return (
    <Zoomer>
      <SideBarLayout pattern={false}>
        <MenuPlannerSidebar
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
        <MainContent
          style={{ backgroundColor: "#fafafa" }}
          noPattern
          smallSidebar
        >
          <Flex
            flex={1}
            pb={20}
            flexDirection="column"
            style={{
              backgroundColor: "#fafafa",
              fontFamily: theme.fonts.ui,
              color: "#444",
              minHeight: "100%"
            }}
          >
            <DateHeader
              text={
                dateSize === "week"
                  ? createWeekString(date, validStartOfWeek)
                  : `${date.format("MMMM")} ${date.year()}`
              }
              next={() => pushDate(date.add(1, dateSize), dateSize)}
              previous={() => pushDate(date.subtract(1, dateSize), dateSize)}
              todayLinkTo={`/menu-planner/${dateSize}${
                dateSize === "month" ? "#today" : ""
              }`}
              defaultStartOfWeek={defaultStartOfWeek}
              onStartOfWeek={x => setDefaultStartOfWeek(x)}
              dateSize={dateSize}
              onDateSize={size => pushDate(date, size)}
              date={date}
              onDate={dt => pushDate(dt, dateSize)}
              validStartOfWeek={validStartOfWeek}
            >
              <AddToGroceryListIcon
                menuSections={menuSections}
                menuDays={menuDays}
                anchorDate={date.toDate()}
              />
              <SettingsModal menuSections={menuSections} />
            </DateHeader>
            <Box style={{ minWidth: 750 }} flex={1}>
              {dateGrid}
            </Box>
            <RecipeModal
              menuItem={menuItemPopUp}
              date={date}
              closeModal={() => setMenuItemPopUp(undefined)}
              menuPlannerQueryVariables={menuPlannerQueryVariables}
              onScaleClick={() => {
                setMenuItemPopUp(undefined);
                setMenuItemToScale(menuItemPopUp);
              }}
            />
            <NewNoteModal
              dtKey={newNoteModalOpenDate}
              onRequestClose={() => setNewNoteModalOpenDate("")}
              menuSections={menuSections}
              menuPlannerQueryVariables={menuPlannerQueryVariables}
            />
            {menuNotePopUp ? (
              <ViewNoteModal
                menuNote={
                  (menuItemsOrNotes.find(
                    x => x.id === menuNotePopUp.id
                  ) as StandardMenuNoteFragment) || menuNotePopUp
                }
                onRequestClose={() => setMenuNotePopUp(undefined)}
                menuPlannerQueryVariables={menuPlannerQueryVariables}
              />
            ) : null}
            <ScaleMenuItem
              menuItem={menuItemToScale}
              requestClose={() => setMenuItemToScale(undefined)}
              menuPlannerQueryVariables={menuPlannerQueryVariables}
            />
          </Flex>
        </MainContent>
      </SideBarLayout>
    </Zoomer>
  );
};
