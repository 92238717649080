import { ADMIN_STATUS } from "@saffron/common";
import { useMeQuery } from "@saffron/controllers";
import React from "react";
import styled from "styled-components";
import { Subscribe } from "unstated";
import { notUserRoleType } from "../../utils/hasUserRoleType";
import { Flex } from "../Flex";
import Icon from "../Icon";
import { MyLink } from "../MyLink";
import { patternWidth } from "../theme";
import { NavBarContainer } from "./NavBarContainer";
import { NavBarWatcher } from "./NavBarWatcher";
import { bottomNavIcon, navIconList } from "./navIconList";
import { FeedbackModal } from "./FeedbackModal";
import { useLocation } from "react-router";
import { mediaSizes } from "../mediaTemplates";

const Bar = styled.div<{ isMenuPlannerPage: boolean }>({
  zIndex: 4,
  position: "fixed",
  top: "0px",
  height: "100%",
  [`@media (max-width: ${mediaSizes.leftNavDies}px)`]: {
    display: "none"
  }
});

const NavBar: React.FC = () => {
  const location = useLocation();
  const { data, loading } = useMeQuery();

  const notAdmin = notUserRoleType(data, loading, ADMIN_STATUS);
  const user = data && data.me && data.me.user;

  return (
    <Subscribe to={[NavBarContainer]}>
      {({ state, setMenuPlannerPath }: NavBarContainer) => {
        return (
          <Bar
            isMenuPlannerPage={location.pathname.startsWith("/menu-planner")}
          >
            <NavBarWatcher
              menuPlannerPath={state.menuPlannerPath}
              setMenuPlannerPath={setMenuPlannerPath}
            />
            <Flex
              pt="56px"
              pb="1.5em"
              bg="darkGray"
              width={patternWidth}
              height="100%"
              flexDirection="column"
              alignItems="center"
            >
              {user && (
                <>
                  {navIconList.map(icon =>
                    icon.status === ADMIN_STATUS && notAdmin ? null : (
                      <MyLink
                        key={icon.name}
                        style={{
                          display: "flex",
                          marginBottom: "1.5em"
                        }}
                        to={
                          icon.to === "menuPlannerPath"
                            ? state.menuPlannerPath
                            : icon.to
                        }
                      >
                        <Icon
                          name={icon.name}
                          tooltipText={icon.tooltipText}
                          tooltipPosition="right"
                        />
                      </MyLink>
                    )
                  )}
                  <Flex flex="1" alignItems="flex-end">
                    <div>
                      <FeedbackModal />

                      <MyLink
                        style={{
                          display: "flex"
                        }}
                        to={bottomNavIcon.to}
                      >
                        <Icon
                          name={bottomNavIcon.name}
                          tooltipText={bottomNavIcon.tooltipText}
                          tooltipPosition="right"
                        />
                      </MyLink>
                    </div>
                  </Flex>
                </>
              )}
            </Flex>
          </Bar>
        );
      }}
    </Subscribe>
  );
};

export default NavBar;
