import React, { useState, useEffect } from "react";
import { Modal } from "../../../ui/Modal";
import { TextArea } from "../../../ui/Input";
import {
  MenuSectionColor,
  useCreateMenuNoteMutation,
  createMenuNoteMutationOptions,
  MenuPlannerQueryVariables
} from "@saffron/controllers";
import { RadioButtonWithLabel } from "../../../ui/RadioButtonWithLabel";

interface NewNoteModalProps {
  dtKey: string;
  onRequestClose: () => void;
  menuSections: MenuSectionColor[];
  menuPlannerQueryVariables: MenuPlannerQueryVariables;
}

export const NewNoteModal: React.FC<NewNoteModalProps> = ({
  dtKey,
  onRequestClose,
  menuSections,
  menuPlannerQueryVariables
}) => {
  const [text, setText] = useState("");
  const [errorText, setErrorText] = useState("");
  const [createNote, { loading }] = useCreateMenuNoteMutation();
  const [menuId, setMenuId] = useState(menuSections[0]?.id);
  const firstMenuId = menuSections[0]?.id;
  useEffect(() => {
    if (!menuId && firstMenuId) {
      setMenuId(firstMenuId);
    }
  }, [menuId, firstMenuId]);

  const reset = () => {
    setText("");
    setErrorText("");
    onRequestClose();
  };
  return (
    <Modal
      loading={loading}
      mainButtonText="SAVE"
      mainButtonClick={() => {
        if (!text || !text.trim()) {
          setErrorText("required");
          return;
        }

        createNote(
          createMenuNoteMutationOptions(
            {
              input: { date: dtKey, menuSectionId: menuId, text }
            },
            menuPlannerQueryVariables
          )
        ).then(() => {
          reset();
        });
      }}
      title="New Note"
      isOpen={!!dtKey}
      onRequestClose={reset}
    >
      <TextArea
        autoFocus
        placeholder="note"
        style={{ maxHeight: 200 }}
        value={text}
        onChange={e => setText(e.target.value)}
        errorText={errorText}
      />
      <div style={{ marginTop: "1em" }}>Pick meal to add it to:</div>
      {menuSections.map(ms => {
        return (
          <div
            style={{ display: "flex", alignItems: "center", marginTop: 12 }}
            key={ms.id}
          >
            <RadioButtonWithLabel
              onClick={() => setMenuId(ms.id)}
              active={ms.id === menuId}
              label={ms.name}
            />
          </div>
        );
      })}
    </Modal>
  );
};
