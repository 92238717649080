import React from "react";
import {
  SortableElement,
  SortableHandle,
  SortableContainer
} from "react-sortable-hoc";
import { Field, getIn } from "formik";

import { Flex } from "../../../ui/Flex";
import Icon from "../../../ui/Icon";
import { FormInput } from "../../shared/formFields/FormInput";
import { Box, ScrollableBox } from "../../../ui/Box";

interface DragHandleProps {
  flexEnd: boolean;
}

const DragHandle = SortableHandle<DragHandleProps>(
  ({ flexEnd }: DragHandleProps) => (
    <Icon
      fill="#BDCBCB"
      style={{
        cursor: "row-resize",
        alignSelf: flexEnd ? "flex-end" : undefined
      }}
      name="menu"
    />
  )
);

interface FormMenuSection {
  id?: string;
  name: string;
}

interface SortableMenuSectionProps {
  i: number;
  removeMenuSection: (i: number) => void;
  errors: any;
  touched: any;
}

const SortableMenuSection = SortableElement<SortableMenuSectionProps>(
  ({ i, removeMenuSection, errors, touched }: SortableMenuSectionProps) => {
    const name = `menuSections[${i}].name`;
    const err = getIn(touched, name) && getIn(errors, name);
    return (
      <Flex mb="1em" style={{ zIndex: 10 }}>
        <DragHandle flexEnd={!err} />
        <Box px="5px" flex={1}>
          <Field
            style={{ flex: 1 }}
            name={name}
            placeholder={`meal ${i + 1}`}
            component={FormInput}
            autoFocus
            fullWidth
          />
        </Box>
        <Icon
          style={{ alignSelf: err ? "" : "flex-end" }}
          name="x"
          template="x"
          onClick={() => {
            removeMenuSection(i);
          }}
        />
      </Flex>
    );
  }
);

interface Props {
  menuSections: FormMenuSection[];
  removeMenuSection: (i: number) => void;
  errors: any;
  touched: any;
}

class C extends React.PureComponent<Props> {
  mapMenuSections = (_: FormMenuSection, i: number) => (
    <SortableMenuSection
      key={i}
      index={i}
      removeMenuSection={this.props.removeMenuSection}
      errors={this.props.errors}
      touched={this.props.touched}
      i={i}
    />
  );

  render() {
    const { menuSections } = this.props;

    return (
      <ScrollableBox mb="1em" flex={1}>
        {menuSections.map(this.mapMenuSections)}
      </ScrollableBox>
    );
  }
}

(C as any).displayName = "SortableMenuSections";

export const SortableMenuSections = SortableContainer<Props>(C);
