import { slugify } from "@saffron/common";
import {
  useMeQuery,
  useGetRecipeByIdWithCookbooksQuery
} from "@saffron/controllers";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { Box } from "../../ui/Box";
import { MainContent } from "../../ui/Layout/MainContent";
import { SideBarLayout } from "../../ui/Layout/SidebarLayout";
import Recipe from "../../ui/Recipe/index";
import { patternWidthValue } from "../../ui/theme";
import { isUuid } from "../../utils/isUuid";
import { pushLocation } from "../../utils/pushLocation";
import NotFound from "../misc/error/NotFound";
import { RiconBar } from "../ricons/RiconBar/index";
import { scaleIgContainer } from "../ricons/ScaleIgContainer";
import { RecipeNoteSidebar } from "../shared/RecipeNoteSidebar";
import { CookbookBreadcrumbs } from "../../ui/CookbookBreadcrumbs";
import { RecipeWrapper } from "../../ui/Recipe/components";

type Props = RouteComponentProps<{ id: string; slug: string }>;

const ViewRecipe: React.FC<Props> = ({
  match: {
    params: { slug, id }
  },
  location: { search },
  history
}) => {
  const { data: meData } = useMeQuery();
  const [showNotes, setShowNotes] = useState(false);
  const validId = isUuid(id);
  const { data, loading } = useGetRecipeByIdWithCookbooksQuery({
    skip: !validId,
    variables: { id },
    onCompleted: ({ getRecipeById }) => {
      if (getRecipeById?.notes) {
        setShowNotes(true);
      }
    }
  });

  useEffect(() => {
    if (search && validId) {
      const parsed = queryString.parse(search);
      if (parsed.scale && typeof parsed.scale === "string") {
        const n = parseFloat(parsed.scale);
        if (!isNaN(n)) {
          scaleIgContainer.setAmount(id, n);
        }
      }
    }
  }, [search, validId, id]);

  if (!validId) {
    return <NotFound />;
  }

  if (!data || loading) {
    return null;
  }

  const { getRecipeById } = data;

  if (!getRecipeById) {
    return <NotFound />;
  }

  if (getRecipeById) {
    const correctSlug = slugify(getRecipeById.name);
    if (slug !== correctSlug) {
      return <Redirect to={`/recipe/${getRecipeById.id}/${correctSlug}`} />;
    }
  }

  const inside = (
    <>
      <div style={{ width: "100%", height: "1em" }} />
      <RiconBar
        recipe={getRecipeById}
        onDeleteRiconClick={() => pushLocation("/browse")}
        onEditRiconClick={() => {
          history.push(`/recipe/edit/${id}/${slug}`, {
            next: `/recipe/${id}/${slug}`
          });
        }}
      />
      {getRecipeById && getRecipeById.cookbookLookup ? (
        <RecipeWrapper style={{ marginTop: "3em", marginBottom: 0 }}>
          <CookbookBreadcrumbs
            cookbookLookup={getRecipeById.cookbookLookup}
            recipeId={getRecipeById.id}
          />
        </RecipeWrapper>
      ) : null}
      <Recipe
        type={meData?.me.user?.id === getRecipeById.owner ? "owner" : "viewer"}
        notesOpen={showNotes}
        onNotesClicked={() => setShowNotes(!showNotes)}
        {...getRecipeById}
      />
    </>
  );

  return (
    <SideBarLayout>
      {showNotes ? (
        <RecipeNoteSidebar
          onRequestClose={() => setShowNotes(false)}
          recipe={getRecipeById}
        />
      ) : null}
      {showNotes ? (
        <MainContent>{inside}</MainContent>
      ) : (
        <Box flex={1} mb="2em" pl={patternWidthValue}>
          {inside}
        </Box>
      )}
    </SideBarLayout>
  );
};

export default ViewRecipe;
