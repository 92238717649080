import { Field, Formik } from "formik";
import React from "react";
import { FormInput } from "../modules/shared/formFields/FormInput";
import { Modal } from "./Modal";

interface Props {
  initialText?: string;
  submit: (text: string) => Promise<string | null | void>;
  closeModal: () => void;
  open: boolean;
  title: string;
  label: string;
  validationSchema?: any;
  skipReset?: boolean;
}

export const LineModalForm: React.FC<Props> = ({
  initialText = "",
  open,
  submit,
  closeModal,
  label,
  title,
  validationSchema,
  skipReset
}) => {
  return (
    <Formik
      onSubmit={async ({ text }, { setErrors, setSubmitting, resetForm }) => {
        try {
          const error = await submit(text);
          if (error) {
            setErrors({ text: error });
            setSubmitting(false);
          } else if (!skipReset) {
            resetForm();
            closeModal();
          }
        } catch {
          setSubmitting(false);
        }
      }}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{ text: initialText }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, resetForm }) => (
        <Modal
          isOpen={open}
          onRequestClose={() => {
            resetForm();
            closeModal();
          }}
          title={title}
          loading={isSubmitting}
          mainButtonClick={handleSubmit}
          mainButtonText="OK"
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%"
            }}
            onSubmit={handleSubmit}
          >
            <Field
              disabled={isSubmitting}
              placeholder={label}
              name="text"
              component={FormInput}
              autoFocus
              autoComplete="off"
            />
          </form>
        </Modal>
      )}
    </Formik>
  );
};
