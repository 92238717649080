import { Node } from "slate";

export const valueToPlainText = (value: Node[]) => {
  return value
    .map(n => {
      if (n.type === "header-three") {
        return `${Node.string(n)}:`;
      } else if (n.type === "recipe-link") {
        return `[${Node.string(n)}](${(n?.data as any)?.recipeId || ""})`;
      }

      return Node.string(n);
    })
    .join("\n");
};
