import styled from "styled-components";

export const DivButton = styled("button")({
  background: "none",
  textAlign: "inherit",
  backgroundColor: "transparent",
  outline: "none",
  fontFamily: "inherit",
  color: "inherit",
  border: "none",
  padding: 0,
  cursor: "pointer"
});

export const SpanButton = DivButton.withComponent("span");
