import React, { createRef } from "react";
import { withFormik, FormikProps, Field } from "formik";
import Icon from "../../../ui/Icon";
import styled from "styled-components";
import { SmallButton } from "../../../ui/Button";
import { SearchFormField } from "../formFields/SearchFormField";

export interface SearchFormValues {
  searchQuery: string;
  recipesNotInCookbooks: boolean;
}

interface Props {
  initialValues?: Partial<SearchFormValues>;
  search: (data: SearchFormValues) => void;
  allRecipesSearch: () => void;
  sidebar?: boolean;
  smaller?: boolean;
  noPaddingBottom?: boolean;
  lessPaddingTop?: boolean;
  placeholder?: string;
}

const Container = styled("div")`
  display: flex;
  padding-top: 2em;
  background-color: Transparent;
`;

class C extends React.PureComponent<Props & FormikProps<SearchFormValues>> {
  inputRef = createRef<HTMLInputElement>();
  render() {
    const {
      handleSubmit,
      sidebar,
      allRecipesSearch,
      smaller,
      noPaddingBottom,
      lessPaddingTop,
      placeholder,
      values,
      setFieldValue
    } = this.props;

    return (
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "0 0 auto",
          paddingBottom: noPaddingBottom ? 0 : "1.5em"
        }}
      >
        <Container
          className="search-form-container"
          style={{
            margin: sidebar ? (smaller ? ".75em" : "1em") : undefined,
            marginBottom: noPaddingBottom ? 0 : undefined,
            paddingTop: lessPaddingTop ? 0 : undefined
          }}
        >
          <Field
            inputRef={this.inputRef}
            style={{
              backgroundColor: "Transparent",
              fontSize: smaller ? ".875rem" : undefined
            }}
            name="searchQuery"
            component={SearchFormField}
            placeholder={placeholder || "title, ingredient, or author..."}
            icon={
              values.searchQuery ? (
                <Icon
                  width={smaller ? "1em" : "1.5em"}
                  height={smaller ? "1em" : "1.5em"}
                  name="circleCancel"
                  fill="#999"
                  onClick={() => {
                    setFieldValue("searchQuery", "");
                    this.inputRef.current?.focus();
                  }}
                />
              ) : (
                <Icon
                  width={smaller ? "1em" : "1.5em"}
                  height={smaller ? "1em" : "1.5em"}
                  name="search"
                  fill="#999"
                  onClick={handleSubmit as any}
                />
              )
            }
            autoFocus
          />
          {values.searchQuery ? null : (
            <div style={{ display: "flex" }}>
              <SmallButton
                style={{
                  marginLeft: smaller ? ".25em" : "1em",
                  marginBottom: "auto"
                }}
                small={smaller}
                type="button"
                onClick={allRecipesSearch}
              >
                All
              </SmallButton>
            </div>
          )}
        </Container>
      </form>
    );
  }
}

(C as any).displayName = "SearchForm";

export const SearchForm = withFormik<Props, SearchFormValues>({
  validateOnBlur: false,
  validateOnChange: false,
  enableReinitialize: true,
  validate: values => {
    const errors: { [key: string]: string } = {};
    if (!values.searchQuery.trim()) {
      errors.searchQuery = "Cannot be blank";
    }
    return errors;
  },
  mapPropsToValues: p => ({
    searchQuery: "",
    recipesNotInCookbooks: false,
    ...p.initialValues
  }),
  handleSubmit: async (data, { props, resetForm }) => {
    props.search(data);
    resetForm();
  }
})(C as any);
