import { Omit } from "@saffron/common";
import { Container } from "unstated";
import { PossibleIcons } from "../../../ui/Icon";
import { genId } from "./utils/genId";

export interface Notif {
  id: string;
  text: string;
  textColor?: string;
  backgroundColor?: string;
  icon?: PossibleIcons;
}

interface State {
  notifications: Notif[];
}

const variants = {
  info: {
    textColor: "#fff",
    backgroundColor: "#212556"
  },
  success: {
    textColor: "#fff",
    backgroundColor: "#5a5c17"
  },
  error: {
    textColor: "#fff",
    backgroundColor: "#972409"
  }
};

export class NotificationState extends Container<State> {
  state: State = {
    notifications: []
  };

  send = ({
    variant,
    ...notification
  }: Omit<Notif, "id"> & { variant?: keyof typeof variants }) => {
    this.setState(state => ({
      notifications: [
        ...state.notifications,
        {
          ...(variant ? variants[variant] : {}),
          ...notification,
          id: genId()
        }
      ]
    }));
  };

  close = (id: string) => {
    this.setState(state => ({
      notifications: state.notifications.filter(x => x.id !== id)
    }));
  };
}

export const notificationState = new NotificationState();
