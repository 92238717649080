import React from "react";
import {
  useGetRecipeByIdQuery,
  RegularIngredient,
  useUpdateMenuItemMutation,
  updateMenuItemMutationOptions,
  MenuPlannerQueryVariables,
  StandardMenuItemFragment
} from "@saffron/controllers";
import ReactModal from "react-modal";
import { Spinner } from "../../../../ui/Spinner";
import { FlexCenter } from "../../../../ui/Flex";
import { ScaleBox } from "../../../ricons/ScaleBox";
import { Formik } from "formik";
import { scaleIngredient, ingredientToParts } from "@saffron/common";
import { ReverseOutlineButton } from "../../../../ui/Button";
import { notificationState } from "../../../misc/notifications/NotificationState";

interface Props {
  requestClose: () => void;
  menuItem?: StandardMenuItemFragment;
  menuPlannerQueryVariables: MenuPlannerQueryVariables;
}

export const ScaleMenuItem: React.FC<Props> = ({
  menuItem,
  requestClose,
  menuPlannerQueryVariables
}) => {
  const { data, loading } = useGetRecipeByIdQuery({
    skip: !menuItem,
    variables: { id: menuItem ? menuItem.recipe.id : "" }
  });
  const [
    updateMenuItem,
    { loading: updateLoading }
  ] = useUpdateMenuItemMutation();

  const ingredients =
    data && data.getRecipeById ? data.getRecipeById.ingredients : [];

  return (
    <ReactModal
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          boxShadow: "3px 6px 6px rgba(0, 0, 0, 0.16)",
          maxHeight: "88vh",
          maxWidth: "88vw",
          padding: 0,
          border: "1px solid #e6e6e6",
          width: 400
        }
      }}
      ariaHideApp={false}
      isOpen={!!menuItem}
      onRequestClose={requestClose}
    >
      {loading || updateLoading ? (
        <FlexCenter>
          <Spinner />
        </FlexCenter>
      ) : (
        <Formik
          onSubmit={() => {}}
          initialValues={{ scaleValue: menuItem ? "" + menuItem.scale : "1" }}
          enableReinitialize
        >
          {({ values, setFieldValue }) => {
            const fScale = parseFloat(values.scaleValue);
            const multiplier = isNaN(fScale) ? 1 : fScale;
            return (
              <>
                <ScaleBox
                  onX={() => requestClose()}
                  tmpAmount={values.scaleValue}
                  value={values.scaleValue}
                  setValue={v => setFieldValue("scaleValue", v)}
                />
                <div
                  style={{
                    padding: 27,
                    display: "grid",
                    gridTemplateColumns: "auto",
                    gridRowGap: "1em",
                    maxHeight: 400,
                    overflowY: "auto"
                  }}
                >
                  {ingredients.map((ig, i) => {
                    if (ig.__typename === "IngredientHeader") {
                      return null;
                    }
                    const scaledIg = scaleIngredient(
                      ig,
                      multiplier
                    ) as RegularIngredient;

                    const { amountAndUnit, rest } = ingredientToParts(scaledIg);

                    return (
                      <div key={i} style={{ wordBreak: "break-word" }}>
                        <strong style={{ color: "#333" }}>
                          {amountAndUnit}
                        </strong>
                        {rest}
                      </div>
                    );
                  })}
                </div>
                <div style={{ padding: 27 }}>
                  <ReverseOutlineButton
                    onClick={async () => {
                      await updateMenuItem(
                        updateMenuItemMutationOptions(
                          {
                            menuItem: {
                              id: menuItem!.id,
                              scale: multiplier,
                              date: menuItem!.date,
                              menuSectionId: menuItem!.menuSectionId
                            }
                          },
                          menuPlannerQueryVariables
                        )
                      );
                      notificationState.send({
                        variant: "success",
                        text: "successfully scaled recipe"
                      });
                      requestClose();
                    }}
                    style={{ width: "100%" }}
                  >
                    SET SCALE
                  </ReverseOutlineButton>
                </div>
              </>
            );
          }}
        </Formik>
      )}
    </ReactModal>
  );
};
