import React from "react";

export default ({ children }: any) => (
  <div
    style={{
      minHeight: "100%",
      width: "100%",
      display: "flex"
    }}
  >
    {children}
  </div>
);
