import styled from "styled-components";
import { space, color, textAlign, fontSize } from "styled-system";
import { themeGet } from "@styled-system/theme-get";
import { LabelProps } from "../types";
import { theme } from "../theme";

export const Label = styled("label")<LabelProps>`
  ${space}
  ${color}
  ${textAlign}
  font-family: ${themeGet("fonts.secondary")};
  font-size: 1.0rem;
  ${fontSize}
  display: flex;
  `;

export const SmallLabel = styled("label")<LabelProps>`
  ${space}
  ${color}
  ${textAlign}
  color: #444;
  font-family: ${theme.fonts.ui};
  font-size: 1.0rem;
  ${fontSize}
  display: flex;
  `;

export const BoldLabel = styled("label")<LabelProps>`
  ${space}
  ${color}
  ${textAlign}
  color: #444;
  font-family: ${theme.fonts.ui};
  font-size: 1.0rem;
  font-weight: 600;
  ${fontSize}
  display: flex;
  `;
