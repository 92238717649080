import React, { useState } from "react";
import { IngredientFormValue } from "@saffron/common";
import { Prompt } from "react-router-dom";
import { navigateAwayMessage } from "../navigateAwayMessage";
import { EditorScreenTemplate } from "../ui/EditorScreenTemplate";
import { TopRightIcon } from "../../../../../ui/TopRightIcon";
import { Formik } from "formik";
import { ingredientsToSlateValue } from "../ingredientsToValueJSON";
import { SlateIngredientsEditor } from "./SlateIngredientsEditor";
import { useLocalStorage } from "../../../../../utils/useLocalStorage";
import { PlainTextIngredientsEditor } from "./PlainTextIngredientsEditor";
import { valueToPlainText } from "./valueToPlainText";
import { plainTextToIngredients } from "./plainTextToIngredients";
import { isSlateTextEqual } from "../../../../../utils/slateSerialize";
import { valueToIngredients } from "./valueToIngredients";

interface Props {
  initialIngredients: IngredientFormValue[];
  onSubmit: (ingredients: IngredientFormValue[]) => void;
  goBack: () => void;
}

export const Ingredients: React.FC<Props> = ({
  initialIngredients,
  onSubmit,
  goBack
}) => {
  const [isPlainTextEditor, setPlainTextEditor] = useLocalStorage(
    "isPlainTextEditor",
    false
  );
  const ingredientsValue = ingredientsToSlateValue(initialIngredients);
  const [when, setWhen] = useState(true);

  return (
    <Formik
      initialValues={{
        ingredientsValue,
        plainText: valueToPlainText(ingredientsValue),
        hasTyped: false,
        showOptionalContent: false
      }}
      onSubmit={async values => {
        setWhen(false);
        if (isPlainTextEditor) {
          onSubmit(plainTextToIngredients(values.plainText));
        } else {
          onSubmit(valueToIngredients(values.ingredientsValue));
        }
        goBack();
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <EditorScreenTemplate
            onCheck={() => {
              if (isPlainTextEditor) {
                setPlainTextEditor(false);
                setFieldValue(
                  "ingredientsValue",
                  ingredientsToSlateValue(
                    plainTextToIngredients(values.plainText)
                  )
                );
              } else {
                setPlainTextEditor(true);
                setFieldValue(
                  "plainText",
                  valueToPlainText(values.ingredientsValue)
                );
              }
            }}
            noBottom
            headerText={
              isPlainTextEditor ? "Plain Text Ingredients" : "Ingredients"
            }
          >
            {/* breaks if you remove the lambda */}
            <TopRightIcon name="x" onClick={() => goBack()} />
            <Prompt
              when={
                !isSlateTextEqual(values.ingredientsValue, ingredientsValue) &&
                when &&
                !isPlainTextEditor
              }
              message={navigateAwayMessage}
            />
            {isPlainTextEditor ? (
              <PlainTextIngredientsEditor
                name="plainText"
                handleSubmit={handleSubmit}
              />
            ) : (
              <SlateIngredientsEditor handleSubmit={handleSubmit} />
            )}
          </EditorScreenTemplate>
        );
      }}
    </Formik>
  );
};
