import React, { useState } from "react";
import { Modal } from "./Modal";
import { UnitPreference } from "@saffron/common";
import { RadioButton } from "./RadioButton";
import { CenterCheckboxContainer } from "./CenterCheckboxContainer";
import { DivButton } from "./DivButton";
import { theme } from "./theme";

interface UnitPreferenceModalProps {
  title: string;
  mainButtonClick: (up: UnitPreference) => void;
  mainButtonText: string;
  onRequestClose: () => void;
  isOpen: boolean;
  textExamples?: Record<UnitPreference, string>;
  initialPreference?: UnitPreference;
}

const options: UnitPreference[] = ["imperial", "metric", "both"];
const titleExamples = { both: "Original", imperial: "First", metric: "Second" };

export const UnitPreferenceModal: React.FC<UnitPreferenceModalProps> = ({
  mainButtonClick,
  mainButtonText,
  onRequestClose,
  textExamples = {
    both: "1 cup (113g) flour",
    imperial: "1 cup flour",
    metric: "113g flour"
  },
  isOpen,
  title,
  initialPreference
}) => {
  // const [updateProfile] = useUpdateProfileMutation();
  const [unitPreference, setUnitPreference] = useState<UnitPreference>(
    initialPreference || "both"
  );
  // const [save, setSave] = useState(false);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      mainButtonClick={() => {
        // if (save) {
        //   updateProfile(
        //     updateProfileMutationOptions({
        //       input: {
        //         unitPreference
        //       }
        //     })
        //   );
        // }
        mainButtonClick(unitPreference);
      }}
      mainButtonText={mainButtonText}
      title={title}
      variant="skinny-instructional"
    >
      <div style={{ marginTop: 4 }}>
        {options.map(o => (
          <div key={o}>
            <DivButton
              onClick={() => setUnitPreference(o)}
              style={{ marginTop: 20 }}
            >
              <CenterCheckboxContainer
                style={{
                  fontSize: 16,
                  fontFamily: theme.secondaryFontStack,
                  fontWeight: 700
                }}
                checkbox={
                  <RadioButton
                    activeColor="#026284"
                    active={unitPreference === o}
                  />
                }
              >
                <div
                  style={{
                    marginLeft: 10,
                    color: "#0d0d0d"
                  }}
                >
                  {titleExamples[o]}
                </div>
              </CenterCheckboxContainer>

              <div style={{ marginLeft: 30, fontSize: 14, color: "#666666" }}>
                {textExamples[o]}
              </div>
            </DivButton>
          </div>
        ))}
      </div>
      {/* {showSaveForFuture ? (
        <DivButton
          style={{ paddingBottom: 10, paddingTop: 32 }}
          onClick={() => setSave(!save)}
        >
          <CenterCheckboxContainer
            style={{
              fontSize: 14,
              fontFamily: theme.secondaryFontStack,
              color: "#0d0d0d"
            }}
            checkbox={
              <div
                style={{ width: 18, display: "flex", justifyContent: "center" }}
              >
                <input
                  readOnly
                  type="checkbox"
                  style={{ margin: 0, cursor: "pointer" }}
                  checked={save}
                />
              </div>
            }
          >
            <div style={{ marginLeft: 10, textTransform: "none" }}>
              Save this preference for future recipes
            </div>
          </CenterCheckboxContainer>
        </DivButton>
      ) : null} */}
    </Modal>
  );
};
