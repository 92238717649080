import React from "react";
import Icon from "./Icon";

interface StarsProps {
  size?: string;
  numStars: number;
}

export const Stars: React.FC<StarsProps> = ({ numStars, size = "1em" }) => {
  return (
    <div style={{ flexDirection: "row", display: "flex" }}>
      {Array.from(Array(5), (_, i) => {
        const realI = i + 1;
        const active = numStars && numStars >= realI;
        return (
          <Icon
            key={i}
            style={{ cursor: "unset" }}
            name={"star"}
            fill={active ? "#977535" : "#C5BFA9"}
            width={size}
            height={size}
          />
        );
      })}
    </div>
  );
};
