import  React from "react";
import { FieldProps, getIn } from "formik";
import { theme } from "../../../ui/theme";
import { Select, SelectProps } from "../../../ui/Select";

export const SelectFormInput = ({
  field: { name, value },
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps<any> & SelectProps) => {
  const errorText = getIn(touched, name) ? getIn(errors, name) : "";
  return (
    <div>
      <Select
        {...props}
        value={value}
        onChange={newValue => setFieldValue(name, newValue)}
      />
      {errorText && <div style={{ color: theme.colors.red }}>{errorText}</div>}
    </div>
  );
};
