import { useApolloClient } from "@apollo/client";
import { normalizeErrors, validChangePassword } from "@saffron/common";
import {
  changePasswordMutationOptions,
  useChangePasswordMutation
} from "@saffron/controllers";
import { Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { SolidButton } from "../../../ui/Button";
import { FlexCenter } from "../../../ui/Flex";
import { FormGroup } from "../../../ui/Forms/FormGroup";
import { CardLogoLayout } from "../../../ui/Layout/CardLogoLayout";
import { clearLocalStorage } from "../../../utils/clearLocalStorage";
import { pushLocation } from "../../../utils/pushLocation";
import { FormInput } from "../../shared/formFields/FormInput";
import { getPhoneModel } from "../Login/utils/getPhoneModel";

const ChangePassword: React.FC = () => {
  const {
    params: { token }
  } = useRouteMatch<{ token: string }>();
  const client = useApolloClient();
  const [changePassword] = useChangePasswordMutation();

  useEffect(() => {
    clearLocalStorage();
    client.resetStore();
  }, [client]);

  return (
    <Formik
      validationSchema={validChangePassword}
      initialValues={{ newPassword: "" }}
      onSubmit={async ({ newPassword }, { setErrors, setSubmitting }) => {
        const response = await changePassword(
          changePasswordMutationOptions({ input: { newPassword, token } })
        );

        if (response && response.data && response.data.changePassword.errors) {
          setErrors(normalizeErrors(response.data.changePassword.errors));
          setSubmitting(false);
          return;
        }

        if (getPhoneModel()) {
          pushLocation("/login");
        } else {
          pushLocation("/browse");
        }
      }}
    >
      {({ isSubmitting }) => (
        <CardLogoLayout>
          <Form
            style={{
              height: "100%",
              width: "100%",
              padding: "0 2.6em"
            }}
          >
            <FormGroup>
              <Field
                name="newPassword"
                type="password"
                placeholder="New Password"
                component={FormInput}
                style={{ width: "100%" }}
              />
            </FormGroup>
            <FlexCenter>
              <SolidButton
                type="submit"
                disabled={isSubmitting}
                hoverColor="darkRed"
                bg="brownRed"
                color="brightWhite"
                style={{
                  marginBottom: "2em"
                }}
              >
                CHANGE PASSWORD
              </SolidButton>
            </FlexCenter>
          </Form>
        </CardLogoLayout>
      )}
    </Formik>
  );
};

export default ChangePassword;
