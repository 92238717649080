export const coverDetailsToFontColor = (coverDetails: {
  coverStyleKey?: string | null;
  c1?: string | null;
  ac?: string | null;
  fontColor?: string | null;
}) => {
  let color = coverDetails.coverStyleKey === "stripe" ? "#ebdec6" : "#c7d8d8";

  if (
    coverDetails.c1 &&
    ["#cfbfa6", "#e5a25e"].includes(coverDetails.c1) &&
    coverDetails.coverStyleKey !== "stripe"
  ) {
    color = "#333";
  }

  if (
    coverDetails.ac &&
    ["#cfbfa6", "#e5a25e"].includes(coverDetails.ac) &&
    coverDetails.coverStyleKey === "stripe"
  ) {
    color = "#333";
  }

  if (coverDetails.fontColor) {
    color = coverDetails.fontColor;
  }

  return color;
};
