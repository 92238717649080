import {
  emptyValueJSON,
  IngredientFormValue,
  ingredientToString
} from "@saffron/common";
import { Node } from "slate";

export const ingredientsToValue = (
  ingredients: IngredientFormValue[]
): Node[] =>
  ingredients.map(ig => {
    let type = "paragraph";
    let data = {};

    const text = ingredientToString(ig);

    if ("text" in ig) {
      type = "header-three";
    } else if ("recipeId" in ig) {
      type = "recipe-link";
      data = {
        recipeId: ig.recipeId,
        text,
        cache: ig
      };
    } else {
      // may need to { ...ig }
      // not sure if immutablejs will get angry
      data = {
        text,
        cache: ig
      };
    }

    return {
      children: [
        {
          text
        }
      ],
      type,
      data
    };
  });

export const ingredientsToSlateValue = (ingredients: IngredientFormValue[]) => {
  return ingredients.length ? ingredientsToValue(ingredients) : emptyValueJSON;
};
