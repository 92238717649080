import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { CardLogoLayout } from "../../../ui/Layout/CardLogoLayout";

const ForgotPasswordCheckEmail = ({
  location: { state }
}: RouteComponentProps<{}, {}, { email: string }>) => {
  return (
    <CardLogoLayout>
      <div style={{ padding: "2em 3em", paddingTop: "1em" }}>
        {state && state.email
          ? `If a Saffron account exists for ${state.email}, an email will be sent with further instructions.`
          : "If you have an account, an email will be sent with further instructions."}
      </div>
    </CardLogoLayout>
  );
};

export default ForgotPasswordCheckEmail;
