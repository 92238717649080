import { RecipePreviewFragment } from "@saffron/controllers";
import * as React from "react";
import Icon from "../../ui/Icon";
import ReactToPrint from "react-to-print";
import { PrintableRecipeClass } from "../recipe/PrintableRecipe";

interface Props {
  recipe: RecipePreviewFragment;
}

export const PdfRicon: React.FC<Props> = React.memo(({ recipe }) => {
  const printCompRef = React.useRef<any>();
  const [renderPrintComponent, setRenderPrintComponent] = React.useState(false);

  return (
    <>
      <div style={{ display: "none" }}>
        <PrintableRecipeClass
          ref={printCompRef}
          recipe={recipe}
          show={renderPrintComponent}
        />
      </div>
      <ReactToPrint
        pageStyle="@page { size: auto;  margin: 10mm; } @media print { body { -webkit-print-color-adjust: exact; } }"
        onBeforeGetContent={() => {
          setRenderPrintComponent(true);
          return Promise.resolve(0);
        }}
        trigger={() => (
          <Icon
            tooltipText="print/save as pdf"
            template="recipe"
            name="print"
            arrow
          />
        )}
        content={() => printCompRef.current}
      />
    </>
  );
});
