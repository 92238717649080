import { TimeFormValue, timeTypes } from "@saffron/common";
import { ArrayHelpers, Field } from "formik";
import React from "react";
import GhostButton from "../../../../ui/Button";
import { FormField } from "../../../../ui/Forms/FormField";
import { FormGroup } from "../../../../ui/Forms/FormGroup";
import Icon from "../../../../ui/Icon";
import { AutoSuggestFormInput } from "../../../shared/formFields/AutoSuggestFormInput";
import { FormInput } from "../../../shared/formFields/FormInput";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import { usePrevious } from "../../../../utils/usePrevious";

interface Props {
  arrayHelpers: ArrayHelpers;
  times: TimeFormValue[];
}

export const FORM_BUTTON_WIDTH = "100%";

const DragHandle = SortableHandle(() => (
  <Icon
    style={{
      alignSelf: "flex-end",
      marginBottom: 6,
      marginRight: 6,
      cursor: "move"
    }}
    name="menu"
  />
));

const SortableItem = SortableElement(({ remove, i, autoFocus }: any) => {
  const typeName = `times[${i}].type`;
  const minName = `times[${i}].min`;
  const hrName = `times[${i}].hr`;
  return (
    <FormField style={{ display: "flex" }} key={i}>
      <DragHandle />
      <Field
        name={typeName}
        placeholder="time title"
        component={AutoSuggestFormInput}
        suggestions={timeTypes}
        containerStyle={{ marginRight: ".5em" }}
        autoFocus={autoFocus}
      />
      <Field
        name={minName}
        placeholder="minutes"
        component={FormInput}
        containerStyle={{ marginRight: ".5em" }}
        fullWidth
      />
      <Field
        name={hrName}
        placeholder="hours"
        component={FormInput}
        fullWidth
      />
      <Icon
        name="x"
        style={{ alignSelf: "flex-end" }}
        onClick={() => remove(i)}
      />
    </FormField>
  );
});

const SortableList = SortableContainer(({ items, remove, autoFocus }: any) => {
  return (
    <div>
      {items.map((_: any, index: number) => (
        <SortableItem
          useDragHandle
          autoFocus={autoFocus && index === items.length - 1}
          key={index}
          index={index}
          i={index}
          remove={remove}
        />
      ))}
    </div>
  );
});

export const Times: React.FC<Props> = ({ arrayHelpers, times }) => {
  const previous = usePrevious(times.length);
  const autoFocus =
    previous !== undefined &&
    previous !== times.length &&
    !times[times.length - 1]?.type;

  return (
    <FormGroup>
      {times.length < 3 && (
        <FormField>
          <GhostButton
            style={{
              minWidth: FORM_BUTTON_WIDTH,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            type="button"
            onClick={() =>
              arrayHelpers.push({
                type: "",
                hr: "0",
                min: "0"
              })
            }
          >
            <Icon
              height="1em"
              width="1em"
              name="clock"
              template="ghostButtonSmall"
              style={{ marginRight: 6 }}
            />
            ADD COOKING TIME
          </GhostButton>
        </FormField>
      )}
      <SortableList
        useDragHandle
        autoFocus={autoFocus}
        remove={arrayHelpers.remove}
        items={times}
        onSortEnd={({ oldIndex, newIndex }: any) => {
          if (oldIndex !== newIndex) {
            arrayHelpers.move(oldIndex, newIndex);
          }
        }}
      />
    </FormGroup>
  );
};
