import {
  MenuDay,
  MenuPlannerQuery,
  MenuPlannerQueryVariables,
  MenuSectionColor
} from "@saffron/controllers";
import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";
import { Flex } from "../../../../ui/Flex";
import { theme } from "../../../../ui/theme";
import { DraggableMenuItem } from "./DraggableMenuItem";
import { MenuSectionDragSource } from "./MenuSectionDragSource";
import { TopOfDay } from "./TopOfDay";

interface Props {
  menuSections: MenuSectionColor[];
  menuDays: MenuDay[];
  onMenuItemClick: (menuItem: MenuPlannerQuery["menuPlanner"][0]) => void;
  onScaleClick: (menuItem: MenuPlannerQuery["menuPlanner"][0]) => void;
  menuPlannerQueryVariables: MenuPlannerQueryVariables;
  onOpenNewNoteModal: (dateKey: string) => void;
  todayRef: React.RefObject<HTMLDivElement>;
}

const Grid = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(7, 1fr)",
  paddingBottom: "10em",
  fontFamily: theme.fonts.ui
});

export const MonthView: React.FC<Props> = ({
  menuDays,
  menuSections,
  onMenuItemClick,
  menuPlannerQueryVariables,
  todayRef,
  onScaleClick,
  onOpenNewNoteModal
}) => {
  const todayDate = dayjs();
  return (
    <Grid>
      {menuDays.map((md, i) => {
        const gridColumn = (i % 7) + 1;
        const gridRow = Math.floor(i / 7) * menuSections.length + 1;
        const activeDay = todayDate.isSame(md.dt, "day");
        return (
          <React.Fragment key={md.dtKey}>
            <Flex
              border="1px solid #DDE2E3;"
              p="5px 10px"
              fontFamily="ui"
              style={{
                backgroundColor: md.isCurrentMonth ? "#fff" : "#fafafa",
                minHeight: 370,
                gridColumn,
                gridRow: `row ${gridRow} / span ${menuSections.length}`,
                borderTop: "none",
                borderLeft: i % 7 === 0 ? undefined : "none"
              }}
              id={activeDay ? "today" : undefined}
            >
              <TopOfDay
                md={md}
                todayRef={todayRef}
                activeDay={activeDay}
                onOpenNewNoteModal={onOpenNewNoteModal}
              />
            </Flex>
            {menuSections.map((ms, k) => {
              const menuItemsOrNotes = md.menuItemOrNoteSectionMap[ms.id] || [];
              const lastSection = k + 1 === menuSections.length;
              return (
                <MenuSectionDragSource
                  key={`${md.dtKey}-${ms.id}`}
                  k={k}
                  gridColumn={gridColumn}
                  gridRow={gridRow}
                  lastSection={lastSection}
                  menuItems={menuItemsOrNotes}
                  ms={ms}
                  md={md}
                  menuPlannerQueryVariables={menuPlannerQueryVariables}
                  month
                >
                  <strong
                    style={{
                      color: ms.color,
                      fontSize: "0.75rem"
                    }}
                  >
                    {ms.name}
                  </strong>
                  {menuItemsOrNotes.map(mi => (
                    <DraggableMenuItem
                      key={mi.id}
                      menuItemOrNote={mi}
                      onClick={() => onMenuItemClick(mi)}
                      onScaleClick={onScaleClick}
                    />
                  ))}
                </MenuSectionDragSource>
              );
            })}
          </React.Fragment>
        );
      })}
    </Grid>
  );
};
