import { ADMIN_STATUS } from "@saffron/common";
import { useMeQuery } from "@saffron/controllers";
import React from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps
} from "react-router-dom";

export const AdminRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  const { data, loading } = useMeQuery();
  return (
    <Route
      {...rest}
      render={(rProps: RouteComponentProps<any>) => {
        if (!data || loading) {
          return null;
        }

        const { me } = data;

        if (!me || !me.user) {
          return (
            <Redirect
              to={{
                pathname: "/login/na",
                state: { next: rProps.location }
              }}
            />
          );
        }

        if (me.user.role.type !== ADMIN_STATUS) {
          return <Redirect to="/not-authorized" />;
        }

        const RouteC = component as any;

        return <RouteC {...rProps} />;
      }}
    />
  );
};
