import React, { useState } from "react";
import { Subscribe } from "unstated";
import { ScaleIgContainer } from "../../modules/ricons/ScaleIgContainer";
import { IngredientSection, IngredientHeader } from "./components";
import { scaleIngredient, ingredientToParts, slugify } from "@saffron/common";
import { RecipePreviewFragment } from "@saffron/controllers";
import { PrettyMyLink } from "../PrettyLink";
import { ScalingBadge } from "./ScalingBadge";
import produce from "immer";

interface CrossableIngredientsProps {
  recipeId?: string | null;
  ingredients: RecipePreviewFragment["ingredients"];
  disableRecipeLinks?: boolean;
  viewOnly?: boolean;
}

export const CrossableIngredients: React.FC<CrossableIngredientsProps> = ({
  recipeId: id,
  ingredients,
  disableRecipeLinks,
  viewOnly
}) => {
  const [completeMap, setCompleteMap] = useState<Record<string, boolean>>({});
  return (
    <div>
      <Subscribe to={[ScaleIgContainer]}>
        {({ state: { amountMap }, setOpen }: ScaleIgContainer) => {
          const multiplier = id ? amountMap[id] : null;
          const currentlyScaling = id && multiplier;
          return (
            <IngredientSection
              style={{ marginTop: currentlyScaling ? 43 : undefined }}
            >
              {ingredients.map((ig, i) => {
                const isComplete = completeMap[i];

                if (ig.__typename === "IngredientHeader") {
                  return (
                    <IngredientHeader
                      style={{
                        textDecoration: isComplete ? "line-through" : undefined,
                        color: isComplete ? "#999" : undefined,
                        cursor: viewOnly ? "default" : "pointer"
                      }}
                      key={i}
                      onClick={
                        viewOnly
                          ? undefined
                          : () => {
                              setCompleteMap(
                                produce(completeMap, x => {
                                  const v = !completeMap[i];
                                  x[i] = v;
                                  for (
                                    let k = i + 1;
                                    k < ingredients.length;
                                    k++
                                  ) {
                                    if (
                                      ingredients[k].__typename ===
                                      "IngredientHeader"
                                    ) {
                                      break;
                                    }
                                    x[k] = v;
                                  }
                                })
                              );
                            }
                      }
                    >
                      {ig.text}
                    </IngredientHeader>
                  );
                }
                if (currentlyScaling) {
                  ig = scaleIngredient(ig, multiplier!) as typeof ig;
                }

                const { amountAndUnit, rest } = ingredientToParts(ig);

                const inside = (
                  <div
                    style={{
                      wordBreak: "break-word",
                      textDecoration: isComplete ? "line-through" : undefined,
                      color: isComplete ? "#999" : undefined,
                      cursor: viewOnly ? "default" : "pointer"
                    }}
                    onClick={
                      viewOnly
                        ? undefined
                        : () => {
                            setCompleteMap({
                              ...completeMap,
                              [i]: !completeMap[i]
                            });
                          }
                    }
                  >
                    <span
                      style={{
                        color: isComplete ? "#999" : "#333",
                        fontWeight: isComplete ? undefined : 700
                      }}
                    >
                      {amountAndUnit}
                    </span>
                    {rest}
                  </div>
                );

                return (
                  <React.Fragment key={i}>
                    {ig.__typename === "RecipeLink" && !disableRecipeLinks ? (
                      <PrettyMyLink
                        to={`/recipe/${ig.recipeId}/${slugify(ig.name)}`}
                      >
                        {inside}
                      </PrettyMyLink>
                    ) : (
                      inside
                    )}
                  </React.Fragment>
                );
              })}
              {!viewOnly && currentlyScaling ? (
                <ScalingBadge
                  onClick={() => setOpen(true)}
                  amount={multiplier!}
                />
              ) : null}
            </IngredientSection>
          );
        }}
      </Subscribe>
    </div>
  );
};
