import * as React from "react";
import { Pattern } from "../Pattern";
import styled from "styled-components";
import { patternWidth } from "../theme";

interface Props {
  pattern?: JSX.Element;
}

const Wrapper = styled.div({
  width: "100%",
  marginLeft: patternWidth,
  marginRight: patternWidth,
  "@media(max-width: 1200px)": {
    marginRight: 0
  }
});

export const SinglePaneLayout: React.FC<Props> = ({
  children,
  pattern = <Pattern />
}) => {
  return (
    <>
      <Wrapper>{children}</Wrapper>
      {pattern}
    </>
  );
};
