import { Formik } from "formik";
import React, { useMemo } from "react";
import { Prompt } from "react-router-dom";
import { TopRightIcon } from "../../../../../ui/TopRightIcon";
import { useLocalStorage } from "../../../../../utils/useLocalStorage";
import { navigateAwayMessage } from "../navigateAwayMessage";
import { EditorScreenTemplate } from "../ui/EditorScreenTemplate";
import {
  instructionsValueToPlainText,
  plainTextToInstructionsValueJSON
} from "./instructionConversions";
import { SlateInstructionsEditor } from "./SlateInstructionsEditor";
import { PlainTextInstructionsEditor } from "./PlainTextInstructionsEditor";
import { isSlateTextEqual } from "../../../../../utils/slateSerialize";
import { Node } from "slate";
import { emptyValueJSON, createEmptyValueJSON } from "@saffron/common";

interface Props {
  initialInstructions: Node[];
  leftExtraInstructions?: string[] | null;
  rightExtraInstructions?: string[] | null;
  onSubmit: (instructions: Node[]) => void;
  goBack: () => void;
}

export const Instructions: React.FC<Props> = ({
  initialInstructions,
  onSubmit,
  goBack
}) => {
  const [isPlainTextEditor, setPlainTextEditor] = useLocalStorage(
    "isPlainTextEditor",
    false
  );

  return (
    <Formik
      initialValues={{
        instructions: useMemo<typeof initialInstructions>(
          () =>
            initialInstructions.length === 0
              ? createEmptyValueJSON()
              : initialInstructions,
          [initialInstructions]
        ),
        plainText: instructionsValueToPlainText(initialInstructions)
      }}
      onSubmit={values => {
        if (isPlainTextEditor) {
          onSubmit(plainTextToInstructionsValueJSON(values.plainText));
        } else {
          const cleanedInstructions = values.instructions.filter(x =>
            Node.string(x)
          );
          onSubmit(
            cleanedInstructions.length ? cleanedInstructions : emptyValueJSON
          );
        }
        goBack();
      }}
    >
      {({ handleSubmit, values, setFieldValue, isSubmitting }) => {
        return (
          <EditorScreenTemplate
            noBottom
            onCheck={() => {
              if (isPlainTextEditor) {
                setPlainTextEditor(false);
                setFieldValue(
                  "instructions",
                  plainTextToInstructionsValueJSON(values.plainText)
                );
              } else {
                setPlainTextEditor(true);
                setFieldValue(
                  "plainText",
                  instructionsValueToPlainText(values.instructions)
                );
              }
            }}
            headerText={
              isPlainTextEditor ? "Plain Text Instructions" : "Instructions"
            }
          >
            <TopRightIcon name="x" onClick={() => goBack()} />
            <Prompt
              when={
                !isSlateTextEqual(values.instructions, initialInstructions) &&
                !isPlainTextEditor &&
                !isSubmitting
              }
              message={navigateAwayMessage}
            />
            {isPlainTextEditor ? (
              <PlainTextInstructionsEditor
                name="plainText"
                handleSubmit={handleSubmit}
              />
            ) : (
              <SlateInstructionsEditor handleSubmit={handleSubmit} />
            )}
          </EditorScreenTemplate>
        );
      }}
    </Formik>
  );
};
