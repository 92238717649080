import { RecipeFormValues, TimeFormValue } from "@saffron/common";
import { RecipePreviewFragment } from "@saffron/controllers";

const formatTime = ({ type, hr = "0", min = "0" }: TimeFormValue) => ({
  type,
  value: Math.floor((parseFloat(hr) || 0) * 60 + (parseFloat(min) || 0))
});

export const formatValues = ({
  croppedBase64Img,
  times = [],
  ingredients = [],
  instructions,
  ...other
}: RecipeFormValues) => ({
  ...other,
  ingredients: ingredients.map(x => {
    if ("text" in x) {
      return {
        __typename: "IngredientHeader",
        ...x
      };
    } else if ("recipeId" in x) {
      return {
        __typename: "RecipeLink",
        ...x
      };
    } else {
      return {
        __typename: "RegularIngredient",
        ...x
      };
    }
  }) as RecipePreviewFragment["ingredients"],
  pictureUrl: croppedBase64Img || other.pictureUrl,
  times: times.map(formatTime),
  rawInstructions: instructions
});
