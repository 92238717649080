import React from "react";
import { ViewRecipeSearchQuery } from "@saffron/controllers";
import { Link } from "react-router-dom";
import { theme } from "./theme";
import styled from "styled-components";

interface Props {
  recipeId: string;
  cookbookLookup: NonNullable<
    ViewRecipeSearchQuery["recipeSearch"]["recipes"][0]["cookbookLookup"]
  >;
}

const Container = styled.div({
  backgroundColor: "#e9e7df",
  color: "#4a4236",
  fontFamily: theme.fonts.ui,
  ":hover": {
    backgroundColor: "#DFDBD3"
  }
});

export const CookbookBreadcrumbs: React.FC<Props> = ({
  cookbookLookup,
  recipeId
}) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {cookbookLookup.map(
        ({ cookbookId, sectionId, cookbookName, sectionName }) => {
          return (
            <Link
              key={cookbookId + sectionId}
              style={{
                marginRight: 16
              }}
              to={`/browse/${cookbookId}/${sectionId}/${recipeId}`}
            >
              <Container
                style={{
                  fontSize: "0.75rem",
                  lineHeight: "0.9375rem",
                  marginBottom: 8,
                  padding: "4px 8px"
                }}
              >
                <span>{cookbookName}</span>
                <span style={{ color: "#7a766f" }}> / {sectionName}</span>
              </Container>
            </Link>
          );
        }
      )}
    </div>
  );
};
