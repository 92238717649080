import React, { useState, useRef, useEffect } from "react";
import {
  JustCookbookFragment,
  useUpdateCookbookMutation
} from "@saffron/controllers";
import { CenterLayout } from "../../../ui/Layout/CenterLayout";
import {
  SmallCookbookCover,
  COOKBOOK_COVER_WIDTH,
  COOKBOOK_COVER_HEIGHT
} from "../../../ui/SmallCookbookCover";
import Dropzone from "react-dropzone";
import { notificationState } from "../../misc/notifications/NotificationState";
import GhostButton, { ReverseOutlineButton } from "../../../ui/Button";
import Cropper from "cropperjs";
import { Select } from "../../../ui/Select";
import { LabelText, Input } from "../../../ui/Input";
import { theme } from "../../../ui/theme";
import { FormField } from "../../../ui/Forms/FormField";
import { FlexCenter } from "../../../ui/Flex";
import { Spinner } from "../../../ui/Spinner";
import { COOKBOOK_NAME_MAX_LENGTH } from "@saffron/common";
import { useHistory } from "react-router";
import { DivButton } from "../../../ui/DivButton";
import styled from "styled-components";
import { Checkbox } from "../../../ui/Checkbox";
import Icon from "../../../ui/Icon";
import { FormGroup } from "../../../ui/Forms/FormGroup";
import { coverDetailsToFontColor } from "../../../utils/coverDetailsToFontColor";

interface Props {
  cookbook: JustCookbookFragment;
}

export const GrayButton = styled(DivButton)({
  fontSize: "1.125rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#E6E6E6",
  width: 36,
  height: 36,
  borderRadius: "50%"
});

export const CookbookCoverForm: React.FC<Props> = ({ cookbook }) => {
  const { __typename, ...otherDetails } = cookbook.coverDetails;
  const [name, setName] = useState(cookbook.name);
  const [coverDetails, setCoverDetails] = useState(otherDetails);
  const cropper = useRef<Cropper>();
  const [imagePreview, setImagePreview] = useState("");
  const [updateCookbook] = useUpdateCookbookMutation();
  const [loading, setLoading] = useState(false);
  const img = useRef<HTMLImageElement>(null);
  const history = useHistory();

  useEffect(() => {
    if (cropper.current) {
      cropper.current
        .reset()
        .clear()
        .replace(imagePreview);
    }
    return () => {
      if (imagePreview) {
        window.URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  useEffect(() => {
    cropper.current = new Cropper(img.current!, {
      dragMode: "move",
      preview: ".preview",
      minCropBoxWidth: COOKBOOK_COVER_WIDTH,
      minCropBoxHeight: COOKBOOK_COVER_HEIGHT,
      aspectRatio: COOKBOOK_COVER_WIDTH / COOKBOOK_COVER_HEIGHT,
      cropBoxResizable: false,
      guides: false
    });

    return () => {
      if (cropper.current) {
        cropper.current.destroy();
      }
    };
  }, []);

  // const borderWidth = coverDetails.coverStyleKey === "outline" ? 8 : 0;
  const minCropBoxWidth = COOKBOOK_COVER_WIDTH;
  const minCropBoxHeight = COOKBOOK_COVER_HEIGHT;

  if (loading) {
    return (
      <CenterLayout
        patternImage="gold"
        backgroundImage="creme"
        width={900}
        innerPadding="3em"
        style={{ maxHeight: "100%" }}
      >
        <FlexCenter>
          <Spinner />
        </FlexCenter>
      </CenterLayout>
    );
  }

  return (
    <CenterLayout
      patternImage="gold"
      backgroundImage="creme"
      width={900}
      innerPadding="3em"
      style={{ maxHeight: "100%" }}
    >
      <div
        style={{
          fontFamily: theme.primaryFontStack,
          fontSize: "1.5625rem",
          marginBottom: 20
        }}
      >
        Cover Design
      </div>
      <FormField>
        <Dropzone
          onDrop={([file]) => {
            if (!file) {
              return;
            }

            // 5mb
            if (file.size > 5000000) {
              notificationState.send({
                text: "Image cannot be larger than 5 MB",
                variant: "error"
              });
            } else {
              setImagePreview(URL.createObjectURL(file));
            }
          }}
          multiple={false}
          accept="image/*"
        >
          {({ getRootProps, getInputProps }) => (
            <div {...(getRootProps() as any)}>
              <input {...(getInputProps() as any)} />
              <GhostButton type="button">upload image</GhostButton>
            </div>
          )}
        </Dropzone>
      </FormField>
      <div style={imagePreview ? { height: 400, width: "100%" } : {}}>
        <img
          ref={img}
          src={imagePreview}
          alt="crop preview"
          style={{ opacity: 0 }}
        />
      </div>
      {imagePreview ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 10,
            marginBottom: 10
          }}
        >
          <div style={{ display: "flex", marginRight: 23 }}>
            <GrayButton
              style={{ marginRight: 10 }}
              onClick={() => {
                if (cropper.current) {
                  cropper.current.zoom(0.1);
                }
              }}
            >
              <Icon
                fill="#666666"
                width="1em"
                height="1em"
                name="magnifierPlus"
              />
            </GrayButton>
            <GrayButton
              onClick={() => {
                if (cropper.current) {
                  cropper.current.zoom(-0.1);
                }
              }}
            >
              <Icon
                fill="#666666"
                width="1em"
                height="1em"
                name="magnifierMinus"
              />
            </GrayButton>
          </div>
          <div style={{ display: "flex", marginLeft: 23 }}>
            <GrayButton
              style={{ marginRight: 10 }}
              onClick={() => {
                if (cropper.current) {
                  cropper.current.rotate(-5);
                }
              }}
            >
              <Icon
                style={{ transform: "scaleX(-1)" }}
                fill="#666666"
                width="1em"
                height="1em"
                name="rotate"
              />
            </GrayButton>
            <GrayButton
              onClick={() => {
                if (cropper.current) {
                  cropper.current.rotate(5);
                }
              }}
            >
              <Icon fill="#666666" width="1em" height="1em" name="rotate" />
            </GrayButton>
          </div>
        </div>
      ) : null}
      <form
        onSubmit={async e => {
          e.preventDefault();
          if (!name) {
            notificationState.send({
              text: "title cannot be empty",
              variant: "error"
            });
            return;
          }

          setLoading(true);

          let picture = null;

          if (imagePreview && cropper.current) {
            picture = await new Promise(res =>
              cropper
                .current!.getCroppedCanvas(
                  cropper.current!.getData().rotate
                    ? {
                        height: COOKBOOK_COVER_HEIGHT * 10,
                        width: COOKBOOK_COVER_WIDTH * 10
                      }
                    : {
                        maxHeight: COOKBOOK_COVER_HEIGHT * 10,
                        maxWidth: COOKBOOK_COVER_WIDTH * 10
                      }
                )
                .toBlob(blob => res(blob))
            );
          }

          await updateCookbook({
            variables: {
              id: cookbook.id,
              cookbook: {
                name,
                coverDetails,
                picture
              }
            }
          });

          history.goBack();
        }}
      >
        <div style={{ display: "flex" }}>
          <div>
            <FormField>
              <Input
                placeholder="title"
                value={name}
                minLength={1}
                maxLength={COOKBOOK_NAME_MAX_LENGTH}
                onChange={e => setName(e.target.value)}
              />
            </FormField>
            <FormField>
              <Checkbox
                label="bottom title"
                value={!!coverDetails.bottomText}
                onChange={() =>
                  setCoverDetails({
                    ...coverDetails,
                    bottomText: !coverDetails.bottomText
                  })
                }
              />
            </FormField>
            <div
              style={{
                border: "1px solid #C5BFA9",
                borderRadius: "3px",
                padding: "10px 15px"
                // height: 300
              }}
            >
              <div
                style={{
                  fontFamily: theme.fonts.ui,
                  fontWeight: 600,
                  fontSize: "1.125rem",
                  lineHeight: "1.125rem",
                  color: "#444444",
                  marginBottom: 18
                }}
              >
                Design Options
              </div>
              <FormGroup style={{ display: "flex" }}>
                <Select
                  variant="button-like"
                  value={coverDetails.coverStyleKey}
                  onChange={value =>
                    setCoverDetails({ ...coverDetails, coverStyleKey: value })
                  }
                  options={[
                    { key: "outline", text: "Outline", value: "outline" },
                    { key: "stripe", text: "Stripe", value: "stripe" },
                    { key: "line", text: "Line", value: "line" }
                  ]}
                />
              </FormGroup>
              {cookbook.pictureUrl || imagePreview ? (
                <FormField>
                  <div style={{ display: "flex" }}>
                    <input
                      type="color"
                      value={coverDetails.bgColor || "#ffffff"}
                      onChange={e => {
                        setCoverDetails({
                          ...coverDetails,
                          bgColor: e.target.value
                        });
                      }}
                    />
                    <LabelText style={{ marginLeft: 10 }}>
                      Background Color
                    </LabelText>
                  </div>
                </FormField>
              ) : null}
              <FormField>
                <div style={{ display: "flex" }}>
                  <input
                    type="color"
                    value={coverDetailsToFontColor(coverDetails)}
                    onChange={e => {
                      setCoverDetails({
                        ...coverDetails,
                        fontColor: e.target.value
                      });
                    }}
                  />
                  <LabelText style={{ marginLeft: 10 }}>Font Color</LabelText>
                </div>
              </FormField>
              <div style={{ display: "flex" }}>
                <input
                  type="color"
                  value={coverDetails.ac}
                  onChange={e => {
                    setCoverDetails({
                      ...coverDetails,
                      ac: e.target.value
                    });
                  }}
                />
                <div style={{ marginLeft: 10 }}>
                  <LabelText>Accent Color</LabelText>
                  <input
                    style={{ marginTop: 5 }}
                    type="range"
                    min="1"
                    max="100"
                    value={coverDetails.acOpacity || "100"}
                    onChange={e =>
                      setCoverDetails({
                        ...coverDetails,
                        acOpacity: e.target.value
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: 100,
              marginLeft: 60,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <div
              style={{
                width: minCropBoxWidth,
                height: minCropBoxHeight,
                marginBottom: 32
              }}
            >
              <SmallCookbookCover
                cookbook={{
                  ...cookbook,
                  name,
                  coverDetails: {
                    __typename: "CoverDetails",
                    ...coverDetails
                  }
                }}
              >
                {imagePreview ? (
                  <div
                    style={{
                      width: minCropBoxWidth,
                      height: minCropBoxHeight,
                      overflow: "hidden"
                    }}
                    className="preview"
                  />
                ) : null}
              </SmallCookbookCover>
            </div>
            <ReverseOutlineButton type="submit">SAVE</ReverseOutlineButton>
          </div>
        </div>
      </form>
    </CenterLayout>
  );
};
