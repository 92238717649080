import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { Subscribe } from "unstated";
import Icon from "../../../ui/Icon";
import { theme } from "../../../ui/theme";
import { Notif, NotificationState } from "./NotificationState";
type NBProps = {
  backgroundColor: string;
  textColor: string;
  idx: number;
};
const NotifBox = styled("div")<NBProps>`
  position: fixed;
  background-color: ${p => p.backgroundColor};
  color: ${p => p.textColor};
  cursor: pointer;
  bottom: ${p => 2 + p.idx * 6}rem;
  right: 0;
  padding: 0.125rem;
  margin-right: 1rem;
  border-radius: 5px;
  font-family: ${theme.uiFontStack};
  font-size: 1.0rem;
  font-weight: 400;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 1em;
  padding-bottom: 1em;
  box-shadow: 3px 6px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  line-height: 1.2;
  z-index: 99999;
`;

interface Props {
  onDismiss: () => void;
  idx: number;
  n: Notif;
}

const root = document.getElementById("body");

class Notification extends React.Component<Props> {
  timeNum?: number;

  el = document.createElement("div");

  componentDidMount() {
    this.timeNum = setTimeout(() => this.props.onDismiss(), 3400);
    root!.appendChild(this.el);
  }

  componentWillUnmount() {
    if (this.timeNum) {
      clearTimeout(this.timeNum);
    }
    root!.removeChild(this.el);
  }

  render() {
    const {
      n: { backgroundColor, icon, text, textColor },
      idx,
      onDismiss
    } = this.props;

    return ReactDOM.createPortal(
      <NotifBox
        backgroundColor={backgroundColor || theme.colors.brightRed}
        textColor={textColor || "#333"}
        idx={idx}
        onClick={onDismiss}
      >
        {icon ? (
          <Icon style={{ marginRight: ".5em" }} fill="#fff" name={icon} />
        ) : null}
        <div style={{ flex: "1" }}>{text}</div>
      </NotifBox>,
      this.el
    );
  }
}

const to = [NotificationState];

export const NotificationManager = () => (
  <Subscribe to={to}>
    {({ state: { notifications }, close }: NotificationState) =>
      notifications.map((n, i) => (
        <Notification key={n.id} onDismiss={() => close(n.id)} n={n} idx={i} />
      ))
    }
  </Subscribe>
);
