import {
  statusToNextPlan,
  statusToNextPlanInfo,
  statusToPlanId
} from "@saffron/common";
import { MeQuery, meQuery, MeUserFragment } from "@saffron/controllers";
import { apolloClient } from "../apollo";
import { notificationState } from "../modules/misc/notifications/NotificationState";

export const openPaddleModal = (user: MeUserFragment, yearly = true) => {
  const nextPlan =
    statusToNextPlanInfo[user.role.type as keyof typeof statusToNextPlan];
  // eslint-disable-next-line no-undef
  Paddle.Checkout.open({
    product: statusToPlanId(nextPlan.key, yearly),
    email: user.email,
    passthrough: JSON.stringify({ userId: user.id }),
    disableLogout: true,
    successCallback: () => {
      notificationState.send({
        variant: "success",
        text: "upgrade successful"
      });
      apolloClient.writeQuery<MeQuery>({
        query: meQuery,
        data: {
          __typename: "Query",
          me: {
            __typename: "UserResponse",
            user: {
              ...user,
              paddleCancelUrl: null,
              paddleUpdateUrl: null,
              role: {
                __typename: "UserRole",
                endDate: null,
                recipeLimit: nextPlan.recipeLimit,
                type: nextPlan.key
              }
            }
          }
        }
      });
    }
  });
};
