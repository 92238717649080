import * as React from "react";
import { ReverseOutlineButton } from "../../../ui/Button";
import styled from "styled-components";
import { patternWidth, theme, HEADER_HEIGHT } from "../../../ui/theme";
import { useAddRecipeToAccountMutation } from "@saffron/controllers";
import { notificationState } from "../../misc/notifications/NotificationState";
import { pushLocation } from "../../../utils/pushLocation";
import { PotLogoShrinker } from "../../../ui/Logo";

interface Props {
  recipeId?: string;
  sample?: boolean;
}

const Container = styled.div`
  padding-left: ${patternWidth};
  padding-right: ${patternWidth};
  width: 100%;
  @media (max-width: 600px) {
    padding-left: 0px;
  }
  @media (max-width: 1200px) {
    padding-right: 0px;
  }
`;

const InnerContainer = styled.div`
  @media (max-width: 1200px) {
    padding-right: ${patternWidth};
  }
  @media (max-width: 600px) {
    padding-right: 0;
  }
`;

const SharedRecipeText = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

export const SharedRecipeHeader: React.FC<Props> = ({ recipeId, sample }) => {
  const [addRecipeToAccount, { loading }] = useAddRecipeToAccountMutation();
  return (
    <Container>
      <InnerContainer
        style={{
          backgroundColor: "#fff",
          width: "100%",
          height: HEADER_HEIGHT,
          borderBottom: "1px solid #E6E6E6"
        }}
      >
        <div
          style={{
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
            width: "100%",
            paddingLeft: 25,
            paddingRight: 25,
            maxWidth: 850
          }}
        >
          <a style={{ display: "flex" }} href="/">
            <PotLogoShrinker />
          </a>
          {!recipeId ? (
            <a href="/">
              <ReverseOutlineButton>LEARN MORE</ReverseOutlineButton>
            </a>
          ) : (
            <div style={{ display: "flex" }}>
              <SharedRecipeText
                style={{
                  fontSize: "1.25rem",
                  fontStyle: "italic",
                  fontFamily: theme.fonts.primary,
                  marginRight: 20,
                  alignItems: "center"
                }}
              >
                {sample ? "Sample Recipe" : "Shared Recipe"}
              </SharedRecipeText>
              <ReverseOutlineButton
                onClick={async () => {
                  if (sample) {
                    pushLocation("/new-recipe");
                    return;
                  }

                  const response = await addRecipeToAccount({
                    variables: {
                      recipeId
                    }
                  });

                  if (response && !response.errors) {
                    notificationState.send({
                      text: "successfully added recipe",
                      variant: "success"
                    });
                  }
                }}
                disabled={loading}
              >
                {sample ? "READY TO ADD YOUR OWN RECIPE?" : "ADD TO ACCOUNT"}
              </ReverseOutlineButton>
            </div>
          )}
        </div>
      </InnerContainer>
    </Container>
  );
};
