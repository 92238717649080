import React from "react";
import { theme } from "../../../ui/theme";
import { DivButton } from "../../../ui/DivButton";
import Icon from "../../../ui/Icon";

interface GroceryCategoryHeaderProps {
  title: string;
  open: boolean;
  onCategoryClick: (id: string) => void;
}

export const GroceryCategoryHeader: React.FC<GroceryCategoryHeaderProps> = React.memo(
  ({ title, open, onCategoryClick }) => {
    return (
      <div
        style={{
          paddingLeft: 30,
          paddingRight: 14,
          backgroundColor: "#4D5862",
          fontFamily: theme.fonts.ui,
          color: open ? "#fff" : "#CDD3D8",
          fontSize: "1.125rem",
          display: "flex",
          width: "100%",
          borderBottom: "solid 1px #333b41"
        }}
      >
        <div
          style={{
            flex: 1,
            padding: "8px 0px",
            textTransform: "uppercase",
            letterSpacing: ".02em",
            display: "flex",
            alignItems: "center"
          }}
        >
          {title}
        </div>
        <DivButton
          style={{
            padding: "8px 16px"
          }}
          onClick={() => onCategoryClick(title)}
        >
          <Icon
            template="gl"
            fill="#DADADA"
            name={open ? "upArrow" : "downArrow"}
          />
        </DivButton>
      </div>
    );
  }
);
