import React from "react";
import { Link, LinkProps } from "react-router-dom";

interface Props extends LinkProps {
  backgroundColor?: string;
  to: string;
}

// export const handleLinkClick = (evt: any, onClick: any, to: any) => {
//   if (onClick) {
//     onClick(evt);
//   }
//   if (evt.metaKey || evt.ctrlKey) {
//     return;
//   }
//   if ((window as any).swUpdate === true) {
//     evt.preventDefault();
//     return ((window as any).location = makeLocation(to, history));
//   }
//   return;
// };

export const MyLink: React.FC<Props> = ({ to, ...props }) => {
  return (
    <Link
      {...props}
      to={to}
      // onClick={evt => handleLinkClick(evt, onClick, to)}
    />
  );
};
