import styled from "styled-components";

import { PossibleBackgroundImages } from "../types";
import { media } from "../mediaTemplates";
import { theme, litePatternOpacity, patternWidth } from "../theme";
type PProps = {
  backgroundImage?: PossibleBackgroundImages;
  lite?: boolean;
};
export const Pattern = styled("div")<PProps>`
  display: none;
  right: 0px;
  top: 0px;
  opacity: ${p => (p.lite ? litePatternOpacity : 1)};
  width: ${patternWidth};
  background-image: ${p =>
    theme.backgroundImages[p.backgroundImage || "white"]};
  height: 100%;
  position: fixed;
  ${media.patternDies`
                display: inline;
              `};
`;
