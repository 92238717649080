import { useLogoutMutation } from "@saffron/controllers";
import React, { useEffect, useState } from "react";
import { clearLocalStorage } from "../../utils/clearLocalStorage";
import { Lifecycle } from "../shared/Lifecycle";

const Logout: React.FC<{ loading: boolean }> = () => {
  const [logout] = useLogoutMutation();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fn = async () => {
      await logout();
      // not needed because we redirect to gatsby site
      // await client.resetStore();
      clearLocalStorage();
      setLoading(false);
    };
    fn();
  }, [logout]);

  if (loading) {
    return null;
  }

  return (
    <div>
      <a href="/">click here if you're not redirected</a>
      <Lifecycle
        onComponentDidMount={() => {
          // @ts-ignore
          window.location = "/";
        }}
      />
    </div>
  );
};

export default Logout;
