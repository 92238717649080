import {
  emptyValueJSON,
  importRecipeMissingInstructionsOrIngredientsMessage,
  IngredientFormValue,
  oldSlateToNew,
  RecipeFormValues,
  validRecipe
} from "@saffron/common";
import { initialRecipeFormValues } from "@saffron/controllers";
import { Field, FieldArray, Formik, FormikErrors } from "formik";
import React from "react";
import { Prompt, Route, RouteComponentProps, Switch } from "react-router-dom";
import { Node } from "slate";
import { Box } from "../../../../ui/Box";
import GhostButton, { BoldButton, OutlineButton } from "../../../../ui/Button";
import { ErrorText } from "../../../../ui/ErrorText";
import { FormField } from "../../../../ui/Forms/FormField";
import { FormGroup } from "../../../../ui/Forms/FormGroup";
import { BoldLabel } from "../../../../ui/Label";
import { LoadingLayout } from "../../../../ui/Layout/LoadingLayout";
import { MainContent } from "../../../../ui/Layout/MainContent";
import { SideBarLayout } from "../../../../ui/Layout/SidebarLayout";
import Recipe from "../../../../ui/Recipe";
import { Sidebar } from "../../../../ui/Sidebar";
import { theme } from "../../../../ui/theme";
import { notificationState } from "../../../misc/notifications/NotificationState";
import { DropzoneFormInput } from "../../../shared/formFields/DropzoneFormInput";
import { FormInput } from "../../../shared/formFields/FormInput";
import { formatValues } from "./FormatValues";
import { ImgPicker } from "./ImgPicker";
import { ImportRecipeButton } from "./ImportRecipeButton";
import { Ingredients } from "./Ingredients";
import { Instructions } from "./Instructions";
import { navigateAwayMessage } from "./navigateAwayMessage";
import { FORM_BUTTON_WIDTH, Times } from "./Times";
import { RecipeHeader, RecipeHeaderContainer } from "./ui/EditorComponents";
import { TextModalFormField } from "../../../shared/formFields/TextModalFormField";
import Icon from "../../../../ui/Icon";
import styled from "styled-components";
import { media } from "../../../../ui/mediaTemplates";

interface Props {
  initialValues: Partial<RecipeFormValues<Node[]>>;
  submit: (
    data: RecipeFormValues
  ) => Promise<FormikErrors<RecipeFormValues<Node[]>> | null>;
  showCookbookSelect?: boolean;
  showImportRecipeButton?: boolean;
  headerText: string;
  loading: boolean;
  webImportOpen?: boolean;
  textImportOpen?: boolean;
}

const newRecipePath = "/new-recipe/cookbook/:cookbookId/:sectionId";
const editRecipePath = "/recipe/edit/:id/:slug";
const emptyValueJSONString = JSON.stringify(emptyValueJSON);

const MediaButtonContainer = styled("div")`
  > button:first-child {
    margin-bottom: 20px;
  }

  ${media.oneColSteps`
  flex-direction: row;
  flex: 1;
  display: flex;
  justify-content: space-between;
  > button:nth-child(2) {
    flex: 1;
    margin-left: 4px;
  }
  > button:first-child {
    flex: 1;
    margin-right: 4px;
    margin-bottom: 0px;
  }
  `}
`;

export const RecipeForm: React.FC<Props> = ({
  headerText,
  showImportRecipeButton,
  loading,
  submit,
  initialValues,
  webImportOpen,
  textImportOpen
}) => {
  return (
    <Formik<RecipeFormValues<Node[]>>
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize={false}
      validationSchema={validRecipe}
      initialValues={{
        ...initialRecipeFormValues,
        ...initialValues
      }}
      onSubmit={async (data, { setErrors, setSubmitting }) => {
        const errors = await submit(data);
        if (errors) {
          setErrors(errors);
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        isSubmitting,
        dirty,
        submitCount,
        setValues,
        setFieldValue
      }) => {
        if (loading) {
          return <LoadingLayout />;
        }

        const hasEnteredSomeIngredients = !!values.ingredients.length;
        let hasEnteredSomeInstructions = true;

        if (
          values.instructions.length === 0 ||
          (values.instructions.length === 1 &&
            Node.string(values.instructions[0]) === "")
        ) {
          hasEnteredSomeInstructions = false;
        }

        const ingredientsPage = ({ history }: RouteComponentProps<{}>) => (
          <Ingredients
            initialIngredients={values.ingredients}
            onSubmit={ingredients => {
              setFieldValue("ingredients", ingredients);
            }}
            goBack={() => history.goBack()}
          />
        );

        const instructionsPage = ({ history }: RouteComponentProps<{}>) => (
          <Instructions
            initialInstructions={values.instructions}
            onSubmit={instructions => {
              setFieldValue("instructions", instructions);
            }}
            goBack={() => history.goBack()}
          />
        );

        const body = ({
          history,
          location: { pathname, state }
        }: RouteComponentProps<{}>) => {
          return (
            <SideBarLayout>
              <Prompt
                when={dirty && !isSubmitting}
                message={location => {
                  const parts = location.pathname.split("/");
                  const lastPart = parts[parts.length - 1];
                  if (
                    lastPart === "ingredients" ||
                    lastPart === "instructions" ||
                    location.pathname === "/new-recipe"
                  ) {
                    return true;
                  }

                  return navigateAwayMessage;
                }}
              />
              <Sidebar>
                <div style={{ overflowY: "auto", flex: 1, paddingTop: 18 }}>
                  <Box px={26} style={{ position: "relative" }}>
                    <FormGroup>
                      <div
                        style={{
                          fontFamily: theme.primaryFontStack,
                          fontSize: "1.375rem",
                          fontStyle: "italic",
                          marginBottom: showImportRecipeButton ? 20 : 0
                        }}
                      >
                        {headerText}
                      </div>
                      {showImportRecipeButton && (
                        <ImportRecipeButton
                          textImportOpen={textImportOpen}
                          webImportOpen={webImportOpen}
                          onImage={base64 =>
                            setFieldValue("croppedBase64Img", base64)
                          }
                          onPictureUrl={url => {
                            setFieldValue("pictureUrl", url);
                          }}
                          onImport={x => {
                            if (
                              x.instructions === emptyValueJSONString ||
                              !x.ingredients.length
                            ) {
                              notificationState.send({
                                text: importRecipeMissingInstructionsOrIngredientsMessage,
                                variant: "info"
                              });
                            }
                            let instructions: Node[] | null = null;
                            try {
                              instructions = JSON.parse(x.instructions);
                              if (!Array.isArray(instructions)) {
                                instructions = oldSlateToNew(instructions);
                              }
                            } catch {}
                            setValues({
                              ...values,
                              ...x,
                              ingredients: x.ingredients as IngredientFormValue[],
                              instructions: instructions || emptyValueJSON
                            });
                          }}
                        />
                      )}
                    </FormGroup>
                    {showImportRecipeButton && (
                      <div
                        style={{
                          fontFamily: theme.primaryFontStack,
                          fontSize: "1.375rem",
                          fontStyle: "italic",
                          marginBottom: 10
                        }}
                      >
                        Or enter one below
                      </div>
                    )}
                    <FormGroup>
                      <Field
                        name="name"
                        placeholder="title"
                        component={FormInput}
                        autoFocus
                      />
                    </FormGroup>
                    <FormGroup>
                      <GhostButton
                        type="button"
                        onClick={() => {
                          history.push(`${pathname}/ingredients`, state);
                        }}
                        style={{
                          minWidth: FORM_BUTTON_WIDTH,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                        data-testid="add-edit-ingredients-button"
                      >
                        <Icon
                          viewBox="0 0 512 512"
                          name="carrot"
                          template="ghostButtonSmall"
                          style={{ marginBottom: 2, marginRight: 3 }}
                        />
                        {hasEnteredSomeIngredients
                          ? "EDIT INGREDIENTS"
                          : "ADD INGREDIENTS"}
                      </GhostButton>
                    </FormGroup>
                    <FormGroup>
                      <GhostButton
                        type="button"
                        onClick={() => {
                          history.push(`${pathname}/instructions`, state);
                        }}
                        style={{
                          minWidth: FORM_BUTTON_WIDTH,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                        data-testid="add-edit-instructions-button"
                      >
                        <Icon
                          style={{ marginRight: 3 }}
                          viewBox="100 300 600 300"
                          name="measuringCup"
                          template="ghostButton"
                        />
                        {hasEnteredSomeInstructions
                          ? "EDIT STEPS"
                          : "ADD STEPS"}
                      </GhostButton>
                    </FormGroup>
                    <ImgPicker
                      imgs={values.otherImgs || []}
                      onPick={img => setFieldValue("pictureUrl", img)}
                      currentImg={values.pictureUrl}
                    >
                      {numGoodImgs => {
                        let pickerState:
                          | "import"
                          | "upload"
                          | "remove-image"
                          | "remove-video" = "upload";

                        if (numGoodImgs !== 0) {
                          pickerState = "import";
                        } else if (values.youtubeVideoId) {
                          pickerState = "remove-video";
                        } else if (
                          values.croppedBase64Img ||
                          values.pictureUrl
                        ) {
                          pickerState = "remove-image";
                        }

                        return (
                          <FormGroup style={{ display: "flex" }}>
                            {pickerState === "upload" ? (
                              <MediaButtonContainer>
                                <Field
                                  name="croppedBase64Img"
                                  component={DropzoneFormInput}
                                  text="ADD IMAGE"
                                />
                                <TextModalFormField
                                  name="youtubeVideoId"
                                  modalTitle="Paste YouTube URL"
                                  placeholder="url"
                                  buttonText={"ADD VIDEO"}
                                />
                              </MediaButtonContainer>
                            ) : null}
                            {pickerState === "remove-image" ? (
                              <GhostButton
                                type="button"
                                onClick={() => {
                                  setFieldValue("croppedBase64Img", null);
                                  setFieldValue("pictureUrl", null);
                                }}
                                style={{
                                  minWidth: FORM_BUTTON_WIDTH,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center"
                                }}
                              >
                                <Icon
                                  viewBox="0 0 24 24"
                                  name="imageOff"
                                  template="ghostButtonSmall"
                                  style={{ marginRight: 5 }}
                                />
                                REMOVE PHOTO
                              </GhostButton>
                            ) : null}
                            {pickerState === "remove-video" ? (
                              <GhostButton
                                type="button"
                                onClick={() => {
                                  setFieldValue("youtubeVideoId", null);
                                  setFieldValue("pictureUrl", null);
                                  setFieldValue("croppedBase64Img", null);
                                }}
                                style={{
                                  minWidth: FORM_BUTTON_WIDTH,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center"
                                }}
                              >
                                <Icon
                                  viewBox="0 0 24 24"
                                  name="imageOff"
                                  template="ghostButtonSmall"
                                  style={{ marginRight: 5 }}
                                />
                                REMOVE VIDEO
                              </GhostButton>
                            ) : null}
                          </FormGroup>
                        );
                      }}
                    </ImgPicker>
                    <FieldArray
                      validateOnChange={false}
                      name="times"
                      render={arrayHelpers => (
                        <Times
                          arrayHelpers={arrayHelpers}
                          times={values.times}
                        />
                      )}
                    />

                    <FormGroup style={{ paddingBottom: "2em" }}>
                      <BoldLabel mb=".75em">About the recipe</BoldLabel>
                      <FormField>
                        <Field
                          name="description"
                          placeholder="description"
                          component={FormInput}
                          textarea
                        />
                      </FormField>
                      <FormField>
                        <Field
                          name="source"
                          placeholder="source"
                          component={FormInput}
                          fullWidth
                        />
                      </FormField>
                      <FormField>
                        <Field
                          name="sourceUrl"
                          placeholder="url"
                          component={FormInput}
                          fullWidth
                        />
                      </FormField>
                      <FormField>
                        <Field
                          name="servings"
                          placeholder="yield"
                          component={FormInput}
                          fullWidth
                        />
                      </FormField>
                    </FormGroup>
                    <div
                      style={{
                        position: "sticky",
                        bottom: 0,
                        width: "100%",
                        paddingBottom: "1em",
                        backgroundColor: "#fafafa"
                      }}
                    >
                      {submitCount > 0 && Object.keys(errors).length ? (
                        <ErrorText style={{ marginBottom: "1em" }}>
                          There's an error above
                        </ErrorText>
                      ) : null}
                      <div style={{ display: "flex" }}>
                        <OutlineButton
                          style={{ marginRight: "1em" }}
                          type="button"
                          color="black"
                          onClick={() => history.goBack()}
                        >
                          CANCEL
                        </OutlineButton>
                        <BoldButton
                          style={{ width: "100%" }}
                          type="submit"
                          onClick={handleSubmit as any}
                          disabled={isSubmitting}
                        >
                          SAVE RECIPE
                        </BoldButton>
                      </div>
                    </div>
                  </Box>
                </div>
              </Sidebar>
              <MainContent>
                <RecipeHeaderContainer style={{ marginTop: "1em" }}>
                  <RecipeHeader>Recipe Preview</RecipeHeader>
                </RecipeHeaderContainer>
                <Recipe type="in-form" {...formatValues(values)} />
              </MainContent>
            </SideBarLayout>
          );
        };

        return (
          <Switch>
            <Route path={[newRecipePath, editRecipePath]} exact render={body} />
            <Route
              path={[
                `${newRecipePath}/ingredients`,
                `${editRecipePath}/ingredients`
              ]}
              exact
              render={ingredientsPage}
            />
            <Route
              path={[
                `${newRecipePath}/instructions`,
                `${editRecipePath}/instructions`
              ]}
              exact
              render={instructionsPage}
            />
          </Switch>
        );
      }}
    </Formik>
  );
};
