import { loginSchema } from "@saffron/common";
import { Field, FormikErrors, FormikProps, withFormik } from "formik";
import React from "react";
import { Box } from "../../../ui/Box";
import { SolidButton } from "../../../ui/Button";
import { Flex } from "../../../ui/Flex";
import { FormField } from "../../../ui/Forms/FormField";
import { FormGroup } from "../../../ui/Forms/FormGroup";
import { PrettyMyLink } from "../../../ui/PrettyLink";
import { theme } from "../../../ui/theme";
import { FormInput } from "../../shared/formFields/FormInput";
import { ButtonSpinner } from "../../../ui/Button/ButtonSpinner";

export interface LoginFormValues {
  email: string;
  password: string;
}

interface Props {
  noForgotPassword?: boolean;
  handleSubmit: (
    values: LoginFormValues
  ) => Promise<FormikErrors<LoginFormValues> | null>;
}

class C extends React.Component<Props & FormikProps<LoginFormValues>> {
  render() {
    const { handleSubmit, isSubmitting, noForgotPassword } = this.props;

    return (
      <form style={{ padding: "0 2.6em" }} onSubmit={handleSubmit}>
        <FormGroup>
          <FormField>
            <Field
              type="email"
              data-testid="login-email"
              name="email"
              placeholder="Email"
              autoComplete="username"
              component={FormInput}
            />
          </FormField>
          <FormField>
            <Field
              data-testid="login-password"
              type="password"
              name="password"
              placeholder="Password"
              autoComplete="current-password"
              component={FormInput}
            />
            {noForgotPassword ? null : (
              <Flex>
                <Box fontSize={"0.875rem"} mt={10} ml="auto">
                  <PrettyMyLink
                    style={{ color: "#444", fontFamily: theme.fonts.ui }}
                    to="/forgot-password"
                  >
                    Forgot Password?
                  </PrettyMyLink>
                </Box>
              </Flex>
            )}
          </FormField>
        </FormGroup>

        <Flex pt="3em">
          <SolidButton
            style={{ maxWidth: 133, height: 41, width: "100%" }}
            mx="auto"
            hoverColor="darkRed"
            bg="brownRed"
            color="brightWhite"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <ButtonSpinner color="#fff" style={{ margin: "auto" }} />
            ) : (
              "SIGN IN"
            )}
          </SolidButton>
        </Flex>
      </form>
    );
  }
}

(C as any).displayName = "LoginForm";

export const LoginForm = withFormik<Props, LoginFormValues>({
  validateOnBlur: false,
  validateOnChange: false,
  validationSchema: loginSchema,
  mapPropsToValues: () => ({ email: "", password: "" }),
  handleSubmit: async (formValues, { props, setErrors, setSubmitting }) => {
    const errors = await props.handleSubmit(formValues);
    if (errors) {
      setErrors(errors);
      setSubmitting(false);
    }
  }
})(C as any);
