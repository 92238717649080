import {
  ADMIN_STATUS,
  BETA_STATUS,
  FREE_STATUS,
  SEASONED_SUBSCRIPTION_CANCELLED_STATUS,
  SEASONED_SUBSCRIPTION_EXPIRED_STATUS,
  SEASONED_SUBSCRIPTION_STATUS,
  STANDARD_SUBSCRIPTION_CANCELLED_STATUS,
  STANDARD_SUBSCRIPTION_EXPIRED_STATUS,
  STANDARD_SUBSCRIPTION_STATUS
} from "@saffron/common";
import { useMeQuery } from "@saffron/controllers";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import {
  OutlineButton,
  ReverseOutlineButton,
  OutlineButtonA
} from "../../../ui/Button";
import { CenterLayout } from "../../../ui/Layout/CenterLayout";
import { media } from "../../../ui/mediaTemplates";
import { theme } from "../../../ui/theme";
import { Admin } from "./Admin";
import { CancelledUser } from "./CancelledUser";
import { FreeUser } from "./FreeUser";
import { GoldCard } from "./GoldCard";
import { StandardUser } from "./StandardUser";
import { UpdateProfileModal } from "./UpdateProfileModal";

interface Props {}

const BodyContainer = styled.div`
  @media only screen and (max-width: 975px) {
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  padding-right: 1em;
  ${media.leftNavDies`
  padding-left: 6em;
  padding-right: 6em;
`}
`;

const AccountStatus: React.FC<Props> = () => {
  const { data, loading } = useMeQuery();
  const [profileModalOpen, setProfileModalOpen] = useState(false);

  if (!data || loading || !data.me) {
    return null;
  }

  if (!data.me.user) {
    return <Redirect to="/login/na" />;
  }

  const {
    me: {
      user: {
        role: { type },
        firstName,
        lastName
      },
      user
    }
  } = data;

  let body = <div />;

  if (type === ADMIN_STATUS) {
    body = <Admin />;
  } else if (
    type === STANDARD_SUBSCRIPTION_STATUS ||
    type === SEASONED_SUBSCRIPTION_STATUS
  ) {
    body = <StandardUser user={user} />;
  } else if (
    type === STANDARD_SUBSCRIPTION_CANCELLED_STATUS ||
    type === STANDARD_SUBSCRIPTION_EXPIRED_STATUS ||
    type === SEASONED_SUBSCRIPTION_CANCELLED_STATUS ||
    type === SEASONED_SUBSCRIPTION_EXPIRED_STATUS
  ) {
    body = <CancelledUser user={user} />;
  } else if (type === FREE_STATUS || type === BETA_STATUS) {
    body = <FreeUser user={user} />;
  }

  return (
    <CenterLayout
      patternImage="gold"
      backgroundImage="creme"
      width={900}
      innerPadding="0px"
      style={{
        maxHeight: "100%",
        maxWidth: 900,
        width: "100%",
        paddingTop: "3em",
        paddingBottom: "3em"
      }}
    >
      <Wrapper>
        <a style={{ marginLeft: "auto" }} href="/contact">
          <ReverseOutlineButton type="button">CONTACT</ReverseOutlineButton>
        </a>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20
          }}
        >
          <div
            style={{
              fontFamily: theme.primaryFontStack,
              fontSize: "1.5625rem"
            }}
          >
            Account Information
          </div>
        </div>
        <BodyContainer
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ paddingBottom: "2em" }}>{body}</div>
          <GoldCard
            style={{ height: 240, maxWidth: 330, width: "100%" }}
            goldText="YOUR USER"
            title="Profile"
          >
            <strong
              style={{ color: "#333" }}
            >{`${firstName} ${lastName}`}</strong>
            <OutlineButton
              onClick={() => setProfileModalOpen(true)}
              color="gold"
            >
              EDIT PROFILE
            </OutlineButton>
          </GoldCard>
        </BodyContainer>
        <GoldCard
          style={{
            marginTop: "2em",
            height: 240,
            maxWidth: 330,
            width: "100%"
          }}
          goldText="YOUR RECIPE"
          title="Export"
        >
          <div>Generate a backup of all your recipes.</div>
          <OutlineButtonA
            href={`${process.env.REACT_APP_SERVER_URL}/export-recipes`}
            rel="noreferrer noopener"
            target="_blank"
            color="gold"
          >
            EXPORT RECIPES
          </OutlineButtonA>
        </GoldCard>
      </Wrapper>
      <UpdateProfileModal
        firstName={firstName}
        lastName={lastName}
        isOpen={profileModalOpen}
        onRequestClose={() => setProfileModalOpen(false)}
      />
    </CenterLayout>
  );
};

export default AccountStatus;
