import React, { ReactNode } from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { theme } from "../theme";
import { SelectController, selectPaddingAmount } from "./SelectController";
import { SelectOptions } from "./SelectOptions";

export interface SelectProps {
  placeholder?: string;
  value?: string | null;
  onChange: (value: string) => void;
  options: SelectOptions[];
  variant?: SelectVariants;
  label?: string;
  style?: React.CSSProperties;
}

export interface NativeSelectProps {
  placeholder?: string;
  value?: string | null;
  onChange: (value: string) => void;
  options: ReactNode;
  variant?: SelectVariants;
  label?: string;
  style?: React.CSSProperties;
}

const variants = {
  "only-border-bottom": {
    borderBottom: "1px solid #dde3e3"
  },
  "no-border": {
    border: "none",
    paddingLeft: 0,
    paddingRight: 0
  },
  "button-like": {
    border: "1px solid #e6e6e6",
    borderRadius: 3,
    lineHeight: "1.125rem"
  },
  default: {
    border: "1px solid #dde2e3"
  }
};

export type SelectVariants = keyof typeof variants;

export const Square = styled("div")<{
  open: boolean;
  variant: SelectVariants;
  noHover?: boolean;
}>(p => ({
  fontFamily: theme.fonts.ui,
  backgroundColor: p.open ? theme.selectedColor : "transparent",
  fontSize: "1.0rem",
  padding: selectPaddingAmount,
  cursor: p.noHover ? undefined : "pointer",
  color: p.open ? "#fff" : "#444",
  ":hover": {
    backgroundColor: !p.noHover && !p.open ? theme.hoverColor : undefined
  },
  ...variants[p.variant]
}));

const SelectSquare = Square.withComponent("select");

export const NativeSelect: React.FC<NativeSelectProps> = ({
  placeholder,
  style,
  value,
  options,
  onChange
}) => {
  return (
    <div style={{ position: "relative", ...style }}>
      <SelectSquare
        open={false}
        variant="default"
        style={
          {
            width: "100%",
            borderRadius: 0,
            appearance: "none",
            // backgroundColor: "#C7CFD1",
            color: "#444",
            fontFamily: theme.fonts.ui,
            "-webkit-appearance": "none",
            "-moz-appearance": "none"
          } as any
        }
        value={value || undefined}
        onChange={e => onChange(e.target.value)}
      >
        <option value="" disabled selected>
          {placeholder}
        </option>
        {options}
      </SelectSquare>
      <Icon
        style={{
          position: "absolute",
          right: "10px",
          top: "13px"
        }}
        name={"thinDownArrow"}
        width=".75em"
        height=".75em"
        fill={"#444"}
      />
    </div>
  );
};

export const Select: React.FC<SelectProps> = ({
  placeholder,
  variant = "default",
  label,
  style,
  ...props
}) => {
  return (
    <SelectController {...props}>
      {({ open, currentOption, toggle }) => (
        <Square
          style={{ padding: label ? "4px 22.4px" : undefined, ...style }}
          variant={variant}
          open={open}
          onClick={toggle}
        >
          {label && (
            <div
              style={{
                fontSize: "0.5rem",
                lineHeight: "0.5rem",
                letterSpacing: 0.3
              }}
            >
              {label}
            </div>
          )}
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: 6 }}>
              <div>{currentOption ? currentOption.text : placeholder}</div>
            </div>
            <Icon
              name={open ? "thinUpArrow" : "thinDownArrow"}
              width=".75em"
              height=".75em"
              fill={"#999"}
            />
          </div>
        </Square>
      )}
    </SelectController>
  );
};
