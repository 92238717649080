import React from "react";
import Icon from "../../ui/Icon";
import { RecipePreviewFragment, RegularIngredient } from "@saffron/controllers";
import { Subscribe } from "unstated";
import { ScaleIgContainer } from "./ScaleIgContainer";
import ReactModal from "react-modal";
import { theme } from "../../ui/theme";
import { ReverseOutlineButton } from "../../ui/Button";
import { scaleIngredient, ingredientToParts } from "@saffron/common";
import { ScaleBox } from "./ScaleBox";

interface Props {
  recipe: RecipePreviewFragment;
}

// if you change this, don't forget to change ScaleMenuItem.tsx

export const ScaleRicon: React.FC<Props> = ({ recipe }) => {
  return (
    <Subscribe to={[ScaleIgContainer]}>
      {({
        setOpen,
        setTmpAmount,
        state: { open, tmpAmount, amountMap },
        setAmount,
        removeScaling
      }: ScaleIgContainer) => {
        const multiplier =
          (tmpAmount && parseFloat(tmpAmount)) || amountMap[recipe.id] || 1;
        return (
          <>
            <Icon
              tooltipText="scale ingredients"
              template="recipe"
              name="ruler"
              onClick={() => setOpen(true)}
              arrow
            />
            <ReactModal
              style={{
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  boxShadow: "3px 6px 6px rgba(0, 0, 0, 0.16)",
                  maxHeight: "88vh",
                  maxWidth: "88vw",
                  padding: 0,
                  border: "1px solid #e6e6e6",
                  width: 400
                }
              }}
              ariaHideApp={false}
              isOpen={open}
              onRequestClose={() => setOpen(false)}
            >
              <ScaleBox
                onX={() => setOpen(false)}
                value={
                  tmpAmount === null ? amountMap[recipe.id] || "1.0" : tmpAmount
                }
                setValue={x => setTmpAmount(x)}
                tmpAmount={tmpAmount}
              />
              <div
                style={{
                  padding: 27,
                  display: "grid",
                  gridTemplateColumns: "auto",
                  gridRowGap: "1em",
                  maxHeight: 400,
                  overflowY: "auto"
                }}
              >
                {recipe.ingredients.map((ig, i) => {
                  if (ig.__typename === "IngredientHeader") {
                    return null;
                  }
                  const scaledIg = scaleIngredient(
                    ig,
                    multiplier
                  ) as RegularIngredient;

                  const { amountAndUnit, rest } = ingredientToParts(scaledIg);

                  return (
                    <div key={i} style={{ wordBreak: "break-word" }}>
                      <strong style={{ color: "#333" }}>{amountAndUnit}</strong>
                      {rest}
                    </div>
                  );
                })}
              </div>
              <div style={{ padding: 27 }}>
                <ReverseOutlineButton
                  onClick={() => {
                    const tmpTmpAmount = tmpAmount || amountMap[recipe.id];
                    if (tmpTmpAmount) {
                      const floatTmpAmount =
                        typeof tmpTmpAmount === "string"
                          ? parseFloat(tmpTmpAmount)
                          : tmpTmpAmount;
                      if (!Number.isNaN(floatTmpAmount)) {
                        if (floatTmpAmount !== 1) {
                          setAmount(recipe.id, floatTmpAmount);
                        } else {
                          removeScaling(recipe.id);
                        }
                      }
                    }
                  }}
                  style={{ width: "100%" }}
                >
                  SET SCALE
                </ReverseOutlineButton>
                <div
                  style={{
                    fontFamily: theme.fonts.ui,
                    color: "#999999",
                    fontSize: "0.875rem",
                    paddingTop: 16
                  }}
                >
                  (Ingredients will temporarily be scaled until you refresh)
                </div>
              </div>
            </ReactModal>
          </>
        );
      }}
    </Subscribe>
  );
};
