import React from "react";
import { PatternCard } from "../Card";
import { PossibleBackgroundImages } from "../types";
import { Flex } from "../Flex";
import { Box } from "../Box";
import { HEADER_HEIGHT } from "../theme";

interface Props {
  width?: string | number | string[] | number[];
  innerPadding?: string;
  patternImage?: PossibleBackgroundImages;
  showHeader?: boolean;
}

export class CardLayout extends React.PureComponent<Props> {
  render() {
    const { width = 360, innerPadding, patternImage, showHeader } = this.props;
    return (
      <Box width="100%">
        <Flex
          pt={showHeader ? HEADER_HEIGHT + 57 : 0}
          backgroundImage="blue"
          justifyContent="center"
          style={{ minHeight: "100%" }}
        >
          <PatternCard
            patternImage={patternImage}
            width={width}
            bg="brightWhite"
            my="auto"
            flexDirection="column"
            p={innerPadding}
          >
            {this.props.children}
          </PatternCard>
          {/* <Pattern backgroundImage={patternImage} lite /> */}
        </Flex>
      </Box>
    );
  }
}
