import { FieldProps, getIn } from "formik";
import React from "react";
import { Input, InputProps, TextArea } from "../../../ui/Input";

export const FormInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ignoreTouch,
  textarea,
  ...props
}: FieldProps<any> &
  InputProps & { ignoreTouch?: boolean; textarea?: boolean }) => {
  const Component = textarea ? TextArea : Input;

  return (
    <Component
      {...field}
      {...props}
      value={field.value || ""}
      errorText={
        ignoreTouch || getIn(touched, field.name)
          ? getIn(errors, field.name)
          : ""
      }
    />
  );
};
