import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";

interface Props {
  setMenuPlannerPath: (s: string) => void;
  menuPlannerPath: string;
}

export const MP_DATE_SIZE_PREFERENCE = "MP_DATE_SIZE_PREFERENCE";

export const NavBarWatcher: React.FC<Props> = ({
  menuPlannerPath,
  setMenuPlannerPath
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== menuPlannerPath && pathname.startsWith("/menu-planner")) {
      setMenuPlannerPath(pathname);
    }
    let dateSizePref = "";
    if (pathname.startsWith("/menu-planner/week")) {
      dateSizePref = "week";
    } else if (pathname.startsWith("/menu-planner/month")) {
      dateSizePref = "month";
    }

    if (dateSizePref) {
      try {
        localStorage.setItem(MP_DATE_SIZE_PREFERENCE, dateSizePref);
      } catch {}
    }
  }, [pathname, menuPlannerPath, setMenuPlannerPath]);

  return null;
};
