import * as React from "react";
import styled from "styled-components";
import { ReverseOutlineButton } from "../../../ui/Button";
import { SmallPotLogo } from "../../../ui/Logo";
import { theme } from "../../../ui/theme";

interface Props {
  buttonText?: string;
  onButtonClick?: () => void;
  flat?: boolean;
  deepLinkHref?: string;
}

const Card = styled.div<{ flat?: boolean }>(p => ({
  margin: "auto",
  width: "100%",
  background: "#FAFAFA",
  boxShadow: p.flat ? undefined : "0px 4px 4px rgba(0, 0, 0, 0.25)",
  display: "flex",
  flexDirection: "column",
  "@media only screen and (min-width: 500px)": {
    maxWidth: 500,
    height: "100%",
    maxHeight: 500
  }
}));

export const CookbookCard: React.FC<Props> = ({
  children,
  onButtonClick,
  buttonText,
  flat,
  deepLinkHref
}) => {
  return (
    <Card flat={flat}>
      <a
        href="/"
        style={{
          margin: "24px 0",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <SmallPotLogo />
      </a>
      <div
        style={{
          marginLeft: 32,
          marginRight: 32,
          marginBottom: 40,
          paddingLeft: 30,
          paddingRight: 30,
          paddingBottom: 32,
          border: "1px solid #977535",
          flex: 1,
          position: "relative"
        }}
      >
        {children}
        {buttonText && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              bottom: -18,
              left: 0
            }}
          >
            {deepLinkHref ? (
              <a href={deepLinkHref}>
                <ReverseOutlineButton
                  onClick={onButtonClick}
                  style={{
                    fontFamily: theme.secondaryFontStack,
                    fontSize: 18 // we want big on phone
                  }}
                  variant="offWhite"
                >
                  {buttonText}
                </ReverseOutlineButton>
              </a>
            ) : (
              <ReverseOutlineButton
                style={{
                  fontFamily: theme.secondaryFontStack,
                  fontSize: 18 // we want big on phone
                }}
                onClick={onButtonClick}
                variant="offWhite"
              >
                {buttonText}
              </ReverseOutlineButton>
            )}
          </div>
        )}
      </div>
    </Card>
  );
};
