import { RecipePreviewFragment, useMeQuery } from "@saffron/controllers";
import React from "react";
import { AddGroceryItemsRicon } from "../AddGroceryItemsRicon";
import { CopyForRedditRicon } from "../CopyForRedditRicon";
import { DeleteRicon } from "../DeleteRicon";
import { EditRicon } from "../EditRicon";
import { ExpandRicon } from "../ExpandRicon";
import { MenuPlannerRicon } from "../MenuPlannerRicon";
import { MoreRicon } from "../MoreRicon";
import { PdfRicon } from "../PdfRicon";
import { RiconContainer } from "../RiconContainer";
import { ScaleRicon } from "../ScaleRicon";
import { ShareRicon } from "../ShareRicon";

interface Props {
  onEditRiconClick: () => void;
  onDeleteRiconClick?: () => void;
  noFullscreen?: boolean;
  recipe: RecipePreviewFragment;
  sectionId?: string;
  cookbookId?: string;
}

export const RiconBar: React.FC<Props> = ({
  recipe: { ingredients, id, name, owner, instructions },
  recipe,
  sectionId,
  cookbookId,
  onEditRiconClick,
  onDeleteRiconClick,
  noFullscreen
}) => {
  const { data, loading } = useMeQuery();
  if (!data || loading || !data.me || !data.me.user) {
    return null;
  }

  const isOwner = data.me.user.id === owner;
  const isAdmin = data.me.user.role.type === "admin";

  return (
    <RiconContainer>
      {isOwner && <EditRicon onClick={onEditRiconClick} />}
      <AddGroceryItemsRicon
        ingredients={ingredients}
        recipeId={id}
        recipeName={name}
      />
      <MenuPlannerRicon recipe={recipe} />
      {!noFullscreen && <ExpandRicon recipe={recipe} />}
      <ScaleRicon recipe={recipe} />
      {isOwner && <ShareRicon recipe={recipe} />}
      {isOwner && <PdfRicon recipe={recipe} />}
      {/* {!isOwner && <AddRecipeRicon recipeId={id} />} */}
      {isAdmin && (
        <CopyForRedditRicon
          instructions={instructions}
          ingredients={ingredients}
        />
      )}
      {isOwner && <DeleteRicon recipeId={id} onDelete={onDeleteRiconClick} />}
      {isOwner && sectionId && cookbookId && (
        <MoreRicon
          recipe={recipe}
          cookbookId={cookbookId}
          sectionId={sectionId}
        />
      )}
    </RiconContainer>
  );
};
