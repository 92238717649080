import { deleteRecipeMessage } from "@saffron/common";
import {
  deleteRecipeMutationOptions,
  useDeleteRecipeMutation
} from "@saffron/controllers";
import React from "react";
import Icon from "../../../ui/Icon";
import { notificationState } from "../../misc/notifications/NotificationState";

interface Props {
  recipeId: string;
  onDelete?: () => void;
}

export const DeleteRicon: React.FC<Props> = ({ recipeId, onDelete }) => {
  const [deleteRecipe] = useDeleteRecipeMutation();
  return (
    <Icon
      name="trash"
      template="recipe"
      tooltipText="delete recipe"
      arrow
      onClick={async () => {
        const confirm = window.confirm(deleteRecipeMessage);

        if (!confirm) {
          return;
        }

        await deleteRecipe(deleteRecipeMutationOptions({ id: recipeId }));

        notificationState.send({
          text: "Recipe successfully deleted",
          variant: "success"
        });

        if (onDelete) {
          onDelete();
        }
      }}
    />
  );
};
