import React from "react";
import { FlexCenter } from "../../../ui/Flex";
import { H3 } from "../../../ui/Heading";
import { CardLogoLayout } from "../../../ui/Layout/CardLogoLayout";

export default () => (
  <CardLogoLayout>
    <FlexCenter style={{ paddingBottom: "2em" }}>
      <H3 mt="0">Page not found.</H3>
    </FlexCenter>
  </CardLogoLayout>
);
