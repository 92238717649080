import React from "react";
import Icon from "../../../ui/Icon";
import { ShareModal } from "./ShareModal";
import { slugify } from "@saffron/common";
import { RecipePreviewFragment } from "@saffron/controllers";

interface State {
  open: boolean;
}

interface Props {
  recipe: RecipePreviewFragment;
}

export class ShareRicon extends React.PureComponent<Props, State> {
  state = {
    open: false
  };

  toggleOpen = () => {
    this.setState(state => ({
      open: !state.open
    }));
  };

  render() {
    const { recipe } = this.props;
    return (
      <React.Fragment>
        <Icon
          name="share"
          template="recipe"
          tooltipText="share recipe"
          onClick={this.toggleOpen}
          arrow
        />
        <ShareModal
          open={this.state.open}
          toggleModal={this.toggleOpen}
          shareId={recipe.shareId}
          id={recipe.id}
          slug={slugify(recipe.name)}
          privacy={recipe.privacy}
        />
      </React.Fragment>
    );
  }
}
