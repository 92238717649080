import { apolloClient } from "../apollo";
import {
  addCookbookToCollectionMutation,
  AddCookbookToCollectionMutation,
  AddCookbookToCollectionMutationVariables
} from "@saffron/controllers";

interface Options {
  cookbookShareId: string;
  name: string;
}

export const addCookbookToAccount = async ({ cookbookShareId }: Options) => {
  await apolloClient.mutate<
    AddCookbookToCollectionMutation,
    AddCookbookToCollectionMutationVariables
  >({
    mutation: addCookbookToCollectionMutation,
    variables: {
      cookbookShareId
    }
  });

  // notificationState.send({
  //   text: `Added ${name} to your account`,
  //   variant: "success"
  // });
};
