import styled from "styled-components";
import { patternWidth, patternWidthValue } from "../theme";
import { mediumSidebar } from "../Sidebar";
import { skinnySideBarWidth } from "../../modules/menuPlanner/shared/constants";
import { media } from "../mediaTemplates";

// const mlBig = patternWidthValue + bigSidebar;
const mlMed = patternWidthValue + mediumSidebar;
const mlSmall = patternWidthValue + skinnySideBarWidth;
type MProps = {
  smallSidebar?: boolean;
  noPattern?: boolean;
};
export const MainContent = styled("div")<MProps>`
  width: 100%;
  padding-left: ${mlSmall}px;
  ${p => media.patternDies`
          padding-right: ${p.noPattern ? "0px" : patternWidth};
        `};
  ${p => media.oneColSteps`
  padding-left: ${p.smallSidebar ? mlSmall : mlMed}px;
        `};
`;
