import React, { useRef } from "react";
import { Box } from "../../../../ui/Box";
import TooltipTrigger from "react-popper-tooltip";
import { ListButton } from "../../../../ui/ListButton";
import Icon from "../../../../ui/Icon";
import { MenuDay } from "@saffron/controllers";

interface TopOfDayProps {
  onOpenNewNoteModal: (dateKey: string) => void;
  todayRef?: React.RefObject<HTMLDivElement>;
  md: MenuDay;
  activeDay: boolean;
}

export const TopOfDay: React.FC<TopOfDayProps> = ({
  todayRef,
  onOpenNewNoteModal,
  md,
  activeDay
}) => {
  const tooltip = useRef<any>(null);
  const day = md.dt.date();
  return (
    <div style={{ display: "flex", width: "100%", height: 40 }}>
      <div>
        <Box
          style={{
            position: "relative"
          }}
          fontSize="1.25rem"
          borderBottom="1px solid #DDE2E3;"
        >
          {activeDay && (
            <div
              style={{
                position: "absolute",
                top: -5,
                left: -10,
                height: 36,
                width: day >= 10 ? 38 : 32, // more space for 2 digit nums
                backgroundColor: "#C7D8D8"
              }}
              ref={todayRef}
            />
          )}
          <div style={{ zIndex: 2, position: "relative" }}>{day}</div>
        </Box>
      </div>
      <div style={{ marginLeft: "auto" }}>
        <TooltipTrigger
          ref={tooltip}
          placement="bottom"
          trigger="click"
          tooltip={({ tooltipRef, getTooltipProps }) => {
            return (
              <div
                {...getTooltipProps({
                  ref: tooltipRef,
                  className: "tooltip-container",
                  style: {
                    width: 185,
                    paddingBottom: 5,
                    paddingTop: 5,
                    borderRadius: 4,
                    backgroundColor: "#fff",
                    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
                    border: "1px solid rgba(27,31,35,.15)"
                  }
                })}
              >
                <ListButton
                  onClick={() => {
                    onOpenNewNoteModal(md.dtKey);
                    if (tooltip.current) {
                      tooltip.current.hideTooltip();
                    }
                  }}
                >
                  Add Note
                </ListButton>
              </div>
            );
          }}
        >
          {({ getTriggerProps, triggerRef }) => (
            <div>
              <div
                {...getTriggerProps({
                  ref: triggerRef,
                  className: "trigger",
                  style: {
                    marginTop: 2.5,
                    padding: 5,
                    paddingRight: 0
                  }
                })}
              >
                <Icon name="dots" template="dotsWhenOnWhite" />
              </div>
            </div>
          )}
        </TooltipTrigger>
      </div>
    </div>
  );
};
