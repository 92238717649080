import { ingredientToString, mapSlate } from "@saffron/common";
import { RecipePreviewFragment } from "@saffron/controllers";
import * as React from "react";
import Icon from "../../ui/Icon";
import { notificationState } from "../misc/notifications/NotificationState";

interface Props {
  ingredients: RecipePreviewFragment["ingredients"];
  instructions: RecipePreviewFragment["instructions"];
}

export const CopyForRedditRicon: React.FC<Props> = ({
  ingredients,
  instructions
}) => {
  return (
    <Icon
      name="clipboard"
      tooltipText="copy recipe to clipboard for reddit"
      template="recipe"
      onClick={() => {
        const textArea = document.createElement("textarea");
        textArea.value = `# INGREDIENTS
${ingredients
  .map(ig => {
    if (ig.__typename === "IngredientHeader") {
      return `## ${ig.text}`;
    }

    return ingredientToString(ig);
  })
  .join("\n\n")}
# METHOD
${mapSlate(JSON.parse(instructions), {
  "header-four": text => `## ${text.toUpperCase()}`,
  paragraph: text => text
}).join("\n\n")}
        `;
        document.body.appendChild(textArea);
        textArea.select();

        let successful = false;

        try {
          successful = document.execCommand("copy");
        } catch {}

        if (successful) {
          notificationState.send({
            variant: "success",
            text: "successfully copied to clipboard"
          });
        } else {
          notificationState.send({
            variant: "error",
            text: "unable to copy to clipboard"
          });
        }

        document.body.removeChild(textArea);
      }}
    />
  );
};
