import { slugify } from "@saffron/common";
import {
  GetSectionAndCookbookIdForRecipeQuery,
  getSectionAndCookbookIdForRecipeQuery,
  GetSectionAndCookbookIdForRecipeQueryVariables,
  useGetRecipeByIdQuery,
  useMeQuery
} from "@saffron/controllers";
import * as React from "react";
import { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Flex } from "../../../ui/Flex";
import { MainContent } from "../../../ui/Layout/MainContent";
import { SideBarLayout } from "../../../ui/Layout/SidebarLayout";
import Recipe from "../../../ui/Recipe";
import { RiconBar } from "../../ricons/RiconBar";
import { CookbookSidebar } from "./CookbookSidebar";
import { isUuid } from "../../../utils/isUuid";
import { pushLocation } from "../../../utils/pushLocation";
import { RecipeNoteSidebar } from "../../shared/RecipeNoteSidebar";
import { usePrevious } from "../../../utils/usePrevious";

export const BROWSE_COOKBOOK_STATE_LOCAL_STORAGE_KEY = "browse-cookbook-state";

const BrowseCookbookView: React.FC<RouteComponentProps<{
  currentRecipeId?: string;
  currentSectionId?: string;
  currentCookbookId?: string;
  recipeIdToLookup?: string;
}>> = ({ history, match: { params }, location }) => {
  const { data: meData } = useMeQuery();
  const [showNotes, setShowNotes] = React.useState(false);
  const [ids, setIds] = React.useState<{
    currentRecipeId?: string;
    currentSectionId?: string;
    currentCookbookId?: string;
  }>(() => {
    if (
      params.currentCookbookId &&
      isUuid(params.currentCookbookId) &&
      (params.currentCookbookId ||
        params.currentSectionId ||
        params.currentRecipeId)
    ) {
      return params;
    }

    const sState = localStorage.getItem(
      BROWSE_COOKBOOK_STATE_LOCAL_STORAGE_KEY
    );

    if (!sState) {
      return {};
    }

    try {
      const state = JSON.parse(sState);
      if (typeof state === "string") {
        return {};
      }
      return state;
    } catch {
      return {};
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem(
        BROWSE_COOKBOOK_STATE_LOCAL_STORAGE_KEY,
        JSON.stringify(ids)
      );
      return;
    } catch {}
    try {
      localStorage.setItem(
        BROWSE_COOKBOOK_STATE_LOCAL_STORAGE_KEY,
        JSON.stringify({})
      );
    } catch {}
  }, [ids]);

  const { data: recipeData, client } = useGetRecipeByIdQuery({
    variables: { id: ids.currentRecipeId! },
    skip: !ids.currentRecipeId
  });

  const isCurrent = React.useRef(true);

  useEffect(() => {
    return () => {
      isCurrent.current = false;
    };
  }, []);

  useEffect(() => {
    if (!params.recipeIdToLookup) {
      return;
    }

    client
      .query<
        GetSectionAndCookbookIdForRecipeQuery,
        GetSectionAndCookbookIdForRecipeQueryVariables
      >({
        query: getSectionAndCookbookIdForRecipeQuery,
        variables: { recipeId: params.recipeIdToLookup }
      })
      .then(x => {
        if (
          x.data &&
          x.data.getSectionAndCookbookIdForRecipe &&
          x.data.getSectionAndCookbookIdForRecipe.cookbookId &&
          x.data.getSectionAndCookbookIdForRecipe.sectionId &&
          isCurrent.current
        ) {
          setIds({
            currentCookbookId:
              x.data.getSectionAndCookbookIdForRecipe.cookbookId,
            currentSectionId: x.data.getSectionAndCookbookIdForRecipe.sectionId,
            currentRecipeId: params.recipeIdToLookup
          });
        }
      });
  }, [params.recipeIdToLookup, client, setIds]);

  useEffect(() => {
    if (location.pathname !== "/browse") {
      pushLocation("/browse", "replace");
    }
  }, [location.pathname]);

  const pKey = usePrevious(location.key);
  useEffect(() => {
    if (showNotes && pKey && location.key && pKey !== location.key) {
      setShowNotes(false);
    }
  }, [showNotes, pKey, location.key]);

  return (
    <SideBarLayout>
      {showNotes && recipeData && recipeData.getRecipeById ? (
        <RecipeNoteSidebar
          onRequestClose={() => setShowNotes(false)}
          recipe={recipeData.getRecipeById}
        />
      ) : (
        <CookbookSidebar ids={ids} setIds={setIds} />
      )}
      <MainContent>
        <Flex flexDirection="column">
          {recipeData && recipeData.getRecipeById && (
            <React.Fragment>
              <RiconBar
                sectionId={ids.currentSectionId}
                cookbookId={ids.currentCookbookId}
                recipe={recipeData.getRecipeById}
                onEditRiconClick={() => {
                  history.push(
                    `/recipe/edit/${recipeData.getRecipeById!.id}/${slugify(
                      recipeData.getRecipeById!.name
                    )}`,
                    { next: `/browse` }
                  );
                }}
                onDeleteRiconClick={() =>
                  setIds({ ...ids, currentRecipeId: undefined })
                }
              />
              <Flex flex={1}>
                <Recipe
                  type={
                    meData?.me.user?.id === recipeData.getRecipeById.owner
                      ? "owner"
                      : "viewer"
                  }
                  onNotesClicked={() => {
                    setShowNotes(!showNotes);
                  }}
                  notesOpen={showNotes}
                  {...recipeData.getRecipeById}
                />
              </Flex>
            </React.Fragment>
          )}
        </Flex>
      </MainContent>
    </SideBarLayout>
  );
};

export default BrowseCookbookView;
