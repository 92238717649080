import React from "react";
import { ClickableBox } from "../Box";

interface Props {
  label?: string;
  value: boolean;
  style?: any;
  onChange?: (newValue: boolean) => void;
  afterChange?: (e: any) => void;
}

export class Checkbox extends React.PureComponent<Props> {
  render() {
    const {
      style = {},
      onChange,
      afterChange,
      label,
      value,
      ...props
    } = this.props;
    return (
      <div style={{ display: "flex", ...style }}>
        <input
          style={{ marginRight: ".5em", cursor: "pointer" }}
          type="checkbox"
          checked={value}
          onChange={e => {
            if (onChange) {
              onChange(e.target.checked);
              if (afterChange) {
                afterChange(e);
              }
            }
          }}
          {...props}
        />
        <ClickableBox
          onClick={(e: any) => {
            if (onChange) {
              onChange(!value);
              if (afterChange) {
                afterChange(e);
              }
            }
          }}
          fontFamily="ui"
          fontSize={"0.875rem"}
        >
          {label}
        </ClickableBox>
      </div>
    );
  }
}
