import React from "react";
import { NotesTimeContainer, NotesServingsContainer } from "./components";
import { SmallButton } from "../Button";

interface NotesButtonProps {
  notes?: string | null;
  onNotesClicked?: () => void;
  notesOpen?: boolean;
  timeSection: boolean;
}

export const NotesButton: React.FC<NotesButtonProps> = ({
  onNotesClicked,
  notes,
  notesOpen,
  timeSection
}) => {
  if (!onNotesClicked) {
    return null;
  }

  const body = (
    <SmallButton
      style={{
        backgroundColor: notes ? "#927640" : "#666666"
      }}
      onClick={onNotesClicked}
    >
      {notesOpen ? "Hide Note" : notes ? "View Note" : "Add  Note"}
    </SmallButton>
  );

  return timeSection ? (
    <NotesTimeContainer>{body}</NotesTimeContainer>
  ) : (
    <NotesServingsContainer>{body}</NotesServingsContainer>
  );
};
