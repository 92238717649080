import React from "react";
import { DivButton } from "./DivButton";
import { CenterCheckboxContainer } from "./CenterCheckboxContainer";
import { theme } from "./theme";
import { RadioButton } from "./RadioButton";

interface RadioButtonWithLabelProps {
  active: boolean;
  onClick: () => void;
  label: string;
}

export const RadioButtonWithLabel: React.FC<RadioButtonWithLabelProps> = ({
  active,
  label,
  onClick
}) => {
  return (
    <DivButton onClick={onClick}>
      <CenterCheckboxContainer
        style={{
          fontFamily: theme.fonts.ui,
          fontSize: 16,
          color: "#444"
        }}
        checkbox={<RadioButton active={active} />}
      >
        <div style={{ marginLeft: 8 }}>{label}</div>
      </CenterCheckboxContainer>
    </DivButton>
  );
};
