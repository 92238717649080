import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DivButton } from "../../../../ui/DivButton";
import { BoldLabel } from "../../../../ui/Label";
import { theme } from "../../../../ui/theme";
import { mediaSizes } from "../../../../ui/mediaTemplates";

interface Props {
  imgs: string[];
  currentImg?: string;
  onPick: (img: string | null) => void;
  children: (numGoodImgs: number) => JSX.Element | null;
}

const imgSize = 88;
const smallImgSize = 54;

const Grid = styled.div({
  display: "grid",
  gridTemplateColumns: `repeat(3, ${imgSize}px)`,
  gridGap: "20px",
  [`@media (max-width: ${mediaSizes.oneColSteps}px)`]: {
    gridTemplateColumns: `repeat(3, ${smallImgSize}px)`
  }
});

const ImgSquare = styled.img({
  cursor: "pointer",
  objectFit: "cover",
  width: imgSize,
  height: imgSize,
  [`@media (max-width: ${mediaSizes.oneColSteps}px)`]: {
    width: smallImgSize,
    height: smallImgSize
  }
});

const NoImage = styled(DivButton)({
  width: imgSize,
  height: imgSize,
  backgroundColor: theme.colors.gray,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "0.875rem",
  color: "#444",
  fontFamily: theme.fonts.ui,
  [`@media (max-width: ${mediaSizes.oneColSteps}px)`]: {
    width: smallImgSize,
    height: smallImgSize
  }
});

const CHUNK_SIZE = 6;

export const ImgPicker: React.FC<Props> = ({
  imgs,
  onPick,
  currentImg,
  children
}) => {
  const [badImgs, setBadImgs] = useState<Record<string, boolean>>({});
  const goodImgs = imgs.filter(x => !badImgs[x]);
  // multiple of 3 for clean rows
  const [limit, setLimit] = useState(CHUNK_SIZE - 1);

  useEffect(() => {
    if (currentImg && badImgs[currentImg]) {
      onPick(goodImgs[0]);
    }
  }, [currentImg, badImgs, goodImgs, onPick]);

  return (
    <>
      <div
        style={{
          display: goodImgs.length ? undefined : "none",
          marginBottom: limit < goodImgs.length ? 0 : "1em"
        }}
      >
        <div style={{ marginBottom: "1em" }}>
          <BoldLabel>Choose an image</BoldLabel>
        </div>
        <Grid>
          {goodImgs.slice(0, limit).map(src => (
            <ImgSquare
              key={src}
              onClick={() => onPick(src)}
              style={{
                border:
                  src === currentImg ? "4px solid #026284" : "1px solid #e6e6e6"
              }}
              onLoad={(x: any) => {
                if (x.target.naturalWidth < 300) {
                  setBadImgs(b => ({ ...b, [src]: true }));
                }
              }}
              onError={() => {
                setBadImgs(b => ({ ...b, [src]: true }));
              }}
              src={src}
              alt="recipe blog choice"
            />
          ))}
          <NoImage
            style={{
              border: !currentImg ? "4px solid #70A3B7" : undefined
            }}
            onClick={() => onPick(null)}
          >
            no image
          </NoImage>
          {limit < goodImgs.length ? (
            <div
              style={{
                cursor: "pointer",
                color: "#3F7C94",
                textAlign: "right",
                gridColumn: "-1/1",
                marginTop: "-16px"
              }}
              onClick={() => setLimit(limit + CHUNK_SIZE)}
            >
              more
            </div>
          ) : null}
        </Grid>
      </div>
      {children(goodImgs.length)}
    </>
  );
};
