import React from "react";
import { RecipePreviewFragment } from "@saffron/controllers";

import { AddGroceryItemsModal } from "./AddGroceryItemsModal";
import Icon from "../../../ui/Icon";
import { AddGroceryPath } from "../../../ui/Icon/svgs/AddGroceryPath";

interface State {
  open: boolean;
}

interface Props {
  recipeName: string;
  recipeId: string;
  ingredients: RecipePreviewFragment["ingredients"];
}

export class AddGroceryItemsRicon extends React.PureComponent<Props, State> {
  state = {
    open: false
  };

  toggleOpen = () => {
    this.setState(state => ({
      open: !state.open
    }));
  };

  render() {
    return (
      <React.Fragment>
        <Icon
          name="addToList"
          viewBox="1 0 21 22"
          path={<AddGroceryPath />}
          template="recipe"
          tooltipText="add ingredients to grocery list"
          onClick={this.toggleOpen}
          arrow
        />
        {this.state.open && (
          <AddGroceryItemsModal
            open={this.state.open}
            toggleModal={this.toggleOpen}
            {...this.props}
          />
        )}
      </React.Fragment>
    );
  }
}
