import * as React from "react";
import { useUsersCookbookIsSharedWithQuery } from "@saffron/controllers";
import { theme } from "../../../ui/theme";

interface Props {
  cookbookId: string;
}

export const UsersSharedWith: React.FC<Props> = ({ cookbookId }) => {
  const { data } = useUsersCookbookIsSharedWithQuery({
    variables: { cookbookId }
  });
  return (
    <div style={{ overflowY: "auto", flex: 1, marginTop: "1em" }}>
      {data &&
        data.usersCookbookIsSharedWith &&
        data.usersCookbookIsSharedWith.users.map(u => (
          <div key={u.id}>
            Shared with{" "}
            <strong>
              {u.firstName} {u.lastName}
            </strong>
          </div>
        ))}
      {data &&
        data.usersCookbookIsSharedWith &&
        !data.usersCookbookIsSharedWith.users.length && (
          <div style={{ fontSize: "0.875rem", fontFamily: theme.fonts.ui }}>
            You'll be able to see who adds the cookbook to their account
            below...
          </div>
        )}
    </div>
  );
};
