import dayjs, { Dayjs } from "dayjs";
import startOfWeek from "date-fns/startOfWeek";

export const createWeekString = (dt: Dayjs, validStartOfWeek: number) => {
  const startOfWeekDt = dayjs(
    startOfWeek(dt.toDate(), {
      weekStartsOn: validStartOfWeek as any
    })
  );
  const endOfWeek = startOfWeekDt.add(6, "day");

  if (startOfWeekDt.month() !== endOfWeek.month()) {
    if (startOfWeekDt.year() !== endOfWeek.year()) {
      return `${startOfWeekDt.format(
        "MMM"
      )} ${startOfWeekDt.year()} - ${endOfWeek.format(
        "MMM"
      )} ${endOfWeek.year()}`;
    }
    return `${startOfWeekDt.format("MMM")} - ${endOfWeek.format(
      "MMM"
    )} ${dt.year()}`;
  }

  return `${dt.format("MMMM")} ${dt.year()}`;
};
