import React from "react";
import styled from "styled-components";
import onClickOutside from "react-onclickoutside";

interface Props {
  onRequestClose: () => void;
  onSymbol: (text: string) => void;
}

const symbols = ["°", "©", "×", "՚", "”", "é", "è", "î", "ñ"];

const SymbolButton = styled("span")`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #eceeef;
    border-radius: 3px;
  }
`;

const Container = styled("div")`
  position: absolute;
  max-height: 180px;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #bfbdbd;
  display: grid;
  overflow: auto;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  justify-items: center;
  column-gap: 0.5em;
  row-gap: 0.5em;
`;

class C extends React.PureComponent<Props> {
  containerRef: any;

  constructor(props: Props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("keyup", this.handleKeyUp, false);
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.handleKeyUp, false);
  }

  handleKeyUp = (e: any) => {
    if (e.key === "Escape") {
      this.props.onRequestClose();
    }
  };

  handleClickOutside = () => {
    this.props.onRequestClose();
  };

  render() {
    return (
      <Container ref={this.containerRef}>
        {symbols.map(x => (
          <SymbolButton
            key={x}
            onClick={() => {
              this.props.onRequestClose();
              Promise.resolve().then(() => {
                this.props.onSymbol(x);
              });
            }}
          >
            {x}
          </SymbolButton>
        ))}
      </Container>
    );
  }
}

export const SymbolModal = (onClickOutside(C as any) as any) as React.SFC<
  Props
>;
