import React, { useState } from "react";
import { Modal } from "../../../ui/Modal";
import { theme } from "../../../ui/theme";
import { IngredientUnitSection } from "./IngredientUnitSection";
import {
  isUnitWeight,
  unitWeightMap,
  gramsPerCupMap,
  convertStringAmountEstimate,
  normalizeUnitMap,
  convertibleVolumes,
  isUnitVolumeConvertible,
  IngredientFormValue
} from "@saffron/common";
import { DivButton } from "../../../ui/DivButton";
import { useCreateUnitRows } from "./useCreateUnitRows";
import { PrettyLink } from "../../../ui/PrettyLink";

type Ig = IngredientFormValue;

interface ConvertUnitsModalProps {
  isOpen: boolean;
  ingredients: Ig[];
  tmpIdMap: Record<string, number>;
  onRequestClose: () => void;
  onConvertedIngredients: (igs: Ig[]) => void;
}

const volumeOptions = [
  ...Object.keys(convertibleVolumes).map(x => ({
    key: x,
    value: x,
    text: x + "s"
  })),
  ...Object.keys(gramsPerCupMap).map(k => {
    return { key: k, value: k, text: `grams (${k})` };
  })
];

const weightOptions = [
  ...Object.keys(unitWeightMap).map(x => ({
    key: x,
    value: x,
    text: x + "s"
  })),
  ...Object.keys(gramsPerCupMap).map(k => {
    return { key: k, value: k, text: `cups (${k})` };
  })
];

const isVolumeIg = (ig: Ig) => "unit" in ig && isUnitVolumeConvertible(ig.unit);
const isWeightIg = (ig: Ig) => "unit" in ig && isUnitWeight(ig.unit);

export const ConvertUnitsModal: React.FC<ConvertUnitsModalProps> = ({
  isOpen,
  ingredients,
  onRequestClose,
  onConvertedIngredients
}) => {
  const [converted, setConverted] = useState<Record<number, boolean>>({});
  const [localIngredients, setLocalIngredients] = useState(ingredients);
  const [weightChecked, setWeightChecked] = useState<Record<number, boolean>>(
    {}
  );
  const [volumeChecked, setVolumeChecked] = useState<Record<number, boolean>>(
    {}
  );

  const allVolumeChecked = localIngredients.every(
    (ig, i) => !isVolumeIg(ig) || volumeChecked[i]
  );
  const allWeightChecked = localIngredients.every(
    (ig, i) => !isWeightIg(ig) || weightChecked[i]
  );

  const volumeRows = useCreateUnitRows({
    localIngredients,
    checked: volumeChecked,
    converted,
    isValid: isVolumeIg,
    onCheck: i =>
      setVolumeChecked({
        ...volumeChecked,
        [i]: !volumeChecked[i]
      })
  });

  const weightRows = useCreateUnitRows({
    localIngredients,
    checked: weightChecked,
    converted,
    isValid: isWeightIg,
    onCheck: i =>
      setWeightChecked({
        ...weightChecked,
        [i]: !weightChecked[i]
      })
  });

  if (!weightRows.length && !volumeRows.length) {
    return (
      <Modal
        variant="skinny"
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        mainButtonText="OK"
        mainButtonClick={onRequestClose}
      >
        <div
          style={{
            fontFamily: theme.uiFontStack,
            marginBottom: 30
          }}
        >
          <div>
            <div
              style={{
                fontSize: "1.125rem"
              }}
            >
              No convertible units found in this recipe.
            </div>
            <div>
              <PrettyLink
                href="https://youtu.be/jDq5dTJAekI"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "1.125rem" }}
              >
                learn more
              </PrettyLink>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      content={{ overflowY: "auto" }}
      variant="skinny"
      title="Unit Conversion"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      mainButtonText="SAVE CONVERSION"
      mainButtonClick={() => {
        onConvertedIngredients(
          localIngredients.map((x, i) => (converted[i] ? x : ingredients[i]))
        );
      }}
    >
      <div style={{ color: "#444", fontFamily: theme.fonts.ui }}>
        Select ingredients to convert.
        {Object.keys(converted).length ? (
          <DivButton
            onClick={() => {
              setWeightChecked({});
              setVolumeChecked({});
              setConverted({});
              setLocalIngredients(ingredients);
            }}
            style={{ color: "#026284", fontSize: 16 }}
          >
            &nbsp; reset
          </DivButton>
        ) : null}
      </div>
      {volumeRows.length ? (
        <IngredientUnitSection
          title="Volume"
          allChecked={allVolumeChecked}
          checked={volumeChecked}
          onCheckAll={() => {
            const newChecked: Record<number, boolean> = {};
            localIngredients.forEach((ig, i) => {
              if (isVolumeIg(ig)) {
                newChecked[i] = !allVolumeChecked;
              }
            });
            setVolumeChecked(newChecked);
          }}
          optionGroups={volumeOptions}
          onUnitChange={unit => {
            const estimateConversion = unit in gramsPerCupMap;
            const newConverted = { ...converted };
            setLocalIngredients(
              localIngredients.map((ig, i) => {
                if (
                  "amount" in ig &&
                  ig.amount &&
                  ig.unit &&
                  isVolumeIg(ig) &&
                  volumeChecked[i] &&
                  normalizeUnitMap[ig.unit] !== unit
                ) {
                  newConverted[i] = true;
                  return {
                    ...ig,
                    ...(estimateConversion
                      ? convertStringAmountEstimate({
                          amount: ig.amount,
                          foodType: unit as any,
                          currentUnit: ig.unit,
                          toUnit: "gram"
                        })
                      : convertStringAmountEstimate({
                          amount: ig.amount,
                          currentUnit: ig.unit,
                          toUnit: unit
                        }))
                  };
                }

                return ig;
              })
            );
            setConverted(newConverted);
            setVolumeChecked({});
          }}
        >
          {volumeRows}
        </IngredientUnitSection>
      ) : null}
      {weightRows.length ? (
        <IngredientUnitSection
          title="Weight"
          allChecked={allWeightChecked}
          checked={weightChecked}
          onCheckAll={() => {
            const newChecked: Record<number, boolean> = {};
            localIngredients.forEach((ig, i) => {
              if (isWeightIg(ig)) {
                newChecked[i] = !allWeightChecked;
              }
            });
            setWeightChecked(newChecked);
          }}
          optionGroups={weightOptions}
          onUnitChange={unit => {
            const estimateConversion = unit in gramsPerCupMap;
            const newConverted = { ...converted };
            setLocalIngredients(
              localIngredients.map((ig, i) => {
                if (
                  "amount" in ig &&
                  ig.amount &&
                  ig.unit &&
                  isWeightIg(ig) &&
                  weightChecked[i] &&
                  normalizeUnitMap[ig.unit] !== unit
                ) {
                  newConverted[i] = true;
                  return {
                    ...ig,
                    ...(estimateConversion
                      ? convertStringAmountEstimate({
                          amount: ig.amount,
                          foodType: unit as any,
                          currentUnit: ig.unit,
                          toUnit: "cup"
                        })
                      : convertStringAmountEstimate({
                          amount: ig.amount,
                          currentUnit: ig.unit,
                          toUnit: unit
                        }))
                  };
                }

                return ig;
              })
            );
            setConverted(newConverted);
            setWeightChecked({});
          }}
        >
          {weightRows}
        </IngredientUnitSection>
      ) : null}
    </Modal>
  );
};
