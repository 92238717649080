import React from "react";
import {
  EditorDiv,
  EditorBar,
  EditorBarLeft,
  EditorBlockButton
} from "../ui/EditorComponents";
import { Field } from "formik";
import { SymbolPicker } from "../../../../shared/draft-utils/SymbolPicker";
import { ReverseOutlineButton } from "../../../../../ui/Button";
import { InstructionsDraftField } from "../../../../shared/formFields/InstructionsDraftField";

export const SlateInstructionsEditor: React.FC<{ handleSubmit: any }> = ({
  handleSubmit
}) => {
  return (
    <EditorDiv>
      <Field
        name="instructions"
        placeholder="Paste steps..."
        renderHeader={({ toggleHeader, insertText }: any) => (
          <>
            <EditorBar style={{ marginTop: "2em" }}>
              <EditorBarLeft>
                <EditorBlockButton onMouseDown={toggleHeader}>
                  Header
                </EditorBlockButton>
                <SymbolPicker onSymbol={insertText} />
              </EditorBarLeft>
              <ReverseOutlineButton type="button" onClick={handleSubmit}>
                SAVE INSTRUCTIONS
              </ReverseOutlineButton>
            </EditorBar>
          </>
        )}
        component={InstructionsDraftField}
      />
    </EditorDiv>
  );
};
