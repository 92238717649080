import React from "react";
import { Formik, Field } from "formik";
import { Modal } from "../../../../ui/Modal";
import { validRecipeImportUrl } from "@saffron/common";
import { FormInput } from "../../../shared/formFields/FormInput";
import { theme } from "../../../../ui/theme";
import { Select } from "../../../../ui/Select";
import { SelectOptions } from "../../../../ui/Select/SelectOptions";
import { useLocalStorage } from "../../../../utils/useLocalStorage";
import { PrettyLink } from "../../../../ui/PrettyLink";

interface ImportRecipeModalProps {
  submit: (
    name: string,
    unitToConvertTo: "imperial" | "metric" | "original"
  ) => Promise<null>;
  closeModal: () => void;
  open: boolean;
}

const options: SelectOptions[] = [
  {
    value: "imperial",
    text: (
      <span>
        convert units to <strong>imperial</strong>
      </span>
    )
  },
  {
    value: "metric",
    text: (
      <span>
        convert units to <strong>metric</strong>
      </span>
    )
  },
  {
    value: "original",
    text: (
      <span>
        keep <strong>original units</strong>
      </span>
    )
  }
];

export const ImportRecipeModal: React.FC<ImportRecipeModalProps> = ({
  submit,
  closeModal,
  open
}) => {
  const [unitToConvertTo, setUnitToConvertTo] = useLocalStorage(
    "unit-preference-for-conversions",
    "original"
  );
  return (
    <Formik
      onSubmit={async ({ name }, { setSubmitting, resetForm }) => {
        try {
          await submit(name, unitToConvertTo as any);
          resetForm();
          closeModal();
        } catch {
          setSubmitting(false);
        }
      }}
      initialValues={{ name: "" }}
      validationSchema={validRecipeImportUrl}
      validateOnBlur={false}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, resetForm }) => (
        <Modal
          isOpen={open}
          onRequestClose={() => {
            resetForm();
            closeModal();
          }}
          mainButtonClick={handleSubmit}
          mainButtonText="IMPORT"
          loading={isSubmitting}
        >
          <>
            <div
              style={{
                fontFamily: theme.uiFontStack,
                marginBottom: 30
              }}
            >
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    fontSize: "1.125rem"
                  }}
                >
                  Paste URL to import recipe.
                </div>
                &nbsp;
                <div>
                  <PrettyLink
                    href="https://youtu.be/ev7BXFwNrAs"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ fontSize: "1.125rem" }}
                  >
                    learn more
                  </PrettyLink>
                </div>
              </div>
            </div>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%"
              }}
              onSubmit={handleSubmit}
            >
              <Field
                placeholder="url"
                name="name"
                component={FormInput}
                autoFocus
                autoComplete="off"
              />
              <Select
                style={{ marginTop: 36 }}
                value={unitToConvertTo}
                onChange={v => setUnitToConvertTo(v)}
                options={options}
              />
            </form>
          </>
        </Modal>
      )}
    </Formik>
  );
};
