import React from "react";
import { Formik } from "formik";
import { Modal } from "../../ui/Modal";
import { SectionSelect } from "../recipe/shared/RecipeForm/SectionSelect";

interface Props {
  open: boolean;
  onRequestClosed: () => void;
  onSubmit: (x: { cookbookId: string; sectionId: string }) => Promise<void>;
  title: string;
  buttonText: string;
  sectionId: string;
  cookbookId: string;
  loading?: boolean;
}

export const PickSectionModal: React.FC<Props> = ({
  onSubmit,
  open,
  onRequestClosed,
  title,
  buttonText,
  sectionId,
  cookbookId,
  loading
}) => {
  return (
    <>
      <Formik
        onSubmit={async (data, { resetForm }) => {
          await onSubmit(data as { cookbookId: string; sectionId: string });
          onRequestClosed();
          resetForm();
        }}
        initialValues={{ cookbookId, sectionId }}
        validate={v => {
          const errors: any = {};

          if (!v.cookbookId) {
            errors.cookbookId = "required";
          }

          if (!v.sectionId) {
            errors.sectionId = "required";
          }

          return errors;
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, handleSubmit, resetForm }) => (
          <Modal
            title={title}
            mainButtonText={buttonText}
            mainButtonClick={() => {
              handleSubmit();
            }}
            isOpen={open}
            onRequestClose={() => {
              onRequestClosed();
              resetForm();
            }}
            loading={loading}
          >
            <div style={{ marginTop: "1em" }}>
              <SectionSelect modalView cookbookId={values.cookbookId} />
            </div>
          </Modal>
        )}
      </Formik>
    </>
  );
};
