import { MeUserFragment } from "@saffron/controllers";
import React from "react";
import { OutlineButton } from "../../../ui/Button";
import { openPaddleModal } from "../../../utils/openPaddleModal";

interface Props {
  user: MeUserFragment;
  yearly: boolean;
}

export const StartSubscriptionButton: React.FC<Props> = ({
  children,
  user,
  yearly
}) => {
  return (
    <OutlineButton
      color="gold"
      onClick={() => {
        openPaddleModal(user, yearly);
      }}
      type="button"
      style={{ width: "100%" }}
    >
      {children}
    </OutlineButton>
  );
};
