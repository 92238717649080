import {
  ImportRecipeFromWebsiteMutation,
  useImportRecipeFromTextMutation,
  useImportRecipeFromWebsiteMutation,
  convertUnits
} from "@saffron/controllers";
import React, { useRef, useState } from "react";
import GhostButton from "../../../../ui/Button";
import { TextArea } from "../../../../ui/Input";
import { Modal } from "../../../../ui/Modal";
import { PrettyLink } from "../../../../ui/PrettyLink";
import { theme } from "../../../../ui/theme";
import { cropPictureObjectFitCover } from "../../../shared/cropPicture";
import { ImportRecipeModal } from "./ImportRecipeModal";

type Values = ImportRecipeFromWebsiteMutation["importRecipeFromWebsite"] & {
  croppedBase64Img?: string | null | undefined;
};

interface Props {
  onImport: (values: Values) => void;
  onImage: (base64: string) => void;
  onPictureUrl: (base64: string) => void;
  textImportOpen?: boolean;
  webImportOpen?: boolean;
}

export const ImportRecipeButton: React.FC<Props> = ({
  onImport,
  onImage,
  onPictureUrl,
  textImportOpen = false,
  webImportOpen = false
}) => {
  const [importRecipe] = useImportRecipeFromWebsiteMutation();
  const [importFromText, { loading }] = useImportRecipeFromTextMutation();
  const [open, setOpen] = useState(webImportOpen);
  const [openText, setOpenText] = useState(textImportOpen);
  const [text, setText] = useState("");
  const openTextRef = useRef(openText);
  openTextRef.current = openText;
  const pictureUrlRef = useRef<string>("");
  const base64ImageRef = useRef<string>("");

  if (!openText) {
    pictureUrlRef.current = "";
    base64ImageRef.current = "";
  }

  const closeTextModal = () => {
    setOpenText(false);
    setText("");
  };

  return (
    <>
      <Modal
        isOpen={openText}
        onRequestClose={closeTextModal}
        mainButtonText="IMPORT"
        loading={loading}
        mainButtonClick={async () => {
          if (!text || !text.trim()) {
            return;
          }
          const response = await importFromText({ variables: { text } });
          if (response && response.data) {
            if (pictureUrlRef.current) {
              onImport({
                ...response.data.importRecipeFromText,
                pictureUrl: pictureUrlRef.current
              });
            } else if (base64ImageRef.current) {
              onImport({
                ...response.data.importRecipeFromText,
                croppedBase64Img: base64ImageRef.current
              });
            } else {
              onImport(response.data.importRecipeFromText);
            }
            closeTextModal();
          }
        }}
      >
        <div
          style={{
            fontFamily: theme.uiFontStack,
            marginBottom: 30
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{
                fontSize: "1.125rem"
              }}
            >
              Paste text for a single recipe.
            </div>
            &nbsp;
            <div>
              <PrettyLink
                href="https://youtu.be/kKJ5PoU3HKo"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "1.125rem" }}
              >
                Learn More
              </PrettyLink>
            </div>
          </div>
        </div>
        <TextArea
          style={{ maxHeight: 300 }}
          autoFocus
          value={text}
          placeholder="text"
          // hint="paste text here..."
          onPaste={async event => {
            // https://stackoverflow.com/questions/6333814/how-does-the-paste-image-from-clipboard-functionality-work-in-gmail-and-google-c
            const items = event.clipboardData.items;
            // find pasted image among pasted items
            let blob = null;
            for (let i = 0; i < items.length; i++) {
              if (items[i].type.indexOf("image") === 0) {
                blob = items[i].getAsFile();
                break;
              } else if (items[i].type.indexOf("text/html") === 0) {
                const domparser = new DOMParser();
                const s = await new Promise<String>(res =>
                  items[i].getAsString(html => {
                    res(html);
                  })
                );
                if (!s || typeof s !== "string") {
                  continue;
                }
                const doc = domparser.parseFromString(s, "text/html");
                const src = doc.querySelector("img")?.src;
                if (src) {
                  if (openTextRef.current) {
                    pictureUrlRef.current = src;
                  } else {
                    onPictureUrl(src);
                  }
                  break;
                }
              }
            }
            // load image if there is a pasted image
            if (blob !== null) {
              var reader = new FileReader();
              reader.onload = e => {
                if (e.target && e.target.result) {
                  const image = new Image();
                  image.onload = () => {
                    const croppedBase64 = cropPictureObjectFitCover(image, {
                      height: 800,
                      width: 1600
                    });
                    if (croppedBase64) {
                      if (openTextRef.current) {
                        base64ImageRef.current = croppedBase64;
                      } else {
                        onImage(croppedBase64);
                      }
                    }
                  };
                  if (typeof e.target.result === "string") {
                    image.src = e.target.result;
                  }
                }
              };
              reader.readAsDataURL(blob);
            }
          }}
          onChange={(e: any) => setText(e.target.value)}
        />
      </Modal>
      <ImportRecipeModal
        submit={async (url, unitToConvertTo) => {
          url = url.trim();
          const response = await importRecipe({
            variables: {
              url
            }
          });

          if (response && response.data) {
            if (unitToConvertTo === "original") {
              onImport(response.data.importRecipeFromWebsite);
            } else {
              onImport({
                ...response.data.importRecipeFromWebsite,
                ingredients: convertUnits(
                  response.data.importRecipeFromWebsite.ingredients,
                  unitToConvertTo
                )
              });
            }
          }

          return null;
        }}
        open={open}
        closeModal={() => setOpen(false)}
      />
      <div style={{ display: "flex", width: "100%" }}>
        <GhostButton
          variant="light grey"
          style={{ flex: 1, marginRight: 6 }}
          onClick={() => setOpen(true)}
        >
          WEBSITE
        </GhostButton>
        <GhostButton
          variant="light grey"
          style={{ flex: 1, marginLeft: 6 }}
          onClick={() => setOpenText(true)}
        >
          TEXT
        </GhostButton>
      </div>
    </>
  );
};
