import  React from "react";
import { CardLogoLayout } from "../../ui/Layout/CardLogoLayout";

export default () => (
  <CardLogoLayout>
    <div style={{ padding: "2em 3em", paddingTop: "1em" }}>
      Please check your email to confirm your account.
    </div>
  </CardLogoLayout>
);
