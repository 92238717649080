import { ViewRecipeSearchQuery } from "@saffron/controllers";
import create from "zustand";
import { SearchFormValues } from "../../shared/RecipeSearch/SearchForm";

export const useFindRecipeState = create<{
  variables: SearchFormValues;
  recipe: ViewRecipeSearchQuery["recipeSearch"]["recipes"][0] | null;
  setCurrentRecipe: (
    recipe: ViewRecipeSearchQuery["recipeSearch"]["recipes"][0]
  ) => void;
  onSearch: (variables: SearchFormValues) => void;
}>(set => ({
  recipe: null,
  variables: {
    searchQuery: "",
    recipesNotInCookbooks: false
  },
  setCurrentRecipe: recipe => set({ recipe }),
  onSearch: variables => set({ variables })
}));
