import { Container } from "unstated";

interface State {
  open: boolean;
  amountMap: Record<string, number | null>;
  tmpAmount: string | null;
}

export class ScaleIgContainer extends Container<State> {
  state: State = {
    tmpAmount: null,
    open: false,
    amountMap: {}
  };

  setOpen = (open: boolean) => {
    this.setState({ open, tmpAmount: null });
  };

  setTmpAmount = (tmpAmount: string | null) => {
    this.setState({ tmpAmount });
  };

  removeScaling = (id: string) => {
    this.setState(s => ({
      amountMap: { ...s.amountMap, [id]: null },
      tmpAmount: null,
      open: false
    }));
  };

  setAmount = (id: string, amount: number | null) => {
    this.setState(s => ({
      amountMap: { ...s.amountMap, [id]: amount },
      tmpAmount: null,
      open: false
    }));
  };
}

export const scaleIgContainer = new ScaleIgContainer();
