import  React from "react";
import { FieldProps, getIn } from "formik";

import { AutoSuggest } from "../AutoSuggest";
import { ErrorText } from "../../../ui/ErrorText";

export class AutoSuggestFormInput extends React.Component<
  FieldProps<any> & {
    suggestions: string[];
    ignoreTouch?: boolean;
    containerStyle?: any;
  }
> {
  onChange = (_: any, text: string) => {
    const {
      field: { name }, // { name, value, onChange, onBlur }
      form: { setFieldValue } // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    } = this.props;

    setFieldValue(name, text);
  };

  render() {
    const {
      field, // { name, value, onChange, onBlur }
      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      ignoreTouch,
      containerStyle,
      ...props
    } = this.props;
    return (
      <div style={containerStyle}>
        <AutoSuggest
          {...field as any}
          {...props}
          onChange={this.onChange}
          value={field.value || ""}
        />
        <ErrorText>
          {ignoreTouch || getIn(touched, field.name)
            ? getIn(errors, field.name)
            : ""}
        </ErrorText>
      </div>
    );
  }
}
