import { useApolloClient } from "@apollo/client";
import { normalizeErrors } from "@saffron/common";
import {
  loginMutationOptions,
  meQuery,
  MeQuery,
  useLoginMutation
} from "@saffron/controllers";
import * as Cookie from "js-cookie";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { addCookbookToAccount } from "../../../utils/addCookbookToAccount";
import { clearLocalStorage } from "../../../utils/clearLocalStorage";
import { pushLocation } from "../../../utils/pushLocation";
import { ADD_COOKBOOK_COOKIE } from "../../cookbook/UnlistedCookbook";
import { LoginPhoneView } from "./LoginPhoneView";
import { LoginView } from "./LoginView";
import { getPhoneModel } from "./utils/getPhoneModel";

const LoginConnector: React.FC = () => {
  const [isOnPhone] = useState(() => !!getPhoneModel());
  const location = useLocation<{ next?: string }>();
  const notAuthed = location.pathname.includes("/na");
  const [login] = useLoginMutation();
  const [loading, setLoading] = useState(true);
  const client = useApolloClient();

  useEffect(() => {
    if (isOnPhone || notAuthed) {
      client.resetStore();
      clearLocalStorage();
      setLoading(false);
      return;
    }
    client
      .query<MeQuery>({
        query: meQuery,
        fetchPolicy: "network-only"
      })
      .then(me => {
        if (me.data?.me.user) {
          pushLocation("/browse", "replace");
        } else {
          client.resetStore();
          clearLocalStorage();
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNextLocation = () => {
    if (location.state && location.state.next) {
      return location.state.next;
    } else {
      return "/browse";
    }
  };

  const redirectUser = () => {
    pushLocation(getNextLocation(), "replace");
  };

  if (loading) {
    return null;
  }

  if (isOnPhone) {
    return <LoginPhoneView />;
  }

  return (
    <LoginView
      handleLogin={async values => {
        const response = await login(
          loginMutationOptions({
            input: {
              ...values,
              email: values.email && values.email.trim()
            }
          })
        );
        if (response.data?.login.errors) {
          return normalizeErrors(response.data.login.errors);
        }

        const addCookbookOptions = Cookie.getJSON(ADD_COOKBOOK_COOKIE);

        if (addCookbookOptions) {
          Cookie.remove(ADD_COOKBOOK_COOKIE);
          try {
            await addCookbookToAccount(addCookbookOptions);
          } catch {}
          pushLocation("/browse", "replace");
          return;
        }

        redirectUser();
        return null;
      }}
    />
  );
};

export default LoginConnector;
