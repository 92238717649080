import { DateSize } from "@saffron/controllers";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { Box } from "../../../ui/Box";
import GhostButton from "../../../ui/Button";
import { Flex } from "../../../ui/Flex";
import { H3 } from "../../../ui/Heading";
import Icon from "../../../ui/Icon";
import { MyLink } from "../../../ui/MyLink";
import { Select } from "../../../ui/Select";
import { DaysHeader } from "../viewMenuPlanner/components/DaysHeader";
import { theme } from "../../../ui/theme";

interface Props {
  text: string;
  next: () => void;
  previous: () => void;
  todayLinkTo: string;
  onStartOfWeek: (x: number) => void;
  children?: React.ReactNode;
  defaultStartOfWeek: number;
  dateSize: DateSize;
  onDateSize: (size: DateSize) => void;
  date: Dayjs;
  onDate: (dt: Dayjs) => void;
  validStartOfWeek: number;
}

const ChildrenContainer = styled.div`
  margin-left: auto;
  & > div {
    margin-left: 20px;
  }
  display: flex;
  align-items: center;
`;

// export const dateHeaderMediaQuery =
//   "@media only screen and (max-width: 1050px)";

const OuterContainer = styled.div({
  zIndex: 3,
  minWidth: 750,
  paddingTop: 10,
  position: "sticky",
  top: 0,
  backgroundColor: "#fafafa"
});

const HeaderContainer = styled.div({
  display: "flex",
  // [dateHeaderMediaQuery]: {
  //   flexDirection: "column"
  // },
  paddingBottom: 39
});

const FirstGroup = styled.div({
  display: "flex"
  // [dateHeaderMediaQuery]: {
  //   paddingBottom: "1em"
  // }
});

const CircleHover = styled.div({
  width: "2em",
  height: "2em",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.hoverColor
  }
});

export const DateHeader: React.FC<Props> = ({
  text,
  next,
  previous,
  children,
  todayLinkTo,
  defaultStartOfWeek,
  onStartOfWeek,
  onDateSize,
  dateSize,
  date,
  onDate,
  validStartOfWeek
}) => {
  return (
    <OuterContainer>
      <HeaderContainer>
        <Flex>
          <MyLink to={todayLinkTo}>
            <GhostButton>Today</GhostButton>
          </MyLink>
          <Flex mx={15} alignItems="center">
            <CircleHover onClick={previous} style={{ marginRight: "1em" }}>
              <Icon
                width="1em"
                height="1em"
                fill="#666666"
                name="thinLeftArrow"
                template="arrow"
              />
            </CircleHover>
            <CircleHover onClick={next}>
              <Icon
                width="1em"
                height="1em"
                fill="#666666"
                name="thinRightArrow"
                template="arrow"
              />
            </CircleHover>
          </Flex>
          <DatePicker
            selected={date.toDate()}
            onChange={dt => {
              if (!dt) {
                return;
              }
              onDate(dayjs(Array.isArray(dt) ? dt[0] : dt));
            }}
            customInput={
              <H3
                style={{ cursor: "pointer", color: "#444" }}
                m={0}
                fontFamily="ui"
              >
                {text}
              </H3>
            }
          />
        </Flex>
        <ChildrenContainer>
          {children}
          <FirstGroup>
            <Box pr="1em">
              <Select
                value={dateSize}
                onChange={onDateSize as any}
                variant="button-like"
                options={[
                  {
                    key: "week",
                    text: "Week",
                    value: "week"
                  },
                  {
                    key: "month",
                    text: "Month",
                    value: "month"
                  }
                ]}
              />
            </Box>
            <Box mr="2em">
              <Select
                value={"" + defaultStartOfWeek}
                onChange={value => onStartOfWeek(parseInt(value))}
                variant="button-like"
                label="START WEEK ON"
                options={[
                  {
                    key: "7",
                    text: "Today",
                    value: "7"
                  },
                  {
                    key: "0",
                    text: "Sunday",
                    value: "0"
                  },
                  {
                    key: "1",
                    text: "Monday",
                    value: "1"
                  },
                  {
                    key: "2",
                    text: "Tuesday",
                    value: "2"
                  },
                  {
                    key: "3",
                    text: "Wednesday",
                    value: "3"
                  },
                  {
                    key: "4",
                    text: "Thursday",
                    value: "4"
                  },
                  {
                    key: "5",
                    text: "Friday",
                    value: "5"
                  },
                  {
                    key: "6",
                    text: "Saturday",
                    value: "6"
                  }
                ]}
              />
            </Box>
          </FirstGroup>
        </ChildrenContainer>
      </HeaderContainer>
      <DaysHeader date={date} validStartOfWeek={validStartOfWeek} />
    </OuterContainer>
  );
};
