import React, { useState } from "react";
import { Modal } from "../../ui/Modal";
import { YearlyToggle } from "../../ui/YearlyToggle";
import {
  useMeQuery,
  useChangeSubscriptionPlanMutation
} from "@saffron/controllers";
import {
  statusToNextPlanInfo,
  UPGRADE_TO_SEASONED_COOK_OPERATION,
  STANDARD_SUBSCRIPTION_STATUS
} from "@saffron/common";
import { notificationState } from "../misc/notifications/NotificationState";
import { openPaddleModal } from "../../utils/openPaddleModal";

interface UpgradeModalProps {
  title?: string;
  children: (
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => React.ReactNode;
}

export const UpgradeModal: React.FC<UpgradeModalProps> = ({
  title = "Confirm Upgrade",
  children
}) => {
  const { data } = useMeQuery();
  const [yearly, setYearly] = useState(true);
  const [open, setOpen] = useState(false);
  const [
    changeSubscriptionPlan,
    { loading }
  ] = useChangeSubscriptionPlanMutation();
  const nextPlan = data?.me.user?.role.type
    ? statusToNextPlanInfo[
        data?.me.user?.role.type as keyof typeof statusToNextPlanInfo
      ]
    : undefined;

  const upgradeCost =
    nextPlan && nextPlan.key !== "custom"
      ? nextPlan.cost + (yearly ? 0 : 1)
      : 0;
  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        title={title}
        secondaryButtonClick={() => setOpen(false)}
        secondaryButtonText="CANCEL"
        mainButtonClick={() => {
          if (data?.me.user?.role.type === STANDARD_SUBSCRIPTION_STATUS) {
            changeSubscriptionPlan({
              variables: {
                operation: UPGRADE_TO_SEASONED_COOK_OPERATION,
                yearly
              }
            }).then(() => {
              notificationState.send({
                variant: "success",
                text: "upgrade successful"
              });
              setOpen(false);
            });
          } else if (data?.me.user?.role.type) {
            openPaddleModal(data.me.user, yearly);
          } else {
            notificationState.send({
              variant: "error",
              text: "something went wrong, refresh and try again"
            });
          }
        }}
        mainButtonText="UPGRADE"
        loading={loading}
      >
        {nextPlan && nextPlan.key !== "custom" ? (
          <div>
            You can upgrade to expand your storage to{" "}
            {nextPlan.recipeLimit.toLocaleString()} recipes.
          </div>
        ) : null}
        <YearlyToggle
          noMarginBottom
          yearly={yearly}
          onSwitch={setYearly}
          cost={upgradeCost}
        />
      </Modal>
      {children(setOpen)}
    </>
  );
};
