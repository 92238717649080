import styled, { css } from "styled-components";
import { theme } from "./theme";
import { baseButton } from "./Button";

export const GoldSubHeading = styled("h3")`
  font-family: ${theme.fonts.secondary};
  margin-top: 68px;
  letter-spacing: 2.6px;
  color: #977535;
  text-align: center;
  margin-bottom: 0;
`;

export const GoldHeading = styled("h1")`
  font-family: ${theme.fonts.secondary};
  font-weight: 800;
  color: #977535;
  margin-top: 8px;
  line-height: 3.375rem;
  font-size: 4rem;
  text-align: center;
`;

const blackLandingPageButtonStyle = css`
  color: #d7be8e;
  background-color: #0d0d0d;
  border: solid 1px #fafafa;
  &:hover {
    background-color: #fafafa;
    color: #0d0d0d;
    border: 1px solid #0d0d0d;
  }
`;

const redLandingPageButtonStyle = css`
  color: #d7be8e;
  background-color: #531410;
  border: solid 1px #d7be8e;
  &:hover {
    background-color: #d7be8e;
    color: #531410;
  }
`;

const landingPageButtonStyleMap = {
  black: blackLandingPageButtonStyle,
  red: redLandingPageButtonStyle
};

type LPProps = {
  color?: keyof typeof landingPageButtonStyleMap;
};

export const LandingPageButton = styled("button")<LPProps>`
  ${baseButton};
  font-family: ${theme.fonts.secondary};
  margin-bottom: 100px;
  padding: 10px 2em;
  width: 100%;
  max-width: 350px;
  font-size: 1.25rem;
  ${p => landingPageButtonStyleMap[p.color || "red"]};
`;

export const HeroCaption = styled("div")`
  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;
