import React from "react";
import { PrettyLink } from "./PrettyLink";
import ReactLinkify from "react-linkify";

interface LinkifyProps {}

export const Linkify: React.FC<LinkifyProps> = ({ children }) => {
  return (
    <ReactLinkify
      componentDecorator={(href, content) => (
        <PrettyLink
          style={{
            color: "#444"
          }}
          target="_blank"
          rel="noopener noreferrer"
          href={href}
        >
          {content}
        </PrettyLink>
      )}
    >
      {children}
    </ReactLinkify>
  );
};
