import  React from "react";
import { FieldProps, getIn } from "formik";

import { SearchInput, SearchInputProps } from "../../../ui/Input/SearchInput";

export const SearchFormField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ignoreTouch,
  ...props
}: FieldProps<any> & SearchInputProps & { ignoreTouch?: boolean }) => (
  <SearchInput
    {...field}
    {...props}
    value={field.value || ""}
    errorText={
      ignoreTouch || getIn(touched, field.name) ? getIn(errors, field.name) : ""
    }
  />
);
