import React, { useState } from "react";
import { UnitPreferenceModal } from "../ui/UnitPreferenceModal";
import {
  UnitPreference,
  getUnitType,
  IngredientFormValue
} from "@saffron/common";
import { parensUnitConversion } from "@saffron/controllers";

type Ig = IngredientFormValue;

interface Options {
  onIngredients: (ingredients: Ig[]) => void;
}

export const useParensConversion = ({ onIngredients }: Options) => {
  const [modalOptions, setModalOptions] = useState<{
    ingredients: Ig[];
    textExamples: Record<UnitPreference, string>;
    takeParensUnit: ReturnType<typeof getUnitType>;
  } | null>(null);

  return {
    setModalOptions,
    modal: (
      <UnitPreferenceModal
        title="This recipe has 2 options for units, which do you like:"
        mainButtonClick={unitPreference => {
          if (unitPreference === "both") {
            onIngredients(modalOptions!.ingredients);
          } else {
            onIngredients(
              parensUnitConversion(
                modalOptions!.ingredients,
                unitPreference === modalOptions!.takeParensUnit
              )
            );
          }
          setModalOptions(null);
        }}
        isOpen={!!modalOptions}
        onRequestClose={() => {
          onIngredients(modalOptions!.ingredients);
          setModalOptions(null);
        }}
        textExamples={modalOptions?.textExamples}
        mainButtonText="SAVE"
      />
    )
  };
};
