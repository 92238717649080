import React, { CSSProperties } from "react";

interface CenterCheckboxContainerProps {
  style?: CSSProperties;
  checkbox: React.ReactNode;
}

export const CenterCheckboxContainer: React.FC<CenterCheckboxContainerProps> = ({
  checkbox,
  children,
  style
}) => {
  return (
    <div style={{ display: "flex", alignItems: "start", ...style }}>
      <div
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        &#8203;
        {checkbox}
      </div>
      <div>{children}</div>
    </div>
  );
};
