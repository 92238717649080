import  React from "react";

import { Flex } from "../Flex";
import NavBar from "../NavBar";
import { patternWidth } from "../theme";

interface Props {
  innerWidth?: string;
}

export class FullscreenLayout extends React.PureComponent<Props> {
  render() {
    const { innerWidth = 900 } = this.props;
    return (
      <Flex height="100%" width="100%">
        <NavBar />
        <div
          style={{
            marginLeft: patternWidth,
            height: "100%",
            width: "100%",
            display: "flex"
          }}
        >
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: innerWidth,
              height: "100%"
            }}
          >
            {this.props.children}
          </div>
        </div>
      </Flex>
    );
  }
}
