import { notificationState } from "../modules/misc/notifications/NotificationState";
import * as Sentry from "@sentry/browser";

function good() {
  notificationState.send({
    text: "successfully copied",
    variant: "success",
    icon: "clipboard"
  });
}

function bad() {
  notificationState.send({
    text: "failed to copy",
    variant: "error",
    icon: "clipboard"
  });
}

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    if (successful) {
      good();
    } else {
      bad();
    }
  } catch (err) {
    bad();
    Sentry.captureException(err);
  }

  document.body.removeChild(textArea);
}

export function copyToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function() {
      good();
    },
    function(err) {
      bad();
      Sentry.captureException(err);
    }
  );
}
